import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import ReactPaginate from 'react-paginate';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as XLSX from 'xlsx';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';

import ConfirmDelete from '../../../CommomPages/ConfirmDelete';
import LoadingSpinner from '../../../CommomPages/LoadingSpinner';
import { exportDataCountry, exportDataState, exportDataCity } from './ExportCountryStateCity';
import { getCountry, deleteCountry, createCountry, updateCountry, getState, createState, updateState, deleteState, getCity, createCity, updateCity, deleteCity } from '../../../Services/CountryStateCityService';
import { getEmployee } from '../../../Services/EmployeeManagementService';
import { getBEData, getBRData, getBUData } from '../../../Services/BusinessEntityNewService';

import './CountryStateCityCss.css';

import viewsvgIcon from '../../../assets/icon/Eye.svg';
import editsvgIcon from '../../../assets/icon/Edit.svg';
import deletesvgIcon from '../../../assets/icon/Delete.svg';
import nodataFound from '../../../assets/img/nodatafound.png';



function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};


const CountryStateCityPage = () => {
    const location = useLocation();
    const [tabActive, setTabActive] = useState('Country');
    const [activeScreen, setActiveScreen] = useState('AccountDetails');
    const [tabStatusActive, setTabStatusActive] = useState('Country');
    const [countryArray, setCountryArray] = useState([]);
    const [stateArray, setStateArray] = useState([]);
    const [stateFilter, setStateFilter] = useState([]);
    const [cityArray, setCityArray] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [pageCountry, setPageCountry] = useState(0);
    const [pageState, setPageState] = useState(0);
    const [pageCity, setPageCity] = useState(0);
    const [rowsCountryPerPage, setRowsCountryPerPage] = useState(5);
    const [rowsStatePerPage, setRowsStatePerPage] = useState(5);
    const [rowsCityPerPage, setRowsCityPerPage] = useState(5);
    const [countryName, setCountryName] = useState('');
    const [countrySubmit, setCountrySubmit] = useState(false);
    const [countryID, setCountryID] = useState(0);
    const [stateName, setStateName] = useState('');
    const [cityName, setCityName] = useState('');
    const [countrySelectID, setCountrySelectID] = useState(0);
    const [stateSelectID, setStateSelectID] = useState(0);
    const [stateSubmit, setStateSubmit] = useState(false);
    const [citySubmit, setCitySubmit] = useState(false);
    const [stateID, setStateID] = useState(0);
    const [cityID, setCityID] = useState(0);
    const [searchQueryCountry, setSearchQueryCountry] = useState('');
    const [searchQueryState, setSearchQueryState] = useState('');
    const [searchQueryCity, setSearchQueryCity] = useState('');
    const [isCountryView, setIsCountryView] = useState(false);
    const [isStateView, setIsStateView] = useState(false);
    const [isCityView, setIsCityView] = useState(false);
    const fileCountryInputRef = useRef(null);
    const fileStateInputRef = useRef(null);
    const fileCityInputRef = useRef(null);

    useEffect(() => {
        const queryParams = getQueryParams(location.search);
        const tabName = queryParams.get('tab');
        selectedTab(tabName);
        const fetchData = async () => {
            await getCoutryDetails();
        };
        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getQueryParams = (query) => {
        return new URLSearchParams(query);
    }

    const getCoutryDetails = async () => {
        setIsLoading(true);
        const CountryData = await getCountry();
        const modifiedCountryData = CountryData.map((country, index) => ({
            ...country,
            Rowid: index + 1
        }));

        setCountryArray(modifiedCountryData);
        await getStateDetails(modifiedCountryData);
        setIsLoading(false);
        setPageCountry(0);
    };

    const getStateDetails = async (_CountryValue) => {
        const StateData = await getState();
        const modifiedStateData = StateData.map((state, index) => ({
            countryName: _CountryValue.find(x => x.id === state.cntrY_ID).cntrY_NAME,
            ...state,
            Rowid: index + 1
        }));

        setStateArray(modifiedStateData);
        await getCityDetails(modifiedStateData);
        setIsLoading(false);
        setPageState(0);
    };

    const getCityDetails = async (_StateValue) => {
        const CityData = await getCity();
        const modifiedCityData = CityData.map((city, index) => ({
            stateName: _StateValue.find(x => x.id === city.statE_ID).statE_NAME,
            countryName: _StateValue.find(x => x.id === city.statE_ID).countryName,
            ...city,
            Rowid: index + 1
        }));

        setCityArray(modifiedCityData);
        setPageCity(0);
    };

    const DeleteCountry = async (contryID) => {
        const stateData = await getState();
        for (let state of stateData) {
            if (state.cntrY_ID === contryID) {
                toast.error(`This Country is associated with State. You can't delete it.`);
                return;
            }
        }

        const unitData = await getBUData();
        for (let unit of unitData) {
            if (unit.bU_CNTRY_ID === contryID) {
                toast.error(`This Country is associated with Unit. You can't delete it.`);
                return;
            }
        }

        const branchData = await getBRData();
        for (let unit of branchData) {
            if (unit.BR_CNTRY_ID === contryID) {
                toast.error(`This Country is associated with Branch. You can't delete it.`);
                return;
            }
        }

        const entityData = await getBEData(1);
        if (entityData.bE_CNTRY_ID === contryID) {
            toast.error(`This Country is associated with Entity. You can't delete it.`);
            return;
        }


        const empData = await getEmployee();
        for (let emp of empData) {
            const primaryAddressValue = JSON.parse(emp.primaryAddress);
            const secondaryAddressValue = JSON.parse(emp.secondaryAddress);
            if (primaryAddressValue.country === contryID || secondaryAddressValue.country === contryID) {
                toast.error(`This Country is associated with Entity. You can't delete it.`);
                return;
            }
        }

        const isConfirmed = await ConfirmDelete();
        if (isConfirmed) {
            setIsLoading(true);
            const response = await deleteCountry(contryID);
            if (response === "Deleted") {
                toast.success("Country Deleted Sucessfully");
                getCoutryDetails();
                setIsLoading(false);
            } else {
                setIsLoading(false);
                toast.error(response);
            }
        }
    };

    const addNewCountry = () => {
        setIsCountryView(false);
        setActiveScreen("CountryEdit");
        setCountryName('');
        setCountrySubmit(false);
        setCountryID(0);
    };

    const addnewState = () => {
        setIsStateView(false);
        setActiveScreen("StateEdit");
        setStateName('');
        setStateSubmit(false);
        setCountrySelectID(0);
        setStateID(0);
    };

    const addnewCity = () => {
        setIsCityView(false);
        setActiveScreen("CityEdit");
        setCityName('');
        setCitySubmit(false);
        setCountrySelectID(0);
        setStateSelectID(0);
        setCityID(0);
    };

    const editCountry = (countryID) => {
        setIsCountryView(false);
        setCountryID(countryID);
        const countryData = countryArray.filter(x => x.id === countryID);
        setCountryName(countryData[0].cntrY_NAME);
        setActiveScreen("CountryEdit");
    };

    const viewCountry = (countryID) => {
        setIsCountryView(true);
        setCountryID(countryID);
        const countryData = countryArray.filter(x => x.id === countryID);
        setCountryName(countryData[0].cntrY_NAME);
        setActiveScreen("CountryEdit");
    };

    const closeCountry = () => {
        setIsCountryView(false);
        setCountrySubmit(false);
        setPageCountry(0);
        setActiveScreen("AccountDetails");
    };

    const closeState = () => {
        setIsStateView(false);
        setStateSubmit(false);
        setPageState(0);
        setActiveScreen("AccountDetails");
    };

    const closeCity = () => {
        setIsCityView(false);
        setCitySubmit(false);
        setPageCity(0);
        setActiveScreen("AccountDetails");
    };

    const handleCountrySubmit = async (e) => {
        e.preventDefault();
        setCountrySubmit(true);
        const countryFilter = countryArray.filter(x => x.cntrY_NAME === countryName);
        const countryFilterUpdate = countryArray.filter(x => x.id === countryID);
        if (countryID === 0) {
            if (e.currentTarget.checkValidity() === false) {
                e.stopPropagation();
            } else if (countryFilter && countryFilter.length !== 0) {
                toast.error("Country name already exists.");
            }
            else if (countryName) {
                setIsLoading(true)
                const param = {
                    ID: countryID,
                    CNTRY_NAME: countryName,
                    CNTRY_CODE: "-"
                }
                const response = await createCountry(param);
                if (response === "Inserted") {
                    getCoutryDetails();
                    setIsLoading(false);
                    toast.success("Country Inserted Successfully");
                    closeCountry();
                } else {
                    toast.error(response);
                    setIsLoading(false);
                }
            }
            else {
                toast.error("Fill the Required Field");
            }
        } else if (countryID !== 0) {
            if (e.currentTarget.checkValidity() === false) {
                e.stopPropagation();
            } else if (countryFilter && countryFilter.length !== 0 && countryFilterUpdate[0].cntrY_NAME !== countryName) {
                toast.error("Country name already exists.");
            }
            else if (countryName) {
                setIsLoading(true)
                const param = {
                    ID: countryID,
                    CNTRY_NAME: countryName,
                    CNTRY_CODE: "-"
                }
                const response = await updateCountry(param);
                if (response === "Updated") {
                    getCoutryDetails();
                    setIsLoading(false);
                    toast.success("Country Updated Successfully");
                    setPageCountry(0);
                    closeCountry();
                } else {
                    toast.error(response);
                    setIsLoading(false);
                }
            }
            else {
                toast.error("Fill the Required Field");
            }
        }
    };

    const handleStateSubmit = async (e) => {
        e.preventDefault();
        setStateSubmit(true);
        const stateFilter = stateArray.find(x => x.statE_NAME === stateName && x.cntrY_ID === countrySelectID.id);
        const stateFilterUpdate = stateArray.filter(x => x.id === stateID);
        if (stateID === 0) {
            if (e.currentTarget.checkValidity() === false || countrySelectID === 0) {
                e.stopPropagation();
            } else if (stateFilter && stateFilter.length !== 0) {
                toast.error("State name already exists.");
            } else if (countrySelectID && stateName) {
                setIsLoading(true)
                const param = {
                    ID: stateID,
                    CNTRY_ID: countrySelectID.id,
                    STATE_NAME: stateName,
                    STATE_CODE: "-"
                }
                const response = await createState(param);
                if (response === "Inserted") {
                    getCoutryDetails();
                    setIsLoading(false);
                    toast.success("State Inserted Successfully");
                    closeState();
                } else {
                    toast.error(response);
                    setIsLoading(false);
                }
            }
            else {
                toast.error("Fill the Required Field");
            }
        } else if (stateID !== 0) {
            if (e.currentTarget.checkValidity() === false) {
                e.stopPropagation();
            } else if (stateFilter && stateFilter.length !== 0 && stateFilterUpdate[0].statE_NAME !== stateName) {
                toast.error("State name already exists.");
            } else if (countrySelectID && stateName) {
                setIsLoading(true)
                const param = {
                    ID: stateID,
                    CNTRY_ID: countrySelectID.id,
                    STATE_NAME: stateName,
                    STATE_CODE: "-"
                }
                const response = await updateState(param);
                if (response === "Updated") {
                    getCoutryDetails();
                    setIsLoading(false);
                    toast.success("State Updated Sucessfully");
                    closeState();
                } else {
                    toast.error(response);
                    setIsLoading(false);
                }
            }
            else {
                toast.error("Fill the Required Field");
            }
        }
    };

    const handleCitySubmit = async (e) => {
        e.preventDefault();
        setCitySubmit(true);
        const cityFilter = cityArray.find(x => x.citY_NAME === cityName && x.statE_ID === stateSelectID.id);
        const cityFilterUpdate = cityArray.filter(x => x.id === cityID);
        if (cityID === 0) {
            if (e.currentTarget.checkValidity() === false || stateSelectID === 0) {
                e.stopPropagation();
            } else if (cityFilter && cityFilter.length !== 0) {
                toast.error("City name already exists.");
            } else if (countrySelectID && cityName && stateSelectID) {
                setIsLoading(true)
                const param = {
                    ID: cityID,
                    STATE_ID: stateSelectID.id,
                    CITY_NAME: cityName,
                    CITY_CODE: "-"
                }
                const response = await createCity(param);
                if (response === "Inserted") {
                    getCoutryDetails();
                    setIsLoading(false);
                    toast.success("City Inserted Sucessfully");
                    closeCity();
                } else {
                    toast.error(response);
                    setIsLoading(false);
                }
            }
            else {
                toast.error("Fill the Required Field");
            }
        } else if (cityID !== 0) {
            if (e.currentTarget.checkValidity() === false || stateSelectID === 0) {
                e.stopPropagation();
            } else if (cityFilter && cityFilter.length !== 0 && cityFilterUpdate[0].citY_NAME !== cityName) {
                toast.error("City name already exists.");
            } else if (countrySelectID && cityName && stateSelectID) {
                setIsLoading(true)
                const param = {
                    ID: cityID,
                    STATE_ID: stateSelectID.id,
                    CITY_NAME: cityName,
                    CITY_CODE: "-"
                }
                const response = await updateCity(param);
                if (response === "Updated") {
                    getCoutryDetails();
                    setIsLoading(false);
                    toast.success("City Updated Sucessfully");
                    closeCity()
                } else {
                    toast.error(response);
                    setIsLoading(false);
                }
            }
            else {
                toast.error("Fill the Required Field");
            }
        }

    };

    const CityCountryDropdownSelect = (selectedOption) => {
        if (selectedOption == null) {
            setCountrySelectID(0);
        } else {
            setCountrySelectID(selectedOption);
            const stateData = stateArray.filter(r => r.cntrY_ID === selectedOption.id)
            setStateFilter(stateData)
        }
    };

    const CountryDropdownSelect = (selectedOption) => {
        if (selectedOption == null) {
            setCountrySelectID(0);
        } else {
            setCountrySelectID(selectedOption);
        }
    };

    const StateDropdownSelect = (selectedOption) => {
        if (selectedOption == null) {
            setStateSelectID(0);
        } else {
            setStateSelectID(selectedOption);
        }
    };

    const editState = (stateID) => {
        setIsStateView(false);
        setStateID(stateID);
        const stateData = stateArray.find(x => x.id === stateID);
        const countryData = countryArray.find(x => x.id === stateData.cntrY_ID);
        setCountrySelectID(countryData);
        setStateName(stateData.statE_NAME);
        setActiveScreen("StateEdit");
    };

    const viewState = (stateID) => {
        setIsStateView(true);
        setStateID(stateID);
        const stateData = stateArray.find(x => x.id === stateID);
        const countryData = countryArray.find(x => x.id === stateData.cntrY_ID);
        setCountrySelectID(countryData);
        setStateName(stateData.statE_NAME);
        setActiveScreen("StateEdit");
    };

    const DeleteState = async (stateID) => {
        const cityData = await getCity();
        for (let state of cityData) {
            if (state.cntrY_ID === stateID) {
                toast.error(`This State is associated with State. You can't delete it.`);
                return;
            }
        }

        const unitData = await getBUData();
        for (let unit of unitData) {
            if (unit.BU_STATE_ID === stateID) {
                toast.error(`This State is associated with Unit. You can't delete it.`);
                return;
            }
        }

        const branchData = await getBRData();
        for (let unit of branchData) {
            if (unit.BR_STATE_ID === stateID) {
                toast.error(`This State is associated with Branch. You can't delete it.`);
                return;
            }
        }

        const entityData = await getBEData(1);
        if (entityData.BE_STATE_ID === stateID) {
            toast.error(`This State is associated with Entity. You can't delete it.`);
            return;
        }

        const empData = await getEmployee();
        for (let emp of empData) {
            const primaryAddressValue = JSON.parse(emp.primaryAddress);
            const secondaryAddressValue = JSON.parse(emp.secondaryAddress);
            if (primaryAddressValue.state === stateID || secondaryAddressValue.state === stateID) {
                toast.error(`This State is associated with Entity. You can't delete it.`);
                return;
            }
        }

        const isConfirmed = await ConfirmDelete();
        if (isConfirmed) {
            setIsLoading(true);
            const response = await deleteState(stateID);
            if (response === "Deleted") {
                getCoutryDetails();
                setIsLoading(false);
                toast.success("State Deleted Sucessfully");
            } else {
                setIsLoading(false);
                toast.error(response);
            }
        }
    };

    const viewCity = (cityID) => {
        setIsCityView(true);
        setCityID(cityID);
        const cityData = cityArray.find(x => x.id === cityID);
        const stateData = stateArray.find(x => x.id === cityData.statE_ID);
        const countryData = countryArray.find(x => x.id === stateData.cntrY_ID);
        setStateSelectID(stateData);
        setCountrySelectID(countryData);
        setCityName(cityData.citY_NAME);
        setActiveScreen("CityEdit");
    };

    const editCity = (cityID) => {
        setIsCityView(false);
        setCityID(cityID);
        const cityData = cityArray.find(x => x.id === cityID);
        const stateData = stateArray.find(x => x.id === cityData.statE_ID);
        const countryData = countryArray.find(x => x.id === stateData.cntrY_ID);
        setStateSelectID(stateData);
        setCountrySelectID(countryData);
        setCityName(cityData.citY_NAME);
        setActiveScreen("CityEdit");
    };

    const DeleteCity = async (cityID) => {
        const unitData = await getBUData();
        for (let unit of unitData) {
            if (unit.BU_CITY_ID === cityID) {
                toast.error(`This City is associated with Unit. You can't delete it.`);
                return;
            }
        }

        const branchData = await getBRData();
        for (let unit of branchData) {
            if (unit.BR_CITY_ID === cityID) {
                toast.error(`This City is associated with Branch. You can't delete it.`);
                return;
            }
        }

        const entityData = await getBEData(1);
        if (entityData.BE_CITY_ID === cityID) {
            toast.error(`This City is associated with Entity. You can't delete it.`);
            return;
        }

        const isConfirmed = await ConfirmDelete();
        if (isConfirmed) {
            setIsLoading(true);
            const response = await deleteCity(cityID);
            if (response === "Deleted") {
                getCoutryDetails();
                setIsLoading(false);
                toast.success("City Deleted Sucessfully");
            } else {
                setIsLoading(false);
                toast.error(response);
            }
        }
    };

    const selectedTab = (tabValue) => {
        setTabActive(tabValue);
        if (tabValue === "Country") {
            setTabStatusActive("Country");
        }
        else if (tabValue === "State") {
            setTabStatusActive("State");
        }
        else if (tabValue === "City") {
            setTabStatusActive("City");
        }
    };

    // Country Pagination
    const handleCountrySearchChange = (event) => {
        setSearchQueryCountry(event.target.value);
        setPageCountry(0);
    };

    const filteredCountry = countryArray.filter((country) =>
        country?.cntrY_NAME?.toLowerCase()?.includes(searchQueryCountry?.toLowerCase())
    );

    const handleRowsPerPageChange = (event) => {
        setRowsCountryPerPage(parseInt(event.target.value));
        setPageCountry(0);
    };

    const handleCountryFirstPage = () => {
        handleCountryChangePagePagination({ selected: 0 });
    };

    const handleCountryLastPage = () => {
        handleCountryChangePagePagination({ selected: Math.ceil(filteredCountry.length / rowsCountryPerPage) - 1 });
    };

    const handleCountryChangePagePagination = ({ selected }) => {
        setPageCountry(selected);
    };

    const totalCountry = filteredCountry.length;
    const startCountryEntry = pageCountry * rowsCountryPerPage + 1;
    const endCountryEntry = Math.min((pageCountry + 1) * rowsCountryPerPage, totalCountry);


    // State Pagination
    const handleStateSearchChange = (event) => {
        setSearchQueryState(event.target.value);
        setPageState(0);
    };

    const filteredState = stateArray.filter((state) =>
        state && (
            state?.statE_NAME?.toLowerCase()?.includes(searchQueryState?.toLowerCase()) ||
            state?.countryName?.toLowerCase()?.includes(searchQueryState?.toLowerCase())
        )
    );

    const handleRowsPerPageStateChange = (event) => {
        setRowsStatePerPage(parseInt(event.target.value));
        setPageState(0);
    };

    const handleStateFirstPage = () => {
        handleStateChangePagePagination({ selected: 0 });
    };

    const handleStateLastPage = () => {
        handleStateChangePagePagination({ selected: Math.ceil(filteredState.length / rowsStatePerPage) - 1 });
    };

    const handleStateChangePagePagination = ({ selected }) => {
        setPageState(selected);
    };

    const totalState = filteredState.length;
    const startStateEntry = pageState * rowsStatePerPage + 1;
    const endStateEntry = Math.min((pageState + 1) * rowsStatePerPage, totalState);

    // City Pagination
    const handleCitySearchChange = (event) => {
        setSearchQueryCity(event.target.value);
        setPageCity(0);
    };

    const filteredCity = cityArray.filter((city) =>
        city && (
            city?.citY_NAME?.toLowerCase()?.includes(searchQueryCity?.toLowerCase()) ||
            city?.countryName?.toLowerCase()?.includes(searchQueryCity?.toLowerCase()) ||
            city?.stateName?.toLowerCase()?.includes(searchQueryCity?.toLowerCase())
        )
    );

    const handleRowsPerPageCityChange = (event) => {
        setRowsCityPerPage(parseInt(event.target.value));
        setPageCity(0);
    };

    const handleCityFirstPage = () => {
        handleCityChangePagePagination({ selected: 0 });
    };

    const handleCityLastPage = () => {
        handleCityChangePagePagination({ selected: Math.ceil(filteredCity.length / rowsCityPerPage) - 1 });
    };

    const handleCityChangePagePagination = ({ selected }) => {
        setPageCity(selected);
    };

    const totalCity = filteredCity.length;
    const startCityEntry = pageCity * rowsCityPerPage + 1;
    const endCityEntry = Math.min((pageCity + 1) * rowsCityPerPage, totalCity);

    const exportCountry = (event) => {
        const selectedOption = event.target.value;
        if (selectedOption !== '') {
            handleExportCountry(selectedOption);
            event.target.value = '';
        }
    };
    const handleExportCountry = (format) => {
        exportDataCountry(countryArray, format);
    };

    const exportState = (event) => {
        const selectedOption = event.target.value;
        if (selectedOption !== '') {
            handleExportState(selectedOption);
            event.target.value = '';
        }
    };

    const handleExportState = (format) => {
        exportDataState(stateArray, format);
    };

    const exportCity = (event) => {
        const selectedOption = event.target.value;
        if (selectedOption !== '') {
            handleExportCity(selectedOption);
            event.target.value = '';
        }
    };

    const handleExportCity = (format) => {
        exportDataCity(cityArray, format);
    };

    const handleCountryImportClick = () => {
        fileCountryInputRef.current.click();
    };

    const handleCountryFileChange = async (event) => {
        const file = event.target.files[0];
        if (file && file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            const data = await file.arrayBuffer();
            const workbook = XLSX.read(data);
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(worksheet);
            let countryArrayData = [];
            let countryErrorArrayCount = [];
            jsonData.forEach((e) => {
                const countryExists = countryArray.some(x => x.cntrY_NAME === e.Country);
                if (countryExists) {
                    toast.error(`${e.Country} name already exists.`);
                    countryErrorArrayCount.push(e);
                } else {
                    countryArrayData.push(e);
                }
            });
            event.target.value = null;
            if (countryArrayData.length > 0 && countryErrorArrayCount.length === 0) {
                uploadCountryData(countryArrayData);
            }
        } else {
            toast.error("Invalid file format.");
        }
    };

    const uploadCountryData = async (data) => {
        try {
            setIsLoading(true);
            const uploadPromises = data.map(async (e) => {
                const param = {
                    ID: 0,
                    CNTRY_NAME: e.Country,
                    CNTRY_CODE: "-"
                };
                const response = await createCountry(param);
                if (response === "Inserted") {
                    return "success";
                } else {
                    throw new Error("Failed to upload data");
                }
            });
            await Promise.all(uploadPromises);
            getCoutryDetails();
            closeCountry();
            setPageCountry(0);
            toast.success("Data uploaded successfully.");
        } catch (error) {
            toast.error("An error occurred while uploading data.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleStateImportClick = () => {
        fileStateInputRef.current.click();
    };

    const handleStateFileChange = async (event) => {
        const file = event.target.files[0];
        if (file && file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            const data = await file.arrayBuffer();
            const workbook = XLSX.read(data);
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(worksheet);
            let stateArrayData = [];
            let stateErrorArrayCount = [];
            jsonData.forEach((e) => {
                const countryExists = countryArray.filter(x => x.cntrY_NAME === e.Country);
                const stateExists = stateArray.filter(x => x.statE_NAME === e.State && x.cntrY_ID === countryExists[0].id);
                if (stateExists.length !== 0 && countryExists.length !== 0) {
                    toast.error(`${e.State} name already exists.`);
                    stateErrorArrayCount.push(e);
                } else if (countryExists.length === 0) {
                    toast.error(`Country name doesn't exist.`);
                    stateErrorArrayCount.push(e);
                }
                else if (countryExists.length !== 0 && stateExists.length === 0) {
                    e.CountryId = countryExists[0].id
                    stateArrayData.push(e);
                }
            });
            event.target.value = null;
            if (stateArrayData.length > 0 && stateErrorArrayCount.length === 0) {
                uploadStateData(stateArrayData);
            }
        } else {
            toast.error("Invalid file format.");
        }
    };

    const uploadStateData = async (data) => {
        try {
            setIsLoading(true);
            const uploadPromises = data.map(async (e) => {
                const param = {
                    ID: 0,
                    CNTRY_ID: e.CountryId,
                    STATE_NAME: e.State,
                    STATE_CODE: "-"
                }
                const response = await createState(param);
                if (response === "Inserted") {
                    return "success";
                } else {
                    throw new Error("Failed to upload data");
                }
            });
            await Promise.all(uploadPromises);
            getCoutryDetails();
            closeState();
            setIsLoading(false);
            toast.success("Data uploaded successfully.");
        } catch (error) {
            toast.error("An error occurred while uploading data.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleCityImportClick = () => {
        fileCityInputRef.current.click();
    };

    const handleCityFileChange = async (event) => {
        const file = event.target.files[0];
        if (file && file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            const data = await file.arrayBuffer();
            const workbook = XLSX.read(data);
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(worksheet);
            let cityArrayData = [];
            let CityErrorArrayCount = [];
            jsonData.forEach((e) => {
                const countryExists = countryArray.filter(x => x.cntrY_NAME === e.Country);
                if (countryExists.length === 0) {
                    toast.error(`Country name doesn't exist.`);
                    CityErrorArrayCount.push(e);
                } else {
                    const stateExists = stateArray.filter(x => x.statE_NAME === e.State && x.cntrY_ID === countryExists[0].id);
                    if (stateExists.length === 0) {
                        toast.error(`${e.State} doesn't exist in ${e.Country}.`);
                        CityErrorArrayCount.push(e);
                    } else {
                        const cityExists = cityArray.filter(x => x.citY_NAME === e.City && x.statE_ID === stateExists[0].id);
                        if (cityExists.length !== 0) {
                            toast.error(`${e.City} name already exists.`);
                            CityErrorArrayCount.push(e);
                        } else {
                            e.StateId = stateExists[0].id;
                            cityArrayData.push(e);
                        }
                    }
                }
            });
            event.target.value = null;
            if (cityArrayData.length > 0 && CityErrorArrayCount.length === 0) {
                uploadCityData(cityArrayData);
            }
        } else {
            toast.error("Invalid file format.");
        }
    };

    const uploadCityData = async (data) => {
        try {
            setIsLoading(true);
            const uploadPromises = data.map(async (e) => {
                const param = {
                    ID: 0,
                    STATE_ID: e.StateId,
                    CITY_NAME: e.City,
                    CITY_CODE: "-"
                }
                const response = await createCity(param);
                if (response === "Inserted") {
                    return "success";
                } else {
                    throw new Error("Failed to upload data");
                }
            });
            await Promise.all(uploadPromises);
            getCoutryDetails();
            closeCity();
            setIsLoading(false);
            toast.success("Data uploaded successfully.");
        } catch (error) {
            toast.error("An error occurred while uploading data.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div style={{ height: "100vh", width: "100%", backgroundColor: '#FAFAFB' }}>
            <ToastContainer theme="colored" />
            {isLoading && <LoadingSpinner />}

            {!isLoading && (
                <div>
                    <div className="pagetitleCountry">
                        <div className="Pageheader fontFamily fontsize24 fontweightSemiBold">Account Details - {tabStatusActive}<div><InfoOutlinedIcon /></div></div>
                    </div>

                    {activeScreen === "AccountDetails" && (
                        <div className="card mt-4">
                            <div className="card-body-country">
                                <div className="CustomBoxShadow">
                                    <div className="HeaderTab">
                                        <a href="#Country" style={{ marginLeft: "40px", width: "101px", height: "40px", textAlign: "center" }} className={`${tabActive === 'Country' ? 'activeTab' : 'InactiveTab'}`} onClick={() => selectedTab('Country')}>
                                            <div className="fontFamily fontweightRegular fontsize20">
                                                Country
                                            </div>
                                        </a>
                                        <a href="#State" style={{ width: "101px", height: "40px", textAlign: "center" }} className={`${tabActive === 'State' ? 'activeTab' : 'InactiveTab'}`} onClick={() => selectedTab('State')}>
                                            <div className="fontFamily fontweightRegular fontsize20">
                                                State
                                            </div>
                                        </a>
                                        <a href="#City" style={{ marginRight: "40px", width: "101px", height: "40px", textAlign: "center" }} className={`${tabActive === 'City' ? 'activeTab' : 'InactiveTab'}`} onClick={() => selectedTab('City')}>
                                            <div className="fontFamily fontweightRegular fontsize20">
                                                City
                                            </div>
                                        </a>
                                    </div>

                                    {tabActive === 'Country' && (
                                        <div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="custom-container">
                                                    <span className="blackTextColor fontFamily fontsize16 fontweightRegular" >
                                                        <span className="show">Show</span>
                                                        <select className="custom-dropdown" value={rowsCountryPerPage} onChange={handleRowsPerPageChange}>
                                                            {[5, 10, 15].map((n) => (
                                                                <option className="blackTextColor fontFamily fontsize14 fontweightRegular" key={n} value={n}>
                                                                    {n}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <span className="blackTextColor entries fontFamily fontsize16 fontweightRegular" >
                                                            Entries
                                                        </span>
                                                        <input
                                                            type="text"
                                                            placeholder="Search..."
                                                            className="searchTextBox"
                                                            value={searchQueryCountry}
                                                            onChange={handleCountrySearchChange}
                                                            style={{ marginLeft: "28px" }}
                                                        />
                                                    </span>
                                                </div>
                                                <div className="addbuttondiv">
                                                    <input
                                                        type="file"
                                                        ref={fileCountryInputRef}
                                                        style={{ display: 'none' }}
                                                        accept=".xlsx"
                                                        onChange={handleCountryFileChange}
                                                    />
                                                    <button type="button" className="fontFamily fontweightRegular fontsize16 coloredButtonAdd" style={{ marginLeft: "20px" }} onClick={() => addNewCountry()}> Add New </button>
                                                    <button type="button" className="fontFamily fontweightRegular fontsize16 importBtn" onClick={handleCountryImportClick}> Import </button>
                                                    <select className="fontFamily fontweightRegular fontsize16 exportBtn" name="language" id="language" defaultValue="" onChange={exportCountry} >
                                                        <option value="" hidden={true}>Export</option>
                                                        <option value="excel">Excel</option>
                                                        <option value="csv">CSV</option>
                                                        <option value="pdf" >Pdf</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="tableScroll" style={{ height: '233px', overflowY: 'auto', scrollbarWidth: 'thin' }}>
                                                <table className="w-100 mt-2">
                                                    <thead>
                                                        <tr className="text-left">
                                                            <th className="fontFamily fontweightMedium fontsize16" style={{ backgroundColor: '#F6F6F6', width: "35%", padding: "2px 2px 2px 3%" }}>SI.No</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "40%" }}>Country</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "10%" }}>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {filteredCountry.length > 0 ? (
                                                            filteredCountry.slice(pageCountry * rowsCountryPerPage, (pageCountry + 1) * rowsCountryPerPage).map((country, index) => (
                                                                <tr key={country.Rowid} className="text-left">
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14  ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`} style={{ padding: "2px 2px 2px 4%" }}>{country.Rowid}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>{country.cntrY_NAME}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>
                                                                        <span style={{ cursor: 'pointer' }}><a href="#viewCountry" onClick={() => viewCountry(country.id)}><img style={{ width: '18px', height: '15px' }} src={viewsvgIcon} alt="viewsvgIcon" /></a></span>
                                                                        <span style={{ marginLeft: '10px', cursor: 'pointer' }}><a href="#editCountry" onClick={() => editCountry(country.id)}><img src={editsvgIcon} alt="editsvgIcon" /></a></span>
                                                                        <span style={{ marginLeft: '10px', cursor: 'pointer' }}><a href="#DeleteCountry" onClick={() => DeleteCountry(country.id)}><img src={deletesvgIcon} alt="deletesvgIcon" /></a></span>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <td colSpan="9" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )}

                                    {tabActive === 'State' && (
                                        <div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="custom-container">
                                                    <span className="blackTextColor fontFamily fontsize16 fontweightRegular" >
                                                        <span className="show">Show</span>
                                                        <select className="custom-dropdown" value={rowsStatePerPage} onChange={handleRowsPerPageStateChange}>
                                                            {[5, 10, 15].map((n) => (
                                                                <option className="blackTextColor fontFamily fontsize14 fontweightRegular" key={n} value={n}>
                                                                    {n}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <span className="blackTextColor entries fontFamily fontsize16 fontweightRegular" >
                                                            Entries
                                                        </span>
                                                        <input
                                                            type="text"
                                                            placeholder="Search..."
                                                            className="searchTextBox"
                                                            value={searchQueryState}
                                                            onChange={handleStateSearchChange}
                                                            style={{ marginLeft: "28px" }}
                                                        />
                                                    </span>
                                                </div>
                                                <div className="addbuttondiv">
                                                    <input
                                                        type="file"
                                                        ref={fileStateInputRef}
                                                        style={{ display: 'none' }}
                                                        accept=".xlsx"
                                                        onChange={handleStateFileChange}
                                                    />
                                                    <button type="button" className="fontFamily fontweightRegular fontsize16 coloredButtonAdd" style={{ marginLeft: "20px" }} onClick={() => addnewState()}> Add New </button>
                                                    <button type="button" className="fontFamily fontweightRegular fontsize16 importBtn" onClick={handleStateImportClick}> Import </button>
                                                    <select className="fontFamily fontweightRegular fontsize16 exportBtn" name="language" id="language" defaultValue="" onChange={exportState} >
                                                        <option value="" hidden={true}>Export</option>
                                                        <option value="excel">Excel</option>
                                                        <option value="csv">CSV</option>
                                                        <option value="pdf" >Pdf</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="tableScroll" style={{ height: '233px', overflowY: 'auto', scrollbarWidth: 'thin' }}>
                                                <table className="w-100 mt-2">
                                                    <thead>
                                                        <tr className="text-left">
                                                            <th className="fontFamily fontweightMedium fontsize16 " style={{ backgroundColor: '#F6F6F6', width: "20%", padding: "2px 2px 2px 3%" }}>SI.No</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "30%" }}>Country</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "30%" }}>State</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "10%" }}>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {filteredState.length > 0 ? (
                                                            filteredState.slice(pageState * rowsStatePerPage, (pageState + 1) * rowsStatePerPage).map((state, index) => (
                                                                <tr key={state.Rowid} >
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14  ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`} style={{ padding: "2px 2px 2px 4%" }}>{state.Rowid}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>{state.countryName}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>{state.statE_NAME}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>
                                                                        <span style={{ cursor: 'pointer' }}><a href="#viewState" onClick={() => viewState(state.id)}><img style={{ width: '18px', height: '15px' }} src={viewsvgIcon} alt="viewsvgIcon" /></a></span>
                                                                        <span style={{ marginLeft: '10px', cursor: 'pointer' }}><a href="#editState" onClick={() => editState(state.id)}><img src={editsvgIcon} alt="editsvgIcon" /></a></span>
                                                                        <span style={{ marginLeft: '10px', cursor: 'pointer' }}><a href="#DeleteState" onClick={() => DeleteState(state.id)}><img src={deletesvgIcon} alt="deletesvgIcon" /></a></span>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <td colSpan="9" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )}

                                    {tabActive === 'City' && (
                                        <div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="custom-container">
                                                    <span className="blackTextColor fontFamily fontsize16 fontweightRegular" >
                                                        <span className="show">Show</span>
                                                        <select className="custom-dropdown" value={rowsCityPerPage} onChange={handleRowsPerPageCityChange}>
                                                            {[5, 10, 15].map((n) => (
                                                                <option className="blackTextColor fontFamily fontsize14 fontweightRegular" key={n} value={n}>
                                                                    {n}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <span className="blackTextColor entries fontFamily fontsize16 fontweightRegular" >
                                                            Entries
                                                        </span>
                                                        <input
                                                            type="text"
                                                            placeholder="Search..."
                                                            className="searchTextBox"
                                                            value={searchQueryCity}
                                                            onChange={handleCitySearchChange}
                                                            style={{ marginLeft: "28px" }}
                                                        />
                                                    </span>
                                                </div>
                                                <div className="addbuttondiv">
                                                    <input
                                                        type="file"
                                                        ref={fileCityInputRef}
                                                        style={{ display: 'none' }}
                                                        accept=".xlsx"
                                                        onChange={handleCityFileChange}
                                                    />
                                                    <button type="button" className="fontFamily fontweightRegular fontsize16 coloredButtonAdd" style={{ marginLeft: "20px" }} onClick={() => addnewCity()}> Add New </button>
                                                    <button type="button" className="fontFamily fontweightRegular fontsize16 importBtn" onClick={handleCityImportClick}> Import </button>
                                                    <select className="fontFamily fontweightRegular fontsize16 exportBtn" name="language" id="language" defaultValue="" onChange={exportCity} >
                                                        <option value="" hidden={true}>Export</option>
                                                        <option value="excel">Excel</option>
                                                        <option value="csv">CSV</option>
                                                        <option value="pdf" >Pdf</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="tableScroll" style={{ height: '233px', overflowY: 'auto', scrollbarWidth: 'thin' }}>
                                                <table className="w-100 mt-2">
                                                    <thead>
                                                        <tr className="text-left">
                                                            <th className="fontFamily fontweightMedium fontsize16" style={{ backgroundColor: '#F6F6F6', width: "15%", padding: "2px 2px 2px 3%" }}>SI.No</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "20%" }}>Country</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "20%" }}>State</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "20%" }}>City</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "8%" }}>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {filteredCity.length > 0 ? (
                                                            filteredCity.slice(pageCity * rowsCityPerPage, (pageCity + 1) * rowsCityPerPage).map((city, index) => (
                                                                <tr key={city.Rowid} >
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14  ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`} style={{ padding: "2px 2px 2px 4%" }}>{city.Rowid}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>{city.countryName}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>{city.stateName}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>{city.citY_NAME}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>
                                                                        <span style={{ cursor: 'pointer' }}><a href="#viewCity" onClick={() => viewCity(city.id)}><img style={{ width: '18px', height: '15px' }} src={viewsvgIcon} alt="viewsvgIcon" /></a></span>
                                                                        <span style={{ marginLeft: '10px', cursor: 'pointer' }}><a href="#editCity" onClick={() => editCity(city.id)}><img src={editsvgIcon} alt="editsvgIcon" /></a></span>
                                                                        <span style={{ marginLeft: '10px', cursor: 'pointer' }}><a href="#DeleteCity" onClick={() => DeleteCity(city.id)}><img src={deletesvgIcon} alt="deletesvgIcon" /></a></span>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <td colSpan="9" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                {tabActive === 'Country' && (
                                    <div className="" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingTop: "16px" }}>
                                        <div >
                                            <div className="grayTextColor fontFamily fontsize16 fontweightMedium">
                                                {`Showing ${startCountryEntry} to ${endCountryEntry} of ${totalCountry} entries`}
                                            </div>
                                        </div>
                                        <div >
                                            <div className="pagination-container d-flex align-items-center">
                                                <button onClick={handleCountryFirstPage} className="btn btn-link">
                                                    <FontAwesomeIcon icon={faAngleDoubleLeft} className="icon-color" />
                                                </button>
                                                <ReactPaginate
                                                    previousLabel={<FontAwesomeIcon icon={faChevronLeft} className="icon-color" />}
                                                    nextLabel={<FontAwesomeIcon icon={faChevronRight} className="icon-color" />}
                                                    breakLabel={'...'}
                                                    breakClassName={'break-me'}
                                                    pageCount={Math.ceil(filteredCountry.length / rowsCountryPerPage)}
                                                    forcePage={pageCountry}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={5}
                                                    onPageChange={handleCountryChangePagePagination}
                                                    containerClassName={'pagination'}
                                                    subContainerClassName={'pages pagination'}
                                                    activeClassName={'active'}
                                                    renderOnZeroPageCount={null}
                                                />
                                                <button onClick={handleCountryLastPage} className="btn btn-link">
                                                    <FontAwesomeIcon icon={faAngleDoubleRight} className="icon-color" />
                                                </button>
                                            </div>
                                        </div>
                                        <div style={{ padding: "0px 100px" }}>
                                        </div>
                                    </div>
                                )}

                                {tabActive === 'State' && (
                                    <div className="" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingTop: "16px" }}>
                                        <div >
                                            <div className="grayTextColor fontFamily fontsize16 fontweightMedium">
                                                {`Showing ${startStateEntry} to ${endStateEntry} of ${totalState} entries`}
                                            </div>
                                        </div>
                                        <div >
                                            <div className="pagination-container d-flex align-items-center">
                                                <button onClick={handleStateFirstPage} className="btn btn-link">
                                                    <FontAwesomeIcon icon={faAngleDoubleLeft} className="icon-color" />
                                                </button>
                                                <ReactPaginate
                                                    previousLabel={<FontAwesomeIcon icon={faChevronLeft} className="icon-color" />}
                                                    nextLabel={<FontAwesomeIcon icon={faChevronRight} className="icon-color" />}
                                                    breakLabel={'...'}
                                                    breakClassName={'break-me'}
                                                    pageCount={Math.ceil(filteredState.length / rowsStatePerPage)}
                                                    forcePage={pageState}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={5}
                                                    onPageChange={handleStateChangePagePagination}
                                                    containerClassName={'pagination'}
                                                    subContainerClassName={'pages pagination'}
                                                    activeClassName={'active'}
                                                    renderOnZeroPageCount={null}
                                                />
                                                <button onClick={handleStateLastPage} className="btn btn-link">
                                                    <FontAwesomeIcon icon={faAngleDoubleRight} className="icon-color" />
                                                </button>
                                            </div>
                                        </div>
                                        <div style={{ padding: "0px 100px" }}>
                                        </div>
                                    </div>
                                )}

                                {tabActive === 'City' && (
                                    <div className="" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingTop: "16px" }}>
                                        <div >
                                            <div className="grayTextColor fontFamily fontsize16 fontweightMedium">
                                                {`Showing ${startCityEntry} to ${endCityEntry} of ${totalCity} entries`}
                                            </div>
                                        </div>
                                        <div >
                                            <div className="pagination-container d-flex align-items-center">
                                                <button onClick={handleCityFirstPage} className="btn btn-link">
                                                    <FontAwesomeIcon icon={faAngleDoubleLeft} className="icon-color" />
                                                </button>
                                                <ReactPaginate
                                                    previousLabel={<FontAwesomeIcon icon={faChevronLeft} className="icon-color" />}
                                                    nextLabel={<FontAwesomeIcon icon={faChevronRight} className="icon-color" />}
                                                    breakLabel={'...'}
                                                    breakClassName={'break-me'}
                                                    pageCount={Math.ceil(filteredCity.length / rowsCityPerPage)}
                                                    forcePage={pageCity}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={5}
                                                    onPageChange={handleCityChangePagePagination}
                                                    containerClassName={'pagination'}
                                                    subContainerClassName={'pages pagination'}
                                                    activeClassName={'active'}
                                                    renderOnZeroPageCount={null}
                                                />
                                                <button onClick={handleCityLastPage} className="btn btn-link">
                                                    <FontAwesomeIcon icon={faAngleDoubleRight} className="icon-color" />
                                                </button>
                                            </div>
                                        </div>
                                        <div style={{ padding: "0px 100px" }}>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {activeScreen === "CountryEdit" && (
                        <div className="card mt-4">
                            <div className="card-body">
                                <form className="p-2" noValidate onSubmit={handleCountrySubmit}>
                                    <div className="row">
                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">Country Name<span className="mandatoryCss"> *</span></div>
                                            <input type="text" value={countryName} placeholder="Enter Country Name" onChange={(e) => setCountryName(e.target.value.toUpperCase())} style={{
                                                paddingLeft: '12px', backgroundColor: isCountryView ? '#FAFAFB' : '#FFFFFF', border: '#00000014 1px solid', borderRadius: '5px', fontFamily: '"Nunito", sans-serif', fontWeight: '400', fontSize: '16px', width: '100%', outline: 'none', height: '45px'
                                            }} disabled={isCountryView} />
                                            {countrySubmit && !countryName && (
                                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                                    Please Enter Country Name.
                                                </div>
                                            )}
                                        </div>

                                        <div className="col-6 col-md-6 col-lg-6">
                                        </div>
                                    </div>

                                    <div className="mb-2 text-center" style={{ marginTop: "25%" }}>
                                        {!isCountryView && (
                                            <button type="submit" className="coloredButtonSave">
                                                Save
                                            </button>)}
                                        <button type="button" className="outlineButton" style={{ marginLeft: '18px' }} onClick={() => closeCountry()}>
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}

                    {activeScreen === "StateEdit" && (
                        <div className="card mt-4">
                            <div className="card-body">
                                <form className="p-2" noValidate onSubmit={handleStateSubmit}>
                                    <div className="row">
                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                                Select Country<span className="mandatoryCss"> *</span>
                                            </div>
                                            <Select
                                                value={countrySelectID}
                                                onChange={CountryDropdownSelect}
                                                options={countryArray}
                                                getOptionLabel={(option) => option.cntrY_NAME}
                                                getOptionValue={(option) => option.id}
                                                placeholder="--Select Country--"
                                                isClearable
                                                isSearchable
                                                className={`${stateSubmit && countrySelectID === 0 ? 'is-invalid' : ''}`}
                                                isDisabled={isStateView}
                                                styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                            />

                                            {stateSubmit && countrySelectID === 0 && (
                                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                                    Please Select Country.
                                                </div>
                                            )}
                                        </div>

                                        <div className="col-6 col-md-6 col-lg-6">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">State Name<span className="mandatoryCss"> *</span></div>
                                            <input type="text" value={stateName} placeholder="Enter State Name" onChange={(e) => setStateName(e.target.value.toUpperCase())} style={{
                                                paddingLeft: '12px', backgroundColor: isStateView ? '#FAFAFB' : '#FFFFFF', border: '#00000014 1px solid', borderRadius: '5px', fontFamily: '"Nunito", sans-serif', fontWeight: '400', fontSize: '16px', width: '100%', outline: 'none', height: '45px'
                                            }} disabled={isStateView} />
                                            {stateSubmit && !stateName && (
                                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                                    Please Enter State Name.
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="mb-2 text-center" style={{ marginTop: "25%" }}>
                                        {!isStateView && (
                                            <button type="submit" className="coloredButtonSave">
                                                Save
                                            </button>)}
                                        <button type="button" className="outlineButton" style={{ marginLeft: '18px' }} onClick={() => closeState()}>
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}

                    {activeScreen === "CityEdit" && (
                        <div className="card mt-4">
                            <div className="card-body">
                                <form className="p-2" noValidate onSubmit={handleCitySubmit}>
                                    <div className="row">
                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                                Select Country<span className="mandatoryCss"> *</span>
                                            </div>
                                            <Select
                                                value={countrySelectID}
                                                onChange={CityCountryDropdownSelect}
                                                options={countryArray}
                                                getOptionLabel={(option) => option.cntrY_NAME}
                                                getOptionValue={(option) => option.id}
                                                placeholder="--Select Country--"
                                                isClearable
                                                isSearchable
                                                className={`${stateSubmit && countrySelectID === 0 ? 'is-invalid' : ''}`}
                                                isDisabled={isCityView}
                                                styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                            />

                                            {citySubmit && countrySelectID === 0 && (
                                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                                    Please Select Country.
                                                </div>
                                            )}
                                        </div>

                                        <div className="col-6 col-md-6 col-lg-6">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                                Select State<span className="mandatoryCss"> *</span>
                                            </div>
                                            <Select
                                                value={stateSelectID}
                                                onChange={StateDropdownSelect}
                                                options={stateFilter}
                                                getOptionLabel={(option) => option.statE_NAME}
                                                getOptionValue={(option) => option.id}
                                                placeholder="--Select State--"
                                                isClearable
                                                isSearchable
                                                className={`${citySubmit && stateSelectID === 0 ? 'is-invalid' : ''}`}
                                                isDisabled={isCityView}
                                                styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                            />

                                            {citySubmit && stateSelectID === 0 && (
                                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                                    Please Select State.
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">City Name<span className="mandatoryCss"> *</span></div>
                                            <input type="text" value={cityName} placeholder="Enter City Name" onChange={(e) => setCityName(e.target.value.toUpperCase())} style={{
                                                paddingLeft: '12px', backgroundColor: isCityView ? '#FAFAFB' : '#FFFFFF', border: '#00000014 1px solid', borderRadius: '5px', fontFamily: '"Nunito", sans-serif', fontWeight: '400', fontSize: '16px', width: '100%', outline: 'none', height: '45px'
                                            }} disabled={isCityView} />
                                            {citySubmit && !cityName && (
                                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                                    Please Enter City Name.
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-6">
                                        </div>
                                    </div>

                                    <div className="mb-2 text-center" style={{ marginTop: "20%" }}>
                                        {!isCityView && (
                                            <button type="submit" className="coloredButtonSave">
                                                Save
                                            </button>)}
                                        <button type="button" className="outlineButton" style={{ marginLeft: '18px' }} onClick={() => closeCity()}>
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div >
    );
};

export default CountryStateCityPage;
