import React, { useState, useEffect } from 'react';
import { PropagateLoader } from 'react-spinners';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import { getLoginData, passwordReset } from '../../Services/SignInService';

import './ForgotPassword.css';
import '../SignIn/SignIn.css';
import '../../assets/css/style.css';
import '../../assets/vendor/quill/quill.snow.css';
import '../../assets/vendor/quill/quill.bubble.css';
import '../../assets/vendor/remixicon/remixicon.css';
import '../../assets/vendor/simple-datatables/style.css';
import '../../assets/vendor/boxicons/css/boxicons.min.css';
import '../../assets/vendor/bootstrap/css/bootstrap.min.css';
import '../../assets/vendor/bootstrap-icons/bootstrap-icons.css';

import iTechLogo from '../../assets/img/iTech_Logo.PNG';


const NewPasswordScreen = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessageNewPassword, setErrorMessageNewPassword] = useState('');
    const [errorMessageConfirmPassword, setErrorMessageConfirmPassword] = useState('');
    const [isExpired, setIsExpired] = useState(false);
    const [passwordVisibleNew, setPasswordVisibleNew] = useState(false);
    const [passwordVisibleConfirm, setPasswordVisibleConfirm] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    const togglePasswordVisibilityNew = () => {
        setPasswordVisibleNew(!passwordVisibleNew);
    };

    const togglePasswordVisibilityConfirm = () => {
        setPasswordVisibleConfirm(!passwordVisibleConfirm);
    };

    useEffect(() => {
        const queryParams = getQueryParams(location.search);
        const emailID = queryParams.get('email');
        localStorage.setItem('emailID', emailID);
        fetchLoginData();
    }, [location.search]);

    const getQueryParams = (query) => {
        return new URLSearchParams(query);
    }

    const fetchLoginData = async () => {
        setIsLoading(true);
        try {
            const userLoginData = await getLoginData();
            const emailID = localStorage.getItem('emailID');
            const user = userLoginData.find(user => user.email === emailID);
            if (user) {
                const expirationDate = new Date(user.forgetLinkExpired);
                setIsExpired(new Date() > expirationDate);
            }
        } catch (error) {
            console.error("An error occurred while getting login data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handlePasswordChange = (e) => {
        const password = e.target.value;
        setNewPassword(password);
        if (!password) {
            setErrorMessageNewPassword('');
        }
        else if (password.length < 8) {
            setErrorMessageNewPassword('Password must contain at least 8 characters');
        }
        else {
            setErrorMessageNewPassword('');
        }
    };

    const handleConfirmPasswordChange = (e) => {
        const password = e.target.value;
        setConfirmPassword(password);
        if (!password) {
            setErrorMessageConfirmPassword('');
        }
        else if (password.length < 8) {
            setErrorMessageConfirmPassword('Password must contain at least 8 characters');
        } else if (password !== newPassword) {
            setErrorMessageConfirmPassword('Passwords do not match');
        } else {
            setErrorMessageConfirmPassword('');
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (newPassword === '' || confirmPassword === '') {
            if (newPassword === '') setErrorMessageNewPassword('Please enter your new password');
            if (confirmPassword === '') setErrorMessageConfirmPassword('Please enter your confirm password');
            return;
        }

        const emailID = localStorage.getItem('emailID');
        setIsLoading(true);
        if (newPassword === confirmPassword) {
            try {
                const response = await passwordReset({ Email: emailID, NewPassword: confirmPassword });
                if (response === "Password Updated Succesfully") {
                    toast.success(response);
                    setTimeout(() => {
                        navigate("/");
                    }, 2000);
                } else {
                    toast.error(response);
                }
            } catch (error) {
                console.error("An error occurred during password reset:", error);
                toast.error("An error occurred. Please try again.");
            } finally {
                setIsLoading(false);
            }
        } else {
            toast.error("New password and Confirm password are not the same.");
        }
    };

    return (
        <div className="">
            <header style={{ position: 'relative', top: '10px', left: '10px' }}>
                <img src={iTechLogo} style={{ width: '200px' }} alt="iTech Logo" />
            </header>
            {isLoading && (
                <div className="spinner-container d-flex justify-content-center align-items-center">
                    <PropagateLoader color="#3498db" size={15} />
                </div>
            )}
            {!isLoading && (
                <main id="main" className="main1">
                    {
                        isExpired ? (
                            <div className="forgotcontainer">
                                <section className="section register d-flex flex-column align-items-center justify-content-center">
                                    <div className="card py-4">
                                        <div className="row justify-content-center">

                                            <h4 className="card-title text-center pb-0 fs-4">This link has expired. Please generate a new link to reset your password.</h4>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        ) : (
                            <div className="forgotcontainer">
                                <ToastContainer theme="colored" />
                                <section className="section register d-flex flex-column align-items-center justify-content-center">
                                    <div className="card py-4">

                                        <h4 className="card-title fontFamily fontsize32 fontweightSemiBold pb-0 fs-4">Reset password</h4>
                                        <div className="card-body">
                                            <form className="row g-3 needs-validation" onSubmit={handleSubmit} noValidate>
                                                <div className="col-12 position-relative">
                                                    <div className="input-group has-validation fontFamily fontsize16 fontweightMedium">
                                                        <input
                                                            type={passwordVisibleNew ? "text" : "password"}
                                                            name="newPassword"
                                                            className="form-control custom-input"
                                                            id="newPassword"
                                                            placeholder="Password"
                                                            value={newPassword}
                                                            onChange={handlePasswordChange}
                                                            required
                                                        />
                                                        {newPassword && (
                                                            <FontAwesomeIcon
                                                                icon={passwordVisibleNew ? faEye : faEyeSlash}
                                                                onClick={togglePasswordVisibilityNew}
                                                                style={{
                                                                    position: 'absolute',
                                                                    right: '10px',
                                                                    top: '50%',
                                                                    transform: 'translateY(-50%)',
                                                                    cursor: 'pointer'
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                    {errorMessageNewPassword && <div className="text-danger">{errorMessageNewPassword}</div>}
                                                </div>
                                                <div className="col-12 position-relative">
                                                    <div className="input-group has-validation fontFamily fontsize16 fontweightMedium">
                                                        <input
                                                            type={passwordVisibleConfirm ? "text" : "password"}
                                                            name="confirmPassword"
                                                            className="form-control custom-input"
                                                            id="confirmPassword"
                                                            placeholder="Confirm password"
                                                            value={confirmPassword}
                                                            onChange={handleConfirmPasswordChange}
                                                            required
                                                        />
                                                        {confirmPassword && (
                                                            <FontAwesomeIcon
                                                                icon={passwordVisibleConfirm ? faEye : faEyeSlash}
                                                                onClick={togglePasswordVisibilityConfirm}
                                                                style={{
                                                                    position: 'absolute',
                                                                    right: '10px',
                                                                    top: '50%',
                                                                    transform: 'translateY(-50%)',
                                                                    cursor: 'pointer'
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                    {errorMessageConfirmPassword && <div className="text-danger">{errorMessageConfirmPassword}</div>}
                                                </div>
                                                <div className="col-12">
                                                    <button
                                                        className="fontFamily subButton fontsize24 fontweightSemiBold w-100"
                                                        type="submit"
                                                        disabled={!newPassword && !confirmPassword}
                                                        style={{
                                                            backgroundColor: newPassword && confirmPassword ? '#605bff' : '#D1D5D6',
                                                            color: '#FFFFFF',
                                                            border: 'none',
                                                        }}
                                                    >Submit</button>
                                                </div>
                                                <div className="col-12">
                                                    <p className="small mb-0">
                                                        <span className="go-back-text fontFamily fontweightMedium fontsize16">Go back to </span>
                                                        <Link to="/">
                                                            <span className="blackTextColor fontFamily fontweightMedium fontsize16">Login page</span>
                                                        </Link>
                                                    </p>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        )
                    }
                </main>
            )}
        </div>
    );
};

export default NewPasswordScreen;
