import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';

import './SideMenu.css';

import HomeIcon from '../assets/icon/Home.svg';
import iTechLogo from '../assets/img/iTech_Logo.PNG';
import SettingsIcon from '../assets/icon/Settings.svg';
import SideMenuEpand from '../assets/icon/SideMenuEpand.svg';


const SideMenuNew = ({ activeMenuItem, setActiveMenuItem }) => {
    const location = useLocation();
    const [activeSubMenuItem, setActiveSubMenuItem] = useState(null);
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

    useEffect(() => {
        const queryParams = `${location.pathname}${location.search}`
        handleMenuItemClick(queryParams);
        if (['/AssetCreationView'].includes(queryParams) || 
        ['/AssetCreationAdd'].includes(queryParams) || 
        ['/AssetDetailsView'].includes(queryParams) || 
        ['/FinancialTransaction'].includes(queryParams)|| 
        ['/CheckOut'].includes(queryParams)|| 
        ['/CheckIn'].includes(queryParams) ||
        ['/Transfer'].includes(queryParams)
    ) {
            setIsSubMenuOpen(true);
        } else {
            setIsSubMenuOpen(false);
        }
    }, [location]);

    const handleMenuItemClick = (menuItem) => {
        setActiveMenuItem(menuItem);
        setActiveSubMenuItem(menuItem);
    };

    const handleSubMenuItemClick = (subMenuItem) => {
        setActiveSubMenuItem(subMenuItem);
        setActiveMenuItem(subMenuItem);
    };

    return (
        <div style={{ width: '225px !important' }}>
            <Sidebar className='sidebarNew'>
                <div style={{ textAlign: 'center', paddingTop: '10%', paddingBottom: '20%' }}>
                    <img src={iTechLogo} style={{ width: '140px', height: '62.15px' }} alt="iTech Logo" />
                </div>
                <Menu >
                    <Link to="/Dashboard" className='no-underline' onClick={() => handleMenuItemClick("/Dashboard", "Dashboard")}>
                        <MenuItem
                            className="menuItem1 fontweightSemiBold"
                            style={{
                                fontWeight: "500",
                                color: activeMenuItem === "/Dashboard" ? "#FFFFFF" : "#000000",
                                background: activeMenuItem === "/Dashboard" ? "#605BFF" : "",
                            }}
                        >
                            <img src={HomeIcon} alt='DashboardIcon' style={{ paddingRight: '10px', paddingBottom: '5px', filter: activeMenuItem === "/Dashboard" ? "invert(1)" : "invert(0)" }} /> <span style={{ color: activeMenuItem === "/Dashboard" ? "#FFFFFF" : "#000000" }}>Dashboard</span>
                        </MenuItem>
                    </Link>
                    <Link to="/Settings" className='no-underline' onClick={() => handleMenuItemClick("/Settings", "Settings")}>
                        <MenuItem
                            className="menuItem1 fontweightSemiBold"
                            style={{
                                fontWeight: "500",
                                color:
                                    activeMenuItem !== "/Dashboard" &&
                                        activeMenuItem !== "/AssetCreationView" &&
                                        !activeMenuItem?.includes("/AssetCreationAdd") &&
                                        !activeMenuItem?.includes("/FinancialTransaction") &&
                                        !activeMenuItem?.includes("/CheckOut") &&
                                        !activeMenuItem?.includes("/CheckIn") &&
                                        !activeMenuItem?.includes("/Transfer") &&
                                        !activeMenuItem?.includes("/AssetDetailsView") ? "#FFFFFF" : "#000000",
                                background:
                                    activeMenuItem !== "/Dashboard" &&
                                        activeMenuItem !== "/AssetCreationView" &&
                                        !activeMenuItem?.includes("/AssetCreationAdd") &&
                                        !activeMenuItem?.includes("/FinancialTransaction") &&
                                        !activeMenuItem?.includes("/CheckOut") &&
                                        !activeMenuItem?.includes("/CheckIn") &&
                                        !activeMenuItem?.includes("/Transfer") &&
                                        !activeMenuItem?.includes("/AssetDetailsView") ? "#605BFF" : "",
                            }}
                        >
                            <img src={SettingsIcon} alt='SettingsIcon' style={{
                                paddingRight: '10px', paddingBottom: '5px',
                                filter:
                                    activeMenuItem !== "/Dashboard" &&
                                        activeMenuItem !== "/AssetCreationView" &&
                                        !activeMenuItem?.includes("/AssetCreationAdd") &&
                                        !activeMenuItem?.includes("/FinancialTransaction") &&
                                        !activeMenuItem?.includes("/CheckOut") &&
                                        !activeMenuItem?.includes("/CheckIn") &&
                                        !activeMenuItem?.includes("/Transfer") &&
                                        !activeMenuItem?.includes("/AssetDetailsView") ? "invert(0)" : "invert(1)"
                            }} />
                            <span style={{
                                color:
                                    activeMenuItem !== "/Dashboard" &&
                                        activeMenuItem !== "/AssetCreationView" &&
                                        !activeMenuItem?.includes("/AssetCreationAdd") &&
                                        !activeMenuItem?.includes("/FinancialTransaction") &&
                                        !activeMenuItem?.includes("/CheckOut") &&
                                        !activeMenuItem?.includes("/CheckIn") &&
                                        !activeMenuItem?.includes("/Transfer") &&
                                        !activeMenuItem?.includes("/AssetDetailsView") ? "#FFFFFF" : "#000000"
                            }}>
                                Settings</span>
                        </MenuItem>
                    </Link>

                    <SubMenu
                        label={
                            <span style={{ display: 'flex', alignItems: 'center', color: 
                            activeSubMenuItem?.includes("/AssetCreationAdd") || 
                            activeSubMenuItem === "/AssetCreationView" || 
                            activeSubMenuItem === "/FinancialTransaction" || 
                            activeSubMenuItem === "/CheckOut" || 
                            activeSubMenuItem === "/CheckIn" || 
                            activeSubMenuItem === "/Transfer" || 
                            activeSubMenuItem?.includes("/AssetDetailsView") ? "#FFFFFF" : "#000000" }}>
                                <img
                                    src={SettingsIcon}
                                    alt='SettingsIcon'
                                    style={{
                                        marginRight: '14px',
                                        paddingBottom: '5px',
                                        filter: 
                                        activeSubMenuItem?.includes("/AssetCreationAdd") || 
                                        activeSubMenuItem === "/AssetCreationView" || 
                                        activeSubMenuItem === "/FinancialTransaction" || 
                                        activeSubMenuItem === "/CheckOut" || 
                                        activeSubMenuItem === "/CheckIn" || 
                                        activeSubMenuItem === "/Transfer" || 
                                        activeSubMenuItem?.includes("/AssetDetailsView") ? "invert(0)" : "invert(1)"
                                    }}
                                />
                                Asset
                                {/* <img
                                    src={SideMenuEpand}
                                    alt='CollapseIcon'
                                    style={{
                                        marginLeft: 'auto',
                                        marginRight: '14px',
                                        paddingBottom: '5px',
                                        transform: isSubMenuOpen ? 'rotate(0deg)' : 'rotate(180deg)',
                                        transition: 'transform 0.3s ease',
                                        filter: isSubMenuOpen ? "invert(0)" : "invert(1)"
                                    }}
                                /> */}
                            </span>
                        }
                        className="menuItem1 fontweightSemiBold"
                        style={{
                            fontWeight: "500",
                            color: 
                            activeSubMenuItem?.includes("/AssetCreationAdd") || 
                            activeSubMenuItem === "/AssetCreationView" || 
                            activeSubMenuItem === "/FinancialTransaction" || 
                            activeSubMenuItem === "/CheckOut" || 
                            activeSubMenuItem === "/CheckIn" || 
                            activeSubMenuItem === "/Transfer" || 
                            activeSubMenuItem?.includes("/AssetDetailsView") ? "#FFFFFF" : "#000000",
                            background: 
                            activeSubMenuItem?.includes("/AssetCreationAdd") || 
                            activeSubMenuItem === "/AssetCreationView" || 
                            activeSubMenuItem === "/FinancialTransaction" || 
                            activeSubMenuItem === "/CheckOut" || 
                            activeSubMenuItem === "/CheckIn" || 
                            activeSubMenuItem === "/Transfer" || 
                            activeSubMenuItem?.includes("/AssetDetailsView") ? "#605BFF" : "",
                        }}
                        defaultOpen={isSubMenuOpen}

                    >
                        <Link to="/AssetCreationView" className='no-underline' onClick={() => handleSubMenuItemClick("/AssetCreationView")}>
                            <MenuItem
                                className="menuItem1 fontweightSemiBold"
                                style={{
                                    fontWeight: "500",
                                    background: activeSubMenuItem === "/AssetCreationView" || activeSubMenuItem?.includes("/AssetDetailsView") ? "#FFFFFF" : "",
                                }}
                            >
                                <span style={{
                                    fontWeight: "500",
                                    marginLeft: "14px",
                                    color: activeSubMenuItem === "/AssetCreationView" || activeSubMenuItem?.includes("/AssetDetailsView") ? "#605BFF" : "#000000",
                                }}>View Asset</span>
                            </MenuItem>
                        </Link>
                        <Link to="/AssetCreationAdd?id=0&view=0" className='no-underline' onClick={() => handleSubMenuItemClick("/AssetCreationAdd?id=0&view=0")}>
                            <MenuItem
                                className="menuItem1 fontweightSemiBold"
                                style={{
                                    fontWeight: "500",
                                    background: activeSubMenuItem?.includes("/AssetCreationAdd") ? "#FFFFFF" : "",
                                }}
                            >
                                <span style={{
                                    fontWeight: "500",
                                    marginLeft: "14px",
                                    color: activeSubMenuItem?.includes("/AssetCreationAdd") ? "#605BFF" : "#000000",
                                }}>Create Asset</span>

                            </MenuItem>
                        </Link>
                        <Link to="/FinancialTransaction" className='no-underline' onClick={() => handleSubMenuItemClick("/FinancialTransaction")}>
                            <MenuItem
                                className="menuItem1 fontweightSemiBold"
                                style={{
                                    fontWeight: "500",
                                    background: activeSubMenuItem?.includes("/FinancialTransaction") ? "#FFFFFF" : "",
                                }}
                            >
                                <span style={{
                                    fontWeight: "500",
                                    marginLeft: "14px",
                                    color: activeSubMenuItem?.includes("/FinancialTransaction") ? "#605BFF" : "#000000",
                                }}>Financial Transaction</span>

                            </MenuItem>
                        </Link>
                        <Link to="/CheckOut" className='no-underline' onClick={() => handleSubMenuItemClick("/CheckOut")}>
                            <MenuItem
                                className="menuItem1 fontweightSemiBold"
                                style={{
                                    fontWeight: "500",
                                    background: activeSubMenuItem?.includes("/CheckOut") ? "#FFFFFF" : "",
                                }}
                            >
                                <span style={{
                                    fontWeight: "500",
                                    marginLeft: "14px",
                                    color: activeSubMenuItem?.includes("/CheckOut") ? "#605BFF" : "#000000",
                                }}>Check Out</span>

                            </MenuItem>
                        </Link>
                        <Link to="/CheckIn" className='no-underline' onClick={() => handleSubMenuItemClick("/CheckIn")}>
                            <MenuItem
                                className="menuItem1 fontweightSemiBold"
                                style={{
                                    fontWeight: "500",
                                    background: activeSubMenuItem?.includes("/CheckIn") ? "#FFFFFF" : "",
                                }}
                            >
                                <span style={{
                                    fontWeight: "500",
                                    marginLeft: "14px",
                                    color: activeSubMenuItem?.includes("/CheckIn") ? "#605BFF" : "#000000",
                                }}>Check In</span>

                            </MenuItem>
                        </Link>
                        <Link to="/Transfer" className='no-underline' onClick={() => handleSubMenuItemClick("/Transfer")}>
                            <MenuItem
                                className="menuItem1 fontweightSemiBold"
                                style={{
                                    fontWeight: "500",
                                    background: activeSubMenuItem?.includes("/Transfer") ? "#FFFFFF" : "",
                                }}
                            >
                                <span style={{
                                    fontWeight: "500",
                                    marginLeft: "14px",
                                    color: activeSubMenuItem?.includes("/Transfer") ? "#605BFF" : "#000000",
                                }}>Transfer</span>

                            </MenuItem>
                        </Link>
                    </SubMenu>

                </Menu>
            </Sidebar>
        </div>
    );
};

SideMenuNew.propTypes = {
    activeMenuItem: PropTypes.string.isRequired,
    setActiveMenuItem: PropTypes.func.isRequired,
};

export default SideMenuNew;
