import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { PieChart } from 'react-minimal-pie-chart';

import LoadingSpinner from '../../CommomPages/LoadingSpinner';

import SettingsSvg from '../../assets/icon/settingDashboard.svg';


const Dashboard = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [userAccessArray] = useState([
        { id: 1, assetTagID: "AST3", description: "Laptop Mouse - Dell Inspiron", checkOutDate: "01-19-2024", DueDate: "No Due Date", assignedTo: "Shanmugapriya" },
        { id: 1, assetTagID: "LAPTC00001", description: "Dell Inspiron 15 300", checkOutDate: "01-19-2024", DueDate: "No Due Date", assignedTo: "Shanmugapriya" }
    ]);

    setTimeout(() => {
        setIsLoading(false);
    }, 3000);
    return (
        <div className='bacgroundStyle'>
            <ToastContainer theme="colored" />
            {isLoading && <LoadingSpinner />}

            {!isLoading && (
                <div>
                    <div className="pagetitleDashboard">
                        <div className="PageheaderDashboard boxShadow fontFamily fontsize24 fontweightSemiBold">Dashboard</div>
                    </div>
                    <div className="useScrollbar">
                        <div className="card mt-4">
                            <div className="card-body-Dashboard">
                                <div className='PageDashboardHeader mBottom24'>
                                    <div className='blackTextColor fontFamily fontweightMedium fontsize20'>
                                        Dashboard & Statistics
                                    </div>
                                    <div>
                                        <button type="button" className="fontFamily fontweightRegular fontsize14 manageDashboardBtn"> Manage Dashboard <img src={SettingsSvg} alt="Settings" /></button>
                                    </div>
                                </div>

                                <div className='d-flex mBottom24'>
                                    <div className="dashboardStatisticsDetails mRight20 pTop24 text-center">
                                        <div className='ashTextColor fontFamily fontweightRegular fontsize16 pBottom20'>
                                            Number of Active
                                        </div>
                                        <div className='blackTextColor fontFamily fontweightRegular fontsize16'>
                                            256
                                        </div>
                                    </div>

                                    <div className="dashboardStatisticsDetails mRight20 pTop24 text-center">
                                        <div className='ashTextColor fontFamily fontweightRegular fontsize16 pBottom20'>
                                            NAV; Net Asset v...
                                        </div>
                                        <div className='blackTextColor fontFamily fontweightRegular fontsize16 '>
                                            ₹10,46,187
                                        </div>
                                    </div>

                                    <div className="dashboardStatisticsDetails mRight20 pTop24 text-center">
                                        <div className='ashTextColor fontFamily fontweightRegular fontsize16 pBottom20'>
                                            Value of Asset
                                        </div>
                                        <div className='blackTextColor fontFamily fontweightRegular fontsize16 '>
                                            ₹11,51,270
                                        </div>
                                    </div>

                                    <div className="dashboardStatisticsDetails pTop24">
                                        <div className='ashTextColor fontFamily fontweightRegular fontsize16 pBottom20 text-center'>
                                            Purchase in Fisc...
                                        </div>
                                        <div className='blackTextColor fontFamily fontweightRegular fontsize16 pBottom5 text-center'>
                                            ₹151,270
                                        </div>
                                        <div className='ashTextColor fontFamily fontweightRegular fontsize14 text-end pRight10'>
                                            6 Assets
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex'>

                                    <div className='dashbordChartBox mRight20'>
                                        <div className='blackTextColor fontFamily fontweightRegular fontsize14 pBottom12 pLeft20 pTop12'>
                                            Asset Value
                                        </div>
                                        <hr></hr>
                                        <div>
                                            <div className="pie-chart-wrapper mTop20 pRight12">
                                                <PieChart
                                                    data={[
                                                        { title: 'product1', value: 10, color: '#F90C0C' }, // Brown color
                                                        { title: 'product2', value: 20, color: '#FA800E' }, // Red color
                                                    ]}
                                                    labelStyle={{ display: 'none' }}
                                                    style={{ height: '250px', width: '250px' }}
                                                />
                                                <div className="chart-legend">
                                                    <div className="legend-item">
                                                        <span className="legend-color" style={{ backgroundColor: '#F90C0C' }}></span>
                                                        <span>Computer Equipment</span>
                                                    </div>
                                                    <div className="legend-item">
                                                        <span className="legend-color" style={{ backgroundColor: '#FA800E' }}></span>
                                                        <span>Equipment</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='dashbordAssetBox'>
                                        <div className='blackTextColor fontFamily fontweightRegular fontsize14 pBottom12 pLeft20 pRight20 pTop12'>
                                            <div className='d-flex justify-content-between'>
                                                <div className='d-flex'>
                                                    <div className='pRight60'>
                                                        Feeds
                                                    </div>
                                                    <div className='pRight60'>
                                                        Checked Out
                                                    </div>
                                                    <div className='pRight60'>
                                                        Checked In
                                                    </div>
                                                    <div className='pRight60'>
                                                        Under Repair
                                                    </div>
                                                    <div className='pRight60'>
                                                        Lost
                                                    </div>
                                                </div>
                                                <div className='d-flex'>
                                                    <img src={SettingsSvg} alt="Settings" />
                                                </div>
                                            </div>
                                        </div>
                                        <hr></hr>

                                        <div className='mTop20 mRight20 mLeft20'>
                                            <table className="w-100 ">
                                                <thead>
                                                    <tr className="text-left useTableHeight">
                                                        <th className="blackTextColor fontFamily fontweightMedium fontsize16 useHeaderBg " style={{ width: "12%", padding: '2px 2px 2px 3%' }}>Asset Tag ID</th>
                                                        <th className="blackTextColor fontFamily fontweightMedium fontsize16 useHeaderBg p-2 " style={{ width: "18%" }}>Description</th>
                                                        <th className="blackTextColor fontFamily fontweightMedium fontsize16 useHeaderBg p-2 " style={{ width: "18%" }}>Check-Out Date</th>
                                                        <th className="blackTextColor fontFamily fontweightMedium fontsize16 useHeaderBg p-2 " style={{ width: "18%" }}>Due Date</th>
                                                        <th className="blackTextColor fontFamily fontweightMedium fontsize16 useHeaderBg p-2 " style={{ width: "10%" }}>Assigned To</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(
                                                        userAccessArray.map((UserAcces, index) => (
                                                            <tr key={UserAcces.Rowid}>
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14  ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`} style={{ padding: "2px 2px 2px 3%" }}>{UserAcces.assetTagID}</td>
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>{UserAcces.description}</td>
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>{UserAcces.checkOutDate}</td>
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>{UserAcces.DueDate}</td>
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}> {UserAcces.assignedTo}</td>
                                                            </tr>
                                                        ))
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Dashboard;
