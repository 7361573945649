import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import Select from "react-select";
import DatePicker from "react-datepicker";
import { useForm, Controller } from 'react-hook-form';
import { Modal, Row, Col, Form } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";

import { getDateFormat, validateDate, getRegexForDateFormat } from "../../../CommomPages/Config";

import { getSupplier } from "../../../Services/SupplierMasterService";
import { getDepartment } from "../../../Services/DepartmentsAndTeamService";
import { getBUData, getBRData } from "../../../Services/BusinessEntityNewService";
import { getAsset, getAssetStatus, bulkUpdateAsset } from "../../../Services/AssetSettingsService";
import { getCategory, getSubCategory, getBrand } from '../../../Services/AssetCategoriesAndBrandService';

import CalendarWithBG from "../../../assets/icon/CalendarWithBG.svg";


const customStyles = {
    placeholder: (provided) => ({
        ...provided,
        fontFamily: "Nunito, sans-serif",
        fontWeight: 400,
        fontSize: "14px",
        color: "#B0ACAC",
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 9999,
        maxHeight: 150,
        overflowY: 'auto',
    }),
};
const BulkUpdatePopup = ({ showModal, closeModal, selectedAssets }) => {
    const [currentStep, setCurrentStep] = useState(1);
    const [selectedFields, setSelectedFields] = useState([]);
    const [departmentArray, setDepartmentArray] = useState([]);
    const [businessUnitArray, setBusinessUnitArray] = useState([]);
    const [businessBranchArray, setBusinessBranchArray] = useState([]);
    const [categoryArray, setcategoryArray] = useState([]);
    const [subCategoryArray, setsubCategoryArray] = useState([]);
    const [brandArray, setbrandArray] = useState([]);
    const [supplierArray, setSupplierArray] = useState([]);
    const [dateFormat, setDateFormat] = useState('');
    const [purchaseDate, setPurchaseDate] = useState(null);
    const [selectedCheckBoxArray, setselectedCheckBoxArray] = useState([]);
    const [assetArray, setAssetArray] = useState([]);
    const [updatedAssetData, setupdatedAssetData] = useState([]);
    const fields = [
        { label: "Asset Name", value: "assetName" },
        { label: "Category", value: "category" },
        { label: "Sub Category", value: "subCategory" },
        { label: "Brand", value: "brand" },
        { label: "Model No.", value: "modelNo" },
        { label: "Serial No.", value: "serialNo" },
        { label: "Cost", value: "cost" },
        { label: "Supplier", value: "supplier" },
        // { label: "Purchase Date", value: "purchaseDate" },
        { label: "Unit", value: "unit" },
        { label: "Branch", value: "branch" },
        { label: "Department", value: "department" },
        // { label: "Asset Life (months)", value: "assetLife" },
        // { label: "Date Acquired", value: "dateAcquired" },
        // { label: "Asset Depreciation", value: "assetDepreciation" },
        // { label: "Depreciable Cost", value: "depreciableCost" },
        // { label: "Salvage Value", value: "salvageValue" },
    ];

    useEffect(() => {
        fetchAssetData();
        getAssetCategory();
        getAssetSubCategory();
        getAssetBrand();
        getBusinessUnit();
        getBusinessBranch();
        getDepartmentData();
        getSupplierData();

    }, []);
    const fetchAssetData = async () => {
        try {
            const dateCurrentFormat = await getDateFormat();
            const data = await getAsset();
            const assetStatus = await getAssetStatus();

            const finalData = data?.length > 0 ? data.map((asset, index) => {
                const brandAsset = brandArray?.find(x => x.id === asset?.brandId);
                const formattedDate = asset.purchaseDate ? format(new Date(asset.purchaseDate), dateCurrentFormat) : null;
                const getAssetStatusValue = assetStatus?.find(e => e.id === asset?.statusValue);
                return {
                    ...asset,
                    Rowid: index + 1,
                    formatedPurchaseDate: formattedDate,
                    brandName: brandAsset ? brandAsset.Status : '',
                    assetStatusValue: getAssetStatusValue?.labelAssignedValue
                };
            }) : [];

            const filterAssetData = [];
            for (let y = 0; y < selectedAssets.length; y++) {
                let filterData = finalData.filter((x) => x.id === selectedAssets[y])
                filterAssetData.push(...filterData);
            }
            setAssetArray(filterAssetData);
        } catch (error) {
            console.error("Error fetching Asset data:", error);
        } finally {

        }
    };
    const getAssetCategory = async () => {
        const data = await getCategory();
        const activeData = data.filter((item) => item.status === "Active");
        const finalData = activeData?.map((dept, index) => {
            return {
                ...dept,
                Rowid: index + 1,
            };
        });
        setcategoryArray(finalData);
    };
    const getAssetSubCategory = async () => {
        const data = await getSubCategory();
        const activeData = data.filter((item) => item.status === "Active");
        const finalData = activeData?.map((dept, index) => {
            return {
                ...dept,
                Rowid: index + 1,
            };
        });
        setsubCategoryArray(finalData);
    };
    const getAssetBrand = async () => {
        const data = await getBrand();
        const activeData = data.filter((item) => item.status === "Active");
        const finalData = activeData?.map((dept, index) => {
            return {
                ...dept,
                Rowid: index + 1,
            };
        });
        setbrandArray(finalData);
    };
    const getBusinessUnit = async () => {
        const data = await getBUData();
        const finalData = data?.map((unit, index) => {
            return {
                ...unit,
                Rowid: index + 1,
            };
        });
        setBusinessUnitArray(finalData);
    };
    const getBusinessBranch = async () => {
        const data = await getBRData();
        const activeData = data.filter((item) => item.bR_ISACTIVE === true);
        const finalData = activeData?.map((branch, index) => {
            return {
                ...branch,
                Rowid: index + 1,
            };
        });
        setBusinessBranchArray(finalData);
    };
    const getDepartmentData = async () => {
        const data = await getDepartment();
        const activeData = data.filter((item) => item.status === "Active");
        const finalData = activeData?.map((dept, index) => {
            return {
                ...dept,
                Rowid: index + 1,
            };
        });
        setDepartmentArray(finalData);
    };
    const getSupplierData = async () => {
        const SupplierData = await getSupplier();
        setSupplierArray(SupplierData);
        const dateCurrentFormat = await getDateFormat();
        setDateFormat(dateCurrentFormat);
    };
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control,
        reset,
        watch,
        getValues,
        resetField,
    } = useForm({
        defaultValues: {
            assetName: "",
            categoryId: 0,
            subCategoryId: 0,
            brandId: 0,
            modelNo: "",
            serialNo: "",
            cost: "",
            supplierId: 0,
            purchaseDate: null,
            unitId: 0,
            branchId: 0,
            departmentId: 0,
        },
    });
    const nextStep = () => {
        if (currentStep === 1) {
            reset();
            setselectedCheckBoxArray(selectedFields);
        }
        setCurrentStep(prev => Math.min(prev + 1, 3));
    };
    const prevStep = () => {
        setCurrentStep(prev => Math.max(prev - 1, 1));
    };
    const handleCloseModal = () => {
        closeModal();
        setCurrentStep(1);
    };
    const updateAsset = async () => {
        const response = await bulkUpdateAsset(updatedAssetData);
        if (response === "Updated") {
            handleCloseModal();
            window.location.reload();
        }
    };
    const handleCheckboxChange = (fieldValue) => {
        setSelectedFields(prev => {
            if (prev.includes(fieldValue)) {
                return prev.filter(value => value !== fieldValue);
            } else {
                return [...prev, fieldValue];
            }
        });
    };
    const onClickVerify = (fieldValues) => {
        let value = [];
        let finalAssetArray = [];
        if (fieldValues.assetName !== "") {
            const valueObj = {
                fieldName: 'assetName',
                newValue: fieldValues.assetName
            }
            value.push(valueObj);
        }
        if (fieldValues.branchId !== 0) {
            const valueObj = {
                fieldName: 'branch',
                newValue: fieldValues.branchId
            }
            value.push(valueObj);
        }
        if (fieldValues.brandId !== 0) {
            const valueObj = {
                fieldName: 'brand',
                newValue: fieldValues.brandId
            }
            value.push(valueObj);
        }
        if (fieldValues.categoryId !== 0) {
            const valueObj = {
                fieldName: 'category',
                newValue: fieldValues.categoryId
            }
            value.push(valueObj);
        }
        if (fieldValues.cost !== "") {
            const valueObj = {
                fieldName: 'cost',
                newValue: fieldValues.cost
            }
            value.push(valueObj);
        }
        if (fieldValues.departmentId !== 0) {
            const valueObj = {
                fieldName: 'department',
                newValue: fieldValues.departmentId
            }
            value.push(valueObj);
        }
        if (fieldValues.modelNo !== "") {
            const valueObj = {
                fieldName: 'modelNo',
                newValue: fieldValues.modelNo
            }
            value.push(valueObj);
        }
        if (fieldValues.purchaseDate !== null) {
            const valueObj = {
                fieldName: 'purchaseDate',
                newValue: fieldValues.purchaseDate
            }
            value.push(valueObj);
        }
        if (fieldValues.serialNo !== "") {
            const valueObj = {
                fieldName: 'serialNo',
                newValue: fieldValues.serialNo
            }
            value.push(valueObj);
        }
        if (fieldValues.subCategoryId !== 0) {
            const valueObj = {
                fieldName: 'subCategory',
                newValue: fieldValues.subCategoryId
            }
            value.push(valueObj);
        }
        if (fieldValues.supplierId !== 0) {
            const valueObj = {
                fieldName: 'supplier',
                newValue: fieldValues.supplierId
            }
            value.push(valueObj);
        }
        if (fieldValues.unitId !== 0) {
            const valueObj = {
                fieldName: 'unit',
                newValue: fieldValues.unitId
            }
            value.push(valueObj);
        }
        for (let i = 0; i < value.length; i++) {
            for (let j = 0; j < assetArray.length; j++) {
                if (value[i].fieldName === 'assetName') {
                    const arrayValue = {
                        assetRowID: assetArray[j].id,
                        assetID: assetArray[j].assetID,
                        fieldChange: 'Asset Name',
                        fieldName: 'assetName',
                        oldValue: assetArray[j].assetName,
                        newValue: value[i].newValue
                    }
                    finalAssetArray.push(arrayValue);
                }
                else if (value[i].fieldName === 'category') {
                    let OldcategoryValue = categoryArray.find(e => e.id === assetArray[j].categoryId);
                    let NewcategoryValue = categoryArray.find(e => e.id === value[i].newValue);
                    const arrayValue = {
                        assetRowID: assetArray[j].id,
                        assetID: assetArray[j].assetID,
                        fieldChange: 'categoryId',
                        fieldName: 'categoryId',
                        oldValue: OldcategoryValue?.categoryName,
                        newValue: NewcategoryValue?.categoryName
                    }
                    finalAssetArray.push(arrayValue);
                }
                else if (value[i].fieldName === 'subCategory') {
                    let OldSubcategoryValue = subCategoryArray.find(e => e.id === assetArray[j].subCategoryId);
                    let NewSubcategoryValue = subCategoryArray.find(e => e.id === value[i].newValue);
                    const arrayValue = {
                        assetRowID: assetArray[j].id,
                        assetID: assetArray[j].assetID,
                        fieldChange: 'Sub Category',
                        fieldName: 'subCategoryId',
                        oldValue: OldSubcategoryValue?.subCategoryName,
                        newValue: NewSubcategoryValue?.subCategoryName
                    }
                    finalAssetArray.push(arrayValue);
                }
                else if (value[i].fieldName === 'brand') {
                    let OldbrandValue = brandArray.find(e => e.id === assetArray[j].brandId);
                    let NewbrandValue = brandArray.find(e => e.id === value[i].newValue);
                    const arrayValue = {
                        assetRowID: assetArray[j].id,
                        assetID: assetArray[j].assetID,
                        fieldChange: 'Brand',
                        fieldName: 'brandId',
                        oldValue: OldbrandValue?.brandName,
                        newValue: NewbrandValue?.brandName
                    }
                    finalAssetArray.push(arrayValue);
                }
                else if (value[i].fieldName === 'modelNo') {
                    const arrayValue = {
                        assetRowID: assetArray[j].id,
                        assetID: assetArray[j].assetID,
                        fieldChange: 'Model No',
                        fieldName: 'modelNo',
                        oldValue: assetArray[j].modelNo,
                        newValue: value[i].newValue
                    }
                    finalAssetArray.push(arrayValue);
                }
                else if (value[i].fieldName === 'serialNo') {
                    const arrayValue = {
                        assetRowID: assetArray[j].id,
                        assetID: assetArray[j].assetID,
                        fieldChange: 'Serial No',
                        fieldName: 'serialNo',
                        oldValue: assetArray[j].serialNo,
                        newValue: value[i].newValue
                    }
                    finalAssetArray.push(arrayValue);
                }
                else if (value[i].fieldName === 'cost') {
                    const arrayValue = {
                        assetRowID: assetArray[j].id,
                        assetID: assetArray[j].assetID,
                        fieldChange: 'Cost',
                        fieldName: 'cost',
                        oldValue: assetArray[j].cost,
                        newValue: value[i].newValue
                    }
                    finalAssetArray.push(arrayValue);
                }
                else if (value[i].fieldName === 'supplier') {
                    let OldSupplierValue = supplierArray.find(e => e.id === assetArray[j].supplierId);
                    let NewSupplierValue = supplierArray.find(e => e.id === value[i].newValue);
                    const arrayValue = {
                        assetRowID: assetArray[j].id,
                        assetID: assetArray[j].assetID,
                        fieldChange: 'Supplier',
                        fieldName: 'supplierId',
                        oldValue: OldSupplierValue?.supplierName,
                        newValue: NewSupplierValue?.supplierName
                    }
                    finalAssetArray.push(arrayValue);
                }
                else if (value[i].fieldName === 'purchaseDate') {
                    const arrayValue = {
                        assetRowID: assetArray[j].id,
                        assetID: assetArray[j].assetID,
                        fieldChange: 'Purchase Date',
                        fieldName: 'purchaseDate',
                        oldValue: assetArray[j].assetName,
                        newValue: value[i].newValue
                    }
                    finalAssetArray.push(arrayValue);
                }
                else if (value[i].fieldName === 'unit') {
                    let OldunitValue = businessUnitArray.find(e => e.bU_ID === assetArray[j].unitId);
                    let NewunitValue = businessUnitArray.find(e => e.bU_ID === value[i].newValue);
                    const arrayValue = {
                        assetRowID: assetArray[j].id,
                        assetID: assetArray[j].assetID,
                        fieldChange: 'Unit',
                        fieldName: 'unitId',
                        oldValue: OldunitValue?.bU_NAME,
                        newValue: NewunitValue?.bU_NAME
                    }
                    finalAssetArray.push(arrayValue);
                }
                else if (value[i].fieldName === 'branch') {
                    let OldbranchValue = businessBranchArray.find(e => e.bR_ID === assetArray[j].branchId);
                    let NewbranchValue = businessBranchArray.find(e => e.bR_ID === value[i].newValue);
                    const arrayValue = {
                        assetRowID: assetArray[j].id,
                        assetID: assetArray[j].assetID,
                        fieldChange: 'Branch',
                        fieldName: 'branchId',
                        oldValue: OldbranchValue?.bR_NAME,
                        newValue: NewbranchValue?.bR_NAME
                    }
                    finalAssetArray.push(arrayValue);
                }
                else if (value[i].fieldName === 'department') {
                    let OlddepartmentValue = departmentArray.find(e => e.id === assetArray[j].departmentId);
                    let NewdepartmentValue = departmentArray.find(e => e.id === value[i].newValue);
                    const arrayValue = {
                        assetRowID: assetArray[j].id,
                        assetID: assetArray[j].assetID,
                        fieldChange: 'Department',
                        fieldName: 'departmentId',
                        oldValue: OlddepartmentValue?.departmentName,
                        newValue: NewdepartmentValue?.departmentName
                    }
                    finalAssetArray.push(arrayValue);
                }
            }
        }
        console.log(finalAssetArray)
        setupdatedAssetData(finalAssetArray);
        setCurrentStep(prev => Math.min(prev + 1, 3));
    }
    const renderStepContent = () => {
        switch (currentStep) {
            case 1:
                return (
                    <div>
                        <div className="fontFamily fontweightMedium fontsize16 blackTextColor" style={{ maxHeight: '500px', margin: '15px 0px' }}>
                            Step 1: Select fields to modify
                        </div>
                        <Row className="mt-3">
                            <div className="fontFamily fontweightMedium fontsize16 grayTextColor blackTextColor">Standard fields</div>
                            {fields.map((field, index) => (
                                <Col key={index} xs={4}>
                                    <Form.Check
                                        className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24"
                                        label={field.label}
                                        checked={selectedFields.includes(field.value)}
                                        onChange={() => handleCheckboxChange(field.value)}
                                    />
                                </Col>
                            ))}
                        </Row>
                    </div>
                );
            case 2:
                return (
                    <div>
                        <div className="fontFamily fontweightMedium fontsize16 blackTextColor">
                            Step 2: Enter Values for Fields
                            <div className="yaxisScrollerBulkUpdate" style={{ maxHeight: '400px', margin: '15px 0px' }}>
                                {selectedCheckBoxArray.map((field, index) => {
                                    if (field === "assetName") {
                                        return (
                                            <div key={index}>
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                                    Asset Name
                                                </div>
                                                <input
                                                    className="textinputCustomAsset"
                                                    placeholder=""
                                                    {...register("assetName")}
                                                />
                                            </div>
                                        );
                                    }
                                    if (field === "category") {
                                        return (
                                            <div key={index}>
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                                    Category
                                                </div>
                                                <Controller
                                                    name="categoryId"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            value={categoryArray.find(option => option.id === field.value) || null}
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption ? selectedOption.id : null);
                                                            }}
                                                            options={categoryArray}
                                                            placeholder=""
                                                            getOptionLabel={(option) => option.categoryName}
                                                            getOptionValue={(option) => option.id}
                                                            isClearable
                                                            isSearchable
                                                            styles={customStyles}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        );
                                    }
                                    if (field === "subCategory") {
                                        return (
                                            <div key={index}>
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                                    Sub Category
                                                </div>
                                                <Controller
                                                    name="subCategoryId"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            value={subCategoryArray.find(option => option.id === field.value) || null}
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption ? selectedOption.id : null);
                                                            }}
                                                            options={subCategoryArray}
                                                            placeholder=""
                                                            getOptionLabel={(option) => option.subCategoryName}
                                                            getOptionValue={(option) => option.id}
                                                            isClearable
                                                            isSearchable
                                                            styles={customStyles}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        )
                                    }
                                    if (field === "brand") {
                                        return (
                                            <div key={index}>
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                                    Brand
                                                </div>
                                                <Controller
                                                    name="brandId"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            value={brandArray.find(option => option.id === field.value) || null}
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption ? selectedOption.id : null);
                                                            }}
                                                            options={brandArray}
                                                            placeholder=""
                                                            getOptionLabel={(option) => option.brandName}
                                                            getOptionValue={(option) => option.id}
                                                            isClearable
                                                            isSearchable
                                                            styles={customStyles}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        )
                                    }
                                    if (field === "modelNo") {
                                        return (
                                            <div key={index}>
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                                    Model No.
                                                </div>
                                                <input
                                                    className="textinputCustomAsset"
                                                    placeholder=""
                                                    {...register("modelNo")}
                                                />
                                            </div>
                                        )
                                    }
                                    if (field === "serialNo") {
                                        return (
                                            <div key={index}>
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                                    Serial No.
                                                </div>
                                                <input
                                                    className="textinputCustomAsset"
                                                    placeholder=""
                                                    {...register("serialNo")}
                                                />
                                            </div>
                                        )
                                    }
                                    if (field === "cost") {
                                        return (
                                            <div key={index}>
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                                    Cost
                                                </div>
                                                <input
                                                    className="textinputCustomAsset"
                                                    placeholder=""
                                                    {...register("cost")}
                                                />
                                            </div>
                                        )
                                    }
                                    if (field === "supplier") {
                                        return (
                                            <div key={index}>
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                                    Supplier
                                                </div>
                                                <Controller
                                                    name="supplierId"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            value={supplierArray.find((c) => c.id === field.value) || null}
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption ? selectedOption.id : null);
                                                            }}
                                                            options={supplierArray}
                                                            getOptionLabel={(option) => option.supplierName}
                                                            getOptionValue={(option) => option.id}
                                                            placeholder=""
                                                            isClearable
                                                            isSearchable
                                                            styles={customStyles}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        )
                                    }
                                    // if (field === "purchaseDate") {
                                    //     return (
                                    //         <div key={index}>
                                    //             <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                    //                 Purchase Date
                                    //             </div>
                                    //             <div className="d-flex">
                                    //                 <Controller
                                    //                     name="purchaseDate"
                                    //                     control={control}
                                    //                     // rules={{
                                    //                     //     validate: {
                                    //                     //         validDate: (value) => {
                                    //                     //             // If the value is empty, it's valid because the field is optional
                                    //                     //             if (!value) {
                                    //                     //                 return true;
                                    //                     //             }

                                    //                     //             // Check if the value is a valid date object
                                    //                     //             const dateValue = new Date(value);
                                    //                     //             if (isNaN(dateValue.getTime())) {
                                    //                     //                 return "Invalid date value";
                                    //                     //             }

                                    //                     //             // Format the date and validate it
                                    //                     //             const formattedDate = format(dateValue, dateFormat);
                                    //                     //             const [day, month, year] = formattedDate.split('/');
                                    //                     //             const selectedDate = validateDate(dateFormat, day, month, year);

                                    //                     //             // Get today's date for comparison
                                    //                     //             const today = new Date();
                                    //                     //             today.setHours(0, 0, 0, 0);

                                    //                     //             // Check if selected date is in the future
                                    //                     //             if (selectedDate > today) {
                                    //                     //                 return "Purchase Date cannot be in the future";
                                    //                     //             }

                                    //                     //             // Validate date format using regex
                                    //                     //             const regex = getRegexForDateFormat(dateFormat);
                                    //                     //             if (!regex.test(formattedDate)) {
                                    //                     //                 return "Invalid date format (" + dateFormat + ")";
                                    //                     //             }
                                    //                     //             return true;
                                    //                     //         },
                                    //                     //     },
                                    //                     // }}
                                    //                     render={({ field }) => (
                                    //                         <DatePicker
                                    //                             {...field}
                                    //                             selected={field.value}
                                    //                             onChange={(date) => {
                                    //                                 field.onChange(date);
                                    //                             }}
                                    //                             // dateFormat={dateFormat}
                                    //                             className="customDatePicker date-picker-input"
                                    //                             ref={(input) => field.ref(input?.inputElement)}
                                    //                         />
                                    //                     )}
                                    //                 />
                                    //                 <img src={CalendarWithBG} alt="CalendarWithBG" height={45} />
                                    //             </div>
                                    //         </div>
                                    //     )
                                    // }
                                    if (field === "unit") {
                                        return (
                                            <div key={index}>
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                                    Unit
                                                </div>
                                                <Controller
                                                    name="unitId"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            value={businessUnitArray.find((c) => c.bU_ID === field.value) || null}
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption ? selectedOption.bU_ID : null);
                                                            }}
                                                            options={businessUnitArray}
                                                            getOptionLabel={(option) => option.bU_NAME}
                                                            getOptionValue={(option) => option.bU_ID}
                                                            placeholder=""
                                                            isClearable
                                                            isSearchable
                                                            styles={customStyles}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        )
                                    }
                                    if (field === "branch") {
                                        return (
                                            <div key={index}>
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                                    Branch
                                                </div>
                                                <Controller
                                                    name="branchId"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            value={businessBranchArray.find((c) => c.bR_ID === field.value) || null}
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption ? selectedOption.bR_ID : null);
                                                            }}
                                                            options={businessBranchArray}
                                                            getOptionLabel={(option) => option.bR_NAME}
                                                            getOptionValue={(option) => option.bR_ID}
                                                            placeholder=""
                                                            isClearable
                                                            isSearchable
                                                            styles={customStyles}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        )
                                    }
                                    if (field === "department") {
                                        return (
                                            <div key={index}>
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                                    Department
                                                </div>
                                                <Controller
                                                    name="departmentId"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            value={departmentArray.find((c) => c.id === field.value) || null}
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption ? selectedOption.id : null);
                                                            }}
                                                            options={departmentArray}
                                                            getOptionLabel={(option) => option.departmentName}
                                                            getOptionValue={(option) => option.id}
                                                            placeholder=""
                                                            isClearable
                                                            isSearchable
                                                            styles={customStyles}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        )
                                    }
                                    return (
                                        <div>No check box was selected</div>
                                    ); // Make sure to return null for any cases not handled
                                })}
                            </div>
                        </div>
                    </div >
                );
            case 3:
                return (
                    <div className="yaxisScrollerBulkUpdate" style={{ maxHeight: '500px', margin: '15px 0px' }}>
                        <div className="fontFamily fontweightMedium fontsize16 blackTextColor">
                            Step 3: Verify Changes and Update Assets
                            <div className="p-1">
                                <div className="~EmpBoxShadow">
                                    <div className="" style={{ padding: "10px" }}>
                                        <table className="w-100 mt-2">
                                            <thead>
                                                <tr className="text-left empTableHeight">
                                                    <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg  " style={{ width: "12%", textAlign: 'center' }} > Asset ID </th>
                                                    <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 " style={{ width: "15%", textAlign: 'center' }} > Field to Change </th>
                                                    <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 " style={{ width: "15%", textAlign: 'center' }} > Old Value </th>
                                                    <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 " style={{ width: "12%", textAlign: 'center' }} > New Value </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {updatedAssetData.length > 0 && (
                                                    updatedAssetData.map((Asset, index) => (
                                                        <tr key={Asset.Rowid} style={{ height: "59px" }}>
                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} style={{ textAlign: 'center' }} >{Asset.assetID}</td>
                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} style={{ textAlign: 'center' }} >{Asset.fieldChange}</td>
                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} style={{ textAlign: 'center' }} >{Asset.oldValue}</td>
                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} style={{ textAlign: 'center' }} >{Asset.newValue}</td>
                                                        </tr>
                                                    ))


                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <Modal show={showModal} onHide={handleCloseModal} centered size="xl" backdrop="static" style={{ height: '800px' }}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="fontFamily fontweightMedium fontsize16 blackTextColor">
                        Instantly add or update information for a group of assets
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {renderStepContent()}
            </Modal.Body>
            <Modal.Footer className="d-flex">
                <div>
                    <button type="button" className="fontFamily fontweightRegular fontsize16 importBtn" onClick={handleCloseModal}>
                        Cancel
                    </button>
                    {currentStep > 1 && (
                        <button type="button" className="fontFamily fontweightRegular fontsize16 importBtn" onClick={prevStep}>
                            Back
                        </button>
                    )}
                </div>
                <div>
                    {currentStep === 1 ? (
                        <button
                            type="button"
                            className="buttonAddNew fontFamily fontweightRegular fontsize16 coloredButtonAdd"
                            onClick={nextStep}
                        >
                            Next
                        </button>
                    ) : currentStep === 2 && selectedCheckBoxArray.length > 0 ? (
                        <button
                            type="button"
                            className="buttonAddNew fontFamily fontweightRegular fontsize16 coloredButtonAdd"
                            onClick={handleSubmit(onClickVerify)}
                        >
                            Verify
                        </button>
                    ) : selectedCheckBoxArray.length > 0 && updatedAssetData.length > 0 && (
                        <button
                            type="button"
                            className="buttonAddNew fontFamily fontweightRegular fontsize16 coloredButtonAdd"
                            onClick={updateAsset}
                        >
                            Update
                        </button>
                    )}
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default BulkUpdatePopup;
