import React from 'react';
import { Circles } from 'react-loader-spinner';

const LoadingSpinner = () => {
    return (
        <div className="loaderStyle">
            <Circles
                height="80"
                width="80"
                color="#605bff"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
        </div>
    );
};

export default LoadingSpinner;
