import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useForm, Controller } from 'react-hook-form';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { createProFormContract } from '../../../../Services/ProcessFormConfigurationService';
import { getSupplier } from '../../../../Services/SupplierMasterService';
import LoadingSpinner from '../../../../CommomPages/LoadingSpinner';
import { getDateFormat, getRegexForDateFormat } from '../../../../CommomPages/Config';

import rupeesvgIcon from '../../../../assets/icon/Rupee.svg';
import calendarsvgIcon from '../../../../assets/icon/calendarDate.svg';

const ProcessFormConfigurationContract = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [isContractView, setIsContractView] = useState(false);
    const [supplierArray, setSupplierArray] = useState([]);
    const [dateFormat, setDateFormat] = useState('');
    const [suplierSelectID, setSuplierSelectID] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const suppliersVal = await getSupplier();
            const dateFormat = await getDateFormat();
            setDateFormat(dateFormat);
            setSupplierArray(suppliersVal);
            setIsContractView(false);
            setIsLoading(false);
        };
        fetchData();
    }, []);

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        reset
    } = useForm({
        defaultValues: {
            contractLicenseTitle: '',
            description: '',
            hyperlink: '',
            contactNumber: '',
            suppiler: '',
            contactPerson: '',
            cost: '',
            phone: '',
            startDate: '',
            endDate: '',
            noOfLicense: '',
            noEndDate: false,
            contractIsForSoftware: false,
        }
    });

    const onCancel = () => {
        reset();
        navigate("/Settings");
    };

    setTimeout(() => {
        setIsLoading(false);
    }, 3000);

    const handleContractSubmit = async (data) => {
        setIsLoading(true);
        const { contractLicenseTitle, description, hyperlink, contactNumber, suppiler, contactPerson, cost, phone, startDate, endDate, noOfLicense, noEndDate, contractIsForSoftware } = data;
        const payload = {
            id: 0,
            contractLicenseTitle: contractLicenseTitle,
            description: description,
            hyperlink: hyperlink,
            contactNumber: contactNumber,
            suppiler: suppiler,
            contactPerson: contactPerson,
            cost: `${cost}`,
            phone: phone,
            startDate: startDate,
            endDate: endDate,
            noOfLicense: noOfLicense,
            noEndDate: noEndDate,
            contractIsForSoftware: contractIsForSoftware
        };

        const response = await createProFormContract(payload);

        if (response === "Inserted") {
            toast.success("Contract data Inserted Successfully");
            setTimeout(() => navigate("/Settings"), 1000);
        } else {
            toast.error(response);
        }
        setIsLoading(false);
    }

    return (
        <div className='bacgroundStyle'>
            <ToastContainer theme="colored" />
            {isLoading && <LoadingSpinner />}

            {!isLoading && (
                <div>
                    <div className="pagetitleCountry">
                        <div className="Pageheader fontFamily fontsize24 fontweightSemiBold">Asset - Process Form Configuration - Contract<div><InfoOutlinedIcon /></div></div>
                    </div>
                    <div className="customScrollbar">
                        <div className="card mt-4">
                            <div className="card-body">
                                <div className="blackTextColor fontFamily fontsize20 fontweightMedium">Contract</div>
                                <form className="p-2" noValidate onSubmit={handleSubmit(handleContractSubmit)}>
                                    <div className="row">
                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">Contract / License Title<span className="mandatoryCss"> *</span></div>
                                            <input
                                                className={isContractView ? "textBoxNewView" : "textBoxNew"}
                                                {...register('contractLicenseTitle', { required: 'Please Enter Contract / License Title' })}
                                                disabled={isContractView}
                                            />
                                            {errors.contractLicenseTitle && (
                                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                                    {errors.contractLicenseTitle.message}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-6">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">Description</div>
                                            <input
                                                className={isContractView ? "textBoxNewView" : "textBoxNew"}
                                                {...register('description')}
                                                disabled={isContractView}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">Hyperlink</div>
                                            <input
                                                className={isContractView ? "textBoxNewView" : "textBoxNew"}
                                                placeholder='http:www.example.com'
                                                {...register('hyperlink', {
                                                    pattern: {
                                                        value: /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)(?=\S)/,
                                                        message: 'Please enter a valid URL'
                                                    }
                                                })}
                                                disabled={isContractView}
                                            />
                                            {errors.hyperlink && (
                                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                                    {errors.hyperlink.message}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-6">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">Contact Number</div>
                                            <input
                                                type='number'
                                                className={isContractView ? "textBoxNewView" : "textBoxNew"}
                                                {...register('contactNumber', {
                                                    pattern: {
                                                        valueAsNumber: true,
                                                        min: 0,
                                                        value: /^\d{10}$/,
                                                        message: 'Contact number should be 10 digits'
                                                    }
                                                })}
                                                disabled={isContractView}
                                            />
                                            {errors.contactNumber && (
                                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                                    {errors.contactNumber.message}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24" >
                                                Suppiler<span className="mandatoryCss"> *</span>
                                            </div>
                                            <Controller
                                                name="suppiler"
                                                control={control}
                                                rules={{ required: 'Please select a suppiler' }}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        value={supplierArray.find(sup => sup.id === suplierSelectID) || null}
                                                        onChange={(selectedOption) => {
                                                            setSuplierSelectID(selectedOption ? selectedOption.id : null);
                                                            field.onChange(selectedOption ? selectedOption.id : null);
                                                        }}
                                                        options={supplierArray}
                                                        placeholder=""
                                                        getOptionLabel={(option) => option.supplierName}
                                                        getOptionValue={(option) => option.id}
                                                        isClearable
                                                        isSearchable
                                                        isDisabled={isContractView}
                                                        styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                                    />
                                                )}
                                            />
                                            {errors.suppiler && (
                                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                                    {errors.suppiler.message}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-6">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">Cost</div>
                                            <div className='position-relative'>
                                                <input
                                                    type='number'
                                                    min={0}
                                                    className={isContractView ? "textBoxNewView" : "textBoxNew"}
                                                    {...register('cost')}
                                                    disabled={isContractView}
                                                />
                                                <img className="eyeContent" src={rupeesvgIcon} alt="rupeesvgIcon" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6 col-md-6 col-lg-6">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">Contact Person</div>
                                            <input
                                                className={isContractView ? "textBoxNewView" : "textBoxNew"}
                                                {...register('contactPerson')}
                                                disabled={isContractView}
                                            />
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-6">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">Phone</div>
                                            <input
                                                type='number'
                                                className={isContractView ? "textBoxNewView" : "textBoxNew"}
                                                {...register('phone', {
                                                    pattern: {
                                                        valueAsNumber: true,
                                                        min: 0,
                                                        value: /^\d{10}$/,
                                                        message: 'Contact number should be 10 digits'
                                                    }
                                                })}
                                                disabled={isContractView}
                                            />
                                            {errors.phone && (
                                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                                    {errors.phone.message}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                                Start Date<span className="mandatoryCss"> *</span>
                                            </div>
                                            <div className='position-relative'>
                                                <Controller
                                                    name="startDate"
                                                    control={control}
                                                    rules={{
                                                        required: "Start Date is required",
                                                        validate: {
                                                            validDate: (value) => {
                                                                if (!value) return "Start Date is required";
                                                                const formattedDate = format(value, dateFormat);
                                                                const regex = getRegexForDateFormat(dateFormat);
                                                                if (!regex.test(formattedDate)) {
                                                                    return "Invalid date format (" + dateFormat + "})";
                                                                }
                                                                return true;
                                                            }
                                                        }
                                                    }}
                                                    render={({ field }) => (
                                                        <DatePicker
                                                            {...field}
                                                            selected={field.value}
                                                            onChange={(date) => field.onChange(date)}
                                                            dateFormat={dateFormat}
                                                            placeholderText={dateFormat.toUpperCase()}
                                                            className={isContractView ? "DatePickerDisable" : "DatePicker"}
                                                            disabled={isContractView}
                                                            ref={(input) => field.ref(input?.inputElement)}
                                                        />
                                                    )}
                                                />
                                                <img className="eyeContent" src={calendarsvgIcon} alt="calendarsvgIcon" />
                                            </div>
                                            {errors.startDate && (
                                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                                    {errors.startDate.message}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                                End Date<span className="mandatoryCss"> *</span>
                                            </div>
                                            <div className='position-relative'>
                                                <Controller
                                                    name="endDate"
                                                    control={control}
                                                    rules={{
                                                        required: "End Date is required",
                                                        validate: {
                                                            validDate: (value) => {
                                                                if (!value) return "End Date is required";
                                                                const formattedDate = format(value, dateFormat);
                                                                const regex = getRegexForDateFormat(dateFormat);
                                                                if (!regex.test(formattedDate)) {
                                                                    return "Invalid date format (" + dateFormat + "})";
                                                                }
                                                                return true;
                                                            }
                                                        }
                                                    }}
                                                    render={({ field }) => (
                                                        <DatePicker
                                                            {...field}
                                                            selected={field.value}
                                                            onChange={(date) => field.onChange(date)}
                                                            dateFormat={dateFormat}
                                                            placeholderText={dateFormat.toUpperCase()}
                                                            className={isContractView ? "DatePickerDisable" : "DatePicker"}
                                                            disabled={isContractView}
                                                            ref={(input) => field.ref(input?.inputElement)}
                                                        />
                                                    )}
                                                />
                                                <img className="eyeContent" src={calendarsvgIcon} alt="calendarsvgIcon" />
                                            </div>
                                            {errors.endDate && (
                                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                                    {errors.endDate.message}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 col-md-6 col-lg-6">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">No. of License</div>
                                            <input
                                                className={isContractView ? "textBoxNewView" : "textBoxNew"}
                                                {...register('noOfLicense')}
                                                disabled={isContractView}
                                            />
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-6">

                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className='d-flex'>
                                            <div className='d-flex pTop24 align-items-center'>
                                                <Controller
                                                    name="noEndDate"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <input
                                                                type="checkbox"
                                                                {...field}
                                                                checked={field.value}
                                                                onChange={(e) => field.onChange(e.target.checked)}
                                                            />
                                                            <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                No end date
                                                            </div>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            <div className='d-flex pLeft12 pTop24 align-items-center'>
                                                <Controller
                                                    name="contractIsForSoftware"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <input
                                                                type="checkbox"
                                                                {...field}
                                                                checked={field.value}
                                                                onChange={(e) => field.onChange(e.target.checked)}
                                                            />
                                                            <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                Contract is for software
                                                            </div>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                    <div className="mb-2 text-center" style={{ marginTop: "10%" }}>
                                        {!isContractView && (
                                            <button type="submit" className="coloredButtonSave">Save</button>
                                        )}
                                        <button type="button" className="outlineButton" onClick={onCancel} style={{ marginLeft: '18px' }} >Cancel</button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProcessFormConfigurationContract;
