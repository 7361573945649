import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { AuthProvider } from "../Auth/AuthContext";

import Layout from "../layouts/Layout";
import SignIn from "../Pages/SignIn/SignIn";
import ProtectedRoute from "../Auth/ProtectedRoute";
import DashBoard from "../Pages/Dashboard/Dashboard";
import ForgotPassword from "../Pages/SignIn/ForgotPassword";
import SettingPage from "../Pages/Settings/SettingPage/Settings";
import NewPasswordScreen from "../Pages/SignIn/NewPasswordScreen";
import RequestSettings from "../Pages/Settings/Request/RequestSettings";
import AssetSettings from "../Pages/Settings/AssetSettings/AssetSettings";
import SupplierMaster from "../Pages/Settings/SupplierMaster/SupplierMaster";
import RolesList from "../Pages/Settings/RoleManagement/RolesList/RolesList";
import GeneralSettings from "../Pages/Settings/GeneralSettings/GeneralSettings";
import AssetDetailsView from "../Pages/Settings/AssetCreation/AssetDetailsView";
import AssetCreationAdd from "../Pages/Settings/AssetCreation/AssetCreationAdd";
import AssetCreationView from "../Pages/Settings/AssetCreation/AssetCreationView";
import CreateRole from "../Pages/Settings/RoleManagement/RoleCreation/CreateRole";
import BusinessEntityNew from "../Pages/Settings/BusinessEntityNew/BusinessEntityNew";
import CountryStateCityPage from "../Pages/Settings/CountryStateCity/CountryStateCity";
import DepartmentsAndTeam from "../Pages/Settings/DepartmentsAndTeam/DepartmentsAndTeam";
import RequestCategoriesAndTeam from "../Pages/Settings/Request/RequestCategoriesAndTeam";
import UserAccessPermission from "../Pages/Settings/UserAccessPermission/UserAccessPermission";
import FinancialTransaction from "../Pages/Settings/FinancialTransaction/financialTransaction";
import AssetCategoriesAndBrand from "../Pages/Settings/AssetCategoriesAndBrand/AssetCategoriesAndBrand";
import ActionFormConfiguration from "../Pages/Settings/ActionFormConfiguration/ActionFormConfiguration";
import EmployeeManagementAdd from "../Pages/Settings/EmployeeManagement/EmployeeManagemantAdd/EmployeeManagementAdd";
import EmployeeManagementView from "../Pages/Settings/EmployeeManagement/EmployeeManagementView/EmployeeManagementView";
import ProcessFormConfigurationWarranty from "../Pages/Settings/ProcessFormConfiguration/ProcessFormConfigurationWarranty/ProcessFormConfigurationWarranty";
import ProcessFormConfigurationContract from "../Pages/Settings/ProcessFormConfiguration/ProcessFormConfigurationContract/ProcessFormConfigurationContract";
import ProcessFormConfigurationInsurance from "../Pages/Settings/ProcessFormConfiguration/ProcessFormConfigurationInsurance/ProcessFormConfigurationInsurance";
import ProcessFormConfigurationMaintenance from "../Pages/Settings/ProcessFormConfiguration/ProcessFormConfigurationMaintenance/ProcessFormConfigurationMaintenance";
import ProcessFormConfigurationServiceRequest from "../Pages/Settings/ProcessFormConfiguration/ProcessFormConfigurationServiceRequest/ProcessFormConfigurationServiceRequest";
import CheckOut from "../Pages/Settings/CheckOut/CheckOut";
import CheckIn from "../Pages/Settings/CheckIn/CheckIn";
import Transfer from "../Pages/Settings/Transfer/Transfer";

const RoutesPage = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/ForgotPassword" element={<ForgotPassword />} />
          <Route path="/NewPasswordScreen" element={<NewPasswordScreen />} />

          <Route path="/" element={<Layout />}>
            <Route element={<ProtectedRoute />}>
              <Route path="/Dashboard" element={<DashBoard />} />
            </Route>

            <Route element={<ProtectedRoute />}>
              <Route path="/Settings" element={<SettingPage />} />
            </Route>

            <Route element={<ProtectedRoute />}>
              <Route path="/AssetCreationAdd" element={<AssetCreationAdd />} />
            </Route>

            <Route element={<ProtectedRoute />}>
              <Route path="/AssetCreationView" element={<AssetCreationView />} />
            </Route>

            <Route element={<ProtectedRoute />}>
              <Route path="/AssetDetailsView" element={<AssetDetailsView />} />
            </Route>

            <Route element={<ProtectedRoute />}>
              <Route path="/AssetSettings" element={<AssetSettings />} />
            </Route>

            <Route element={<ProtectedRoute />}>
              <Route path="/GeneralSettings" element={<GeneralSettings />} />
            </Route>

            <Route element={<ProtectedRoute />}>
              <Route path="/RolesList" element={<RolesList />} />
            </Route>

            <Route element={<ProtectedRoute />}>
              <Route path="/CreateRole" element={<CreateRole />} />
            </Route>

            <Route element={<ProtectedRoute />}>
              <Route path="/Country" element={<CountryStateCityPage />} />
            </Route>

            <Route element={<ProtectedRoute />}>
              <Route
                path="/BusinessEntityNew"
                element={<BusinessEntityNew />}
              />
            </Route>

            <Route element={<ProtectedRoute />}>
              <Route
                path="/DepartmentsAndTeam"
                element={<DepartmentsAndTeam />}
              />
            </Route>

            <Route element={<ProtectedRoute />}>
              <Route
                path="/AssetCategoriesAndBrand"
                element={<AssetCategoriesAndBrand />}
              />
            </Route>

            <Route element={<ProtectedRoute />}>
              <Route
                path="/RequestSettings"
                element={<RequestSettings />}
              />
            </Route>

            <Route element={<ProtectedRoute />}>
              <Route
                path="/RequestCategoriesAndTeam"
                element={<RequestCategoriesAndTeam />}
              />
            </Route>

            <Route element={<ProtectedRoute />}>
              <Route
                path="/EmployeeManagement"
                element={<EmployeeManagementView />}
              />
            </Route>

            <Route element={<ProtectedRoute />}>
              <Route
                path="/EmployeeManagementAdd"
                element={<EmployeeManagementAdd />}
              />
            </Route>

            <Route element={<ProtectedRoute />}>
              <Route path="/SupplierMaster" element={<SupplierMaster />} />
            </Route>

            <Route element={<ProtectedRoute />}>
              <Route
                path="/UserAccessPermission"
                element={<UserAccessPermission />}
              />
            </Route>

            <Route element={<ProtectedRoute />}>
              <Route
                path="/ProcessFormConfigurationContract"
                element={<ProcessFormConfigurationContract />}
              />
            </Route>

            <Route element={<ProtectedRoute />}>
              <Route
                path="/ProcessFormConfigurationMaintenance"
                element={<ProcessFormConfigurationMaintenance />}
              />
            </Route>

            <Route element={<ProtectedRoute />}>
              <Route
                path="/ProcessFormConfigurationWarranty"
                element={<ProcessFormConfigurationWarranty />}
              />
            </Route>

            <Route element={<ProtectedRoute />}>
              <Route
                path="/ProcessFormConfigurationInsurance"
                element={<ProcessFormConfigurationInsurance />}
              />
            </Route>

            <Route element={<ProtectedRoute />}>
              <Route
                path="/ProcessFormConfigurationServiceRequest"
                element={<ProcessFormConfigurationServiceRequest />}
              />
            </Route>

            <Route element={<ProtectedRoute />}>
              <Route
                path="/ActionFormConfiguration"
                element={<ActionFormConfiguration />}
              />
            </Route>

            <Route element={<ProtectedRoute />}>
              <Route
                path="/FinancialTransaction"
                element={<FinancialTransaction />}
              />
            </Route>
            <Route element={<ProtectedRoute />}>
              <Route
                path="/CheckOut"
                element={<CheckOut />}
              />
            </Route>
            <Route element={<ProtectedRoute />}>
              <Route
                path="/CheckIn"
                element={<CheckIn />}
              />
            </Route>
            <Route element={<ProtectedRoute />}>
              <Route
                path="/Transfer"
                element={<Transfer />}
              />
            </Route>
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default RoutesPage;
