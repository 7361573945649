import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import ReactPaginate from 'react-paginate';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { format } from 'date-fns';
import * as XLSX from 'xlsx';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';

import { getDateFormat } from '../../../CommomPages/Config';
import ConfirmDelete from '../../../CommomPages/ConfirmDelete';
import LoadingSpinner from '../../../CommomPages/LoadingSpinner';
import { exportSepplierData, exportSourcedAssetData } from './ExportSupplierMaster';
import { getAsset } from '../../../Services/AssetSettingsService';
import { getDocument } from '../../../Services/GeneralSettingsService';
import { getCountry, getState } from '../../../Services/CountryStateCityService';
import { getProFormContract } from '../../../Services/ProcessFormConfigurationService';
import { getDesignation } from '../../../Services/EmployeeManagementService';
import { getSupplier, createSupplier, updateSupplier, deleteSupplier, getSMContact, createSMContact, updateSMContact, deleteSMContact, getSMPurchase, createSMPurchase, updateSMPurchase, deleteSMPurchase } from '../../../Services/SupplierMasterService';

import './SupplierMasterCss.css';

import viewsvgIcon from '../../../assets/icon/Eye.svg';
import editsvgIcon from '../../../assets/icon/Edit.svg';
import XMarksvgIcon from '../../../assets/icon/X mark.svg';
import deletesvgIcon from '../../../assets/icon/Delete.svg';
import nodataFound from '../../../assets/img/nodatafound.png';
import datesvgIcon from '../../../assets/icon/CalendarWithBG.svg';


const SupplierMaster = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [tabActive, setTabActive] = useState("");
    const [activeScreen, setActiveScreen] = useState("GeneralView");
    const [tabStatusActive, setTabStatusActive] = useState("View");
    const paginateArray = [5, 10, 15];
    const [dateFormat, setDateFormat] = useState('');

    // Supplier
    const [documentID, setDocumentID] = useState(0);
    const [isSupplierView, setIsSupplierView] = useState(false);
    const [isSupplierAdd, setIsSupplierAdd] = useState(false);
    const [supplierArray, setSupplierArray] = useState([]);
    const [countryArray, setCountryArray] = useState([]);
    const [stateArray, setStateArray] = useState([]);
    const [allStateArray, setAllStateArray] = useState([]);
    const [designationArray, setDesignationArray] = useState([]);
    const [supplierID, setSupplierID] = useState(0);
    const [newSupplierId, setNewSupplierId] = useState('');
    const [countrySelectID, setCountrySelectID] = useState(null);
    const [stateSelectID, setStateSelectID] = useState(null);
    const [pageSupplier, setPageSupplier] = useState(0);
    const [rowsPerPageSupplier, setRowsPerPageSupplier] = useState(5);
    const [searchQuerySupplier, setSearchQuerySupplier] = useState('');
    const fileSupplierInputRef = useRef(null);
    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
        setValue,
        reset,
        resetField,
        watch,
        control
    } = useForm({
        defaultValues: {
            supplierName: '',
            supplierShortName: '',
            supplierId: '',
            phoneNumber: '',
            mobileNumber: '',
            email: '',
            gstNumber: '',
            address: '',
            country: null,
            state: null,
            city: '',
            zipCode: '',
            status: true,
        }
    });

    // Contact
    const [isContactView, setIsContactView] = useState(false);
    const [contactArray, setContactArray] = useState([]);
    const [contactID, setContactID] = useState(0);
    const [pageContact, setPageContact] = useState(0);
    const [rowsPerPageContact, setRowsPerPageContact] = useState(5);
    const [searchQueryContact, setSearchQueryContact] = useState('');
    const {
        register: registerContact,
        handleSubmit: handleSubmitContact,
        formState: { errors: errorsContact },
        setValue: setValueContact,
        reset: resetContact,
        control: controlContact
    } = useForm({
        defaultValues: {
            contactName: '',
            designation: null,
            contactNumber: ''
        }
    });

    // Purchase
    const [isPurchaseView, setIsPurchaseView] = useState(false);
    const [purchaseArray, setPurchaseArray] = useState([]);
    const [purchaseID, setPurchaseID] = useState(0);
    const [pagePurchase, setPagePurchase] = useState(0);
    const [rowsPerPagePurchase, setRowsPerPagePurchase] = useState(5);
    const [searchQueryPurchase, setSearchQueryPurchase] = useState('');
    const {
        register: registerPurchase,
        handleSubmit: handleSubmitPurchase,
        formState: { errors: errorsPurchase },
        setValue: setValuePurchase,
        reset: resetPurchase,
        control: controlPurchase,
        watch: watchPurchase
    } = useForm({
        defaultValues: {
            purchaseDate: '',
            orderInvoiceDetails: '',
            document: '',
            documentLink: ''
        }
    });

    // WatchPurchase the documentLink value
    const documentLink = watchPurchase('documentLink');

    // Sourced Asset
    const [assetArray, setAssetArray] = useState([]);
    const [pageAsset, setPageAsset] = useState(0);
    const [rowsPerPageAsset, setRowsPerPageAsset] = useState(5);
    const [searchQueryAsset, setSearchQueryAsset] = useState('');


    useEffect(() => {
        getSupplierData();
        getContactData(0);
        getPurchaseData(0);
        fetchAssetData(0);
        fetchData();
    }, []);

    useEffect(() => {
        switch (tabActive) {
            case "General":
                setActiveScreen("GeneralEdit");
                setTabStatusActive("General");
                break;
            case "Contact":
                setActiveScreen("ContactView");
                setTabStatusActive("Contact");
                break;
            case "Purchase":
                setActiveScreen("PurchaseView");
                setTabStatusActive("Purchase");
                break;
            case "Sourced Asset":
                setActiveScreen("Sourced Asset");
                setTabStatusActive("Source Asset");
                break;
            default:
                setActiveScreen("GeneralView");
                setTabStatusActive("View");
                break;
        }
    }, [tabActive]);

    const statusWatchSupplier = watch('status');

    // Retrieve the current value of supplierId
    const supplierIdValue = getValues('supplierId');

    useEffect(() => {
        if (countrySelectID !== null) {
            setStateArray(allStateArray.filter(state => state.cntrY_ID === countrySelectID));
        } else {
            setStateArray([]);
        }
        // setStateSelectID(null); // Clear selected state when country changes
    }, [allStateArray, countrySelectID]);


    const fetchData = async () => {
        try {
            setIsLoading(true);

            const designationData = await getDesignation();
            const sortedDesignationData = designationData.sort((a, b) => a.designationName.localeCompare(b.designationName, undefined, { sensitivity: 'base' }));
            setDesignationArray(sortedDesignationData);
            console.log("sortedDesignationData", sortedDesignationData)

            const countryData = await getCountry();
            const sortedCountyData = countryData.sort((a, b) => a.cntrY_NAME.localeCompare(b.cntrY_NAME, undefined, { sensitivity: 'base' }));
            setCountryArray(sortedCountyData);

            const stateData = await getState();
            const sortedStatementData = stateData.sort((a, b) => a.statE_NAME.localeCompare(b.statE_NAME, undefined, { sensitivity: 'base' }));
            setAllStateArray(sortedStatementData);
            // eslint-disable-next-line eqeqeq
            await getSupplierDocNo();

        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchAssetData = async (id) => {
        try {
            setIsLoading(true);
            const dateCurrentFormat = await getDateFormat();
            setDateFormat(dateCurrentFormat);
            const data = await getAsset();
            const filterAsset = data?.filter(user => user.supplierId === id)
            const finalData = filterAsset?.map((asset, index) => {
                // Convert purchaseDate to a Date object and format it
                const formattedDate = asset.purchaseDate ? format(new Date(asset.purchaseDate), dateCurrentFormat) : '';

                return {
                    ...asset,
                    Rowid: index + 1,
                    formatedPurchaseDate: formattedDate,
                    assetStatus: asset.status === true ? "Available" : "Checked out"
                };
            });
            setAssetArray(finalData);
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching Asset Setting data:", error);
            setIsLoading(false);
        }
    };

    const getSupplierData = async () => {
        const SupplierData = await getSupplier();
        const finalData = SupplierData?.map((Supplier, index) => {
            return {
                ...Supplier,
                Rowid: index + 1,
                SupplierStatus: Supplier.status === true ? "Active" : "Inactive"
            };
        });
        setSupplierArray(finalData);
        setPageSupplier(0);
    };

    const getContactData = async (id) => {
        const ContactData = await getSMContact();
        const filterContact = ContactData?.filter(user => user.supplierMasterId === id)
        const finalData = filterContact?.map((Contact, index) => {
            const designation = designationArray.find(desig => desig.id === Contact?.designation);
            return {
                ...Contact,
                Rowid: index + 1,
                designationName: designation ? designation?.designationName : 'Unknown'
            };
        });
        setContactArray(finalData);
        setPageContact(0);
    };

    const getPurchaseData = async (id) => {
        const dateCurrentFormat = await getDateFormat();
        setDateFormat(dateCurrentFormat);
        const PurchaseData = await getSMPurchase();
        const filterPurchase = PurchaseData?.filter(user => user.supplierMasterId === id)
        const finalData = filterPurchase?.map((Purchase, index) => {
            const formattedDate = Purchase.purchaseDate ? format(new Date(Purchase.purchaseDate), dateCurrentFormat) : '';
            return {
                ...Purchase,
                Rowid: index + 1,
                formatedPurchaseDate: formattedDate,
            };
        });
        setPurchaseArray(finalData);
        setPagePurchase(0);
    };

    const getSupplierDocNo = async () => {
        try {
            setIsLoading(true);
            const documentData = await getDocument();
            const maxId = documentData?.find(doc => doc.id === 2);
            const newId = maxId.documentNo + 1;
            setDocumentID(newId);
            setNewSupplierId(`SUP${newId}`);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };


    // Sourced Asset Actions
    const handleSupplierSubmit = async (data) => {
        const { supplierName, supplierShortName, supplierId, phoneNumber, mobileNumber, email, gstNumber, address, country, state, city, zipCode, status } = data;
        const supplierNameExists = supplierArray.some(x => x.supplierName === supplierName);
        const supplierIdExists = supplierArray.some(x => x.supplierID === supplierId);

        const isUpdating = supplierID !== 0;
        if (isUpdating) {
            const currentSupplier = supplierArray.find(x => x.supplierID === supplierId);
            if (supplierNameExists && currentSupplier.supplierName !== supplierName) {
                toast.error("supplier Name already exists.");
                return;
            }
            if (supplierIdExists && currentSupplier.supplierID !== supplierId) {
                toast.error("supplier Id already exists.");
                return;
            }
        } else {
            if (supplierNameExists) {
                toast.error("supplier Name already exists.");
                return;
            }
            if (supplierIdExists) {
                toast.error("supplier Id already exists.");
                return;
            }
        }
        setIsLoading(true);

        const payload = {
            id: supplierID || 0,
            supplierName: supplierName,
            supplierShortName: supplierShortName,
            supplierID: supplierId,
            phoneNumber: phoneNumber,
            mobileNumber: mobileNumber,
            email: email,
            gstNumber: gstNumber,
            address: address,
            country: country,
            state: state,
            city: city,
            zipCode: zipCode,
            status: status,
            NextID: documentID,
        };

        const response = isUpdating ? await updateSupplier(payload) : await createSupplier(payload);

        if (response === (isUpdating ? "Updated" : "Inserted")) {
            reset();
            resetField("supplierId");
            toast.success(`Supplier ${isUpdating ? "Updated" : "Inserted"} Successfully`);
            getSupplierData();
            getContactData(0);
            getPurchaseData(0);
            fetchAssetData(0);
            setTabActive('General');
            setActiveScreen('GeneralView');
        } else {
            toast.error(response);
        }
        setIsLoading(false);
    };

    const handleSupplierAdd = () => {
        setSupplierID(0);
        getContactData(0);
        getPurchaseData(0);
        fetchAssetData(0);
        setValue('supplierId', newSupplierId);
        setIsSupplierView(false);
        setIsSupplierAdd(true);
        setTabActive('General');
        setTabStatusActive('General');
        setActiveScreen('GeneralEdit');
    };

    const handleViewSupplier = (supplierId) => {
        setIsSupplierView(true);
        setSupplierID(supplierId);
        getContactData(supplierId);
        getPurchaseData(supplierId);
        fetchAssetData(supplierId);
        const supplierData = supplierArray?.find(x => x.id === supplierId);
        setValue('supplierName', supplierData.supplierName);
        setValue('supplierShortName', supplierData.supplierShortName);
        setValue('supplierId', supplierData.supplierID);
        setValue('phoneNumber', supplierData.phoneNumber);
        setValue('mobileNumber', supplierData.mobileNumber);
        setValue('email', supplierData.email);
        setValue('gstNumber', supplierData.gstNumber);
        setValue('address', supplierData.address);
        setValue('country', supplierData.country);
        setValue('state', supplierData.state);
        setValue('city', supplierData.city);
        setValue('zipCode', supplierData.zipCode);
        setValue('status', supplierData.status);
        setActiveScreen("GeneralEdit");
        setTabActive('General');
        setTabStatusActive('General');
    };

    const handleEditSupplier = (supplierId) => {
        setIsSupplierView(false);
        setIsSupplierAdd(false);
        setSupplierID(supplierId);
        getContactData(supplierId);
        getPurchaseData(supplierId);
        fetchAssetData(supplierId);
        const supplierData = supplierArray?.find(x => x.id === supplierId);
        setValue('supplierName', supplierData.supplierName);
        setValue('supplierShortName', supplierData.supplierShortName);
        setValue('supplierId', supplierData.supplierID);
        setValue('phoneNumber', supplierData.phoneNumber);
        setValue('mobileNumber', supplierData.mobileNumber);
        setValue('email', supplierData.email);
        setValue('gstNumber', supplierData.gstNumber);
        setValue('address', supplierData.address);
        setValue('country', supplierData.country);
        setValue('state', supplierData.state);
        setValue('city', supplierData.city);
        setValue('zipCode', supplierData.zipCode);
        setValue('status', supplierData.status);
        setActiveScreen("GeneralEdit");
        setTabActive('General');
        setTabStatusActive('General');
    };

    const handleDeleteSupplier = async (Supplier) => {
        const contractData = await getProFormContract();

        for (let cIn of contractData) {
            if (cIn.supplier === Supplier.id) {
                toast.error(`This Supplier is associated with ProcessFormContract. You can't delete it.`);
                return;
            }
        }

        const result = await ConfirmDelete();
        if (result) {
            setIsLoading(true);
            const response = await deleteSupplier(Supplier.id);
            if (response === "Deleted") {
                getSupplierData();
                getContactData(0);
                getPurchaseData(0);
                fetchAssetData(0);
                setIsLoading(false);
                toast.success("Supplier Deleted Sucessfully");
            } else {
                setIsLoading(false);
                toast.error(response);
            }
        }
    };

    const exportSupplier = (event) => {
        const selectedOption = event.target.value;
        if (selectedOption !== '') {
            handleExportSupplier(selectedOption);
            event.target.value = '';
        }
    };

    const handleExportSupplier = (format) => {
        exportSepplierData(supplierArray, format);
    };

    const handleSupplierImportClick = () => {
        fileSupplierInputRef.current.click();
    };

    const handleSupplierFileChange = async (event) => {
        const file = event.target.files[0];
        if (file && file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            const data = await file.arrayBuffer();
            const workbook = XLSX.read(data);
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(worksheet);

            let supplierArrayData = [];
            let supplierErrorArrayCount = [];

            jsonData.forEach((e) => {
                const supplierNameExists = supplierArray.some(x => x.supplierName === e.supplierName);
                const supplierIdExists = supplierArray.some(x => x.supplierID === e.supplierID);
                if (supplierNameExists) {
                    toast.error(`${e.supplierName} already exists.`);
                    supplierErrorArrayCount.push(e);
                }
                else if (supplierIdExists) {
                    toast.error(`${e.supplierID} already exists.`);
                    supplierErrorArrayCount.push(e);
                }
                else {
                    supplierArrayData.push(e);
                }
            });

            event.target.value = null;
            if (supplierArrayData.length > 0 && supplierErrorArrayCount.length === 0) {
                uploadSupplierData(supplierArrayData);
            }
        } else {
            toast.error("Invalid file type. Please upload an Excel file.");
        }
    };

    const uploadSupplierData = async (data) => {
        try {
            setIsLoading(true);
            const uploadPromises = data.map(async (e) => {
                const payload = {
                    id: 0,
                    supplierName: e.supplierName,
                    supplierShortName: e.supplierShortName,
                    supplierID: e.supplierID,
                    phoneNumber: e.phoneNumber,
                    mobileNumber: e.mobileNumber,
                    email: e.email,
                    gstNumber: e.gstNumber,
                    address: e.address,
                    country: e.country,
                    state: e.state,
                    city: e.city,
                    zipCode: e.zipCode,
                    status: e.status,
                };
                const response = await createSupplier(payload);
                if (response !== "Inserted") throw new Error("Failed to upload data");
            });
            await Promise.all(uploadPromises);
            getSupplierData();
            getContactData(0);
            getPurchaseData(0);
            fetchAssetData(0);
            setPageSupplier(0);
            toast.success("Data uploaded successfully.");
        } catch (error) {
            toast.error("An error occurred while uploading data.");
        } finally {
            setIsLoading(false);
        }
    };

    const closeSupplierEdit = () => {
        reset();
        resetField("supplierId");
        setValue('supplierId', '');
        setTabStatusActive('View');
        setActiveScreen('GeneralView');
    };


    // Contact Actions
    const handleContactSubmit = async (data) => {
        const { contactName, designation, contactNumber } = data;
        const isUpdating = contactID !== 0;
        setIsLoading(true);

        const payload = {
            id: contactID || 0,
            contactName: contactName,
            designation: designation || 0,
            contactNumber: contactNumber,
            supplierMasterId: supplierID || 0,
        };

        const response = isUpdating ? await updateSMContact(payload) : await createSMContact(payload);

        if (response === (isUpdating ? "Updated" : "Inserted")) {
            getContactData(supplierID);
            setTabActive('Contact');
            setActiveScreen('ContactView');
            toast.success(`Contact ${isUpdating ? "Updated" : "Inserted"} Successfully`);
        } else {
            toast.error(response);
        }
        setIsLoading(false);
    };

    const handleContactAdd = () => {
        resetContact();
        setContactID(0);
        setIsContactView(false);
        setIsSupplierAdd(false);
        setTabActive('Contact');
        setActiveScreen('ContactEdit');
    };

    const handleViewContact = (ContactId) => {
        setIsContactView(true);
        setContactID(ContactId);
        const contactData = contactArray?.find(x => x.id === ContactId);
        setValueContact('contactName', contactData.contactName);
        setValueContact('designation', contactData?.designation);
        setValueContact('contactNumber', contactData.contactNumber);
        setActiveScreen("ContactEdit");
    };

    const handleEditContact = (ContactId) => {
        setIsContactView(false);
        setContactID(ContactId);
        const contactData = contactArray?.find(x => x.id === ContactId);
        setValueContact('contactName', contactData.contactName);
        setValueContact('designation', contactData?.designation);
        setValueContact('contactNumber', contactData.contactNumber);
        setActiveScreen("ContactEdit");
    };

    const handleDeleteContact = async (Contact) => {
        const result = await ConfirmDelete();
        if (result) {
            setIsLoading(true);
            const response = await deleteSMContact(Contact.id);
            if (response === "Deleted") {
                getContactData(Contact.supplierMasterId);
                setIsLoading(false);
                toast.success("Contact Deleted Sucessfully");
            } else {
                setIsLoading(false);
                toast.error(response);
            }
        }
    };

    const closeContactEdit = () => {
        resetContact();
        setTabActive('Contact');
        setTabStatusActive('Contact');
        setActiveScreen('ContactView');
    };


    // Purchase Actions
    const handlePurchaseSubmit = async (data) => {
        console.log("handlePurchaseSubmit data", data)
        const { purchaseDate, orderInvoiceDetails, document, documentLink } = data;
        const isUpdating = purchaseID !== 0;
        setIsLoading(true);

        const payload = {
            id: purchaseID || 0,
            purchaseDate: purchaseDate,
            orderInvoiceDetails: orderInvoiceDetails,
            document: document || "",
            documentLink: documentLink || "",
            contactNumber: "",
            supplierMasterId: supplierID || 0,
        };

        const response = isUpdating ? await updateSMPurchase(payload) : await createSMPurchase(payload);

        if (response === (isUpdating ? "Updated" : "Inserted")) {
            getPurchaseData(supplierID);
            setTabActive('Purchase');
            setActiveScreen('PurchaseView');
            toast.success(`Purchase ${isUpdating ? "Updated" : "Inserted"} Successfully`);
        } else {
            toast.error(response);
        }
        setIsLoading(false);
    };

    const handlePurchaseAdd = () => {
        resetPurchase();
        setPurchaseID(0);
        setIsPurchaseView(false);
        setIsSupplierAdd(false);
        setTabActive('Purchase');
        setActiveScreen('PurchaseEdit');
    };

    const handleViewPurchase = (PurchaseId) => {
        setIsPurchaseView(true);
        setPurchaseID(PurchaseId);
        const purchaseData = purchaseArray?.find(x => x.id === PurchaseId);
        const formattedDate = purchaseData.purchaseDate ? format(new Date(purchaseData.purchaseDate), dateFormat) : '';

        setValuePurchase('purchaseDate', formattedDate);
        setValuePurchase('orderInvoiceDetails', purchaseData.orderInvoiceDetails);
        setValuePurchase('document', purchaseData?.document);
        setValuePurchase('documentLink', purchaseData?.documentLink);
        setActiveScreen("PurchaseEdit");
    };

    const handleEditPurchase = (PurchaseId) => {
        setIsPurchaseView(false);
        setPurchaseID(PurchaseId);
        const purchaseData = purchaseArray?.find(x => x.id === PurchaseId);
        const formattedDate = purchaseData.purchaseDate ? format(new Date(purchaseData.purchaseDate), dateFormat) : '';

        setValuePurchase('purchaseDate', formattedDate);
        setValuePurchase('orderInvoiceDetails', purchaseData.orderInvoiceDetails);
        setValuePurchase('document', purchaseData?.document);
        setValuePurchase('documentLink', purchaseData?.documentLink);
        setActiveScreen("PurchaseEdit");
    };

    const handleDeletePurchase = async (Purchase) => {
        const result = await ConfirmDelete();
        if (result) {
            setIsLoading(true);
            const response = await deleteSMPurchase(Purchase.id);
            if (response === "Deleted") {
                getPurchaseData(Purchase.supplierMasterId);
                setIsLoading(false);
                toast.success("Purchase Deleted Sucessfully");
            } else {
                setIsLoading(false);
                toast.error(response);
            }
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > 2 * 1024 * 1024) {
                alert('Maximum allowed file size is 2MB');
                event.target.value = null;
                return;
            }

            if (!['application/pdf', 'image/jpeg', 'image/png'].includes(file.type)) {
                alert('Only JPG, JPEG, PNG, and PDF files are allowed');
                event.target.value = null;
                return;
            }

            const fileName = file.name;
            const fileSize = (file.size / 1024).toFixed(0); // Size in KB
            const fileDisplayText = `${fileName} (${fileSize}k)`;
            // Read file as base64 string
            const reader = new FileReader();
            reader.onload = (e) => {
                const base64String = e.target.result.split(',')[1]; // Get base64 part
                setValuePurchase('document', base64String);
                setValuePurchase('documentLink', fileDisplayText);
            };
            reader.readAsDataURL(file); // Read file as data URL
        }
    };

    const clearFileInput = () => {
        setValuePurchase('document', '');
        setValuePurchase('documentLink', '');
        document.getElementById('fileInput').value = null;
    };

    const closePurchaseEdit = () => {
        resetPurchase();
        setTabActive('Purchase');
        setTabStatusActive('Purchase');
        setActiveScreen('PurchaseView');
    };


    // Sourced Asset Actions
    const exportSourcedAsset = (event) => {
        const selectedOption = event.target.value;
        if (selectedOption !== '') {
            handleExportSourcedAsset(selectedOption);
            event.target.value = '';
        }
    };

    const handleExportSourcedAsset = (format) => {
        exportSourcedAssetData(assetArray, format);
    };


    // Supplier Section Search and Pagination
    const handleSearchChangeSupplier = (event) => {
        setSearchQuerySupplier(event.target.value);
        setPageSupplier(0);
    };

    const filteredSupplier = supplierArray.filter((supplier) =>
        supplier &&
        (
            supplier?.supplierName?.toLowerCase()?.includes(searchQuerySupplier?.toLowerCase()) ||
            supplier?.supplierID?.toLowerCase()?.includes(searchQuerySupplier?.toLowerCase()) ||
            supplier?.phoneNumber?.toLowerCase()?.includes(searchQuerySupplier?.toLowerCase())
        )
    );

    const handleRowsPerPageChangeSupplier = (event) => {
        setRowsPerPageSupplier(parseInt(event.target.value));
        setPageSupplier(0);
    };

    const handleFirstPageSupplier = () => {
        handleChangePagePaginationSupplier({ selected: 0 });
    };

    const handleChangePagePaginationSupplier = ({ selected }) => {
        setPageSupplier(selected);
    };

    const handleLastPageSupplier = () => {
        handleChangePagePaginationSupplier({ selected: Math.ceil(supplierArray.length / rowsPerPageSupplier) - 1 });
    };

    const totalEntriesSupplier = filteredSupplier.length;
    const startEntrySupplier = pageSupplier * rowsPerPageSupplier + 1;
    const endEntrySupplier = Math.min((pageSupplier + 1) * rowsPerPageSupplier, totalEntriesSupplier);


    // Contact Section Search and Pagination
    const handleSearchChangeContact = (event) => {
        setSearchQueryContact(event.target.value);
        setPageContact(0);
    };

    const filteredContact = contactArray.filter((contact) =>
        contact &&
        (
            contact?.contactName?.toLowerCase()?.includes(searchQueryContact?.toLowerCase()) ||
            contact?.designationName?.toLowerCase()?.includes(searchQueryContact?.toLowerCase()) ||
            contact?.contactNumber?.toLowerCase()?.includes(searchQueryContact?.toLowerCase())
        )
    );

    const handleRowsPerPageChangeContact = (event) => {
        setRowsPerPageContact(parseInt(event.target.value));
        setPageContact(0);
    };

    const handleFirstPageContact = () => {
        handleChangePagePaginationContact({ selected: 0 });
    };

    const handleChangePagePaginationContact = ({ selected }) => {
        setPageContact(selected);
    };

    const handleLastPageContact = () => {
        handleChangePagePaginationContact({ selected: Math.ceil(contactArray.length / rowsPerPageContact) - 1 });
    };

    const totalEntriesContact = filteredContact.length;
    const startEntryContact = pageContact * rowsPerPageContact + 1;
    const endEntryContact = Math.min((pageContact + 1) * rowsPerPageContact, totalEntriesContact);


    // Purchase Section Search and Pagination
    const handleSearchChangePurchase = (event) => {
        setSearchQueryPurchase(event.target.value);
        setPagePurchase(0);
    };

    const filteredPurchase = purchaseArray.filter((purchase) =>
        purchase &&
        (
            purchase?.orderInvoiceDetails?.toLowerCase()?.includes(searchQueryPurchase?.toLowerCase()) ||
            purchase?.formatedPurchaseDate?.toLowerCase()?.includes(searchQueryPurchase?.toLowerCase()) ||
            purchase?.documentLink?.toLowerCase()?.includes(searchQueryPurchase?.toLowerCase())
        )
    );

    const handleRowsPerPageChangePurchase = (event) => {
        setRowsPerPagePurchase(parseInt(event.target.value));
        setPagePurchase(0);
    };

    const handleFirstPagePurchase = () => {
        handleChangePagePaginationPurchase({ selected: 0 });
    };

    const handleChangePagePaginationPurchase = ({ selected }) => {
        setPagePurchase(selected);
    };

    const handleLastPagePurchase = () => {
        handleChangePagePaginationPurchase({ selected: Math.ceil(purchaseArray.length / rowsPerPagePurchase) - 1 });
    };

    const totalEntriesPurchase = filteredPurchase.length;
    const startEntryPurchase = pagePurchase * rowsPerPagePurchase + 1;
    const endEntryPurchase = Math.min((pagePurchase + 1) * rowsPerPagePurchase, totalEntriesPurchase);


    // Asset Section Search and Pagination
    const handleSearchChangeAsset = (event) => {
        setSearchQueryAsset(event.target.value);
        setPageAsset(0);
    };

    const filteredAsset = assetArray.filter((asset) =>
        asset &&
        (
            asset?.assetID?.toLowerCase()?.includes(searchQueryAsset?.toLowerCase()) ||
            asset?.categoryId?.toLowerCase()?.includes(searchQueryAsset?.toLowerCase()) ||
            asset?.subCategoryId?.toLowerCase()?.includes(searchQueryAsset?.toLowerCase()) ||
            asset?.brandId?.toLowerCase()?.includes(searchQueryAsset?.toLowerCase()) ||
            asset?.formatedPurchaseDate?.toLowerCase()?.includes(searchQueryAsset?.toLowerCase())
        )
    );

    const handleRowsPerPageChangeAsset = (event) => {
        setRowsPerPageAsset(parseInt(event.target.value));
        setPageAsset(0);
    };

    const handleFirstPageAsset = () => {
        handleChangePagePaginationAsset({ selected: 0 });
    };

    const handleChangePagePaginationAsset = ({ selected }) => {
        setPageAsset(selected);
    };

    const handleLastPageAsset = () => {
        handleChangePagePaginationAsset({ selected: Math.ceil(assetArray?.length / rowsPerPageAsset) - 1 });
    };

    const totalEntriesAsset = filteredAsset?.length;
    const startEntryAsset = pageAsset * rowsPerPageAsset + 1;
    const endEntryAsset = Math.min((pageAsset + 1) * rowsPerPageAsset, totalEntriesAsset);


    return (
        <div className="bacgroundStyle">
            <ToastContainer theme="colored" />
            {isLoading && <LoadingSpinner />}
            {!isLoading && (
                <div>
                    <div className="pagetitleSupplier">
                        <div className="Pageheader fontFamily fontsize24 fontweightSemiBold">Supplier Master - {tabStatusActive}<div><InfoOutlinedIcon /></div></div>
                    </div>

                    {activeScreen === "GeneralView" && (
                        <div className="customScrollbar">
                            <div className="card mt-4">
                                <div className="cardBodySupplierMaster">
                                    <div >
                                        <div className="d-flex justify-content-between align-items-center" style={{ paddingBottom: '8px' }}>
                                            <div className="custom-container">
                                                <span className="blackTextColor fontFamily fontsize16 fontweightRegular" >
                                                    <span className="show">Show</span>
                                                    <select className="custom-dropdown" value={rowsPerPageSupplier} onChange={handleRowsPerPageChangeSupplier}>
                                                        {paginateArray.map((n) => (
                                                            <option className="blackTextColor fontFamily fontsize14 fontweightRegular" key={n} value={n}>
                                                                {n}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <span className="blackTextColor entries fontFamily fontsize16 fontweightRegular" >
                                                        Entries
                                                    </span>
                                                </span>
                                                <input
                                                    type="text"
                                                    placeholder="Search..."
                                                    value={searchQuerySupplier}
                                                    onChange={handleSearchChangeSupplier}
                                                    className="searchTextBox"
                                                />
                                            </div>

                                            <div className="addbuttondiv">
                                                <input
                                                    type="file"
                                                    ref={fileSupplierInputRef}
                                                    style={{ display: 'none' }}
                                                    accept=".xlsx"
                                                    onChange={handleSupplierFileChange}
                                                />
                                                <button type="button" id="BtnaddUnit" className="buttonAddNew fontFamily fontweightRegular fontsize16 coloredButtonAdd" onClick={handleSupplierAdd}>Add New </button>
                                                <button type="button" className="fontFamily fontweightRegular fontsize16 importBtn" onClick={handleSupplierImportClick}> Import </button>
                                                <select className="buttonExport fontFamily fontweightRegular fontsize16 exportBtn" name="language" id="language" defaultValue="" onChange={exportSupplier}>
                                                    <option value="" hidden={true}>Export</option>
                                                    <option value="csv">CSV</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="p-1">
                                            <div className="EmpBoxShadow">
                                                <div className="" style={{ padding: '10px' }}>
                                                    <table className="w-100 mt-2">
                                                        <thead>
                                                            <tr className="text-left empTableHeight">
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg  " style={{ width: "12%", padding: '2px 2px 2px 3%' }}>SI.No</th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 " style={{ width: "20%" }}>Supplier ID</th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 " style={{ width: "20%" }}>Supplier Name</th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 " style={{ width: "20%" }}>Mobile Number</th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 " style={{ width: "20%" }}>Phone Number</th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 " style={{ width: "10%" }}>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {filteredSupplier.length > 0 ? (
                                                                filteredSupplier.slice(pageSupplier * rowsPerPageSupplier, (pageSupplier + 1) * rowsPerPageSupplier).map((Supplier, index) => (
                                                                    <tr key={Supplier.Rowid} style={{ height: "59px" }}>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`} style={{ padding: "2px 2px 2px 4%" }}>{Supplier.Rowid}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>{Supplier.supplierID}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>{Supplier.supplierName}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>{Supplier.mobileNumber}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>{Supplier.phoneNumber}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>
                                                                            <span style={{ cursor: 'pointer' }}><a href="#viewSupplier" onClick={() => handleViewSupplier(Supplier.id)}><img style={{ width: '18px', height: '15px' }} src={viewsvgIcon} alt="viewsvgIcon" /></a></span>
                                                                            <span style={{ marginLeft: '10px', cursor: 'pointer' }}><a href="#editSupplier" onClick={() => handleEditSupplier(Supplier.id)}><img src={editsvgIcon} alt="editsvgIcon" /></a></span>
                                                                            <span style={{ marginLeft: '10px', cursor: 'pointer' }}><a href="#deleteSupplier" onClick={() => handleDeleteSupplier(Supplier)}><img src={deletesvgIcon} alt="deletesvgIcon" /></a></span>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan="4" className="text-center fontFamily fontweightRegular fontsize16">
                                                                        <img src={nodataFound} alt="no data found" />
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            {totalEntriesSupplier > 0 && (
                                                <div >
                                                    <div className="" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingTop: "16px" }}>
                                                        <div className="text-muted fontFamily fontsize12 fontweightRegular">
                                                            Showing {startEntrySupplier} to {endEntrySupplier} of {totalEntriesSupplier} entries
                                                        </div>
                                                        <div >
                                                            <div className="pagination-container d-flex align-items-center">
                                                                <button className="paginationButton btn btn-link" onClick={handleFirstPageSupplier}>
                                                                    <FontAwesomeIcon icon={faAngleDoubleLeft} className="icon-color" />
                                                                </button>
                                                                <ReactPaginate
                                                                    previousLabel={<FontAwesomeIcon icon={faChevronLeft} className="icon-color" />}
                                                                    nextLabel={<FontAwesomeIcon icon={faChevronRight} className="icon-color" />}
                                                                    breakLabel={'...'}
                                                                    breakClassName={'break-me'}
                                                                    pageCount={Math.ceil(totalEntriesSupplier / rowsPerPageSupplier)}
                                                                    forcePage={pageSupplier}
                                                                    marginPagesDisplayed={2}
                                                                    pageRangeDisplayed={5}
                                                                    onPageChange={handleChangePagePaginationSupplier}
                                                                    containerClassName={'pagination'}
                                                                    subContainerClassName={'pages pagination'}
                                                                    activeClassName={'active'}
                                                                    renderOnZeroPageCount={null}
                                                                />
                                                                <button className="paginationButton btn btn-link" onClick={handleLastPageSupplier}>
                                                                    <FontAwesomeIcon icon={faAngleDoubleRight} className="icon-color" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div style={{ padding: "0px 100px" }}>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {activeScreen === "GeneralEdit" && (
                        <div className="customScrollbar">
                            <div className="card mt-4">
                                <div className="HeaderTab" style={{ margin: '30px 20px' }}>
                                    {['General', 'Contact', 'Purchase', 'Sourced Asset'].map(tab => (
                                        <a
                                            key={tab}
                                            href={`#${tab.replace(' ', '')}`}
                                            className={`${tabActive === tab ? 'activeTab' : 'InactiveTab'}`}
                                            onClick={() => setTabActive(tab)}
                                        >
                                            <div className="fontFamily fontweightRegular fontsize20">
                                                {tab}
                                            </div>
                                        </a>
                                    ))}
                                </div>

                                <div>
                                    <form className="p-4" noValidate onSubmit={handleSubmit(handleSupplierSubmit)}>
                                        <div className="row">
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor">
                                                    Supplier Name<span className="mandatoryCss"> *</span>
                                                </div>
                                                <input
                                                    type="text"
                                                    placeholder=""
                                                    {...register('supplierName', {
                                                        required: 'Please enter Supplier Name',
                                                        validate: async (value) => {
                                                            if (value && !/^[a-zA-Z0-9\s]*$/.test(value)) {
                                                                return 'Invalid supplier name, only alphanumeric characters are allowed';
                                                            }
                                                            // A function `checkUniqueSupplierName` that checks the uniqueness of the supplier name
                                                            // const isUnique = await checkUniqueSupplierName(value);
                                                            // if (!isUnique) {
                                                            //     return 'Supplier name must be unique';
                                                            // }
                                                        }
                                                    })}
                                                    className={isSupplierView ? "textBoxNewView" : "textBoxNew"}
                                                    disabled={isSupplierView}
                                                />
                                                {errors.supplierName && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errors.supplierName.message}
                                                    </div>
                                                )}
                                            </div>

                                            <div className="col-6 col-md-6 col-lg-6">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Supplier Short Name</div>
                                                <input
                                                    type="text"
                                                    placeholder=""
                                                    {...register('supplierShortName', {
                                                        validate: (value) => {
                                                            if (value && !/^[a-zA-Z0-9\s]*$/.test(value)) {
                                                                return 'Invalid supplier short name, only alphanumeric characters are allowed';
                                                            }
                                                        }
                                                    })}
                                                    className={isSupplierView ? "textBoxNewView" : "textBoxNew"}
                                                    disabled={isSupplierView}
                                                />
                                                {errors.supplierShortName && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errors.supplierShortName.message}
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                                    Supplier ID<span className="mandatoryCss"> *</span>
                                                </div>
                                                <input
                                                    type="text"
                                                    placeholder=""
                                                    value={supplierIdValue}
                                                    {...register('supplierId')}
                                                    className={isSupplierView ? "textBoxNewView" : "textBoxNew"}
                                                    disabled={true} // Auto-generated and should not be editable
                                                />
                                                {errors.supplierId && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errors.supplierId.message}
                                                    </div>
                                                )}
                                            </div>

                                            <div className="col-6 col-md-6 col-lg-6">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">Phone Number</div>
                                                <input
                                                    type="text"
                                                    placeholder=""
                                                    maxLength="10"
                                                    {...register('phoneNumber', {
                                                        validate: (value) => {
                                                            if (value && !/^\d{10}$/.test(value)) {
                                                                return 'Invalid phone number, must be 10 digits';
                                                            }
                                                        }
                                                    })}
                                                    onInput={(e) => {
                                                        e.target.value = e.target.value.replace(/\D/g, '');
                                                    }}
                                                    className={isSupplierView ? "textBoxNewView" : "textBoxNew"}
                                                    disabled={isSupplierView}
                                                />
                                                {errors.phoneNumber && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errors.phoneNumber.message}
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                                    Mobile Number<span className="mandatoryCss"> *</span>
                                                </div>
                                                <input
                                                    type="text"
                                                    placeholder=""
                                                    maxLength="10"
                                                    {...register('mobileNumber', {
                                                        required: 'Please enter your mobile number',
                                                        validate: (value) => {
                                                            if (value && !/^[6-9]\d{9}$/.test(value)) {
                                                                return 'Invalid mobile number, must be a valid 10-digit Indian mobile number';
                                                            }
                                                        }
                                                    })}
                                                    onInput={(e) => {
                                                        e.target.value = e.target.value.replace(/\D/g, '');
                                                    }}
                                                    className={isSupplierView ? "textBoxNewView" : "textBoxNew"}
                                                    disabled={isSupplierView}
                                                />
                                                {errors.mobileNumber && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errors.mobileNumber.message}
                                                    </div>
                                                )}
                                            </div>

                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24" >Email ID</div>
                                                <input
                                                    type="email"
                                                    placeholder=""
                                                    {...register('email', {
                                                        pattern: {
                                                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                                            message: 'Please Enter a Valid Email Address'
                                                        }
                                                    })}
                                                    className={isSupplierView ? "textBoxNewView" : "textBoxNew"}
                                                    disabled={isSupplierView}
                                                />
                                                {errors.email && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}  >
                                                        {errors.email.message}
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24" >GST Number</div>
                                                <input
                                                    type="text"
                                                    placeholder=""
                                                    maxLength="15"
                                                    {...register('gstNumber', {
                                                        validate: (value) => {
                                                            if (value && !/^\d{2}[A-Z]{5}\d{4}[A-Z][1-9A-Z]Z[\dA-Z]$/.test(value)) {
                                                                return 'Invalid GST number format';
                                                            }
                                                        }
                                                    })}
                                                    className={isSupplierView ? "textBoxNewView" : "textBoxNew"}
                                                    disabled={isSupplierView}
                                                />
                                                {errors.gstNumber && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errors.gstNumber.message}
                                                    </div>
                                                )}
                                            </div>

                                            <div className="col-6 col-md-6 col-lg-6">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">Address</div>
                                                <input
                                                    type="text"
                                                    placeholder=""
                                                    {...register('address', {
                                                        validate: (value) => {
                                                            if (value && !/^[a-zA-Z0-9\s]*$/.test(value)) {
                                                                return 'Invalid address, only alphanumeric characters are allowed';
                                                            }
                                                        }
                                                    })}
                                                    className={isSupplierView ? "textBoxNewView" : "textBoxNew"}
                                                    disabled={isSupplierView}
                                                />
                                                {errors.address && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errors.address.message}
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24" >County</div>
                                                <Controller
                                                    name="country"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            value={countryArray.find(repo => repo.id === countrySelectID) || null}
                                                            onChange={(selectedOption) => {
                                                                setCountrySelectID(selectedOption ? selectedOption.id : null);
                                                                field.onChange(selectedOption ? selectedOption.id : null);
                                                            }}
                                                            options={countryArray}
                                                            getOptionLabel={(option) => option.cntrY_NAME}
                                                            getOptionValue={(option) => option.ID}
                                                            placeholder="Select Country"
                                                            isClearable
                                                            isSearchable
                                                            isDisabled={isSupplierView}
                                                            styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                                        />
                                                    )}
                                                />
                                            </div>

                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24" >State</div>
                                                <Controller
                                                    name="state"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            value={stateArray.find(repo => repo.id === stateSelectID) || null}
                                                            onChange={(selectedOption) => {
                                                                setStateSelectID(selectedOption ? selectedOption.id : null);
                                                                field.onChange(selectedOption ? selectedOption.id : null);
                                                            }}
                                                            options={stateArray}
                                                            getOptionLabel={(option) => option.statE_NAME}
                                                            getOptionValue={(option) => option.Id}
                                                            placeholder="Select State"
                                                            isClearable
                                                            isSearchable
                                                            isDisabled={isSupplierView}
                                                            styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">City</div>
                                                <input
                                                    type="text"
                                                    placeholder=""
                                                    {...register('city', {
                                                        validate: (value) => {
                                                            if (value && !/^[a-zA-Z0-9\s]*$/.test(value)) {
                                                                return 'Invalid city, only alphanumeric characters are allowed';
                                                            }
                                                        }
                                                    })}
                                                    className={isSupplierView ? "textBoxNewView" : "textBoxNew"}
                                                    disabled={isSupplierView}
                                                />
                                                {errors.city && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errors.city.message}
                                                    </div>
                                                )}
                                            </div>

                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">Zip Code</div>
                                                <input
                                                    type="text"
                                                    placeholder=""
                                                    maxLength="6"
                                                    {...register('zipCode', {
                                                        validate: (value) => {
                                                            if (value && !/^\d{6}$/.test(value)) {
                                                                return 'Invalid zip code, must be 6 digits';
                                                            }
                                                        }
                                                    })}
                                                    className={isSupplierView ? "textBoxNewView" : "textBoxNew"}
                                                    disabled={isSupplierView}
                                                />
                                                {errors.zipCode && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errors.zipCode.message}
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24" >
                                                    Status
                                                </div>
                                                <div className="d-flex align-items-center justify-content-start">
                                                    <a
                                                        href="#status"
                                                        className="switch-container"
                                                        onClick={!isSupplierView ? () => setValue('status', !statusWatchSupplier) : undefined}
                                                        tabIndex={isSupplierView ? -1 : 0}
                                                        style={{ cursor: isSupplierView ? 'not-allowed' : 'pointer' }}
                                                        disabled={isSupplierView}
                                                    >
                                                        <div className={`switch ${statusWatchSupplier ? 'active' : ''}`}>
                                                            <div className="handle"></div>
                                                        </div>
                                                    </a>
                                                    <div
                                                        className="fontFamily fontweightRegular fontsize16 grayTextColor"
                                                        style={{ paddingLeft: '20px' }}
                                                    >
                                                        {statusWatchSupplier ? 'Active' : 'Inactive'}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                            </div>
                                        </div>

                                        <div className="text-center buttonSpace">
                                            {!isSupplierView && (
                                                <button type="submit" className="coloredButtonSave">{isLoading ? 'Loading...' : 'Save'}</button>
                                            )}
                                            <button type="button" className="outlineButton" onClick={closeSupplierEdit}>Cancel</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    )}

                    {activeScreen === "ContactView" && (
                        <div className="customScrollbar">
                            <div className="card mt-4">
                                <div className="cardBodySupplierMaster">
                                    <div >
                                        <div className="p-1">
                                            <div className="EmpBoxShadow">
                                                <div className="HeaderTab" style={{ margin: '5px 10px' }}>
                                                    {['General', 'Contact', 'Purchase', 'Sourced Asset'].map(tab => (
                                                        <a
                                                            key={tab}
                                                            href={`#${tab.replace(' ', '')}`}
                                                            className={`${tabActive === tab ? 'activeTab' : 'InactiveTab'}`}
                                                            onClick={() => setTabActive(tab)}
                                                        >
                                                            <div className="fontFamily fontweightRegular fontsize20">
                                                                {tab}
                                                            </div>
                                                        </a>
                                                    ))}
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center" style={{ padding: '10px' }}>
                                                    <div className="custom-container">
                                                        <span className="blackTextColor fontFamily fontsize16 fontweightRegular" >
                                                            <span className="show">Show</span>
                                                            <select className="custom-dropdown" value={rowsPerPageContact} onChange={handleRowsPerPageChangeContact}>
                                                                {paginateArray.map((n) => (
                                                                    <option className="blackTextColor fontFamily fontsize14 fontweightRegular" key={n} value={n}>
                                                                        {n}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            <span className="blackTextColor entries fontFamily fontsize16 fontweightRegular" >
                                                                Entries
                                                            </span>
                                                        </span>
                                                        <input
                                                            type="text"
                                                            placeholder="Search..."
                                                            value={searchQueryContact}
                                                            onChange={handleSearchChangeContact}
                                                            className="searchTextBox"
                                                        />
                                                    </div>

                                                    <div className="addbuttondiv">
                                                        <button type="button" id="BtnaddUnit" className="buttonAddNew fontFamily fontweightRegular fontsize16 coloredButtonAdd" disabled={isSupplierAdd || isSupplierView} onClick={handleContactAdd}>Add New </button>
                                                    </div>
                                                </div>
                                                <div className="" style={{ padding: '10px' }}>
                                                    <table className="w-100 mt-2">
                                                        <thead>
                                                            <tr className="text-left empTableHeight">
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg  " style={{ width: "12%", padding: '2px 2px 2px 3%' }}>SI.No</th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 " style={{ width: "15%" }}>Contact Name</th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 " style={{ width: "15%" }}>Contact Number</th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 " style={{ width: "15%" }}>Designation</th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 " style={{ width: "8%" }}>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {filteredContact.length > 0 ? (
                                                                filteredContact.slice(pageContact * rowsPerPageContact, (pageContact + 1) * rowsPerPageContact).map((Contact, index) => (
                                                                    <tr key={Contact.Rowid} style={{ height: "59px" }}>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`} style={{ padding: "2px 2px 2px 4%" }}>{Contact?.Rowid}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>{Contact.contactName}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>{Contact.contactNumber}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>{Contact?.designationName}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>
                                                                            <span style={{ cursor: 'pointer' }}><a href="#viewContact" onClick={() => handleViewContact(Contact.id)}><img style={{ width: '18px', height: '15px' }} src={viewsvgIcon} alt="viewsvgIcon" /></a></span>
                                                                            <span style={{ marginLeft: '10px', cursor: 'pointer' }}><a href="#editContact" style={{ cursor: isSupplierAdd || isSupplierView ? 'not-allowed' : 'pointer' }} onClick={isSupplierAdd || isSupplierView ? null : () => handleEditContact(Contact.id)}><img src={editsvgIcon} alt="editsvgIcon" /></a></span>
                                                                            <span style={{ marginLeft: '10px', cursor: 'pointer' }}><a href="#deleteContact" style={{ cursor: isSupplierAdd || isSupplierView ? 'not-allowed' : 'pointer' }} onClick={isSupplierAdd || isSupplierView ? null : () => handleDeleteContact(Contact)}><img src={deletesvgIcon} alt="deletesvgIcon" /></a></span>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan="4" className="text-center fontFamily fontweightRegular fontsize16">
                                                                        <img src={nodataFound} alt="no data found" />
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            {totalEntriesContact > 0 && (
                                                <div >
                                                    <div className="" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingTop: "16px" }}>
                                                        <div className="text-muted fontFamily fontsize12 fontweightRegular">
                                                            Showing {startEntryContact} to {endEntryContact} of {totalEntriesContact} entries
                                                        </div>
                                                        <div >
                                                            <div className="pagination-container d-flex align-items-center">
                                                                <button className="paginationButton btn btn-link" onClick={handleFirstPageContact}>
                                                                    <FontAwesomeIcon icon={faAngleDoubleLeft} className="icon-color" />
                                                                </button>
                                                                <ReactPaginate
                                                                    previousLabel={<FontAwesomeIcon icon={faChevronLeft} className="icon-color" />}
                                                                    nextLabel={<FontAwesomeIcon icon={faChevronRight} className="icon-color" />}
                                                                    breakLabel={'...'}
                                                                    breakClassName={'break-me'}
                                                                    pageCount={Math.ceil(totalEntriesContact / rowsPerPageContact)}
                                                                    forcePage={pageContact}
                                                                    marginPagesDisplayed={2}
                                                                    pageRangeDisplayed={5}
                                                                    onPageChange={handleChangePagePaginationContact}
                                                                    containerClassName={'pagination'}
                                                                    subContainerClassName={'pages pagination'}
                                                                    activeClassName={'active'}
                                                                    renderOnZeroPageCount={null}
                                                                />
                                                                <button className="paginationButton btn btn-link" onClick={handleLastPageContact}>
                                                                    <FontAwesomeIcon icon={faAngleDoubleRight} className="icon-color" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div style={{ padding: "0px 100px" }}>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {activeScreen === "ContactEdit" && (
                        <div className="customScrollbar">
                            <div className="card mt-4">
                                <div>
                                    <form className="p-4" noValidate onSubmit={handleSubmitContact(handleContactSubmit)}>
                                        <div className="row">
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                                    Contact Name<span className="mandatoryCss"> *</span>
                                                </div>
                                                <input
                                                    type="text"
                                                    placeholder=""
                                                    {...registerContact('contactName', {
                                                        required: 'Please enter Contact Name',
                                                        validate: async (value) => {
                                                            if (value && !/^[a-zA-Z0-9\s]*$/.test(value)) {
                                                                return 'Invalid contact name, only alphanumeric characters are allowed';
                                                            }
                                                        }
                                                    })}
                                                    className={isContactView ? "textBoxNewView" : "textBoxNew"}
                                                    disabled={isContactView}
                                                />
                                                {errorsContact.contactName && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errorsContact.contactName.message}
                                                    </div>
                                                )}
                                            </div>

                                            <div className="col-6 col-md-6 col-lg-6">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24" >Designation</div>
                                                <Controller
                                                    name="designation"
                                                    control={controlContact}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            value={designationArray.find(des => des.id === field.value) || null}
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption ? selectedOption.id : null);
                                                            }}
                                                            options={designationArray}
                                                            getOptionLabel={(option) => option.designationName}
                                                            getOptionValue={(option) => option.id}
                                                            placeholder="Select Designation"
                                                            isClearable
                                                            isSearchable
                                                            isDisabled={isContactView}
                                                            styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                                    Contact Number<span className="mandatoryCss"> *</span>
                                                </div>
                                                <input
                                                    type="text"
                                                    placeholder=""
                                                    maxLength="10"
                                                    {...registerContact('contactNumber', {
                                                        required: 'Please enter your Contact number',
                                                        validate: (value) => {
                                                            if (value && !/^[6-9]\d{9}$/.test(value)) {
                                                                return 'Invalid contact number, must be a valid 10-digit Indian contact number';
                                                            }
                                                        }
                                                    })}
                                                    onInput={(e) => {
                                                        e.target.value = e.target.value.replace(/\D/g, '');
                                                    }}
                                                    className={isContactView ? "textBoxNewView" : "textBoxNew"}
                                                    disabled={isContactView}
                                                />
                                                {errorsContact.contactNumber && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errorsContact.contactNumber.message}
                                                    </div>
                                                )}
                                            </div>

                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                            </div>
                                        </div>

                                        <div className="text-center buttonSpace">
                                            {!isContactView && (
                                                <button type="submit" className="coloredButtonSave">{isLoading ? 'Loading...' : 'Save'}</button>
                                            )}
                                            <button type="button" className="outlineButton" onClick={closeContactEdit}>Cancel</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    )}

                    {activeScreen === "PurchaseView" && (
                        <div className="customScrollbar">
                            <div className="card mt-4">
                                <div className="cardBodySupplierMaster">
                                    <div >
                                        <div className="p-1">
                                            <div className="EmpBoxShadow">
                                                <div className="HeaderTab" style={{ margin: '5px 10px' }}>
                                                    {['General', 'Contact', 'Purchase', 'Sourced Asset'].map(tab => (
                                                        <a
                                                            key={tab}
                                                            href={`#${tab.replace(' ', '')}`}
                                                            className={`${tabActive === tab ? 'activeTab' : 'InactiveTab'}`}
                                                            onClick={() => setTabActive(tab)}
                                                        >
                                                            <div className="fontFamily fontweightRegular fontsize20">
                                                                {tab}
                                                            </div>
                                                        </a>
                                                    ))}
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center" style={{ padding: '10px' }}>
                                                    <div className="custom-container">
                                                        <span className="blackTextColor fontFamily fontsize16 fontweightRegular" >
                                                            <span className="show">Show</span>
                                                            <select className="custom-dropdown" value={rowsPerPagePurchase} onChange={handleRowsPerPageChangePurchase}>
                                                                {paginateArray.map((n) => (
                                                                    <option className="blackTextColor fontFamily fontsize14 fontweightRegular" key={n} value={n}>
                                                                        {n}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            <span className="blackTextColor entries fontFamily fontsize16 fontweightRegular" >
                                                                Entries
                                                            </span>
                                                        </span>
                                                        <input
                                                            type="text"
                                                            placeholder="Search..."
                                                            value={searchQueryPurchase}
                                                            onChange={handleSearchChangePurchase}
                                                            className="searchTextBox"
                                                        />
                                                    </div>

                                                    <div className="addbuttondiv">
                                                        <button type="button" id="BtnaddUnit" className="buttonAddNew fontFamily fontweightRegular fontsize16 coloredButtonAdd" disabled={isSupplierAdd || isSupplierView} onClick={handlePurchaseAdd}>Add New </button>
                                                    </div>
                                                </div>
                                                <div className="" style={{ padding: '10px' }}>
                                                    <table className="w-100 mt-2">
                                                        <thead>
                                                            <tr className="text-left empTableHeight">
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg  " style={{ width: "12%", padding: '2px 2px 2px 3%' }}>SI.No</th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 " style={{ width: "15%" }}>Date</th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 " style={{ width: "15%" }}>Invoice Details</th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 " style={{ width: "15%" }}>Documents</th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 " style={{ width: "8%" }}>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {filteredPurchase.length > 0 ? (
                                                                filteredPurchase.slice(pagePurchase * rowsPerPagePurchase, (pagePurchase + 1) * rowsPerPagePurchase).map((Purchase, index) => (
                                                                    <tr key={Purchase.Rowid} style={{ height: "59px" }}>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`} style={{ padding: "2px 2px 2px 4%" }}>{Purchase.Rowid}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>
                                                                            {Purchase?.formatedPurchaseDate}
                                                                        </td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>{Purchase?.orderInvoiceDetails}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>{Purchase?.documentLink}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>
                                                                            <span style={{ cursor: 'pointer' }}><a href="#viewPurchase" onClick={() => handleViewPurchase(Purchase.id)}><img style={{ width: '18px', height: '15px' }} src={viewsvgIcon} alt="viewsvgIcon" /></a></span>
                                                                            <span style={{ marginLeft: '10px', cursor: 'pointer' }}><a href="#editPurchase" style={{ cursor: isSupplierAdd || isSupplierView ? 'not-allowed' : 'pointer' }} onClick={isSupplierAdd || isSupplierView ? null : () => handleEditPurchase(Purchase.id)}><img src={editsvgIcon} alt="editsvgIcon" /></a></span>
                                                                            <span style={{ marginLeft: '10px', cursor: 'pointer' }}><a href="#deletePurchase" style={{ cursor: isSupplierAdd || isSupplierView ? 'not-allowed' : 'pointer' }} onClick={isSupplierAdd || isSupplierView ? null : () => handleDeletePurchase(Purchase)}><img src={deletesvgIcon} alt="deletesvgIcon" /></a></span>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan="4" className="text-center fontFamily fontweightRegular fontsize16">
                                                                        <img src={nodataFound} alt="no data found" />
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            {totalEntriesPurchase > 0 && (
                                                <div >
                                                    <div className="" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingTop: "16px" }}>
                                                        <div className="text-muted fontFamily fontsize12 fontweightRegular">
                                                            Showing {startEntryPurchase} to {endEntryPurchase} of {totalEntriesPurchase} entries
                                                        </div>
                                                        <div >
                                                            <div className="pagination-container d-flex align-items-center">
                                                                <button className="paginationButton btn btn-link" onClick={handleFirstPagePurchase}>
                                                                    <FontAwesomeIcon icon={faAngleDoubleLeft} className="icon-color" />
                                                                </button>
                                                                <ReactPaginate
                                                                    previousLabel={<FontAwesomeIcon icon={faChevronLeft} className="icon-color" />}
                                                                    nextLabel={<FontAwesomeIcon icon={faChevronRight} className="icon-color" />}
                                                                    breakLabel={'...'}
                                                                    breakClassName={'break-me'}
                                                                    pageCount={Math.ceil(totalEntriesPurchase / rowsPerPagePurchase)}
                                                                    forcePage={pagePurchase}
                                                                    marginPagesDisplayed={2}
                                                                    pageRangeDisplayed={5}
                                                                    onPageChange={handleChangePagePaginationPurchase}
                                                                    containerClassName={'pagination'}
                                                                    subContainerClassName={'pages pagination'}
                                                                    activeClassName={'active'}
                                                                    renderOnZeroPageCount={null}
                                                                />
                                                                <button className="paginationButton btn btn-link" onClick={handleLastPagePurchase}>
                                                                    <FontAwesomeIcon icon={faAngleDoubleRight} className="icon-color" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div style={{ padding: "0px 100px" }}>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {activeScreen === "PurchaseEdit" && (
                        <div className="customScrollbar">
                            <div className="card mt-4">
                                <div>
                                    <form className="p-4" noValidate onSubmit={handleSubmitPurchase(handlePurchaseSubmit)}>
                                        <div className="row">
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor">
                                                    Date<span className="mandatoryCss"> *</span>
                                                </div>
                                                <div className="d-flex">
                                                    <Controller
                                                        name="purchaseDate"
                                                        control={controlPurchase}
                                                        render={({ field }) => (
                                                            <DatePicker
                                                                {...field}
                                                                selected={field.value}
                                                                onChange={(date) => field.onChange(date)}
                                                                dateFormat="yyyy-MM-dd"
                                                                placeholderText="Select Date"
                                                                className="customDatePicker date-picker-input"
                                                                disabled={isPurchaseView}
                                                            />
                                                        )}
                                                    />
                                                    <img src={datesvgIcon} alt="datesvgIcon" height={45} />
                                                </div>
                                                {errorsPurchase.purchaseDate && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errorsPurchase.purchaseDate.message}
                                                    </div>
                                                )}
                                            </div>

                                            <div className="col-6 col-md-6 col-lg-6">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor">
                                                    Order Invoice Details<span className="mandatoryCss"> *</span>
                                                </div>
                                                <input
                                                    type="text"
                                                    placeholder=""
                                                    {...registerPurchase('orderInvoiceDetails', {
                                                        required: 'Please enter Order Invoice Details',
                                                        validate: (value) => {
                                                            if (value && !/^[a-zA-Z0-9\s]*$/.test(value)) {
                                                                return 'Invalid Order Invoice Details, only alphanumeric characters are allowed';
                                                            }
                                                        }
                                                    })}
                                                    className={isPurchaseView ? "textBoxNewView" : "textBoxNew"}
                                                    disabled={isPurchaseView}
                                                />
                                                {errorsPurchase.orderInvoiceDetails && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errorsPurchase.orderInvoiceDetails.message}
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="row" style={{ paddingTop: '24px' }}>
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <input
                                                    id="fileInput"
                                                    type="file"
                                                    style={{ display: 'none' }}
                                                    accept=".jpg,.jpeg,.png,.pdf"
                                                    onChange={handleFileChange}
                                                />
                                                {documentLink ? (
                                                    <div style={{ position: 'relative', marginTop: "16px" }}>
                                                        <input
                                                            {...registerPurchase('documentLink')}
                                                            style={{
                                                                paddingLeft: '12px',
                                                                backgroundColor: isPurchaseView ? '#FAFAFB' : '#FFFFFF',
                                                                border: '#00000014 1px solid',
                                                                borderRadius: '5px',
                                                                fontFamily: '"Nunito", sans-serif',
                                                                fontWeight: '400',
                                                                fontSize: '16px',
                                                                width: '100%',
                                                                outline: 'none',
                                                                height: '32px',
                                                            }}
                                                            disabled={isPurchaseView}
                                                        />
                                                        <button
                                                            type="button"
                                                            onClick={clearFileInput}
                                                            disabled={isPurchaseView}
                                                            style={{
                                                                position: 'absolute',
                                                                right: '8px',
                                                                top: '50%',
                                                                transform: 'translateY(-50%)',
                                                                background: 'none',
                                                                border: 'none',
                                                                cursor: isPurchaseView ? 'not-allowed' : 'pointer',
                                                            }}
                                                        >
                                                            <img src={XMarksvgIcon} alt="clear" />
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <button
                                                        type="button"
                                                        style={{ cursor: isPurchaseView ? 'not-allowed' : 'pointer' }}
                                                        disabled={isPurchaseView}
                                                        className="coloredButtonSave"
                                                        onClick={() => document.getElementById('fileInput').click()}
                                                    >
                                                        Browse
                                                    </button>
                                                )}
                                                <div className="fontFamily fontweightRegular grayTextColor" style={{ fontSize: "10px", marginTop: "4px" }}>
                                                    Allowed file formats are Pdf, JPG, PNG. Maximum allowed file size is 2MB
                                                </div>
                                            </div>

                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                            </div>
                                        </div>

                                        <div className="text-center buttonSpace">
                                            {!isPurchaseView && (
                                                <button type="submit" className="coloredButtonSave">{isLoading ? 'Loading...' : 'Save'}</button>
                                            )}
                                            <button type="button" className="outlineButton" onClick={closePurchaseEdit}>Cancel</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    )}

                    {activeScreen === "Sourced Asset" && (
                        <div className="customScrollbar">
                            <div className="card mt-4">
                                <div className="cardBodySupplierMaster">
                                    <div >
                                        <div className="p-1">
                                            <div className="EmpBoxShadow">
                                                <div className="HeaderTab" style={{ margin: '5px 10px' }}>
                                                    {['General', 'Contact', 'Purchase', 'Sourced Asset'].map(tab => (
                                                        <a
                                                            key={tab}
                                                            href={`#${tab.replace(' ', '')}`}
                                                            className={`${tabActive === tab ? 'activeTab' : 'InactiveTab'}`}
                                                            onClick={() => setTabActive(tab)}
                                                        >
                                                            <div className="fontFamily fontweightRegular fontsize20">
                                                                {tab}
                                                            </div>
                                                        </a>
                                                    ))}
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center" style={{ padding: '10px' }}>
                                                    <div className="custom-container">
                                                        <span className="blackTextColor fontFamily fontsize16 fontweightRegular" >
                                                            <span className="show">Show</span>
                                                            <select className="custom-dropdown" value={rowsPerPageAsset} onChange={handleRowsPerPageChangeAsset}>
                                                                {paginateArray.map((n) => (
                                                                    <option className="blackTextColor fontFamily fontsize14 fontweightRegular" key={n} value={n}>
                                                                        {n}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            <span className="blackTextColor entries fontFamily fontsize16 fontweightRegular" >
                                                                Entries
                                                            </span>
                                                        </span>
                                                        <input
                                                            type="text"
                                                            placeholder="Search..."
                                                            value={searchQueryAsset}
                                                            onChange={handleSearchChangeAsset}
                                                            className="searchTextBox"
                                                        />
                                                    </div>

                                                    <div className="addbuttondiv">
                                                        <select className="buttonExport fontFamily fontweightRegular fontsize16 exportBtn" name="language" id="language" defaultValue="" onChange={exportSourcedAsset}>
                                                            <option value="" hidden={true}>Export</option>
                                                            <option value="csv">CSV</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="" style={{ padding: '10px' }}>
                                                    <table className="w-100 mt-2">
                                                        <thead>
                                                            <tr className="text-left empTableHeight">
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg  " style={{ width: "12%", padding: '2px 2px 2px 3%' }}>SI.No</th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 " style={{ width: "20%" }}>Asset ID</th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 " style={{ width: "20%" }}>Category</th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 " style={{ width: "20%" }}>Sub Category</th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 " style={{ width: "15%" }}>Brand</th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 " style={{ width: "12%" }}>Associated Date</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {filteredAsset.length > 0 ? (
                                                                filteredAsset.slice(pageAsset * rowsPerPageAsset, (pageAsset + 1) * rowsPerPageAsset).map((Asset, index) => (
                                                                    <tr key={Asset?.Rowid} style={{ height: "59px" }}>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`} style={{ padding: "2px 2px 2px 4%" }}>{Asset?.Rowid}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>{Asset?.assetID}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>{Asset?.categoryId}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>{Asset?.subCategoryId}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>{Asset?.brandId}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>{Asset?.formatedPurchaseDate}</td>
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan="4" className="text-center fontFamily fontweightRegular fontsize16">
                                                                        <img src={nodataFound} alt="no data found" />
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            {totalEntriesAsset > 0 && (
                                                <div >
                                                    <div className="" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingTop: "16px" }}>
                                                        <div className="text-muted fontFamily fontsize12 fontweightRegular">
                                                            Showing {startEntryAsset} to {endEntryAsset} of {totalEntriesAsset} entries
                                                        </div>
                                                        <div >
                                                            <div className="pagination-container d-flex align-items-center">
                                                                <button className="paginationButton btn btn-link" onClick={handleFirstPageAsset}>
                                                                    <FontAwesomeIcon icon={faAngleDoubleLeft} className="icon-color" />
                                                                </button>
                                                                <ReactPaginate
                                                                    previousLabel={<FontAwesomeIcon icon={faChevronLeft} className="icon-color" />}
                                                                    nextLabel={<FontAwesomeIcon icon={faChevronRight} className="icon-color" />}
                                                                    breakLabel={'...'}
                                                                    breakClassName={'break-me'}
                                                                    pageCount={Math.ceil(totalEntriesAsset / rowsPerPageAsset)}
                                                                    forcePage={pageAsset}
                                                                    marginPagesDisplayed={2}
                                                                    pageRangeDisplayed={5}
                                                                    onPageChange={handleChangePagePaginationAsset}
                                                                    containerClassName={'pagination'}
                                                                    subContainerClassName={'pages pagination'}
                                                                    activeClassName={'active'}
                                                                    renderOnZeroPageCount={null}
                                                                />
                                                                <button className="paginationButton btn btn-link" onClick={handleLastPageAsset}>
                                                                    <FontAwesomeIcon icon={faAngleDoubleRight} className="icon-color" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div style={{ padding: "0px 100px" }}>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            )}
        </div>
    );
};

export default SupplierMaster;
