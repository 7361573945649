import React, { useState, useEffect, useRef } from 'react';
import Select from "react-select";
import DatePicker from 'react-datepicker';
import ReactPaginate from "react-paginate";
import 'react-datepicker/dist/react-datepicker.css';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import { differenceInMonths, isBefore, format, parse } from 'date-fns';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faAngleDoubleLeft, faAngleDoubleRight, faTimes } from '@fortawesome/free-solid-svg-icons';

import ConfirmDelete from "../../../../CommomPages/ConfirmDelete";
import LoadingSpinner from '../../../../CommomPages/LoadingSpinner';
import { exportWarrantyData, downloadSampleExcelWarranty } from "../ExportProcessFormConfiguration/ExportProcessFormConfiguration";

import { getDocument } from "../../../../Services/GeneralSettingsService";
import { SampleExcelDownload } from "../../ActionFormPopup/ActionFormFormPopup";
import { getAsset, CreateAssetHistory } from "../../../../Services/AssetSettingsService";
import { getDateFormat, getRegexForDateFormat, getAssetPrefix, getAssetPadding } from '../../../../CommomPages/Config';
import { getProFormWarranty, createProFormWarranty, updateProFormWarranty, deleteProFormWarranty } from '../../../../Services/ProcessFormConfigurationService';

import viewsvgIcon from '../../../../assets/icon/Eye.svg';
import editsvgIcon from "../../../../assets/icon/Edit.svg";
import redXmark from "../../../../assets/icon/RedXmark.svg";
import XMarksvgIcon from '../../../../assets/icon/X mark.svg';
import deletesvgIcon from "../../../../assets/icon/Delete.svg";
import nodataFound from "../../../../assets/img/nodatafound.png";
import greenTickMark from "../../../../assets/icon/GreenTickMark.svg";
import calendarsvgIcon from '../../../../assets/icon/calendarDate.svg';

const ProcessFormConfigurationWarranty = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        control,
        setValue,
        watch,
        resetField
    } = useForm({
        defaultValues: {
            length: 0,
            notes: '',
            assetTagId: '',
            assetName: '',
            document: '',
            documentLink: '',
            expirationDate: '',
        }
    });
    const expirationDate = watch('expirationDate');
    const documentLink = watch('documentLink');
    const assetTagId = watch('assetTagId');

    const [isLoading, setIsLoading] = useState(true);
    const [userName, setUserName] = useState('');
    const [dateFormat, setDateFormat] = useState('');
    const [activeScreen, setActiveScreen] = useState('WarrantyView');
    const fileAssetInputRef = useRef(null);
    const paginateArray = [5, 10, 15];
    const [pageAsset, setPageAsset] = useState(0);
    const [rowsPerPageAsset, setRowsPerPageAsset] = useState(5);
    const [searchQueryAsset, setSearchQueryAsset] = useState("");

    const [assetArray, setAssetArray] = useState([]);
    const [assetWarrantyArray, setAssetWarrantyArray] = useState([]);
    const [selectedWarrantyValue, setSelectedWarrantyValue] = useState('');
    const [isWarrantyView, setIsWarrantyView] = useState(false);
    const [enteredNumber, setEnteredNumber] = useState(null);
    const [selectedNumbers, setSelectedNumbers] = useState([]);
    const [showSampleExcelDownload, setShowSampleExcelDownload] = useState(false);
    const [warrantyDocID, setWarrantyDocID] = useState(0);
    const [warrantyTagID, setWarrantyTagID] = useState('');
    const [allActiveWarranty, setAllActiveWarranty] = useState(0);
    const [allExpiriedWrranty, setAllExpiriedWrranty] = useState(0);
    const [allNearlyExpiry, setAllNearlyExpiry] = useState(0);

    let localAssetDate = new Date();
    let offset = localAssetDate.getTimezoneOffset(); // Offset in minutes
    // Adjust for local time
    localAssetDate.setMinutes(localAssetDate.getMinutes() - offset);
    // Convert to ISO format without the 'Z'
    let localISODate = localAssetDate.toISOString().slice(0, -1);

    useEffect(() => {
        fetchProFormWarranty();
        getWarrantyDocNo();
        const user = localStorage.getItem('UserName');
        setUserName(user);
    }, []);

    useEffect(() => {
        if (assetTagId) {
            console.log('assetTagId', assetTagId);
            const selectedAsset = assetArray?.find(asset => asset.assetID === assetTagId);
            console.log('selectedAsset', selectedAsset);
            if (selectedAsset) {
                console.log('selectedAsset.assetName', selectedAsset.assetName);
                setValue('assetName', selectedAsset.assetName);
            } else {
                resetField('assetName');
            }
        } else {
            resetField('assetName');
        }
    }, [assetTagId, setValue]);

    // Function to handle length calculation based on expiration date
    useEffect(() => {
        if (expirationDate) {
            const currentDate = new Date();
            const selectedDate = new Date(expirationDate);

            // Check if the selected date is valid (not before purchaseDate or in the past)
            if (isBefore(selectedDate, currentDate)) {
                setValue('length', ''); // Reset length if date is in the past
                toast.error("Please select future date");
            } else {
                // Calculate the number of months between current date and expiration date
                const lengthInMonths = differenceInMonths(selectedDate, currentDate);
                setValue('length', lengthInMonths); // Set the calculated length
            }
        }
    }, [expirationDate, setValue]);

    const formatDates = (dateString, dateCurrentFormat) => {
        const date = new Date(dateString);
        if (isNaN(date)) {
            return null;
        }
        return format(date, dateCurrentFormat);  // Converts to the desired format
    };

    const getWarrantyDocNo = async () => {
        try {
            const documentData = await getDocument();
            const maxId = documentData.find(doc => doc.id === 6);
            const newId = maxId.documentNo + 1;
            setWarrantyDocID(newId);

            const assetWarrantyPrefix = 'WI';
            const assetPadding = await getAssetPadding();
            const zeros = '0'.repeat(parseInt(assetPadding, 10));
            setWarrantyTagID(`${assetWarrantyPrefix.toUpperCase()}${zeros}${newId}`);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const fetchProFormWarranty = async () => {
        setIsLoading(true);
        const dateCurrentFormat = await getDateFormat();
        setDateFormat(dateCurrentFormat);
        const assetData = await getAsset();
        setAssetArray(assetData);
        const warrantyData = await getProFormWarranty();

        const currentDate = new Date();
        const oneMonthLater = new Date();
        oneMonthLater.setMonth(currentDate.getMonth() + 1);

        let activeCount = 0;
        let expiredCount = 0;
        let nearlyExpiringCount = 0;

        const finalData = warrantyData?.map((war, index) => {
            const expirationDate = new Date(war?.expirationDate);

            // Determine if the warranty is active, expired, or nearly expiring
            let currentStatus = false;
            if (expirationDate > currentDate) {
                currentStatus = true;
                activeCount++; // Active Warranty

                // Check if it is nearly expiring (within 1 month)
                if (expirationDate <= oneMonthLater) {
                    nearlyExpiringCount++; // Nearly Expiring Warranty
                }
            } else {
                expiredCount++; // Expired Warranty
            }

            return {
                ...war,
                Rowid: index + 1,
                formattedExpirationDate: formatDates(war?.expirationDate, dateCurrentFormat),
                currentStatus,
                lengthWithMonths: war.length + ' Months' || '',
            };
        });

        setAllActiveWarranty(activeCount);
        setAllExpiriedWrranty(expiredCount);
        setAllNearlyExpiry(nearlyExpiringCount);

        setAssetWarrantyArray(finalData.reverse());
        setIsLoading(false);
    };

    const handleSelectNumber = (event) => {
        const number = parseInt(event.target.value, 10);
        if (number >= 1 && number <= 365) {
            setEnteredNumber(number);
        } else {
            setEnteredNumber(null);
        }
    };

    const handleAddDate = () => {
        if (isWarrantyView) {
            return;
        }
        if (enteredNumber !== null) {
            // Ensure selectedNumbers is always an array before using includes()
            if (!selectedNumbers?.includes(enteredNumber)) {
                const updatedSelectedNumbers = [...selectedNumbers, enteredNumber];
                setSelectedNumbers(updatedSelectedNumbers);
                setValue("selectedNumbers", updatedSelectedNumbers);
                setEnteredNumber(null);
            } else {
                toast.error("You have already entered this number");
            }
        } else {
            toast.error("Please enter a number between 1 and 365");
        }
    };

    const handleRemoveDate = (number) => {
        if (isWarrantyView) {
            return;
        }
        setSelectedNumbers(prevSelected => prevSelected?.filter(selected => selected !== number));
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            // Check file size (limit to 2MB)
            if (file.size > 2 * 1024 * 1024) {
                alert('Maximum allowed file size is 2MB');
                event.target.value = null;
                return;
            }

            // Disallow PDFs and only allow JPEG, JPG, and PNG
            if (!['image/jpeg', 'image/png'].includes(file.type)) {
                alert('Only JPG, JPEG, and PNG files are allowed.');
                event.target.value = null;
                return;
            }

            const fileName = file.name;
            const fileSize = (file.size / 1024).toFixed(0); // Size in KB
            const fileDisplayText = `${fileName} (${fileSize}k)`;

            // Read file as base64 string
            const reader = new FileReader();
            reader.onload = (e) => {
                const base64String = e.target.result.split(',')[1]; // Get base64 part
                setValue('document', base64String);
                setValue('documentLink', fileDisplayText);
            };
            reader.readAsDataURL(file); // Read file as data URL
        }
    };

    const clearFileInput = () => {
        setValue('document', '');
        setValue('documentLink', '');
        document.getElementById('fileInput').value = null;
    };

    const onCancel = () => {
        setActiveScreen('WarrantyView');
        reset();
        setEnteredNumber(null);
        setSelectedNumbers([]);
        setIsWarrantyView(false);
        setSelectedWarrantyValue('');
    };

    const handleWarrantyAdd = () => {
        setEnteredNumber(null);
        setSelectedNumbers([]);
        setIsWarrantyView(false);
        setSelectedWarrantyValue('');
        setActiveScreen('WarrantyEdit');
    };

    const handleWarrantyViewOrEdit = (selectedValue, viewStatus) => {
        console.log('inside warranty view or edit', selectedValue, viewStatus)
        setIsWarrantyView(viewStatus);
        setActiveScreen('WarrantyEdit');
        setValue('assetTagId', selectedValue?.assetTagId);
        setValue('assetName', selectedValue?.assetName);
        setValue('length', selectedValue?.length);
        setValue('notes', selectedValue?.notes);
        setValue('document', selectedValue?.document);
        setValue('documentLink', selectedValue?.documentLink);
        setValue('expirationDate', selectedValue?.expirationDate?.split('T')[0]);
        if (selectedValue?.setReminders) {
            const remindersArray = selectedValue?.setReminders?.split(',').map(Number);
            setSelectedNumbers(remindersArray);
        }
        setSelectedWarrantyValue(selectedValue);
    };

    const handleWarrantySubmit = async (data) => {
        try {
            setIsLoading(true);
            const selectedAsset = assetArray?.find(asset => asset.assetID === data.assetTagId);
            const payload = {
                id: selectedWarrantyValue == '' ? 0 : selectedWarrantyValue.id,
                assetId: selectedAsset.id,
                assetTagId: selectedAsset.assetID,
                warrantyTagId: selectedWarrantyValue == '' ? warrantyTagID : selectedWarrantyValue.warrantyTagId,
                assetName: selectedAsset.assetName,
                length: data.length,
                expirationDate: data.expirationDate,
                notes: data.notes || '',
                status: true,
                setReminders: selectedWarrantyValue == '' ? data.selectedNumbers?.join(',') : selectedNumbers?.join(','),
                document: data.document || '',
                documentLink: data.documentLink || '',
                NextID: warrantyDocID
            };

            const response = selectedWarrantyValue == '' ? await createProFormWarranty(payload) : await updateProFormWarranty(payload);
            if (response === (selectedWarrantyValue == '' ? "Inserted" : "Updated")) {
                toast.success(`Warranty ${selectedWarrantyValue == '' ? "Inserted" : "Updated"} Successfully`);
                fetchProFormWarranty();
                onCancel();
                const changedFields = [];
                // Compare each field in the payload with the corresponding field in currentAssetData
                for (const key in payload) {
                    if (payload.hasOwnProperty(key) && selectedWarrantyValue[key] !== payload[key] && key !== 'document' && key !== 'NextID') {
                        let changedFrom = selectedWarrantyValue[key] || '';
                        let changedTo = payload[key] || '';
                        if (changedFrom !== changedTo) {
                            changedFields.push({
                                assetField: key,
                                assetChangedFrom: changedFrom,
                                assetChangedTo: changedTo
                            });
                        }
                    }
                }
                for (const change of changedFields) {
                    const historyPayload = {
                        id: 0,
                        assetId: payload.assetId,
                        assetDate: localISODate,
                        assetEvent: "Warranty " + (selectedWarrantyValue == '' ? "Inserted" : "Updated"),
                        assetField: change.assetField,
                        assetChangedFrom: String(change.assetChangedFrom),
                        assetChangedTo: String(change.assetChangedTo),
                        assetActionBy: userName || '',
                    };
                    await CreateAssetHistory(historyPayload);
                };
            } else {
                toast.error(response);
            }
        } catch (error) {
            toast.error("Failed to Save Warranty");
        } finally {
            onCancel();
            setIsLoading(false);
        }
    };

    const handleDeleteWarranty = async (warranty) => {
        try {
            const isConfirmed = await ConfirmDelete();
            if (isConfirmed) {
                setIsLoading(true);
                const response = await deleteProFormWarranty(warranty.id);
                if (response === "Deleted") {
                    toast.success("Warranty Deleted Successfully");
                    const historyPayload = {
                        id: 0,
                        assetId: warranty.assetId,
                        assetDate: localISODate,
                        assetEvent: "Warranty Delete",
                        assetField: '',
                        assetChangedFrom: '',
                        assetChangedTo: '',
                        assetActionBy: userName || '',
                    };
                    await CreateAssetHistory(historyPayload);
                    fetchProFormWarranty();
                } else {
                    toast.error(response);
                }
            }
        } catch (error) {
            console.error("Error deleting warranty:", error);
            toast.error(`An error occurred while deleting the warranty.`);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAssetFileChange = async (event) => {
        const file = event.target.files[0];
        const fileType = file?.name.split('.').pop();

        if (file && (fileType === "xlsx" || fileType === "xls")) {
            const data = await file.arrayBuffer();
            const workbook = XLSX.read(data);
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { defval: "" }); // Default empty string for missing cells

            // Function to trim both the keys (headers) and their corresponding values
            const trimHeadersAndValues = (obj) => {
                const trimmedObj = {};
                Object.keys(obj).forEach(key => {
                    const trimmedKey = key.trim(); // Trim spaces in header names
                    const trimmedValue = typeof obj[key] === 'string' ? obj[key].trim() : obj[key];
                    trimmedObj[trimmedKey] = trimmedValue; // Assign trimmed key and value
                });
                return trimmedObj;
            };

            // Map through jsonData and apply trimHeadersAndValues to each row
            const trimmedJsonData = jsonData.map(trimHeadersAndValues);

            let warrantyArrayData = [];
            let warrantyErrorArrayCount = [];

            const assetPrefix = 'WI';
            // const assetPrefix = await getAssetPrefix();
            const assetPadding = await getAssetPadding();

            // Process each row of trimmed data
            trimmedJsonData.forEach((e) => {
                const warrantyExists = assetArray.some(x => x?.warrantyTagId === e?.['Warranty ID']?.trim());
                const zeros = '0'.repeat(parseInt(assetPadding, 10));
                const warrantyTagID = e?.['Warranty ID']?.trim()?.toUpperCase();

                // Create a regular expression to match the pattern
                const regex = new RegExp(`^${assetPrefix.toUpperCase()}${zeros}\\d+$`);
                if (warrantyExists) {
                    toast.error(`${e?.['Warranty ID']?.trim()} already exists.`);
                    warrantyErrorArrayCount.push(e);
                } else if (!regex.test(warrantyTagID)) {
                    toast.error(`${e?.['Warranty ID']?.trim()} this pattern not matches with configured pattern.`);
                } else {
                    warrantyArrayData.push(e);
                }
                const selectedAsset = assetArray?.find(asset => asset.assetID === e?.['Asset ID']);
                if (!selectedAsset) {
                    toast.error(`Asset ${e?.['Asset ID']} Not Exists`);
                }
            });

            event.target.value = null;
            if (warrantyArrayData.length > 0 && warrantyErrorArrayCount.length === 0) {
                uploadWarrantyData(warrantyArrayData);
            }
        } else {
            toast.error("Invalid file type. Please upload an Excel file.");
        }
    };

    const uploadWarrantyData = async (data) => {
        try {
            setIsLoading(true);
            let assetUploadErrorArrayCount = [];

            const uploadPromises = data.map(async (e) => {
                try {
                    const selectedAsset = assetArray?.find(asset => asset.assetID === e?.['Asset ID']);
                    console.log('selectedAsset', selectedAsset);
                    const expirationDate = e?.['Expiration Date'] ? formatDateToISO(e['Expiration Date']) : null;
                    console.log('expirationDate', expirationDate);
                    const payload = {
                        id: 0,
                        assetName: e?.['Asset Name'] || "",
                        assetID: selectedAsset.id,
                        assetTagID: e?.['Asset ID'] || "",
                        warrantyTagID: e?.['Warranty ID'] || "",
                        length: e?.Length || 0,
                        expirationDate: expirationDate,
                        notes: e?.Notes || '',
                        status: true,
                        setReminders: e?.Reminders || "",
                        document: e?.['Document (Base64)'] || '',
                        documentLink: e?.['Document Name'] || ''
                    };
                    console.log('payload', payload);
                    await createProFormWarranty(payload);
                } catch (error) {
                    assetUploadErrorArrayCount.push(e);
                    toast.error(`Failed to upload Asset Warranty with ID: ${e?.['Warranty ID']}`);
                }
            });

            await Promise.all(uploadPromises);
            if (assetUploadErrorArrayCount.length > 0) {
                toast.error(`${assetUploadErrorArrayCount.length} asset warranties failed to upload.`);
            } else {
                toast.success("One or More Asset Warranty uploaded successfully.");
            }
            fetchProFormWarranty();
            setPageAsset(0);
        } catch (error) {
            toast.error("An error occurred while uploading data.");
        } finally {
            setIsLoading(false);
        }
    };

    // Function to reformat the date
    const formatDateToISO = (dateString) => {
        // List of possible date formats
        const possibleFormats = ['MM/dd/yyyy', 'dd/MM/yyyy', 'yyyy/MM/dd'];

        let parsedDate;

        // Try each format until one successfully parses the date
        for (const formatString of possibleFormats) {
            try {
                parsedDate = parse(dateString, formatString, new Date());

                // Check if the parsed date is valid
                if (parsedDate instanceof Date && !isNaN(parsedDate)) {
                    return format(parsedDate, 'yyyy-MM-dd'); // Return ISO formatted date
                }
            } catch (error) {
                // Ignore the error and try the next format
            }
        }
        // Return null or throw an error if no format matched
        return null;
    };

    const handleAssetImportClick = () => {
        setShowSampleExcelDownload(true);
    };

    const closeSampleExcelDownload = () => {
        setShowSampleExcelDownload(false);
    };

    const handleDownloadSampleExcel = () => {
        downloadSampleExcelWarranty();
    };

    const onClickFileAssetInputRef = () => {
        fileAssetInputRef.current.click();
    };

    const exportAssetWarranty = (event) => {
        const selectedOption = event.target.value;
        if (selectedOption !== "") {
            handleExportAssetWarranty(selectedOption);
            event.target.value = "";
        }
    };

    const handleExportAssetWarranty = (format) => {
        exportWarrantyData(assetWarrantyArray, format);
    };


    // Asset Section Search and Pagination
    const handleSearchChangeAsset = (event) => {
        setSearchQueryAsset(event.target.value);
        setPageAsset(0);
    };

    const filteredAssetWarranty = assetWarrantyArray?.filter(
        (war) =>
            war && (
                war?.assetTagId?.toLowerCase()?.includes(searchQueryAsset?.toLowerCase()) ||
                war?.warrantyTagId?.toLowerCase()?.includes(searchQueryAsset?.toLowerCase()) ||
                war?.assetName?.toLowerCase()?.includes(searchQueryAsset?.toLowerCase()) ||
                war?.formattedExpirationDate?.toLowerCase()?.includes(searchQueryAsset?.toLowerCase()) ||
                war?.lengthWithMonths?.toLowerCase()?.includes(searchQueryAsset?.toLowerCase())
            )
    );

    const handleRowsPerPageChangeAsset = (event) => {
        setRowsPerPageAsset(parseInt(event.target.value));
        setPageAsset(0);
    };

    const handleFirstPageAsset = () => {
        handleChangePagePaginationAsset({ selected: 0 });
    };

    const handleChangePagePaginationAsset = ({ selected }) => {
        setPageAsset(selected);
    };

    const handleLastPageAsset = () => {
        handleChangePagePaginationAsset({
            selected: Math.ceil(assetWarrantyArray?.length / rowsPerPageAsset) - 1,
        });
    };

    const totalEntriesAsset = filteredAssetWarranty?.length;
    const startEntryAsset = pageAsset * rowsPerPageAsset + 1;
    const endEntryAsset = Math.min((pageAsset + 1) * rowsPerPageAsset, totalEntriesAsset);


    return (
        <div className='bacgroundStyle'>
            <ToastContainer theme="colored" />
            {isLoading && <LoadingSpinner />}

            {!isLoading && (
                <div>
                    <div className="pagetitleCountry">
                        <div className="Pageheader fontFamily fontsize24 fontweightSemiBold">Asset - Process Form Configuration - Warranty<div><InfoOutlinedIcon /></div></div>
                    </div>
                    {activeScreen === "WarrantyView" && (
                        <div className="customScrollbar">
                            <div className="card mt-4">
                                <div className="card-body-category">
                                    <div id="printableContent">
                                        <div className='' style={{ display: 'flex', justifyContent: 'flex-start', margin: '20px' }}>
                                            <div className="CustomBoxShadow" style={{ width: '10%', padding: '2% 1%' }}>
                                                <div className="ashTextColor fontFamily fontsize16 fontweightMedium textAlignCenter">
                                                    Active
                                                </div>
                                                <div className="blackTextColor fontFamily fontsize20 fontweightMedium textAlignCenter mt-4">
                                                    {allActiveWarranty}
                                                </div>
                                            </div>
                                            <div className="CustomBoxShadow" style={{ width: '10%', padding: '2% 1%', marginLeft: '2%' }}>
                                                <div className="ashTextColor fontFamily fontsize16 fontweightMedium textAlignCenter">
                                                    Expired
                                                </div>
                                                <div className="blackTextColor fontFamily fontsize20 fontweightMedium textAlignCenter mt-4">
                                                    {allExpiriedWrranty}
                                                </div>
                                            </div>
                                            <div className="CustomBoxShadow" style={{ width: '10%', padding: '2% 1%', marginLeft: '2%' }}>
                                                <div className="ashTextColor fontFamily fontsize16 fontweightMedium textAlignCenter">
                                                    Near Expiry
                                                </div>
                                                <div className="blackTextColor fontFamily fontsize20 fontweightMedium textAlignCenter mt-4">
                                                    {allNearlyExpiry}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-between align-items-center" style={{ padding: "0px 20px" }}>
                                            <div className="custom-container">
                                                <span className="blackTextColor fontFamily fontsize16 fontweightRegular">
                                                    <span className="show">Show</span>
                                                    <select className="custom-dropdown" value={rowsPerPageAsset} onChange={handleRowsPerPageChangeAsset} >
                                                        {paginateArray.map((n) => (
                                                            <option className="blackTextColor fontFamily fontsize14 fontweightRegular" key={n} value={n} >
                                                                {n}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <span className="blackTextColor entries fontFamily fontsize16 fontweightRegular">
                                                        Entries
                                                    </span>
                                                </span>
                                                <input
                                                    type="text"
                                                    placeholder="Search..."
                                                    value={searchQueryAsset}
                                                    onChange={handleSearchChangeAsset}
                                                    className="searchTextBox"
                                                />
                                            </div>

                                            <div className="addbuttondiv">
                                                <input
                                                    type="file"
                                                    ref={fileAssetInputRef}
                                                    style={{ display: 'none' }}
                                                    accept=".xlsx"
                                                    onChange={handleAssetFileChange}
                                                />
                                                <button
                                                    type="button"
                                                    id="BtnaddUnit"
                                                    className="fontFamily fontweightRegular fontsize16 coloredButtonAdd"
                                                    onClick={handleWarrantyAdd}
                                                >
                                                    Add New
                                                </button>
                                                <button
                                                    type="button"
                                                    className="fontFamily fontweightRegular fontsize16 importBtn"
                                                    onClick={handleAssetImportClick}
                                                >
                                                    Import
                                                </button>
                                                <select
                                                    className="buttonExport fontFamily fontweightRegular fontsize16 exportBtn"
                                                    name="language"
                                                    id="language"
                                                    defaultValue=""
                                                    onChange={exportAssetWarranty}
                                                >
                                                    <option value="" hidden={true}>
                                                        Export
                                                    </option>
                                                    <option value="csv">CSV</option>
                                                    <option value="excel">Excel</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="p-1" style={{ margin: "15px" }}>
                                            <div className="EmpBoxShadow">
                                                <div className="" style={{ padding: "10px" }}>
                                                    <table className="w-100 mt-2">
                                                        <thead>
                                                            <tr className="text-left empTableHeight">
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 " style={{ width: "13%" }} > Asset ID </th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 " style={{ width: "14%" }} > Warranty ID </th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 " style={{ width: "14%" }} > Asset Name </th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 " style={{ width: "14%" }} > Expire Date </th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 " style={{ width: "12%" }} > Length </th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 paddingForUniqueRow" style={{ width: "12%" }} > Status </th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 " style={{ width: "8%" }} > Action </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {filteredAssetWarranty.length > 0 ? (
                                                                filteredAssetWarranty.slice(pageAsset * rowsPerPageAsset, (pageAsset + 1) * rowsPerPageAsset).map((Warranty, index) => (
                                                                    <tr key={Warranty.Rowid} style={{ height: "59px" }}>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} ><a href={`/AssetDetailsView?id=${Warranty.assetId}`}><span style={{ color: "#605BFF" }}>{Warranty.assetTagId}</span></a></td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} ><a href={`/AssetDetailsView?id=${Warranty.assetId}`}><span style={{ color: "#605BFF" }}>{Warranty.warrantyTagId}</span></a></td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} >{Warranty.assetName}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} >{Warranty.formattedExpirationDate}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} >{Warranty.lengthWithMonths}</td>
                                                                        <td className={`${index % 2 === 0 ? "" : "tablerowBackgroundColor"} p-2 paddingForUniqueRow`} style={{ padding: '1% 2%' }}>
                                                                            {Warranty.currentStatus ? (
                                                                                <img src={greenTickMark} alt="greenTickMark" height={15} />
                                                                            ) : (
                                                                                <img src={redXmark} alt="redXmark" height={15} />
                                                                            )}
                                                                        </td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} >
                                                                            <span style={{ cursor: "pointer" }}><a href="#viewWarranty" onClick={() => handleWarrantyViewOrEdit(Warranty, true)}><img style={{ width: "18px", height: "15px", }} src={viewsvgIcon} alt="viewsvgIcon" /></a></span>
                                                                            <span style={{ marginLeft: "10px", cursor: "pointer", }}><a href="#editWarranty" onClick={() => handleWarrantyViewOrEdit(Warranty, false)}><img src={editsvgIcon} alt="editsvgIcon" /></a></span>
                                                                            <span style={{ marginLeft: "10px", cursor: "pointer", }}><a href="#deleteWarranty" onClick={() => handleDeleteWarranty(Warranty)}><img src={deletesvgIcon} alt="deletesvgIcon" /></a></span>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <td colSpan="4" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            {totalEntriesAsset > 0 && (
                                                <div>
                                                    <div className="" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingTop: "16px", }}>
                                                        <div className="text-muted fontFamily fontsize12 fontweightRegular">
                                                            Showing {startEntryAsset} to {endEntryAsset} of {totalEntriesAsset} entries
                                                        </div>
                                                        <div>
                                                            <div className="pagination-container d-flex align-items-center">
                                                                <button className="paginationButton btn btn-link" onClick={handleFirstPageAsset} >
                                                                    <FontAwesomeIcon icon={faAngleDoubleLeft} className="icon-color" />
                                                                </button>
                                                                <ReactPaginate
                                                                    previousLabel={<FontAwesomeIcon icon={faChevronLeft} className="icon-color" />}
                                                                    nextLabel={<FontAwesomeIcon icon={faChevronRight} className="icon-color" />}
                                                                    breakLabel={"..."}
                                                                    breakClassName={"break-me"}
                                                                    pageCount={Math.ceil(totalEntriesAsset / rowsPerPageAsset)}
                                                                    forcePage={pageAsset}
                                                                    marginPagesDisplayed={2}
                                                                    pageRangeDisplayed={5}
                                                                    onPageChange={handleChangePagePaginationAsset}
                                                                    containerClassName={"pagination"}
                                                                    subContainerClassName={"pages pagination"}
                                                                    activeClassName={"active"}
                                                                    renderOnZeroPageCount={null}
                                                                />
                                                                <button className="paginationButton btn btn-link" onClick={handleLastPageAsset}>
                                                                    <FontAwesomeIcon icon={faAngleDoubleRight} className="icon-color" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div style={{ padding: "0px 100px" }}></div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {activeScreen === "WarrantyEdit" && (
                        <div className="customScrollbar">
                            <div className="card mt-4">
                                <div className="card-body">
                                    <div className="blackTextColor fontFamily fontsize20 fontweightMedium">
                                        Warranty - {selectedWarrantyValue == '' ? warrantyTagID : selectedWarrantyValue.warrantyTagId}</div>
                                    <form className="p-2" noValidate onSubmit={handleSubmit(handleWarrantySubmit)}>
                                        <div className="row pTop24">
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Asset ID<span className="mandatoryCss"> *</span></div>
                                                <Controller
                                                    name="assetTagId"
                                                    control={control}
                                                    rules={{ required: 'Asset Tag ID field is required' }}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            value={assetArray.find(repo => repo.assetID === field.value) || null}
                                                            onChange={(selectedOption) => { field.onChange(selectedOption ? selectedOption.assetID : null) }}
                                                            options={assetArray}
                                                            placeholder="Select Asset Tag ID"
                                                            getOptionLabel={(option) => option.assetID + " - " + option.assetName}
                                                            getOptionValue={(option) => option.assetID}
                                                            isClearable
                                                            isSearchable
                                                            isDisabled={isWarrantyView}
                                                            styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                                        />
                                                    )}
                                                />
                                                {errors.assetTagId && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errors.assetTagId.message}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Asset Name</div>
                                                <input
                                                    type="text"
                                                    className="textBoxNewView"
                                                    {...register('assetName')}
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>

                                        <div className="row pTop24">
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">Length<span className="mandatoryCss"> *</span></div>
                                                <input
                                                    type="number"
                                                    className="textBoxNewView"
                                                    {...register('length', { required: "Length is required" })}
                                                    disabled={true}
                                                />
                                                {errors.length && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errors.length.message}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                                    Expiration Date<span className="mandatoryCss"> *</span>
                                                </div>
                                                <div className='position-relative'>
                                                    <Controller
                                                        name="expirationDate"
                                                        control={control}
                                                        rules={{
                                                            required: "Expiration Date is required",
                                                            validate: {
                                                                validDate: (value) => {
                                                                    if (!value) return "Expiration Date is required";
                                                                    if (!selectedWarrantyValue.expirationDate) {
                                                                        const formattedDate = format(value, dateFormat);
                                                                        const regex = getRegexForDateFormat(dateFormat);
                                                                        if (!regex.test(formattedDate)) {
                                                                            return "Invalid date format (" + dateFormat + ")";
                                                                        }
                                                                    }
                                                                    return true;
                                                                }
                                                            }
                                                        }}
                                                        render={({ field }) => (
                                                            <DatePicker
                                                                {...field}
                                                                selected={field.value}
                                                                onChange={(date) => field.onChange(date)}
                                                                dateFormat={dateFormat}
                                                                placeholderText={dateFormat.toUpperCase()}
                                                                className={isWarrantyView ? "DatePickerDisable" : "DatePicker"}
                                                                disabled={isWarrantyView}
                                                                ref={(input) => field.ref(input?.inputElement)}
                                                            />
                                                        )}
                                                    />
                                                    <img className="eyeContent" src={calendarsvgIcon} alt="calendarsvgIcon" />
                                                </div>
                                                {errors.expirationDate && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errors.expirationDate.message}
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">Notes</div>
                                                <textarea
                                                    className={isWarrantyView ? "textBoxNewView textAeraHeight" : "textBoxNew textAeraHeight"}
                                                    {...register('notes')}
                                                    disabled={isWarrantyView}
                                                />
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="flexContainer" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '20px' }}>
                                                    <div className="fontFamily fontweightRegular fontsize16 blackTextColor">
                                                        Set Reminder before warranty expiry (in days)
                                                    </div>
                                                    <div>
                                                        <input
                                                            type="number"
                                                            min={1}
                                                            max={365}
                                                            value={enteredNumber || ''}
                                                            className="custom-textBox blackTextColor fontFamily fontweightRegular fontsize12"
                                                            onChange={handleSelectNumber}
                                                            placeholder="Enter Count"
                                                            disabled={isWarrantyView}
                                                        />
                                                    </div>
                                                    <div className="flexEnd" style={{ marginLeft: '16px' }}>
                                                        <button
                                                            type="button"
                                                            className="fontFamily fontweightRegular fontsize16 coloredButton"
                                                            onClick={handleAddDate}
                                                            style={{ cursor: isWarrantyView ? 'not-allowed' : 'pointer' }}
                                                            disabled={isWarrantyView}
                                                        >
                                                            + Add
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="card mt-2" style={{ height: '158px', overflowY: 'auto', scrollbarWidth: 'thin' }}>
                                                    <div className="employee-container">
                                                        {selectedNumbers.map((number) => (
                                                            <div key={number} className="smallCard Card">
                                                                <div className="employee-info">
                                                                    <span className="numberInCard">{number}</span>
                                                                </div>
                                                                <a href="#remove-icon" className="remove-icon" onClick={() => handleRemoveDate(number)}>
                                                                    <FontAwesomeIcon icon={faTimes} color='red' />
                                                                </a>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row pTop24">
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 blackTextColor">
                                                    Attach Document
                                                </div>
                                                <div className="card mt-2" style={{ height: '100px' }}>
                                                    <div className='' style={{ display: 'flex', alignItems: 'center' }}>
                                                        <button
                                                            type="button"
                                                            style={{ cursor: isWarrantyView ? 'not-allowed' : 'pointer', margin: '15px 0px 10px 10px' }}
                                                            disabled={isWarrantyView}
                                                            className="coloredButtonSave"
                                                            onClick={() => document.getElementById('fileInput').click()}
                                                        >
                                                            Browse
                                                        </button>
                                                        <div className="fontFamily fontweightRegular grayTextColor fontsize14 mLeft8 mTop12">
                                                            Only (JPG, PNG) Max file size 2mb
                                                        </div>
                                                    </div>
                                                    <input
                                                        id="fileInput"
                                                        type="file"
                                                        style={{ display: 'none' }}
                                                        accept=".jpg,.jpeg,.png"
                                                        onChange={handleFileChange}
                                                    />
                                                    {documentLink && <div style={{ position: 'relative', margin: "10px" }}>
                                                        <input
                                                            {...register('documentLink')}
                                                            style={{
                                                                padding: '12px',
                                                                backgroundColor: isWarrantyView ? '#FAFAFB' : '#FFFFFF',
                                                                border: '#00000014 1px solid',
                                                                borderRadius: '5px',
                                                                fontFamily: '"Nunito", sans-serif',
                                                                fontWeight: '400',
                                                                fontSize: '16px',
                                                                width: '100%',
                                                                outline: 'none',
                                                                height: '32px',
                                                            }}
                                                            disabled={isWarrantyView}
                                                        />
                                                        <button
                                                            type="button"
                                                            onClick={clearFileInput}
                                                            disabled={isWarrantyView}
                                                            style={{
                                                                position: 'absolute',
                                                                right: '8px',
                                                                top: '50%',
                                                                transform: 'translateY(-50%)',
                                                                background: 'none',
                                                                border: 'none',
                                                                cursor: isWarrantyView ? 'not-allowed' : 'pointer',
                                                            }}
                                                        >
                                                            <img src={XMarksvgIcon} alt="clear" />
                                                        </button>
                                                    </div>
                                                    }
                                                </div>
                                            </div>

                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                            </div>
                                        </div>


                                        <div className="mb-2 text-center" style={{ marginTop: "10%" }}>
                                            {!isWarrantyView && (
                                                <button type="submit" className="coloredButtonSave">Save</button>
                                            )}
                                            <button type="button" className="outlineButton" onClick={onCancel} style={{ marginLeft: '18px' }} >Cancel</button>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    )}

                    {showSampleExcelDownload && (
                        <SampleExcelDownload
                            showModal={showSampleExcelDownload}
                            closeModal={closeSampleExcelDownload}
                            handleDownloadSampleExcel={handleDownloadSampleExcel}
                            onClickFileAssetInputRef={onClickFileAssetInputRef}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default ProcessFormConfigurationWarranty;
