import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import ReactPaginate from 'react-paginate';
import { useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as XLSX from 'xlsx';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';

import ConfirmDelete from '../../../CommomPages/ConfirmDelete';
import LoadingSpinner from '../../../CommomPages/LoadingSpinner';
import {
    getRequestCategory,
    deleteRequestCategory,
    createRequestCategory,
    updateRequestCategory,
    getRequestSubCategory,
    createRequestSubCategory,
    updateRequestSubCategory,
    deleteRequestSubCategory,
    getRequestTeam,
    deleteRequestTeam,
    createRequestTeam,
    updateRequestTeam,
} from '../../../Services/RequestService';
import { getTeam } from '../../../Services/DepartmentsAndTeamService';
import { getEmployee } from '../../../Services/EmployeeManagementService';
import { exportDataRequestCategory, exportDataRequestSubCategory, exportDataRequestTeam } from './ExportRequest';

import './RequestCss.css';

import viewsvgIcon from '../../../assets/icon/Eye.svg';
import editsvgIcon from '../../../assets/icon/Edit.svg';
import deletesvgIcon from '../../../assets/icon/Delete.svg';
import nodataFound from '../../../assets/img/nodatafound.png';


const customStyles = {
    placeholder: (provided) => ({
        ...provided,
        fontFamily: 'Nunito, sans-serif',
        fontWeight: 400,
        fontSize: '14px',
        color: '#B0ACAC',
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 9999,
    }),
};


const RequestCategoriesAndTeam = () => {
    const location = useLocation();
    const [tabActive, setTabActive] = useState('RequestCategory');
    const [activeScreen, setActiveScreen] = useState('RequestCategory');
    const [tabStatusActive, setTabStatusActive] = useState('Request Category');
    const [isLoading, setIsLoading] = useState(true);
    const [teamArray, setTeamArray] = useState([]);
    const [employeeArray, setEmployeeArray] = useState([]);

    // Request Category Section
    const [isRequestCategoryView, setIsRequestCategoryView] = useState(false);
    const [requestCategoryID, setRequestCategoryID] = useState(0);
    const [requestCategoryArray, setRequestCategoryArray] = useState([]);
    const [pageCategory, setPageCategory] = useState(0);
    const [rowsCategoryPerPage, setRowsCategoryPerPage] = useState(5);
    const [searchQueryCategory, setSearchQueryCategory] = useState('');
    const fileRequestCategoryInputRef = useRef(null);
    const {
        register: registerReqCategory,
        handleSubmit: handleSubmitReqCategory,
        formState: { errors: errorsReqCategory },
        setValue: setValueReqCategory,
        reset: resetReqCategory,
        watch: watchReqCategory
    } = useForm({
        defaultValues: {
            requestCategoryName: '',
            requestCategoryCode: '',
            requestCategoryDescription: '',
            requestCategoryStatus: true,
        }
    });

    const statusWatchRequestCategory = watchReqCategory('requestCategoryStatus');

    // Request Request Sub Category Section
    const [isRequestSubCategoryView, setIsRequestSubCategoryView] = useState(false);
    const [requestCategorySelectID, setRequestCategorySelectID] = useState(0);
    const [requestSubCategoryID, setRequestSubCategoryID] = useState(0);
    const [requestSubCategoryArray, setRequestSubCategoryArray] = useState([]);
    const [pageRequestSubCategory, setPageRequestSubCategory] = useState(0);
    const [rowsRequestSubCategoryPerPage, setRowsRequestSubCategoryPerPage] = useState(5);
    const [searchQueryRequestSubCategory, setSearchQueryRequestSubCategory] = useState('');
    const fileRequestSubCategoryInputRef = useRef(null);
    const {
        register: registerReqSubCategory,
        handleSubmit: handleSubmitReqSubCategory,
        formState: { errors: errorsReqSubCategory },
        setValue: setValueReqSubCategory,
        reset: resetReqSubCategory,
        watch: watchReqSubCategory,
        control: controlReqSubCategory,
        trigger: triggerReqSubCategory
    } = useForm({
        defaultValues: {
            requestCategorySelectID: 0,
            requestSubCategoryName: '',
            requestSubCategoryCode: '',
            requestSubCategoryDescription: '',
            requestSubCategoryStatus: true,
        }
    });

    const statusWatchReqSubCategory = watchReqSubCategory('requestSubCategoryStatus');

    // Add useEffect to trigger validation when the form loads
    useEffect(() => {
        triggerReqSubCategory('requestCategorySelectID');
    }, [triggerReqSubCategory]);


    // RequestTeam Section
    const [isRequestTeamView, setIsRequestTeamView] = useState(false);
    const [requestTeamID, setRequestTeamID] = useState(0);
    const [requestTeamArray, setRequestTeamArray] = useState([]);
    const [pageRequestTeam, setPageRequestTeam] = useState(0);
    const [rowsRequestTeamPerPage, setRowsRequestTeamPerPage] = useState(5);
    const [searchQueryRequestTeam, setSearchQueryRequestTeam] = useState('');
    const [requestTeamCategorySelectID, setRequestTeamCategorySelectID] = useState(null);
    const [requestTeamSubCategorySelectID, setRequestTeamSubCategorySelectID] = useState(null);
    const [requestFilteredSubCategoryArrayForTeam, setFilteredRequestSubCategoryArrayForTeam] = useState([]);

    const teamOrEmployeeOptions = [
        { value: "Team", label: "Team" },
        { value: "Employee", label: "Employee" }
    ];

    const {
        register: registerRequestTeam,
        handleSubmit: handleSubmitRequestTeam,
        formState: { errors: errorsRequestTeam },
        setValue: setValueRequestTeam,
        reset: resetRequestTeam,
        resetField: resetFieldRequestTeam,
        watch: watchRequestTeam,
        control: controlRequestTeam,
        trigger: triggerRequestTeam
    } = useForm({
        defaultValues: {
            requestTeamCategorySelectID: 0,
            requestTeamSubCategorySelectID: 0,
            requestTeamOrEmpId: 0,
            requestIsTeam: 'Team',
            requestAssignTo: '',
            requestTeamStatus: true,
        }
    });

    const [selectedTeams, setSelectedTeams] = useState([]);
    const [selectedEmployees, setSelectedEmployees] = useState([]);

    const statusWatchRequestTeam = watchRequestTeam('requestTeamStatus');
    const requestIsTeam = watchRequestTeam("requestIsTeam");

    useEffect(() => {
        if (requestTeamCategorySelectID) {
            const filteredRequestSubCategory = requestSubCategoryArray.filter((item) => item.requestCategoryId === requestTeamCategorySelectID);
            setFilteredRequestSubCategoryArrayForTeam(filteredRequestSubCategory);
        }
    }, [requestTeamCategorySelectID]);

    const getOptions = () => {
        return requestIsTeam === "Team" ? teamArray : employeeArray;
    };

    // Handle the Assign button click
    const handleAssign = () => {
        const selectedTeamOrEmployee = getOptions().find(option => option.id === watchRequestTeam("requestTeamOrEmpId"));

        if (selectedTeamOrEmployee) {
            const assignValue = requestIsTeam === "Team"
                ? selectedTeamOrEmployee.teamName
                : selectedTeamOrEmployee.employeeName;

            // Get the current value of 'Assign To'
            const currentAssignToValue = watchRequestTeam("requestAssignTo");

            // Check if the selected team or employee has already been assigned
            if (requestIsTeam === "Team" && selectedTeams.includes(selectedTeamOrEmployee.id)) {
                toast.error("You have already selected this team!");
                return; // Prevent further execution
            }

            if (requestIsTeam === "Employee" && selectedEmployees.includes(selectedTeamOrEmployee.id)) {
                toast.error("You have already selected this employee!");
                return; // Prevent further execution
            }

            // Add the selected ID to the appropriate array
            if (requestIsTeam === "Team") {
                setSelectedTeams(prev => {
                    const updatedTeams = [...prev, selectedTeamOrEmployee.id];
                    return updatedTeams;
                });
            } else if (requestIsTeam === "Employee") {
                setSelectedEmployees(prev => {
                    const updatedEmployees = [...prev, selectedTeamOrEmployee.id];
                    return updatedEmployees;
                });
            }

            // If not assigned before, proceed to assign
            const newAssignToValue = currentAssignToValue
                ? `${currentAssignToValue}, ${assignValue}`
                : assignValue;

            setValueRequestTeam("requestAssignTo", newAssignToValue);
            resetFieldRequestTeam("requestTeamOrEmpId");
            // To make sure state is updated before the next check
            toast.success(`${assignValue} has been successfully assigned!`);
        }
    };


    // Add useEffect to trigger validation when the form loads
    useEffect(() => {
        triggerRequestTeam('requestTeamCategorySelectID');
        triggerRequestTeam('requestTeamSubCategorySelectID');
    }, [triggerRequestTeam]);

    useEffect(() => {
        const queryParams = getQueryParams(location.search);
        const tabName = queryParams.get('tab');
        // use tabName to set the active tab while navigate from settings page
        if (tabName) {
            setTabActive(tabName);
            selectedTab(tabName);
        } else {
            selectedTab("RequestCategory");
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchData = async () => {
        setIsLoading(true);
        await getRequestCategoryDetails();
        await getTeamAndEmployeeData();
        setIsLoading(false);
    };

    const getQueryParams = (query) => {
        return new URLSearchParams(query);
    }

    const getRequestCategoryDetails = async () => {
        const reqCategoryData = await getRequestCategory();
        const modifiedReqCategoryData = reqCategoryData?.map((reqCat, index) => ({
            ...reqCat,
            Rowid: index + 1
        }));
        setRequestCategoryArray(modifiedReqCategoryData);
        await getRequestSubCategoryDetails(modifiedReqCategoryData);
        setPageCategory(0);
    };

    const getRequestSubCategoryDetails = async (_CategoryValue) => {
        const reqSubCategoryData = await getRequestSubCategory();
        const modifiedReqSubCategoryData = reqSubCategoryData?.map((reqSubCat, index) => ({
            ...reqSubCat,
            Rowid: index + 1,
            requestCategoryNameInSubCategory: _CategoryValue?.find(x => x.id === reqSubCat.requestCategoryId)?.requestCategoryName,
        }));
        setRequestSubCategoryArray(modifiedReqSubCategoryData);
        await getRequestTeamDetails(modifiedReqSubCategoryData);
        setPageRequestSubCategory(0);
    };

    const getRequestTeamDetails = async (_RequestSubCategoryValue) => {
        const RequestTeamData = await getRequestTeam();
        const modifiedRequestTeamData = RequestTeamData?.map((reqTeam, index) => ({
            ...reqTeam,
            Rowid: index + 1,
            requestCategoryName: _RequestSubCategoryValue?.find(x => x.requestCategoryId === reqTeam.requestCategoryId)?.requestCategoryNameInSubCategory,
            requestSubCategoryName: _RequestSubCategoryValue?.find(x => x.id === reqTeam.requestSubCategoryId)?.requestSubCategoryName,
        }));
        setRequestTeamArray(modifiedRequestTeamData);
        setPageRequestTeam(0);
    };

    const getTeamAndEmployeeData = async () => {
        const teamData = await getTeam();
        setTeamArray(teamData);

        const empData = await getEmployee();
        const finalData = empData.map((Emp, index) => {
            return {
                ...Emp,
                employeeName: Emp.firstName + " " + Emp.lastName,
            };
        });
        setEmployeeArray(finalData);
    };

    const selectedTab = (tabValue) => {
        setTabActive(tabValue);
        if (tabValue === "RequestCategory") {
            setTabStatusActive("Request Category")
        }
        else if (tabValue === "RequestSubCategory") {
            setTabStatusActive("Request Sub Category")
        }
        else if (tabValue === "RequestTeam") {
            setTabStatusActive("Request Team")
        }
    };


    // Request Category Section
    const handleRequestCategorySubmit = async (data) => {
        const { requestCategoryName, requestCategoryCode, requestCategoryDescription, requestCategoryStatus } = data;
        const reqCategoryExists = requestCategoryArray?.some(x => x.requestCategoryName === requestCategoryName);
        const isUpdating = requestCategoryID !== 0;

        if (isUpdating) {
            const currentCategory = requestCategoryArray?.find(x => x.id === requestCategoryID);
            if (reqCategoryExists && currentCategory.requestCategoryName !== requestCategoryName) {
                toast.error("Request Category name already exists.");
                return;
            }
        } else if (reqCategoryExists) {
            toast.error("Request Category name already exists.");
            return;
        }

        setIsLoading(true);
        const payload = {
            id: requestCategoryID || 0,
            requestCategoryName,
            requestCategoryCode,
            requestDescription: requestCategoryDescription,
            requestStatus: requestCategoryStatus ? 'Active' : 'Inactive'
        };
        const response = isUpdating ? await updateRequestCategory(payload) : await createRequestCategory(payload);
        if (response === (isUpdating ? "Updated" : "Inserted")) {
            getRequestCategoryDetails();
            toast.success(`Request Category ${isUpdating ? "Updated" : "Inserted"} Successfully`);
            if (isUpdating) setPageCategory(0);
            closeRequestCategory();
        } else {
            toast.error(response);
        }
        setIsLoading(false);
    };

    const addNewRequestCategory = () => {
        setIsRequestCategoryView(false);
        resetReqCategory();
        setRequestCategoryID(0);
        setActiveScreen("RequestCategoryEdit");
    };

    const viewOrEditRequestCategory = (requestCategoryID, viewStatus) => {
        setIsRequestCategoryView(viewStatus);
        setRequestCategoryID(requestCategoryID);
        const categoryData = requestCategoryArray?.find(x => x.id === requestCategoryID);
        setValueReqCategory('requestCategoryName', categoryData.requestCategoryName);
        setValueReqCategory('requestCategoryCode', categoryData.requestCategoryCode);
        setValueReqCategory('requestCategoryDescription', categoryData.requestDescription);
        setValueReqCategory('requestCategoryStatus', categoryData.requestStatus === 'Active');
        setActiveScreen("RequestCategoryEdit");
    };

    const DeleteRequestCategory = async (requestCategoryID) => {
        try {
            const subCategoryData = await getRequestSubCategory();
            const isAssociated = subCategoryData?.some(subCat => subCat.requestCategoryId === requestCategoryID);
            if (isAssociated) {
                toast.error(`Cannot delete the Request Category as it is associated with one or more sub-Categorys.`);
                return;
            }

            const requestteamData = await getRequestTeam();
            const isAssociatedRequestTeam = requestteamData.some(team => team.requestCategoryId === requestCategoryID);
            if (isAssociatedRequestTeam) {
                toast.error(`Cannot delete the Category as it is associated with one or more RequestTeams.`);
                return;
            }

            const isConfirmed = await ConfirmDelete();
            if (isConfirmed) {
                setIsLoading(true);
                const response = await deleteRequestCategory(requestCategoryID);
                if (response === "Deleted") {
                    toast.success("RequestCategory Deleted Successfully");
                    getRequestCategoryDetails();
                } else {
                    toast.error(response);
                }
                setIsLoading(false);
            }
        } catch (error) {
            console.error("Error deleting request category:", error);
            toast.error(`An error occurred while deleting the request category.`);
        } finally {
            setIsLoading(false);
        }
    };

    const closeRequestCategory = () => {
        setIsRequestCategoryView(false);
        resetReqCategory();
        setActiveScreen("RequestCategory");
    };

    const handleRequestCategoryImportClick = () => {
        fileRequestCategoryInputRef.current.click();
    };

    const handleRequestCategoryFileChange = async (event) => {
        const file = event.target.files[0];
        if (file && file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            const data = await file.arrayBuffer();
            const workbook = XLSX.read(data);
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(worksheet);

            let requestCategoryArrayData = [];
            let reqCategoryErrorArrayCount = [];

            jsonData.forEach((e) => {
                const reqCategoryExists = requestCategoryArray?.some(x => x?.requestCategoryName === e.RequestCategoryName);
                if (reqCategoryExists) {
                    toast.error(`${e.RequestCategoryName} already exists.`);
                    reqCategoryErrorArrayCount.push(e);
                } else {
                    requestCategoryArrayData.push(e);
                }
            });

            event.target.value = null;
            if (requestCategoryArrayData?.length > 0 && reqCategoryErrorArrayCount?.length === 0) {
                uploadRequestCategoryData(requestCategoryArrayData);
            }
        } else {
            toast.error("Invalid file type. Please upload an Excel file.");
        }
    };

    const uploadRequestCategoryData = async (data) => {
        try {
            setIsLoading(true);
            const uploadPromises = data?.map(async (e) => {
                const payload = {
                    id: 0,
                    requestCategoryName: e.RequestCategoryName,
                    requestCategoryCode: e.RequestCategoryCode,
                    requestDescription: e.RequestCategoryDescription,
                    requestStatus: e.RequestCategoryStatus
                }
                const response = await createRequestCategory(payload);
                if (response !== "Inserted") throw new Error("Failed to upload data");
            });
            await Promise.all(uploadPromises);
            getRequestCategoryDetails();
            closeRequestCategory();
            setPageCategory(0);
            toast.success("Data uploaded successfully.");
        } catch (error) {
            toast.error("An error occurred while uploading data.");
        } finally {
            setIsLoading(false);
        }
    };

    const exportCategory = (event) => {
        const selectedOption = event.target.value;
        if (selectedOption !== '') {
            handleExportCategory(selectedOption);
            event.target.value = '';
        }
    };

    const handleExportCategory = (format) => {
        exportDataRequestCategory(requestCategoryArray, format);
    };


    // Request Sub Category Section
    const handleRequestSubCategorySubmit = async (data) => {
        const { requestSubCategoryName, requestSubCategoryCode, requestSubCategoryDescription, requestSubCategoryStatus } = data;
        const subCategoryExists = requestSubCategoryArray?.some(x => x.requestSubCategoryName === requestSubCategoryName && x.requestCategoryId === requestCategorySelectID);
        const isUpdating = requestSubCategoryID !== 0;

        if (isUpdating) {
            const currentRequestSubCategory = requestSubCategoryArray?.find(x => x.id === requestSubCategoryID);
            if (subCategoryExists && currentRequestSubCategory.requestSubCategoryName !== requestSubCategoryName) {
                toast.error("Sub-Category name already exists.");
                return;
            }
        } else if (subCategoryExists) {
            toast.error("Sub-Category name already exists.");
            return;
        }

        setIsLoading(true);
        const payload = {
            id: requestSubCategoryID || 0,
            requestCategoryId: requestCategorySelectID || 0,
            requestSubCategoryName,
            requestSubCategoryCode,
            requestDescription: requestSubCategoryDescription,
            requestStatus: requestSubCategoryStatus ? 'Active' : 'Inactive'
        };

        const response = isUpdating ? await updateRequestSubCategory(payload) : await createRequestSubCategory(payload);
        if (response === (isUpdating ? "Updated" : "Inserted")) {
            getRequestCategoryDetails();
            toast.success(`Request Sub-Category ${isUpdating ? "Updated" : "Inserted"} Successfully`);
            closeRequestSubCategory();
        } else {
            toast.error(response);
        }
        setIsLoading(false);
    };

    const addNewRequestSubCategory = () => {
        setIsRequestSubCategoryView(false);
        resetReqSubCategory();
        setRequestCategorySelectID(0);
        setRequestSubCategoryID(0);
        setActiveScreen("RequestSubCategoryEdit");
    };

    const viewOrEditRequestSubCategory = (requestSubCategoryID, viewStatus) => {
        setIsRequestSubCategoryView(viewStatus);
        setRequestSubCategoryID(requestSubCategoryID);
        const subCategoryData = requestSubCategoryArray?.find(x => x.id === requestSubCategoryID);
        const categoryData = requestCategoryArray?.find(x => x.id === subCategoryData?.requestCategoryId);
        setRequestCategorySelectID(categoryData?.id);
        setValueReqSubCategory('requestCategorySelectID', categoryData?.id);
        setValueReqSubCategory('requestSubCategoryName', subCategoryData?.requestSubCategoryName);
        setValueReqSubCategory('requestSubCategoryCode', subCategoryData?.requestSubCategoryCode);
        setValueReqSubCategory('requestSubCategoryDescription', subCategoryData?.requestDescription);
        setValueReqSubCategory('requestSubCategoryStatus', subCategoryData?.requestStatus === 'Active');
        setActiveScreen("RequestSubCategoryEdit");
    };

    const DeleteRequestSubCategory = async (requestSubCategoryID) => {
        try {
            const requestteamData = await getRequestTeam();
            const isAssociatedRequestTeam = requestteamData.some(team => team.requestSubCategoryId === requestSubCategoryID);
            if (isAssociatedRequestTeam) {
                toast.error(`Cannot delete the Sub-Category as it is associated with one or more RequestTeams.`);
                return;
            }

            const isConfirmed = await ConfirmDelete();
            if (isConfirmed) {
                setIsLoading(true);
                const response = await deleteRequestSubCategory(requestSubCategoryID);
                if (response === "Deleted") {
                    getRequestCategoryDetails();
                    toast.success("Request Sub Category Deleted Sucessfully");
                } else {
                    toast.error(response);
                }
                setIsLoading(false);
            }
        } catch (error) {
            console.error("Error deleting Sub-Category:", error);
            toast.error(`An error occurred while deleting the Sub-Category.`);
        } finally {
            setIsLoading(false);
        }
    };

    const closeRequestSubCategory = () => {
        setIsRequestSubCategoryView(false);
        resetReqSubCategory();
        setActiveScreen("RequestCategory");
    };

    const handleRequestSubCategoryImportClick = () => {
        fileRequestSubCategoryInputRef.current.click();
    };

    const handleRequestSubCategoryFileChange = async (event) => {
        const file = event.target.files[0];
        if (file && file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            const data = await file.arrayBuffer();
            const workbook = XLSX.read(data);
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(worksheet);

            let requestSubCategoryArrayData = [];
            let subCategoryErrorArrayCount = [];

            jsonData.forEach((e) => {
                const reqCategoryExists = requestCategoryArray?.find(x => x.requestCategoryName === e.RequestCategoryName);
                const subCategoryExists = requestSubCategoryArray?.some(x => x.requestSubCategoryName === e.RequestSubCategoryName && x.requestCategoryId === reqCategoryExists?.id);

                if (subCategoryExists) {
                    toast.error(`${e.RequestSubCategoryName} already exists.`);
                    subCategoryErrorArrayCount.push(e);
                } else if (!reqCategoryExists) {
                    toast.error(`Category name doesn't exist.`);
                    subCategoryErrorArrayCount.push(e);
                } else {
                    e.RequestCategoryId = reqCategoryExists.id;
                    requestSubCategoryArrayData.push(e);
                }
            });

            event.target.value = null;
            if (requestSubCategoryArrayData?.length > 0 && subCategoryErrorArrayCount?.length === 0) {
                await uploadRequestSubCategoryData(requestSubCategoryArrayData);
            }
        } else {
            toast.error("Invalid file type. Please upload an Excel file.");
        }
    };

    const uploadRequestSubCategoryData = async (data) => {
        try {
            setIsLoading(true);
            const uploadPromises = data?.map(async (e) => {
                const payload = {
                    id: 0,
                    requestCategoryId: e.RequestCategoryId || 0,
                    requestSubCategoryName: e.RequestSubCategoryName,
                    requestSubCategoryCode: e.RequestSubCategoryCode,
                    requestDescription: e.RequestSubCategoryDescription,
                    requestStatus: e.RequestSubCategoryStatus
                };
                const response = await createRequestSubCategory(payload);
                if (response !== "Inserted") throw new Error("Failed to upload data");
            });
            await Promise.all(uploadPromises);
            getRequestCategoryDetails();
            closeRequestSubCategory();
            setPageRequestSubCategory(0);
            toast.success("Data uploaded successfully.");
        } catch (error) {
            toast.error("An error occurred while uploading data.");
        } finally {
            setIsLoading(false);
        }
    };

    const exportRequestSubCategory = (event) => {
        const selectedOption = event.target.value;
        if (selectedOption !== '') {
            handleExportRequestSubCategory(selectedOption);
            event.target.value = '';
        }
    };

    const handleExportRequestSubCategory = (format) => {
        exportDataRequestSubCategory(requestSubCategoryArray, format);
    };


    // RequestTeam Section
    const handleRequestTeamSubmit = async (data) => {
        setIsLoading(true);
        const isUpdating = requestTeamID !== 0;

        const payload = {
            id: requestTeamID || 0,
            requestCategoryId: requestTeamCategorySelectID || 0,
            requestSubCategoryId: requestTeamSubCategorySelectID || 0,
            empId: selectedEmployees.length > 0 ? JSON.stringify(selectedEmployees) : "",
            teamId: selectedTeams.length > 0 ? JSON.stringify(selectedTeams) : "",
            isTeam: data.requestIsTeam === "Team",
            assignTo: data.requestAssignTo || "",
            requestStatus: data.requestTeamStatus ? 'Active' : 'Inactive'
        };
        console.log("payload in request Team submit", payload);
        const response = isUpdating ? await updateRequestTeam(payload) : await createRequestTeam(payload);
        if (response === (isUpdating ? "Updated" : "Inserted")) {
            getRequestCategoryDetails();
            toast.success(`Request Team ${isUpdating ? "Updated" : "Inserted"} Successfully`);
            if (isUpdating) setPageRequestTeam(0);
            closeRequestTeam();
        } else {
            toast.error(response);
        }
        setIsLoading(false);
    };

    const viewOrEditRequestTeam = (requestTeamID, viewStatus) => {
        setIsRequestTeamView(viewStatus);
        setRequestTeamID(requestTeamID);
        const requestTeamData = requestTeamArray?.find(x => x.id === requestTeamID);
        const requestTeamCategoryData = requestCategoryArray?.find(x => x.id === requestTeamData?.requestCategoryId);
        const requestTeamSubategoryData = requestSubCategoryArray?.find(x => x.id === requestTeamData?.requestSubCategoryId);

        // Parse empId and teamId if they are stringified arrays
        const parsedEmployees = requestTeamData?.empId ? JSON.parse(requestTeamData.empId) : [];
        const parsedTeams = requestTeamData?.teamId ? JSON.parse(requestTeamData.teamId) : [];

        // Set the parsed values to the state
        setSelectedEmployees(parsedEmployees);
        setSelectedTeams(parsedTeams);

        setRequestTeamCategorySelectID(requestTeamCategoryData?.id);
        setRequestTeamSubCategorySelectID(requestTeamSubategoryData?.id);
        setValueRequestTeam('requestTeamCategorySelectID', requestTeamCategoryData?.id);
        setValueRequestTeam('requestTeamSubCategorySelectID', requestTeamSubategoryData?.id);
        setValueRequestTeam('requestIsTeam', requestTeamData.isTeam ? "Team" : "Employee");
        setValueRequestTeam('requestAssignTo', requestTeamData.assignTo);
        setValueRequestTeam('requestTeamStatus', requestTeamData.requestStatus === 'Active');
        setActiveScreen("RequestTeamEdit");
    };

    const addNewRequestTeam = () => {
        setIsRequestTeamView(false);
        resetRequestTeam();
        setSelectedTeams([]);
        setSelectedEmployees([]);
        setRequestTeamID(0);
        setRequestTeamCategorySelectID(0);
        setRequestTeamSubCategorySelectID(0);
        setActiveScreen("RequestTeamEdit");
    };

    const closeRequestTeam = () => {
        setIsRequestTeamView(false);
        resetRequestTeam();
        setActiveScreen("RequestCategory");
        setSelectedTeams([]);
        setSelectedEmployees([]);
        setRequestTeamID(0);
        setRequestTeamCategorySelectID(0);
        setRequestTeamSubCategorySelectID(0);
    };

    const DeleteRequestTeam = async (requestTeamID) => {
        const isConfirmed = await ConfirmDelete();
        if (isConfirmed) {
            setIsLoading(true);
            const response = await deleteRequestTeam(requestTeamID);
            if (response === "Deleted") {
                toast.success("Request Team Deleted Successfully");
                getRequestCategoryDetails();
            } else {
                toast.error(response);
            }
            setIsLoading(false);
        }
    };

    const exportRequestTeam = (event) => {
        const selectedOption = event.target.value;
        if (selectedOption !== '') {
            handleExportRequestTeam(selectedOption);
            event.target.value = '';
        }
    };

    const handleExportRequestTeam = (format) => {
        exportDataRequestTeam(requestTeamArray, format);
    };


    // Category Pagination
    const handleCategorySearchChange = (event) => {
        setSearchQueryCategory(event.target.value);
        setPageCategory(0);
    };

    const filteredCategory = requestCategoryArray?.filter((category) =>
        category?.requestCategoryName?.toLowerCase()?.includes(searchQueryCategory?.toLowerCase()) ||
        category?.requestCategoryCode?.toLowerCase()?.includes(searchQueryCategory?.toLowerCase()) ||
        category?.requestStatus?.toLowerCase()?.startsWith(searchQueryCategory?.toLowerCase())
    );

    const handleRowsPerPageChange = (event) => {
        setRowsCategoryPerPage(parseInt(event.target.value));
        setPageCategory(0);
    };

    const handleCategoryFirstPage = () => {
        handleCategoryChangePagePagination({ selected: 0 });
    };

    const handleCategoryLastPage = () => {
        handleCategoryChangePagePagination({ selected: Math.ceil(filteredCategory?.length / rowsCategoryPerPage) - 1 });
    };

    const handleCategoryChangePagePagination = ({ selected }) => {
        setPageCategory(selected);
    };

    const totalCategory = filteredCategory?.length;
    const startCategoryEntry = pageCategory * rowsCategoryPerPage + 1;
    const endCategoryEntry = Math.min((pageCategory + 1) * rowsCategoryPerPage, totalCategory);


    // RequestSubCategory Pagination
    const handleRequestSubCategorySearchChange = (event) => {
        setSearchQueryRequestSubCategory(event.target.value);
        setPageRequestSubCategory(0);
    };


    const searchQuerySubCat = searchQueryRequestSubCategory?.toLowerCase() || '';
    const filteredRequestSubCategory = requestSubCategoryArray?.filter((subCategory) =>
        subCategory?.requestCategoryNameInSubCategory?.toLowerCase()?.includes(searchQuerySubCat) ||
        subCategory?.requestSubCategoryName?.toLowerCase()?.includes(searchQuerySubCat) ||
        subCategory?.requestSubCategoryCode?.toLowerCase()?.includes(searchQuerySubCat) ||
        subCategory?.requestStatus?.toLowerCase()?.startsWith(searchQuerySubCat)
    );

    const handleRowsPerpageRequestSubCategoryChange = (event) => {
        setRowsRequestSubCategoryPerPage(parseInt(event.target.value));
        setPageRequestSubCategory(0);
    };

    const handleRequestSubCategoryFirstPage = () => {
        handleRequestSubCategoryChangePagePagination({ selected: 0 });
    };

    const handleRequestSubCategoryLastPage = () => {
        handleRequestSubCategoryChangePagePagination({ selected: Math.ceil(filteredRequestSubCategory?.length / rowsRequestSubCategoryPerPage) - 1 });
    };

    const handleRequestSubCategoryChangePagePagination = ({ selected }) => {
        setPageRequestSubCategory(selected);
    };

    const totalRequestSubCategory = filteredRequestSubCategory?.length;
    const startRequestSubCategoryEntry = pageRequestSubCategory * rowsRequestSubCategoryPerPage + 1;
    const endRequestSubCategoryEntry = Math.min((pageRequestSubCategory + 1) * rowsRequestSubCategoryPerPage, totalRequestSubCategory);


    // RequestTeam Pagination
    const handleRequestTeamSearchChange = (event) => {
        setSearchQueryRequestTeam(event.target.value);
        setPageRequestTeam(0);
    };

    const searchQueryTeam = searchQueryRequestTeam?.toLowerCase() || '';
    const filteredRequestTeam = requestTeamArray?.filter((requestTeam) =>
        requestTeam?.requestCategoryName?.toLowerCase()?.includes(searchQueryTeam) ||
        requestTeam?.requestSubCategoryName?.toLowerCase()?.includes(searchQueryTeam) ||
        requestTeam?.assignTo?.toLowerCase()?.includes(searchQueryTeam?.toLowerCase()) ||
        requestTeam?.requestStatus?.toLowerCase()?.startsWith(searchQueryTeam?.toLowerCase())
    );

    const handleRowsPerpageRequestTeamChange = (event) => {
        setRowsRequestTeamPerPage(parseInt(event.target.value));
        setPageRequestTeam(0);
    };

    const handleRequestTeamFirstPage = () => {
        handleRequestTeamChangePagePagination({ selected: 0 });
    };

    const handleRequestTeamLastPage = () => {
        handleRequestTeamChangePagePagination({ selected: Math.ceil(filteredRequestTeam?.length / rowsRequestTeamPerPage) - 1 });
    };

    const handleRequestTeamChangePagePagination = ({ selected }) => {
        setPageRequestTeam(selected);
    };

    const totalRequestTeam = filteredRequestTeam?.length;
    const startRequestTeamEntry = pageRequestTeam * rowsRequestTeamPerPage + 1;
    const endRequestTeamEntry = Math.min((pageRequestTeam + 1) * rowsRequestTeamPerPage, totalRequestTeam);


    return (
        <div style={{ height: "100vh", width: "100%", backgroundColor: '#FAFAFB' }}>
            <ToastContainer theme="colored" />
            {isLoading && <LoadingSpinner />}

            {!isLoading && (
                <div>
                    <div className="pagetitleCategory">
                        <div className="Pageheader fontFamily fontsize24 fontweightSemiBold">{tabStatusActive}<div><InfoOutlinedIcon /></div></div>
                    </div>
                    {activeScreen === "RequestCategory" && (
                        <div className="card mt-4">
                            <div className="card-body-category">
                                <div className="CustomBoxShadow" style={{ margin: "20px" }}>
                                    <div className="HeaderTab">
                                        <a href="#tab=RequestCategory" style={{ marginLeft: "40px", width: "187px", height: "40px", textAlign: "center" }} className={`${tabActive === 'RequestCategory' ? 'activeTab' : 'InactiveTab'}`} onClick={() => selectedTab('RequestCategory')}>
                                            <div className="fontFamily fontweightRegular fontsize20">
                                                Request Category
                                            </div>
                                        </a>
                                        <a href="#tab=RequestSubCategory" style={{ width: "300px", height: "40px", textAlign: "center" }} className={`${tabActive === 'RequestSubCategory' ? 'activeTab' : 'InactiveTab'}`} onClick={() => selectedTab('RequestSubCategory')}>
                                            <div className="fontFamily fontweightRegular fontsize20">
                                                Request Sub Category
                                            </div>
                                        </a>
                                        <a href="#tab=RequestTeam" style={{ marginRight: "40px", width: "180px", height: "40px", textAlign: "center" }} className={`${tabActive === 'RequestTeam' ? 'activeTab' : 'InactiveTab'}`} onClick={() => selectedTab('RequestTeam')}>
                                            <div className="fontFamily fontweightRegular fontsize20">
                                                Request Team
                                            </div>
                                        </a>
                                    </div>

                                    {tabActive === 'RequestCategory' && (
                                        <div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="custom-container">
                                                    <span className="blackTextColor fontFamily fontsize16 fontweightRegular" >
                                                        <span className="show">Show</span>
                                                        <select className="custom-dropdown" value={rowsCategoryPerPage} onChange={handleRowsPerPageChange}>
                                                            {[5, 10, 15].map((n) => (
                                                                <option className="blackTextColor fontFamily fontsize14 fontweightRegular" key={n} value={n}>
                                                                    {n}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <span className="blackTextColor entries fontFamily fontsize16 fontweightRegular" >
                                                            Entries
                                                        </span>
                                                        <input
                                                            type="text"
                                                            placeholder="Search..."
                                                            className="searchTextBox"
                                                            value={searchQueryCategory}
                                                            onChange={handleCategorySearchChange}
                                                            style={{ marginLeft: '28px' }}
                                                        />
                                                    </span>
                                                </div>
                                                <div className="addbuttondiv">
                                                    <input
                                                        type="file"
                                                        ref={fileRequestCategoryInputRef}
                                                        style={{ display: 'none' }}
                                                        accept=".xlsx"
                                                        onChange={handleRequestCategoryFileChange}
                                                    />

                                                    <button type="button" className="fontFamily fontweightRegular fontsize16 coloredButtonAdd" style={{ marginLeft: "20px" }} onClick={() => addNewRequestCategory()}> Add New </button>
                                                    <button type="button" className="fontFamily fontweightRegular fontsize16 importBtn" onClick={handleRequestCategoryImportClick}> Import </button>
                                                    <select className="fontFamily fontweightRegular fontsize16 exportBtn" name="language" id="language" defaultValue="" onChange={exportCategory} >
                                                        <option value="" hidden={true}>Export</option>
                                                        <option value="csv">CSV</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="tableScroll" style={{ height: '233px', overflowY: 'auto', scrollbarWidth: 'thin' }}>
                                                <table className="w-100 mt-2">
                                                    <thead>
                                                        <tr className="text-left">
                                                            <th className="fontFamily fontweightMedium fontsize16" style={{ backgroundColor: '#F6F6F6', width: "20%", padding: "2px 2px 2px 3%" }}>SI.No</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "25%" }}>Category Name</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "20%" }}>Category Code</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "15%" }}>Status</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "8%" }}>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {filteredCategory?.length > 0 ? (
                                                            filteredCategory.slice(pageCategory * rowsCategoryPerPage, (pageCategory + 1) * rowsCategoryPerPage).map((category, index) => (
                                                                <tr key={category.Rowid} className="text-left">
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14  ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`} style={{ padding: "2px 2px 2px 4%" }}>{category.Rowid}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}
                                                                        style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                                        title={category.requestCategoryName}>{category.requestCategoryName}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}
                                                                        style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                                        title={category.requestCategoryCode}>{category.requestCategoryCode}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}
                                                                        style={{ color: category.requestStatus === 'Active' ? '#2ED47A' : '#EC2020' }}>{category.requestStatus}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>
                                                                        <span style={{ cursor: 'pointer' }}><a href="#viewRequestCategory" onClick={() => viewOrEditRequestCategory(category.id, true)}><img style={{ width: '18px', height: '15px' }} src={viewsvgIcon} alt="viewsvgIcon" /></a></span>
                                                                        <span style={{ marginLeft: '10px', cursor: 'pointer' }}><a href="#editRequestCategory" onClick={() => viewOrEditRequestCategory(category.id, false)}><img src={editsvgIcon} alt="editsvgIcon" /></a></span>
                                                                        <span style={{ marginLeft: '10px', cursor: 'pointer' }}><a href="#DeleteRequestCategory" onClick={() => DeleteRequestCategory(category.id)}><img src={deletesvgIcon} alt="deletesvgIcon" /></a></span>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <td colSpan="9" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )}

                                    {tabActive === 'RequestSubCategory' && (
                                        <div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="custom-container">
                                                    <span className="blackTextColor fontFamily fontsize16 fontweightRegular" >
                                                        <span className="show">Show</span>
                                                        <select className="custom-dropdown" value={rowsRequestSubCategoryPerPage} onChange={handleRowsPerpageRequestSubCategoryChange}>
                                                            {[5, 10, 15].map((n) => (
                                                                <option className="blackTextColor fontFamily fontsize14 fontweightRegular" key={n} value={n}>
                                                                    {n}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <span className="blackTextColor entries fontFamily fontsize16 fontweightRegular" >
                                                            Entries
                                                        </span>
                                                        <input
                                                            type="text"
                                                            placeholder="Search..."
                                                            className="searchTextBox"
                                                            value={searchQueryRequestSubCategory}
                                                            onChange={handleRequestSubCategorySearchChange}
                                                            style={{ marginLeft: '28px' }}
                                                        />
                                                    </span>
                                                </div>
                                                <div className="addbuttondiv">
                                                    <input
                                                        type="file"
                                                        ref={fileRequestSubCategoryInputRef}
                                                        style={{ display: 'none' }}
                                                        accept=".xlsx"
                                                        onChange={handleRequestSubCategoryFileChange}
                                                    />
                                                    <button type="button" className="fontFamily fontweightRegular fontsize16 coloredButtonAdd" style={{ marginLeft: "20px" }} onClick={() => addNewRequestSubCategory()}> Add New </button>
                                                    <button type="button" className="fontFamily fontweightRegular fontsize16 importBtn" onClick={handleRequestSubCategoryImportClick}> Import </button>
                                                    <select className="fontFamily fontweightRegular fontsize16 exportBtn" name="language" id="language" defaultValue="" onChange={exportRequestSubCategory} >
                                                        <option value="" hidden={true}>Export</option>
                                                        <option value="csv">CSV</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="tableScroll" style={{ height: '233px', overflowY: 'auto', scrollbarWidth: 'thin' }}>
                                                <table className="w-100 mt-2">
                                                    <thead>
                                                        <tr className="text-left">
                                                            <th className="fontFamily fontweightMedium fontsize16 " style={{ backgroundColor: '#F6F6F6', width: "15%", padding: "2px 2px 2px 3%" }}>SI.No</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "20%" }}>Category Name</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "23%" }}>Request Sub Category Name</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "20%" }}>Request Sub Category Code</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "13%" }}>Status</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "10%" }}>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {filteredRequestSubCategory?.length > 0 ? (
                                                            filteredRequestSubCategory.slice(pageRequestSubCategory * rowsRequestSubCategoryPerPage, (pageRequestSubCategory + 1) * rowsRequestSubCategoryPerPage).map((subCategory, index) => (
                                                                <tr key={subCategory.Rowid} >
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14  ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`} style={{ padding: "2px 2px 2px 4%" }}>{subCategory.Rowid}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}
                                                                        style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                                        title={subCategory.requestCategoryNameInSubCategory}>{subCategory.requestCategoryNameInSubCategory}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}
                                                                        style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                                        title={subCategory.requestSubCategoryName}>{subCategory.requestSubCategoryName}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}
                                                                        style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                                        title={subCategory.requestSubCategoryCode}>{subCategory.requestSubCategoryCode}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}
                                                                        style={{ color: subCategory.requestStatus === 'Active' ? '#2ED47A' : '#EC2020' }}>{subCategory.requestStatus}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>
                                                                        <span style={{ cursor: 'pointer' }}><a href="#viewRequestSubCategory" onClick={() => viewOrEditRequestSubCategory(subCategory.id, true)}><img style={{ width: '18px', height: '15px' }} src={viewsvgIcon} alt="viewsvgIcon" /></a></span>
                                                                        <span style={{ marginLeft: '10px', cursor: 'pointer' }}><a href="#editRequestSubCategory" onClick={() => viewOrEditRequestSubCategory(subCategory.id, false)}><img src={editsvgIcon} alt="editsvgIcon" /></a></span>
                                                                        <span style={{ marginLeft: '10px', cursor: 'pointer' }}><a href="#DeleteRequestSubCategory" onClick={() => DeleteRequestSubCategory(subCategory.id)}><img src={deletesvgIcon} alt="deletesvgIcon" /></a></span>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <td colSpan="9" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )}

                                    {tabActive === 'RequestTeam' && (
                                        <div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="custom-container">
                                                    <span className="blackTextColor fontFamily fontsize16 fontweightRegular" >
                                                        <span className="show">Show</span>
                                                        <select className="custom-dropdown" value={rowsRequestTeamPerPage} onChange={handleRowsPerpageRequestTeamChange}>
                                                            {[5, 10, 15].map((n) => (
                                                                <option className="blackTextColor fontFamily fontsize14 fontweightRegular" key={n} value={n}>
                                                                    {n}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <span className="blackTextColor entries fontFamily fontsize16 fontweightRegular" >
                                                            Entries
                                                        </span>
                                                        <input
                                                            type="text"
                                                            placeholder="Search..."
                                                            className="searchTextBox"
                                                            value={searchQueryRequestTeam}
                                                            onChange={handleRequestTeamSearchChange}
                                                            style={{ marginLeft: '28px' }}
                                                        />
                                                    </span>
                                                </div>
                                                <div className="addbuttondiv">
                                                    <button type="button" className="fontFamily fontweightRegular fontsize16 coloredButtonAdd" style={{ marginLeft: "20px" }} onClick={() => addNewRequestTeam()}> Add New </button>
                                                    <select className="fontFamily fontweightRegular fontsize16 exportBtn" name="language" id="language" defaultValue="" onChange={exportRequestTeam} >
                                                        <option value="" hidden={true}>Export</option>
                                                        <option value="csv">CSV</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="tableScroll" style={{ height: '233px', overflowY: 'auto', scrollbarWidth: 'thin' }}>
                                                <table className="w-100 mt-2">
                                                    <thead>
                                                        <tr className="text-left">
                                                            <th className="fontFamily fontweightMedium fontsize16" style={{ backgroundColor: '#F6F6F6', width: "15%", padding: "2px 2px 2px 3%" }}>SI.No</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "18%" }}>Request Category</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "19%" }}>Request Sub Category</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "15%" }}>Assigned To</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "10%" }}>Status</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "10%" }}>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {filteredRequestTeam?.length > 0 ? (
                                                            filteredRequestTeam.slice(pageRequestTeam * rowsRequestTeamPerPage, (pageRequestTeam + 1) * rowsRequestTeamPerPage).map((requestTeam, index) => (
                                                                <tr key={requestTeam.Rowid} >
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14  ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`} style={{ padding: "2px 2px 2px 4%" }}>{requestTeam.Rowid}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}
                                                                        style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                                        title={requestTeam.requestCategoryName}>{requestTeam.requestCategoryName}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}
                                                                        style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                                        title={requestTeam.requestSubCategoryName}>{requestTeam.requestSubCategoryName}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}
                                                                        style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                                        title={requestTeam.assignTo}>{requestTeam.assignTo}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}
                                                                        style={{ color: requestTeam.requestStatus === 'Active' ? '#2ED47A' : '#EC2020' }}>{requestTeam.requestStatus}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>
                                                                        <span style={{ cursor: 'pointer' }}><a href="#viewRequestTeam" onClick={() => viewOrEditRequestTeam(requestTeam.id, true)}><img style={{ width: '18px', height: '15px' }} src={viewsvgIcon} alt="viewsvgIcon" /></a></span>
                                                                        <span style={{ marginLeft: '10px', cursor: 'pointer' }}><a href="#editRequestTeam" onClick={() => viewOrEditRequestTeam(requestTeam.id, false)}><img src={editsvgIcon} alt="editsvgIcon" /></a></span>
                                                                        <span style={{ marginLeft: '10px', cursor: 'pointer' }}><a href="#DeleteRequestTeam" onClick={() => DeleteRequestTeam(requestTeam.id)}><img src={deletesvgIcon} alt="deletesvgIcon" /></a></span>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <td colSpan="9" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                {tabActive === 'RequestCategory' && (
                                    <div className="" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginLeft: '20px' }}>
                                        <div >
                                            <div className="grayTextColor fontFamily fontsize16 fontweightMedium">
                                                {`Showing ${startCategoryEntry} to ${endCategoryEntry} of ${totalCategory} entries`}
                                            </div>
                                        </div>
                                        <div >
                                            <div className="pagination-container d-flex align-items-center">
                                                <button onClick={handleCategoryFirstPage} className="btn btn-link">
                                                    <FontAwesomeIcon icon={faAngleDoubleLeft} className="icon-color" />
                                                </button>
                                                <ReactPaginate
                                                    previousLabel={<FontAwesomeIcon icon={faChevronLeft} className="icon-color" />}
                                                    nextLabel={<FontAwesomeIcon icon={faChevronRight} className="icon-color" />}
                                                    breakLabel={'...'}
                                                    breakClassName={'break-me'}
                                                    pageCount={Math.ceil(filteredCategory?.length / rowsCategoryPerPage)}
                                                    forcePage={pageCategory}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={5}
                                                    onPageChange={handleCategoryChangePagePagination}
                                                    containerClassName={'pagination'}
                                                    subContainerClassName={'pages pagination'}
                                                    activeClassName={'active'}
                                                    renderOnZeroPageCount={null}
                                                />
                                                <button onClick={handleCategoryLastPage} className="btn btn-link">
                                                    <FontAwesomeIcon icon={faAngleDoubleRight} className="icon-color" />
                                                </button>
                                            </div>
                                        </div>
                                        <div style={{ padding: "0px 100px" }}>
                                        </div>
                                    </div>
                                )}

                                {tabActive === 'RequestSubCategory' && (
                                    <div className="" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginLeft: '20px' }}>
                                        <div >
                                            <div className="grayTextColor fontFamily fontsize16 fontweightMedium">
                                                {`Showing ${startRequestSubCategoryEntry} to ${endRequestSubCategoryEntry} of ${totalRequestSubCategory} entries`}
                                            </div>
                                        </div>
                                        <div >
                                            <div className="pagination-container d-flex align-items-center">
                                                <button onClick={handleRequestSubCategoryFirstPage} className="btn btn-link">
                                                    <FontAwesomeIcon icon={faAngleDoubleLeft} className="icon-color" />
                                                </button>
                                                <ReactPaginate
                                                    previousLabel={<FontAwesomeIcon icon={faChevronLeft} className="icon-color" />}
                                                    nextLabel={<FontAwesomeIcon icon={faChevronRight} className="icon-color" />}
                                                    breakLabel={'...'}
                                                    breakClassName={'break-me'}
                                                    pageCount={Math.ceil(filteredRequestSubCategory?.length / rowsRequestSubCategoryPerPage)}
                                                    forcePage={pageRequestSubCategory}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={5}
                                                    onPageChange={handleRequestSubCategoryChangePagePagination}
                                                    containerClassName={'pagination'}
                                                    subContainerClassName={'pages pagination'}
                                                    activeClassName={'active'}
                                                    renderOnZeroPageCount={null}
                                                />
                                                <button onClick={handleRequestSubCategoryLastPage} className="btn btn-link">
                                                    <FontAwesomeIcon icon={faAngleDoubleRight} className="icon-color" />
                                                </button>
                                            </div>
                                        </div>
                                        <div style={{ padding: "0px 100px" }}>
                                        </div>
                                    </div>
                                )}

                                {tabActive === 'RequestTeam' && (
                                    <div className="" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginLeft: '20px' }}>
                                        <div >
                                            <div className="grayTextColor fontFamily fontsize16 fontweightMedium">
                                                {`Showing ${startRequestTeamEntry} to ${endRequestTeamEntry} of ${totalRequestTeam} entries`}
                                            </div>
                                        </div>
                                        <div >
                                            <div className="pagination-container d-flex align-items-center">
                                                <button onClick={handleRequestTeamFirstPage} className="btn btn-link">
                                                    <FontAwesomeIcon icon={faAngleDoubleLeft} className="icon-color" />
                                                </button>
                                                <ReactPaginate
                                                    previousLabel={<FontAwesomeIcon icon={faChevronLeft} className="icon-color" />}
                                                    nextLabel={<FontAwesomeIcon icon={faChevronRight} className="icon-color" />}
                                                    breakLabel={'...'}
                                                    breakClassName={'break-me'}
                                                    pageCount={Math.ceil(filteredRequestTeam?.length / rowsRequestTeamPerPage)}
                                                    forcePage={pageRequestTeam}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={5}
                                                    onPageChange={handleRequestTeamChangePagePagination}
                                                    containerClassName={'pagination'}
                                                    subContainerClassName={'pages pagination'}
                                                    activeClassName={'active'}
                                                    renderOnZeroPageCount={null}
                                                />
                                                <button onClick={handleRequestTeamLastPage} className="btn btn-link">
                                                    <FontAwesomeIcon icon={faAngleDoubleRight} className="icon-color" />
                                                </button>
                                            </div>
                                        </div>
                                        <div style={{ padding: "0px 100px" }}>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {activeScreen === "RequestCategoryEdit" && (
                        <div className="card mt-4 customScrollbar">
                            <div className="card-body">
                                <div className="blackTextColor fontFamily fontsize20 fontweightMedium">
                                    Request Category Details
                                </div>
                                <form className="p-2" noValidate onSubmit={handleSubmitReqCategory(handleRequestCategorySubmit)}>
                                    <div className="row">
                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" style={{ paddingTop: '4px' }}>
                                                Request Category Name<span className="mandatoryCss"> *</span></div>
                                            <input
                                                className="textinputCustomAsset"
                                                placeholder="Enter Request Category Name"
                                                {...registerReqCategory('requestCategoryName', { required: 'Please Enter Category Name' })}
                                                disabled={isRequestCategoryView}
                                            />
                                            {errorsReqCategory.requestCategoryName && (
                                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                                    {errorsReqCategory.requestCategoryName.message}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" style={{ paddingTop: '4px' }}>
                                                Request Category Code<span className="mandatoryCss"> *</span></div>
                                            <input
                                                className="textinputCustomAsset"
                                                placeholder="Enter Request Category Code"
                                                {...registerReqCategory('requestCategoryCode', {
                                                    required: 'Please Enter Category Code',
                                                    maxLength: {
                                                        value: 10,
                                                        message: 'Code cannot exceed 10 characters'
                                                    }
                                                })}
                                                maxLength={10}
                                                disabled={isRequestCategoryView}
                                            />
                                            {errorsReqCategory.requestCategoryCode && (
                                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                                    {errorsReqCategory.requestCategoryCode.message}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="row" style={{ paddingTop: '24px' }}>
                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" style={{ paddingTop: '4px' }}>Description</div>
                                            <textarea
                                                className="textareaCustomAsset"
                                                placeholder="Enter Request Category Description"
                                                {...registerReqCategory('requestCategoryDescription')}
                                                disabled={isRequestCategoryView}
                                            />
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12" style={{ paddingTop: '10px' }}>
                                            <div>
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ paddingTop: '4px' }}>
                                                    Status
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                                    <a
                                                        href="#status"
                                                        className="switch-container"
                                                        onClick={!isRequestCategoryView ? () => setValueReqCategory('requestCategoryStatus', !statusWatchRequestCategory) : undefined}
                                                        style={{ cursor: isRequestCategoryView ? 'not-allowed' : 'pointer' }}
                                                        disabled={isRequestCategoryView}
                                                    >
                                                        <div className={`switch ${statusWatchRequestCategory ? 'active' : ''}`}>
                                                            <div className="handle"></div>
                                                        </div>
                                                    </a>
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ paddingLeft: '20px' }}>
                                                        {statusWatchRequestCategory ? 'Active' : 'Inactive'}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-2 text-center" style={{ marginTop: "10%" }}>
                                        {!isRequestCategoryView && (
                                            <button type="submit" className="coloredButtonSave">{isLoading ? 'Loading...' : 'Save'}</button>
                                        )}
                                        <button type="button" className="outlineButton" style={{ marginLeft: '18px' }} onClick={closeRequestCategory}>Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}

                    {activeScreen === "RequestSubCategoryEdit" && (
                        <div className="card mt-4 customScrollbar">
                            <div className="card-body">
                                <div className="blackTextColor fontFamily fontsize20 fontweightMedium">
                                    Request Sub Category Details
                                </div>
                                <form className="p-2" noValidate onSubmit={handleSubmitReqSubCategory(handleRequestSubCategorySubmit)}>
                                    <div className="row">
                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                                Select Request Category<span className="mandatoryCss"> *</span>
                                            </div>
                                            <Controller
                                                name="requestCategorySelectID"
                                                control={controlReqSubCategory}
                                                rules={{ required: 'Please select a Request Category' }}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        value={requestCategoryArray?.find(c => c.id === field.value) || null}
                                                        onChange={(selectedOption) => {
                                                            field.onChange(selectedOption ? selectedOption.id : null);
                                                            setRequestCategorySelectID(selectedOption ? selectedOption.id : null); // Update state
                                                        }}
                                                        options={requestCategoryArray}
                                                        getOptionLabel={(option) => option.requestCategoryName}
                                                        getOptionValue={(option) => option.id}
                                                        placeholder="Select Request Category"
                                                        isClearable
                                                        isSearchable
                                                        className={`${errorsReqSubCategory.requestCategorySelectID ? 'is-invalid' : ''}`}
                                                        isDisabled={isRequestSubCategoryView}
                                                        styles={customStyles}
                                                    />
                                                )}
                                            />
                                            {errorsReqSubCategory.requestCategorySelectID && (
                                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                                    {errorsReqSubCategory.requestCategorySelectID.message}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                                Request Sub Category Name<span className="mandatoryCss"> *</span>
                                            </div>
                                            <input
                                                className="textinputCustomAsset"
                                                placeholder="Enter Request Sub Category Name"
                                                {...registerReqSubCategory('requestSubCategoryName', { required: 'Please Enter Request Sub Category Name' })}
                                                disabled={isRequestSubCategoryView}
                                            />
                                            {errorsReqSubCategory.requestSubCategoryName && (
                                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                                    {errorsReqSubCategory.requestSubCategoryName.message}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row" style={{ paddingTop: '24px' }}>
                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" style={{ paddingTop: '4px' }}>
                                                Request Sub Category Code<span className="mandatoryCss"> *</span>
                                            </div>
                                            <input
                                                className="textinputCustomAsset"
                                                placeholder="Enter Request Sub Category Code"
                                                {...registerReqSubCategory('requestSubCategoryCode', {
                                                    required: 'Enter Request Sub Category Code',
                                                    maxLength: {
                                                        value: 10,
                                                        message: 'Code cannot exceed 10 characters'
                                                    }
                                                })}
                                                maxLength={10}
                                                disabled={isRequestSubCategoryView}
                                            />
                                            {errorsReqSubCategory.requestSubCategoryCode && (
                                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                                    {errorsReqSubCategory.requestSubCategoryCode.message}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" style={{ paddingTop: '4px' }}>
                                                Description
                                            </div>
                                            <textarea
                                                className="textareaCustomAsset"
                                                placeholder="Enter Request Sub Category Description"
                                                {...registerReqSubCategory('requestSubCategoryDescription')}
                                                disabled={isRequestSubCategoryView}
                                            />
                                        </div>
                                    </div>
                                    <div className="row" style={{ paddingTop: '20px' }}>
                                        <div>
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ paddingTop: '4px' }}>
                                                Status
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                                <a
                                                    href="#status"
                                                    className="switch-container"
                                                    onClick={!isRequestSubCategoryView ? () => setValueReqSubCategory('requestSubCategoryStatus', !statusWatchReqSubCategory) : undefined}
                                                    style={{ cursor: isRequestSubCategoryView ? 'not-allowed' : 'pointer' }}
                                                    disabled={isRequestSubCategoryView}
                                                >
                                                    <div className={`switch ${statusWatchReqSubCategory ? 'active' : ''}`}>
                                                        <div className="handle"></div>
                                                    </div>
                                                </a>
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ paddingLeft: '20px' }}>
                                                    {statusWatchReqSubCategory ? 'Active' : 'Inactive'}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-2 text-center" style={{ marginTop: "10%" }}>
                                        {!isRequestSubCategoryView && (
                                            <button type="submit" className="coloredButtonSave">{isLoading ? 'Loading...' : 'Save'}</button>
                                        )}
                                        <button type="button" className="outlineButton" style={{ marginLeft: '18px' }} onClick={closeRequestSubCategory}>Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}

                    {activeScreen === "RequestTeamEdit" && (
                        <div className="card mt-4 customScrollbar">
                            <div className="card-body">
                                <div className="blackTextColor fontFamily fontsize20 fontweightMedium">
                                    Request Team Details
                                </div>
                                <form className="p-2" noValidate onSubmit={handleSubmitRequestTeam(handleRequestTeamSubmit)}>
                                    <div className="row">
                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                                Select Request Category<span className="mandatoryCss"> *</span>
                                            </div>
                                            <Controller
                                                name="requestTeamCategorySelectID"
                                                control={controlRequestTeam}
                                                rules={{ required: 'Please select a Request Category' }}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        value={requestCategoryArray?.find(c => c.id === field.value) || null}
                                                        onChange={(selectedOption) => {
                                                            field.onChange(selectedOption ? selectedOption.id : null);
                                                            setRequestTeamCategorySelectID(selectedOption ? selectedOption.id : null); // Update state
                                                        }}
                                                        options={requestCategoryArray}
                                                        getOptionLabel={(option) => option.requestCategoryName}
                                                        getOptionValue={(option) => option.id}
                                                        placeholder="Select Request Category"
                                                        isClearable
                                                        isSearchable
                                                        className={`${errorsRequestTeam.requestTeamCategorySelectID ? 'is-invalid' : ''}`}
                                                        isDisabled={isRequestTeamView}
                                                        styles={customStyles}
                                                    />
                                                )}
                                            />
                                            {errorsRequestTeam.requestTeamCategorySelectID && (
                                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                                    {errorsRequestTeam.requestTeamCategorySelectID.message}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-6">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                                Select Request Sub Category<span className="mandatoryCss"> *</span>
                                            </div>
                                            <Controller
                                                name="requestTeamSubCategorySelectID"
                                                control={controlRequestTeam}
                                                rules={{ required: 'Please select a Request Sub Category' }}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        value={requestSubCategoryArray?.find(c => c.id === field.value) || null}
                                                        onChange={(selectedOption) => {
                                                            field.onChange(selectedOption ? selectedOption.id : null);
                                                            setRequestTeamSubCategorySelectID(selectedOption ? selectedOption.id : null); // Update state
                                                        }}
                                                        options={requestFilteredSubCategoryArrayForTeam}
                                                        getOptionLabel={(option) => option.requestSubCategoryName}
                                                        getOptionValue={(option) => option.id}
                                                        placeholder="Select Request Sub Category"
                                                        isClearable
                                                        isSearchable
                                                        className={`${errorsRequestTeam.requestTeamSubCategorySelectID ? 'is-invalid' : ''}`}
                                                        isDisabled={isRequestTeamView}
                                                        styles={customStyles}
                                                    />
                                                )}
                                            />
                                            {errorsRequestTeam.requestTeamSubCategorySelectID && (
                                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                                    {errorsRequestTeam.requestTeamSubCategorySelectID.message}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="row" style={{ paddingTop: '24px' }}>
                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                                Managed By
                                            </div>
                                            <Controller
                                                name="requestIsTeam"
                                                control={controlRequestTeam}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        value={teamOrEmployeeOptions.find(option => option.value === field.value) || null}
                                                        onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.value : null)}
                                                        options={teamOrEmployeeOptions}
                                                        getOptionLabel={(option) => option.label}
                                                        getOptionValue={(option) => option.value}
                                                        placeholder="Choose Team Or Employee"
                                                        isSearchable
                                                        className={`${errorsRequestTeam.requestIsTeam ? 'is-invalid' : ''}`}
                                                        isDisabled={isRequestTeamView}
                                                        styles={customStyles}
                                                    />
                                                )}
                                            />
                                            {errorsRequestTeam.requestIsTeam && (
                                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                                    {errorsRequestTeam.requestIsTeam.message}
                                                </div>
                                            )}
                                        </div>

                                        {/* Team/Employee Selection Dropdown */}
                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                                Team / Employee
                                            </div>
                                            <Controller
                                                name="requestTeamOrEmpId"
                                                control={controlRequestTeam}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        value={getOptions().find(c => c.id === field.value) || null}
                                                        onChange={(selectedOption) => {
                                                            field.onChange(selectedOption ? selectedOption.id : null);
                                                        }}
                                                        options={getOptions()} // Dynamically bind based on Team/Employee
                                                        getOptionLabel={(option) =>
                                                            requestIsTeam === "Team" ? option.teamName : option.employeeName
                                                        }
                                                        getOptionValue={(option) => option.id}
                                                        placeholder={`Select ${requestIsTeam}`}
                                                        isSearchable
                                                        className={`${errorsRequestTeam.requestTeamOrEmpId ? 'is-invalid' : ''}`}
                                                        isDisabled={isRequestTeamView}
                                                        styles={customStyles}
                                                    />
                                                )}
                                            />
                                            {errorsRequestTeam.requestTeamOrEmpId && (
                                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                                    {errorsRequestTeam.requestTeamOrEmpId.message}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    {/* Assign To Field */}
                                    <div className="row">
                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12" style={{ paddingTop: '24px' }}>
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                                Assign To<span className="mandatoryCss"> *</span>
                                            </div>
                                            <textarea
                                                className="textareaCustomAsset"
                                                {...registerRequestTeam('requestAssignTo', {
                                                    required: "Assign To is required",
                                                })}
                                                disabled={true}
                                            />
                                            {errorsRequestTeam.requestAssignTo && (
                                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                                    {errorsRequestTeam.requestAssignTo.message}
                                                </div>
                                            )}
                                        </div>

                                        {/* Assign Button */}
                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12" style={{ paddingTop: '10px' }}>
                                            <div className="flexEnd">
                                                <button
                                                    type="button"
                                                    className="fontFamily fontweightRegular fontsize16 coloredButtonAdd"
                                                    style={{ marginLeft: "20px" }}
                                                    onClick={handleAssign}
                                                >
                                                    Assign
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row" style={{ paddingTop: '24px' }}>
                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor">
                                                Status
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                                <a
                                                    href="#status"
                                                    className="switch-container"
                                                    onClick={!isRequestTeamView ? () => setValueRequestTeam('requestTeamStatus', !statusWatchRequestTeam) : undefined}
                                                    style={{ cursor: isRequestTeamView ? 'not-allowed' : 'pointer' }}
                                                    disabled={isRequestTeamView}
                                                >
                                                    <div className={`switch ${statusWatchRequestTeam ? 'active' : ''}`}>
                                                        <div className="handle"></div>
                                                    </div>
                                                </a>
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ paddingLeft: '20px' }}>
                                                    {statusWatchRequestTeam ? 'Active' : 'Inactive'}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-6"></div>
                                    </div>

                                    <div className="mb-2 text-center" style={{ marginTop: "5%" }}>
                                        {!isRequestTeamView && (
                                            <button type="submit" className="coloredButtonSave">{isLoading ? 'Loading...' : 'Save'}</button>
                                        )}
                                        <button type="button" className="outlineButton" style={{ marginLeft: '18px' }} onClick={closeRequestTeam}>Cancel</button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    )}

                </div >
            )}
        </div >
    );
};

export default RequestCategoriesAndTeam;
