import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import { ForgetPasswordSendMail } from '../../Services/SignInService';

import './ForgotPassword.css';
import '../../assets/css/style.css';
import '../../assets/vendor/quill/quill.snow.css';
import '../../assets/vendor/quill/quill.bubble.css';
import '../../assets/vendor/remixicon/remixicon.css';
import '../../assets/vendor/simple-datatables/style.css';
import '../../assets/vendor/boxicons/css/boxicons.min.css';
import '../../assets/vendor/bootstrap/css/bootstrap.min.css';
import '../../assets/vendor/bootstrap-icons/bootstrap-icons.css';

import iTechLogo from '../../assets/img/iTech_Logo.PNG';


const ForgotPassword = () => {
    const [username, setUsername] = useState('');
    const [errorUsername, setErrorUsername] = useState('');
    const navigate = useNavigate();

    const handleUserNameChange = (e) => {
        const userName = e.target.value;
        setUsername(userName);
        if (!userName) {
            setErrorUsername('Please enter your email');
        } else {
            setErrorUsername('');
        }
    };

    const handleForgotPassword = async (event) => {
        event.preventDefault();
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!username) {
            setErrorUsername('Please enter your email');
            return;
        } else if (!emailRegex.test(username)) {
            setErrorUsername('Please enter a valid email address');
            return;
        }
        try {
            const response = await ForgetPasswordSendMail(username);
            if (response === 'Email sent successfully') {
                toast.success(response);
                setTimeout(() => {
                    navigate("/");
                }, 2000);
            } else {
                toast.error(response);
            }
        } catch (error) {
            toast.error("An error occurred. Please try again.");
        }
    };

    return (
        <div>
            <header style={{ position: 'relative', top: '10px', left: '10px' }}>
                <img src={iTechLogo} style={{ width: '200px' }} alt="iTech Logo" />
            </header>
            <main className="full-height-container">
                <ToastContainer theme="colored" />
                <div className="forgotcontainer">
                    <section className="section register d-flex align-items-center justify-content-center">
                        <div className="card py-4">
                            <h4 className="card-title fontFamily fontsize32 fontweightSemiBold pb-0 fs-4">Reset your password</h4>
                            <div className="card-body">
                                <div className="instruction fontFamily fontsize16 fontweightSemiBold pb-0 fs-4">Enter your official email and we’ll send you instructions on how to reset your password</div>
                                <form className="row g-3 needs-validation" onSubmit={handleForgotPassword} noValidate>
                                    <div className="col-12">
                                        <div className="input-group has-validation fontFamily fontsize16 fontweightMedium">
                                            <input
                                                type="text"
                                                name="username"
                                                className="form-control custom-input"
                                                id="yourUsername"
                                                placeholder="Email address"
                                                value={username}
                                                onChange={handleUserNameChange}
                                                required
                                            />
                                        </div>
                                        {errorUsername && <div className="text-danger">{errorUsername}</div>}
                                    </div>

                                    <div className="col-12">
                                        <button
                                            className="fontFamily subButton fontsize24 fontweightSemiBold w-100"
                                            type="submit"
                                            disabled={!username}
                                            style={{
                                                backgroundColor: username ? '#605bff' : '#D1D5D6',
                                                color: '#FFFFFF',
                                                border: 'none',
                                            }}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                    <div className="col-12">
                                        <p className="small mb-0">
                                            <span className="go-back-text fontFamily fontweightMedium fontsize16">Go back to </span>
                                            <Link to="/">
                                                <span className="blackTextColor fontFamily fontweightMedium fontsize16">Login page</span>
                                            </Link>
                                        </p>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </div>
    );
};

export default ForgotPassword;
