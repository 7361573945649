import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import ReactPaginate from "react-paginate";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker.css';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faAngleDoubleLeft, faAngleDoubleRight, } from "@fortawesome/free-solid-svg-icons";

import LoadingSpinner from '../../../../CommomPages/LoadingSpinner';
import { getDateFormat } from '../../../../CommomPages/Config';
import { getAsset, CreateFinancialTransaction } from "../../../../Services/AssetSettingsService";
import { getProFormMaintenance, updateProFormMaintenance } from "../../../../Services/ProcessFormConfigurationService"

import viewsvgIcon from "../../../../assets/icon/Eye.svg";
import editsvgIcon from "../../../../assets/icon/Edit.svg";
import nodataFound from "../../../../assets/img/nodatafound.png";
import calendarsvgIcon from '../../../../assets/icon/calendarDate.svg';


const ProcessFormConfigurationMaintenance = () => {
    const [dateFormat, setDateFormat] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isMaintenanceView, setIsMaintenanceView] = useState(false);
    const [isMaintenanceList, setIsMaintenanceList] = useState(true);
    const maintenanceStatus = [
        { idValue: 'schedule', Value: 'Schedule' },
        { idValue: 'inprogress', Value: 'In-Progress' },
        { idValue: 'onhold', Value: 'On-Hold' },
        { idValue: 'cancelled', Value: 'Cancelled' },
        { idValue: 'completed', Value: 'Completed' },
    ];
    const maintenanceByDropdown = [
        { idValue: 'inhouse', Value: 'In-House' },
        { idValue: 'vendor', Value: 'Vendor' }
    ];
    // const [statusSelectID, setStatusSelectID] = useState(null);
    const [rowsPerPageAsset, setRowsPerPageAsset] = useState(5);
    const paginateArray = [5, 10, 15];
    const [pageAsset, setPageAsset] = useState(0);
    const [MaintenanceListArray, setMaintenanceListArray] = useState([]);
    const [FilterMaintenanceListArray, setFilterMaintenanceListArray] = useState([]);
    const [searchQueryAsset, setSearchQueryAsset] = useState("");
    const [ScheduleCount, setScheduleCount] = useState("");
    const [InProgress, setInProgress] = useState("");
    const [OnHold, setOnHold] = useState("");
    const [Cancelled, setCancelled] = useState("");
    const [Completed, setCompleted] = useState("");
    const [MaintenanceID, setMaintenanceID] = useState("");
    const [CurrentStatus, setCurrentStatus] = useState("");

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        reset,
        setValue,
        getValues
    } = useForm({
        defaultValues: {
            assetId: '',
            assetName: '',
            title: '',
            details: '',
            scheduleDate: null,
            maintenanceBy: '',
            maintenanceStatus: '',
            dateCompleted: null,
            maintenanceCost: '',
            estimateDate: null
            // repeating: '',
        }
    });

    useEffect(() => {
        fetchMaintenanceData();
        const fetchData = async () => {
            const dateFormat = await getDateFormat();
            setDateFormat(dateFormat);
        };
        fetchData();
    }, []);

    const fetchMaintenanceData = async () => {
        const assetData = await getAsset();
        const maintenanceData = await getProFormMaintenance();
        const finalMaintenanceArray = maintenanceData.map((_maintenace, index) => {
            const associatedAsset = assetData.find(asset => asset.id === _maintenace.assetId);
            let statusValue = ''
            let maintenanceByValue = ''
            if (_maintenace.maintenanceBy == 'vendor') {
                maintenanceByValue = 'Vendor'
            } else if (_maintenace.maintenanceBy == 'inhouse') {
                maintenanceByValue = 'In-House'
            }
            if (_maintenace.maintenanceStatus == 'schedule') {
                statusValue = 'Schedule'
            } else if (_maintenace.maintenanceStatus == 'inprogress') {
                statusValue = 'In-Progress'
            } else if (_maintenace.maintenanceStatus == 'onhold') {
                statusValue = 'On-Hold'
            } else if (_maintenace.maintenanceStatus == 'cancelled') {
                statusValue = 'Cancelled'
            } else if (_maintenace.maintenanceStatus == 'completed') {
                statusValue = 'Completed'
            }

            return {
                ..._maintenace,
                assetCodeID: associatedAsset.assetID,
                assetName: associatedAsset.assetName,
                maintenanceStatusValue: statusValue,
                maintenanceByValue: maintenanceByValue,
                formattedScheduleDate: _maintenace.scheduleDate == null ? null : _maintenace.scheduleDate.split("T")[0]
            }
        });
        const _scheduledData = finalMaintenanceArray.filter((e) => e.maintenanceStatus == 'schedule');
        const _inprogressdData = finalMaintenanceArray.filter((e) => e.maintenanceStatus == 'inprogress');
        const _onholdData = finalMaintenanceArray.filter((e) => e.maintenanceStatus == 'onhold');
        const _cancelledData = finalMaintenanceArray.filter((e) => e.maintenanceStatus == 'cancelled');
        const _completedData = finalMaintenanceArray.filter((e) => e.maintenanceStatus == 'completed');
        setScheduleCount(_scheduledData.length);
        setInProgress(_inprogressdData.length);
        setOnHold(_onholdData.length);
        setCancelled(_cancelledData.length);
        setCompleted(_completedData.length);
        setMaintenanceListArray(finalMaintenanceArray);
        setFilterMaintenanceListArray(finalMaintenanceArray);
    };

    const onCancel = () => {
        reset();
        setIsMaintenanceView(false)
        setIsMaintenanceList(true);
    };

    setTimeout(() => {
        setIsLoading(false);
    }, 3000);

    const totalEntriesMaintenance = FilterMaintenanceListArray.length;
    const startEntryMaintenance = pageAsset * rowsPerPageAsset + 1;
    const endEntryMaintenance = Math.min((pageAsset + 1) * rowsPerPageAsset, totalEntriesMaintenance);

    const handleRowsPerPageChangeAsset = (event) => {
        setRowsPerPageAsset(parseInt(event.target.value));
        setPageAsset(0);
    };

    const handleFirstPageAsset = () => {
        handleChangePagePaginationAsset({ selected: 0 });
    };

    const handleChangePagePaginationAsset = ({ selected }) => {
        setPageAsset(selected);
    };

    const handleLastPageAsset = () => {
        handleChangePagePaginationAsset({
            selected: Math.ceil(FilterMaintenanceListArray?.length / rowsPerPageAsset) - 1,
        });
    };

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        return date.toISOString().slice(0, 19);
    };

    const handleMaintenanceSubmit = async (inputFields) => {
        try {
            setIsLoading(true);
            const selectedMaintenance = MaintenanceListArray.find((e) => e.assetCodeID == inputFields.assetId);
            const payload = {
                id: selectedMaintenance.id,
                title: inputFields.title,
                details: inputFields.details,
                scheduleDate: inputFields.scheduleDate ? formatDate(inputFields.scheduleDate) : null,
                maintenanceBy: inputFields.maintenanceBy,
                maintenanceStatus: inputFields.maintenanceStatus,
                dateCompleted: inputFields.dateCompleted ? formatDate(inputFields.dateCompleted) : null,
                maintenanceCost: inputFields.maintenanceCost,
                assetId: selectedMaintenance.assetId,
                estimatedReturnDate: inputFields.estimateDate ? formatDate(inputFields.estimateDate) : null,
                maintenanceId: inputFields.maintenanceId
            }
            if (inputFields.maintenanceCost != 0 && inputFields.maintenanceStatus == 'completed') {
                const transactionPayload = {
                    id: 0,
                    ftAssetId: selectedMaintenance.assetId || 0,
                    ftAssetCodeId: inputFields.assetCodeID,
                    ftTransactionDate: inputFields.dateCompleted ? formatDate(inputFields.dateCompleted) : null,
                    ftDescription: 'Maintenance of Asset',
                    ftTransaction: 'Maintenance',
                    ftAmount: inputFields.maintenanceCost,
                    ftPaymentType: 'Paid',
                };
                await CreateFinancialTransaction(transactionPayload);
            }
            const response = await updateProFormMaintenance(payload);
            if (response === "Inserted") {
                fetchMaintenanceData();
                toast.success("Maintenance added Successfully");
                setIsMaintenanceList(true);
            } else if (response === "Updated") {
                fetchMaintenanceData();
                toast.success("Maintenance updated Successfully");
                setIsMaintenanceList(true);
            } else {
                toast.error(response);
            }
        } catch (error) {
            toast.error("Failed to update Maintenance");
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearchChangeAsset = (event) => {
        const searchValue = event.target.value;
        setSearchQueryAsset(searchValue);
        const filteredMaintenance = searchValue != '' ? MaintenanceListArray?.filter((maintenance) =>
            maintenance?.assetCodeID?.toLowerCase()?.includes(searchValue?.toLowerCase()) ||
            maintenance?.assetName?.toLowerCase()?.includes(searchValue?.toLowerCase()) ||
            maintenance?.title?.toLowerCase()?.includes(searchValue?.toLowerCase()) ||
            maintenance?.formattedScheduleDate?.toLowerCase()?.includes(searchValue?.toLowerCase()) ||
            maintenance?.maintenanceBy?.toLowerCase()?.includes(searchValue?.toLowerCase()) ||
            maintenance?.maintenanceStatusValue?.toLowerCase()?.includes(searchValue?.toLowerCase())
        ) : MaintenanceListArray;
        setFilterMaintenanceListArray(filteredMaintenance);
        setPageAsset(0);
    };

    const handleChangeMaintenanceView = (selectedMaintenace) => {
        setIsMaintenanceView(true);
        setMaintenanceID(selectedMaintenace.maintenanceId);
        setCurrentStatus(selectedMaintenace.maintenanceStatusValue);
        setValue('assetId', selectedMaintenace.assetCodeID);
        setValue('assetName', selectedMaintenace.assetName);
        setValue('title', selectedMaintenace.title);
        setValue('details', selectedMaintenace.details);
        setValue('scheduleDate', selectedMaintenace.scheduleDate == null ? null : selectedMaintenace.scheduleDate.split("T")[0]);
        setValue('maintenanceBy', selectedMaintenace.maintenanceBy);
        setValue('maintenanceStatus', selectedMaintenace.maintenanceStatus);
        setValue('estimateDate', selectedMaintenace.estimatedReturnDate == null ? null : selectedMaintenace.estimatedReturnDate.split("T")[0]);
        setValue('dateCompleted', selectedMaintenace.dateCompleted == null ? null : selectedMaintenace.dateCompleted.split("T")[0]);
        setValue('maintenanceCost', selectedMaintenace.maintenanceCost);
        setIsMaintenanceList(false);
    };

    const handleChangeMaintenanceEdit = (selectedMaintenace) => {
        setMaintenanceID(selectedMaintenace.maintenanceId);
        setCurrentStatus(selectedMaintenace.maintenanceStatusValue);
        setValue('assetId', selectedMaintenace.assetCodeID);
        setValue('assetName', selectedMaintenace.assetName);
        setValue('title', selectedMaintenace.title);
        setValue('details', selectedMaintenace.details);
        setValue('scheduleDate', selectedMaintenace.scheduleDate == null ? null : selectedMaintenace.scheduleDate.split("T")[0]);
        setValue('maintenanceBy', selectedMaintenace.maintenanceBy);
        setValue('maintenanceStatus', selectedMaintenace.maintenanceStatus);
        setValue('estimateDate', selectedMaintenace.estimatedReturnDate == null ? null : selectedMaintenace.estimatedReturnDate.split("T")[0]);
        setValue('dateCompleted', selectedMaintenace.dateCompleted == null ? null : selectedMaintenace.dateCompleted.split("T")[0]);
        setValue('maintenanceCost', selectedMaintenace.maintenanceCost);
        setIsMaintenanceList(false);
    };

    const OnchangeMaintenanceStatus = (event) => {
        setValue('maintenanceStatus', event.idValue);
    };

    const OnchangeMaintenanceBy = (event) => {
        setValue('maintenanceBy', event.idValue);
    };


    return (
        <div className='bacgroundStyle'>
            <ToastContainer theme="colored" />
            {isLoading && <LoadingSpinner />}

            {!isLoading && (
                <div>
                    <div className="pagetitleCountry">
                        <div className="Pageheader fontFamily fontsize24 fontweightSemiBold">Asset - Process Form Configuration - Maintenance<div><InfoOutlinedIcon /></div></div>
                    </div>
                    <div className="customScrollbar">
                        {isMaintenanceList ? (
                            <div className="card mt-4" style={{ height: '700px' }}>
                                <div className="card-body">
                                    <div>
                                        <div className='d-flex justify-content-between'>
                                            <div className="CustomBoxShadow" style={{ width: '15%', padding: '2% 1%' }}>
                                                <div className="ashTextColor fontFamily fontsize16 fontweightMedium textAlignCenter">
                                                    Schedule
                                                </div>
                                                <div className="blackTextColor fontFamily fontsize20 fontweightMedium textAlignCenter mt-4">
                                                    {ScheduleCount}
                                                </div>
                                            </div>
                                            <div className="CustomBoxShadow" style={{ width: '15%', padding: '2% 1%' }}>
                                                <div className="ashTextColor fontFamily fontsize16 fontweightMedium textAlignCenter">
                                                    In-Progress
                                                </div>
                                                <div className="blackTextColor fontFamily fontsize20 fontweightMedium textAlignCenter mt-4">
                                                    {InProgress}
                                                </div>
                                            </div>
                                            <div className="CustomBoxShadow" style={{ width: '15%', padding: '2% 1%' }}>
                                                <div className="ashTextColor fontFamily fontsize16 fontweightMedium textAlignCenter">
                                                    On Hold
                                                </div>
                                                <div className="blackTextColor fontFamily fontsize20 fontweightMedium textAlignCenter mt-4">
                                                    {OnHold}
                                                </div>
                                            </div>
                                            <div className="CustomBoxShadow" style={{ width: '15%', padding: '2% 1%' }}>
                                                <div className="ashTextColor fontFamily fontsize16 fontweightMedium textAlignCenter">
                                                    Cancelled
                                                </div>
                                                <div className="blackTextColor fontFamily fontsize20 fontweightMedium textAlignCenter mt-4">
                                                    {Cancelled}
                                                </div>
                                            </div>
                                            <div className="CustomBoxShadow" style={{ width: '15%', padding: '2% 1%' }}>
                                                <div className="ashTextColor fontFamily fontsize16 fontweightMedium textAlignCenter">
                                                    Completed
                                                </div>
                                                <div className="blackTextColor fontFamily fontsize20 fontweightMedium textAlignCenter mt-4">
                                                    {Completed}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center mt-3" style={{ paddingBottom: "8px" }}>
                                            <div className="custom-container">
                                                <span className="blackTextColor fontFamily fontsize16 fontweightRegular">
                                                    <span className="show">Show</span>
                                                    <select className="custom-dropdown" value={rowsPerPageAsset} onChange={handleRowsPerPageChangeAsset} >
                                                        {paginateArray.map((n) => (
                                                            <option className="blackTextColor fontFamily fontsize14 fontweightRegular" key={n} value={n} >
                                                                {n}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <span className="blackTextColor entries fontFamily fontsize16 fontweightRegular">
                                                        Entries
                                                    </span>
                                                </span>
                                                <input
                                                    type="text"
                                                    placeholder="Search..."
                                                    value={searchQueryAsset}
                                                    onChange={handleSearchChangeAsset}
                                                    className="searchTextBox"
                                                />
                                            </div>
                                        </div>
                                        <div className="p-1">
                                            <div className="EmpBoxShadow">
                                                <div className="" style={{ padding: "10px" }}>
                                                    <table className="w-100 mt-2">
                                                        <thead>
                                                            <tr className="text-left empTableHeight">

                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 textAlignCenter" style={{ width: "10%" }} > Asset ID </th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 textAlignCenter" style={{ width: "10%" }} > Maintenance ID </th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 textAlignCenter" style={{ width: "15%" }} > Asset Name </th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 textAlignCenter" style={{ width: "15%" }} > Title </th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 textAlignCenter" style={{ width: "10%" }} > Schedule Date </th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 textAlignCenter" style={{ width: "12%" }} > Maintenance By </th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 textAlignCenter" style={{ width: "12%" }} > Status </th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 textAlignCenter" style={{ width: "7%" }} > Action </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {FilterMaintenanceListArray.length > 0 ? (
                                                                FilterMaintenanceListArray.slice(pageAsset * rowsPerPageAsset, (pageAsset + 1) * rowsPerPageAsset).map((maintenance, index) => (
                                                                    <tr key={maintenance.id} style={{ height: "59px" }}>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 textAlignCenter ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} ><span style={{ color: "#605BFF" }}>{maintenance.assetCodeID}</span></td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 textAlignCenter ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} ><span style={{ color: "#605BFF" }}>{maintenance.maintenanceId}</span></td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 textAlignCenter ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} >{maintenance.assetName}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 textAlignCenter ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} >{maintenance.title}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 textAlignCenter ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} >{maintenance.formattedScheduleDate}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 textAlignCenter ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} >{maintenance.maintenanceByValue}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 textAlignCenter ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} >{maintenance.maintenanceStatusValue}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 textAlignCenter ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} >
                                                                            <span style={{ cursor: "pointer" }}><img style={{ width: "18px", height: "15px", }} src={viewsvgIcon} alt="viewsvgIcon" onClick={() => handleChangeMaintenanceView(maintenance)} /></span>
                                                                            {maintenance.maintenanceStatus != 'completed' && (
                                                                                <span style={{ marginLeft: "10px", cursor: "pointer", }}><img src={editsvgIcon} alt="editsvgIcon" onClick={() => handleChangeMaintenanceEdit(maintenance)} /></span>
                                                                            )}


                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <td colSpan="8" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            {totalEntriesMaintenance > 0 && (
                                                <div>
                                                    <div className="" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingTop: "16px", }}>
                                                        <div className="text-muted fontFamily fontsize12 fontweightRegular">
                                                            Showing {startEntryMaintenance} to {endEntryMaintenance} of {totalEntriesMaintenance} entries
                                                        </div>
                                                        <div>
                                                            <div className="pagination-container d-flex align-items-center">
                                                                <button className="paginationButton btn btn-link" onClick={handleFirstPageAsset} >
                                                                    <FontAwesomeIcon icon={faAngleDoubleLeft} className="icon-color" />
                                                                </button>
                                                                <ReactPaginate
                                                                    previousLabel={<FontAwesomeIcon icon={faChevronLeft} className="icon-color" />}
                                                                    nextLabel={<FontAwesomeIcon icon={faChevronRight} className="icon-color" />}
                                                                    breakLabel={"..."}
                                                                    breakClassName={"break-me"}
                                                                    pageCount={Math.ceil(totalEntriesMaintenance / rowsPerPageAsset)}
                                                                    forcePage={pageAsset}
                                                                    marginPagesDisplayed={2}
                                                                    pageRangeDisplayed={5}
                                                                    onPageChange={handleChangePagePaginationAsset}
                                                                    containerClassName={"pagination"}
                                                                    subContainerClassName={"pages pagination"}
                                                                    activeClassName={"active"}
                                                                    renderOnZeroPageCount={null}
                                                                />
                                                                <button className="paginationButton btn btn-link" onClick={handleLastPageAsset}>
                                                                    <FontAwesomeIcon icon={faAngleDoubleRight} className="icon-color" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div style={{ padding: "0px 100px" }}></div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="card mt-4">
                                <div className="card-body">
                                    <div>
                                        <div className='d-flex justify-content-between'>
                                            <div className="blackTextColor fontFamily fontsize20 fontweightMedium">Maintenance ID - {MaintenanceID}</div>
                                            <div className="blackTextColor fontFamily fontsize20 fontweightMedium">Current Status - {CurrentStatus}</div>
                                        </div>

                                        <form className="p-2" noValidate onSubmit={handleSubmit(handleMaintenanceSubmit)}>
                                            <div className="row">
                                                <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor textFieldPadding">Asset ID</div>
                                                    <input
                                                        className="textBoxNewView"
                                                        {...register('assetId')}
                                                        disabled={true}
                                                    />
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor textFieldPadding">Asset Name</div>
                                                    <input
                                                        className="textBoxNewView"
                                                        {...register('assetName')}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor textFieldPadding">Title<span className="mandatoryCss"> *</span></div>
                                                    <input
                                                        className={isMaintenanceView ? "textBoxNewView" : "textBoxNew"}
                                                        {...register('title', { required: 'Please Enter Title' })}
                                                        disabled={isMaintenanceView}
                                                    />
                                                    {errors.title && (
                                                        <div className="invalid-feedback" style={{ display: 'block' }}>
                                                            {errors.title.message}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor textFieldPadding">Details</div>
                                                    <textarea
                                                        placeholder=""
                                                        style={{ height: '110px' }}
                                                        className={isMaintenanceView ? "textBoxNewView" : "textBoxNew"}
                                                        {...register('details')}
                                                        disabled={isMaintenanceView}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor textFieldPadding">
                                                        Schedule Date
                                                    </div>
                                                    <div className='position-relative'>
                                                        <Controller
                                                            name="scheduleDate"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <DatePicker
                                                                    {...field}
                                                                    selected={field.value}
                                                                    onChange={(date) => field.onChange(date)}
                                                                    dateFormat={dateFormat}
                                                                    placeholderText={dateFormat.toUpperCase()}
                                                                    className={isMaintenanceView ? "DatePickerDisable" : "DatePicker"}
                                                                    disabled={isMaintenanceView}
                                                                    ref={(input) => field.ref(input?.inputElement)}
                                                                />
                                                            )}
                                                        />
                                                        <img className="eyeContent" src={calendarsvgIcon} alt="calendarsvgIcon" />
                                                    </div>
                                                </div>

                                                <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor textFieldPadding">Maintenance By</div>
                                                    <Controller
                                                        name="maintenanceBy"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                value={maintenanceByDropdown.find(repo => repo.idValue === getValues('maintenanceBy')) || null}
                                                                onChange={OnchangeMaintenanceBy}
                                                                options={maintenanceByDropdown}
                                                                placeholder=""
                                                                getOptionLabel={(option) => option.Value}
                                                                getOptionValue={(option) => option.idValue}
                                                                // isClearable
                                                                isSearchable
                                                                isDisabled={isMaintenanceView}
                                                                styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                                            />

                                                        )}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor textFieldPadding">
                                                        Estimate Return Date
                                                    </div>
                                                    <div className='position-relative'>
                                                        <Controller
                                                            name="estimateDate"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <DatePicker
                                                                    {...field}
                                                                    selected={field.value}
                                                                    onChange={(date) => field.onChange(date)}
                                                                    dateFormat={dateFormat}
                                                                    placeholderText={dateFormat.toUpperCase()}
                                                                    className={`${isMaintenanceView ? 'DatePickerDisable' : 'DatePicker'}`}
                                                                    disabled={isMaintenanceView}
                                                                    ref={(input) => field.ref(input?.inputElement)}
                                                                />
                                                            )}
                                                        />
                                                        <img className="eyeContent" src={calendarsvgIcon} alt="calendarsvgIcon" />
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor textFieldPadding">
                                                        Date Completed
                                                    </div>
                                                    <div className='position-relative'>
                                                        <Controller
                                                            name="dateCompleted"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <DatePicker
                                                                    {...field}
                                                                    selected={field.value}
                                                                    onChange={(date) => field.onChange(date)}
                                                                    dateFormat={dateFormat}
                                                                    placeholderText={dateFormat.toUpperCase()}
                                                                    className={`${isMaintenanceView ? 'DatePickerDisable' : 'DatePicker'}`}
                                                                    disabled={isMaintenanceView}
                                                                    ref={(input) => field.ref(input?.inputElement)}
                                                                />
                                                            )}
                                                        />
                                                        <img className="eyeContent" src={calendarsvgIcon} alt="calendarsvgIcon" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-6 col-md-6 col-lg-6 col-sm-12">

                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor textFieldPadding">Maintenance Cost</div>
                                                    <input
                                                        type='number'
                                                        min={0}
                                                        className={isMaintenanceView ? "textBoxNewView" : "textBoxNew"}
                                                        {...register('maintenanceCost')}
                                                        disabled={isMaintenanceView}
                                                    />
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor textFieldPadding">
                                                        Maintenance Status
                                                    </div>
                                                    <Controller
                                                        name="maintenanceStatus"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                value={maintenanceStatus.find(repo => repo.idValue === getValues('maintenanceStatus')) || null}
                                                                onChange={OnchangeMaintenanceStatus}
                                                                options={maintenanceStatus}
                                                                placeholder=""
                                                                getOptionLabel={(option) => option.Value}
                                                                getOptionValue={(option) => option.idValue}
                                                                // isClearable
                                                                isSearchable
                                                                isDisabled={isMaintenanceView}
                                                                styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                                            />

                                                        )}
                                                    />
                                                </div>
                                            </div>

                                            <div className="mb-2 text-center" style={{ marginTop: "3%" }}>
                                                {!isMaintenanceView && (
                                                    <button type="submit" className="coloredButtonSave">Save</button>
                                                )}
                                                <button type="button" className="outlineButton" onClick={onCancel} style={{ marginLeft: '18px' }} >Cancel</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProcessFormConfigurationMaintenance;
