import { saveAs } from 'file-saver';
import 'jspdf-autotable';

export const exportDataRequestCategory = (data, format) => {
    const headers = ["SI.No", "Request Category", "Request Category Code", "Description", "Status"];

    const rows = data.map(Category => {
        return [
            Category.Rowid || '',
            Category.requestCategoryName || '',
            Category.requestCategoryCode || '',
            Category.requestDescription || '',
            Category.requestStatus || '',
        ];
    });

    let fileData;
    let fileType;
    let fileExtension;

    if (format === 'csv') {
        const csvContent = [headers.join(','), ...rows.map(row => row.join(','))].join('\n');
        fileData = csvContent;
        fileType = 'text/csv;charset=utf-8;';
        fileExtension = 'csv';
    } else {
        return;
    }

    const formattedDate = new Date().toISOString().replace(/T/, '_').replace(/:|\..+/, '');
    const blob = new Blob([fileData], { type: fileType });
    saveAs(blob, `Request_Category_Data_${formattedDate}.${fileExtension}`);
};

export const exportDataRequestSubCategory = (data, format) => {
    const headers = ["SI.No", "Request Category", "Request Sub Category", "Request Sub Category Code", "Description", "Status"];
    const rows = data.map(SubCategory => {
        return [
            SubCategory.Rowid || '',
            SubCategory.requestCategoryNameInSubCategory || '',
            SubCategory.requestSubCategoryName || '',
            SubCategory.requestSubCategoryCode || '',
            SubCategory.requestDescription || '',
            SubCategory.requestStatus || '',
        ];
    });

    let fileData;
    let fileType;
    let fileExtension;

    if (format === 'csv') {
        const csvContent = [headers.join(','), ...rows.map(row => row.join(','))].join('\n');
        fileData = csvContent;
        fileType = 'text/csv;charset=utf-8;';
        fileExtension = 'csv';
    } else {
        return;
    }

    const formattedDate = new Date().toISOString().replace(/T/, '_').replace(/:|\..+/, '');
    const blob = new Blob([fileData], { type: fileType });
    saveAs(blob, `Request_SubCategory_Data_${formattedDate}.${fileExtension}`);
};

export const exportDataRequestTeam = (data, format) => {
    const headers = ["SI.No", "Request Category", "Request Sub Category", "Assigned To", "Status"];

    const rows = data.map(Team => {
        return [
            Team.Rowid || '',
            Team.requestCategoryName || '',
            Team.requestSubCategoryName || '',
            Team.assignTo || '',
            Team.requestStatus || '',
        ];
    });

    let fileData;
    let fileType;
    let fileExtension;

    if (format === 'csv') {
        const csvContent = [headers.join(','), ...rows.map(row => row.join(','))].join('\n');
        fileData = csvContent;
        fileType = 'text/csv;charset=utf-8;';
        fileExtension = 'csv';
    } else {
        return;
    }

    const formattedDate = new Date().toISOString().replace(/T/, '_').replace(/:|\..+/, '');
    const blob = new Blob([fileData], { type: fileType });
    saveAs(blob, `Request_Team_Data_${formattedDate}.${fileExtension}`);
};