import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import { useAuth } from '../Auth/AuthContext';

import '../../src/index.css';
import '../assets/css/style.css';
import '../assets/vendor/quill/quill.snow.css';
import '../assets/vendor/quill/quill.bubble.css';
import '../assets/vendor/remixicon/remixicon.css';
import '../assets/vendor/simple-datatables/style.css';
import '../assets/vendor/boxicons/css/boxicons.min.css';
import '../assets/vendor/bootstrap/css/bootstrap.min.css';
import '../assets/vendor/bootstrap-icons/bootstrap-icons.css';

import avatarImg from '../assets/icon/Profile.svg';
import ChangelogImg from '../assets/icon/Changelog.svg';
import NotificationImg from '../assets/icon/Notification.svg';


const Header = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [userName, setUserName] = useState('');

  useEffect(() => {
    const user = localStorage.getItem('UserName');
    setUserName(user);
  }, []);

  const handleLogout = async () => {
    try {
      await logout();
      localStorage.clear();
      navigate('/');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <header id="header" className="header fixed-top d-flex align-items-center" style={{ boxShadow: '1px 1px 0px 0px rgba(0, 0, 0, 0.10) !importantone', marginLeft: '16.5%' }}>
      <nav className="header-nav ms-auto">
        <ul className="d-flex justify-content-between justify-content-end" >

          <div style={{ width: '50%', marginLeft: '-8%', marginRight: "40px" }}>
            <img src={ChangelogImg} alt="ChangelogImg" style={{ paddingBottom: '3px' }} /> <span style={{ fontWeight: '400', paddingLeft: '2%', paddingRight: '2%', fontSize: '16px', color: '#000000' }}>Changelog</span><sapn style={{ paddingLeft: '3%', fontWeight: '500', fontSize: '10px', background: '#605BFF', margin: '2px', padding: '4px 5px 4px 5px', borderRadius: '5px', color: '#FFFFFF' }}>Mar 12</sapn>
          </div>
          <div style={{ marginRight: '1%', marginLeft: '1%' }}>
            <img src={NotificationImg} alt="NotificationImg" />
          </div>
          <ul>
            <img src={avatarImg} alt="profileImg" style={{ height: '30px', marginBottom: '0px', padding: '3px 3px 3px 3px', width: '28px', borderRadius: '50%', color: '#000000', backgroundColor: '#D9D9D9' }} />
          </ul>
          <li className="nav-item dropdown pe-3 " style={{ marginBottom: '0px', marginRight: '4%' }}>

            <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#section" data-bs-toggle="dropdown">
              <span className="d-none d-md-block  ps-2" style={{ fontWeight: '400', color: "#000000", fontSize: '16px' }}> {userName} </span><FontAwesomeIcon icon={faAngleDown} style={{ fontSize: 'smaller', padding: '2px 2px 2px 5px' }} />
            </a>

            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
              <li>
                <a className="dropdown-item d-flex justify-content-center" href="#ChangeRole">
                  <span>Change Role</span>
                </a>
              </li>
              <li>
                <a className="dropdown-item d-flex justify-content-center" href="#MyProfile">
                  <span>My Profile</span>
                </a>
              </li>
              <li>
                <a className="dropdown-item d-flex justify-content-center" href="#LogOut" onClick={handleLogout}>
                  <span>LogOut</span>
                </a>
              </li>

            </ul>
          </li>

        </ul>
      </nav>

    </header>
  )
}

export default Header