import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';


export const exportWarrantyData = (data, format) => {
    const headers = ["SI.No", "Asset ID", "Warranty ID", "Asset Name", "Expiration Date", "Length", "Status"];

    const rows = data.map(war => {
        return [
            war.Rowid || '',
            war.assetTagId || '',
            war.warrantyTagId || '',
            war.assetName || '',
            war.formattedExpirationDate || '',
            war.length || '',
            war.currentStatus || '',
        ];
    });

    let fileData;
    let fileType;
    let fileExtension;

    switch (format) {
        case 'csv': {
            const csvContent = [headers.join(','), ...rows.map(row => row.join(','))].join('\n');
            fileData = csvContent;
            fileType = 'text/csv;charset=utf-8;';
            fileExtension = 'csv';
            break;
        }
        case 'excel': {
            const worksheet = XLSX.utils.aoa_to_sheet([headers, ...rows]);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            fileData = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;';
            fileExtension = 'xlsx';
            break;
        }
        default:
            return;
    }

    const formattedDate = new Date().toISOString().replace(/T/, '_').replace(/:|\..+/, '');
    const blob = new Blob([fileData], { type: fileType });
    saveAs(blob, `Warranty_Data_${formattedDate}.${fileExtension}`);
};


export const downloadSampleExcelWarranty = () => {
    const headers = [
        "Asset ID", "Warranty ID", "Asset Name", "Expiration Date", "Length", "Notes", "Reminders", "Document Name", "Document (Base64)"
    ];

    // Sample data for rows (optional)
    const rows = [
        ["AST000010", "WI00001", "HP Desktop", "15/03/2025", "36", "Warranty Notes", "1,2,3", "Warranty Document", "/9j/4AAQSkZJRgABAQEAYABgAAD/4QLcRXhpZgAATU0AKgAAAAgABAE7AAIAAAAGAAABSodpAAQAAAABAAABUJydAAEAAAAMAAACyOocAAcAAAEMAAAAPgAAAAAc6gAAA+DQo8eDp4"]
    ];

    // Create the worksheet and workbook
    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...rows]);
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

    // Write the workbook to an Excel file
    const fileData = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;';
    const fileExtension = 'xlsx';

    // Create a formatted date string for the filename
    const formattedDate = new Date().toISOString().replace(/T/, '_').replace(/:|\..+/, '');

    // Create a blob and trigger download
    const blob = new Blob([fileData], { type: fileType });
    saveAs(blob, `Sample_Warranty_Format_${formattedDate}.${fileExtension}`);
};