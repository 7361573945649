import React, { useState, useEffect, useRef, useCallback } from "react";
import Select from 'react-select';
import { format, set } from 'date-fns';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { useForm, Controller } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronLeft,
    faChevronRight,
    faAngleDoubleLeft,
    faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";

import LoadingSpinner from '../../../CommomPages/LoadingSpinner';
import { exportAssetData } from "../AssetCreation/ExportAssetCreation";

import { getDateFormat, getRegexForDateFormat, validateDate } from "../../../CommomPages/Config";
import { getSupplier } from "../../../Services/SupplierMasterService";
import { getEmployee } from "../../../Services/EmployeeManagementService";
import { getDepartment } from "../../../Services/DepartmentsAndTeamService";
import { getBUData, getBRData } from "../../../Services/BusinessEntityNewService";
import { getCategory, getSubCategory, getBrand } from "../../../Services/AssetCategoriesAndBrandService";
import {
    getAsset,
    getAssetStatus,
    getFinancialTransaction,
} from "../../../Services/AssetSettingsService";

import CalendarWithBG from "../../../assets/icon/CalendarWithBG.svg";
import nodataFound from "../../../assets/img/nodatafound.png";
import calendarsvgIcon from '../../../assets/icon/calendarDate.svg';
import { createCheckOut } from "../../../Services/ActionFormConfigurationService";
import { Modal } from 'react-bootstrap';


const customStyles = {
    placeholder: (provided) => ({
        ...provided,
        fontFamily: "Nunito, sans-serif",
        fontWeight: 400,
        fontSize: "14px",
        color: "#B0ACAC",
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 9999,
    }),
    // container: (provided) => ({
    //     ...provided,
    //     marginLeft: '10px',
    //     width: '275px'
    // }),
};


const CheckOut = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const paginateArray = [5, 10, 15];
    const [pageAsset, setPageAsset] = useState(0);
    const [rowsPerPageAsset, setRowsPerPageAsset] = useState(5);
    const [searchQueryAsset, setSearchQueryAsset] = useState("");
    const [dateFormat, setDateFormat] = useState("");

    const [assetArrayfilter, setassetArrayfilter] = useState([]);
    const [assetArray, setAssetArray] = useState([]);
    const [assetStatusArray, setAssetStatusArray] = useState([]);
    const [employeeArray, setEmployeeArray] = useState([]);
    const [departmentArray, setDepartmentArray] = useState([]);
    const [supplierArray, setSupplierArray] = useState([]);
    const [categoryArray, setCategoryArray] = useState([]);
    const [subCategoryArray, setSubCategoryArray] = useState([]);
    const [brandArray, setBrandArray] = useState([]);
    const [unitArray, setUnitArray] = useState([]);
    const [branchArray, setBranchArray] = useState([]);
    const [selectedAssets, setSelectedAssets] = useState([]);
    const [selectAll, setSelectAll] = useState(false);


    const [SelectAssetData, setSelectAssetData] = useState([]);
    const [IsAddtoList, setIsAddtoList] = useState(false);
    const [SelectedAssetsPending, setSelectedAssetsPending] = useState([]);
    const [selectAllAssetPending, setselectAllAssetPending] = useState(false);
    const [IsSendMail, setIsSendMail] = useState(false);

    const [isCheckOut, setisCheckOut] = useState(false);
    const [addAssetList, setaddAssetList] = useState([]);
    const [SelectedAddasset, setSelectedAddasset] = useState([]);
    const [selectAlladdAsset, setselectAlladdAsset] = useState(false);

    //Asset
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control,
        reset,
        watch,
        getValues,
        resetField,
    } = useForm({
        defaultValues: {
            assetName: "",
            assetID: "",
            assetTagID: "",
            categoryId: 0,
            subCategoryId: 0,
            brandId: 0,
            modelNo: "",
            serialNo: "",
            cost: "",
            supplierId: 0,
            purchaseDate: null,
            unitId: 0,
            branchId: 0,
            departmentId: 0,
            empId: 0,
            assetImage: "",
            depreciableAsset: "",
            depreciableCost: "",
            assetLife: "",
            salvageValue: "",
            depreciationMethod: "",
            dateAcquired: null,
            date: null,
            status: 0,
        },
    });

    //CheckOut
    const {
        register: registerCheckOut,
        handleSubmit: handleSubmitCheckOut,
        formState: { errors: errorsCheckOut },
        control: controlCheckOut,
        reset: resetCheckOut,
        watch: watchCheckOut,
    } = useForm({
        defaultValues: {
            checkOutDate: null,
            employee: false,
            unit: false,
            dueDate: null,
            assignTo: 0,
            unitId: 0,
            branchId: 0,
            departmentId: 0,
            checkOutNotes: '',
            sendEmail: '',
            sendEmailCheck: false,
        },
    });

    // Define dataInitialization as a regular async function
    const dataInitialization = useCallback(async () => {
        try {
            setIsLoading(true);
            await Promise.all([
                getSupplierData(),
                getEmployeeData(),
                getDepartmentData(),
                getUnitBranchDetails(),
                getCategoryAndBrandData(),
                fetchAssetData()
            ]);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        dataInitialization();
    }, []);


    const fetchAssetData = async () => {
        try {
            const dateCurrentFormat = await getDateFormat();
            setDateFormat(dateCurrentFormat);
            const assetData = await getAsset();
            const assetStatus = await getAssetStatus();
            const assetCategory = await getCategory();
            const assetSubCategory = await getSubCategory();
            const assetBrand = await getBrand();
            const assetUnit = await getBUData();
            const assetBranch = await getBRData();
            const empData = await getEmployee();
            const finalEmployeeData = empData.map((Emp, index) => {
                return {
                    ...Emp,
                    EmployeeName: Emp?.firstName + " " + Emp?.lastName,
                };
            });

            setAssetStatusArray(assetStatus);
            const finalData = assetData?.length > 0 ? assetData.map((asset, index) => {
                const categoryAsset = assetCategory?.find(x => x.id === asset?.categoryId);
                const subcategoryAsset = assetSubCategory?.find(x => x.id === asset?.subCategoryId);
                const brandAsset = assetBrand?.find(x => x.id === asset?.brandId);
                const branchAsset = assetBranch?.find(x => x.bR_ID === asset?.branchId);
                const unitAsset = assetUnit?.find(x => x.bU_ID === asset?.unitId);
                const formattedDate = asset.purchaseDate ? format(new Date(asset.purchaseDate), dateCurrentFormat) : null;
                const FilterformattedDate = asset.purchaseDate ? format(new Date(asset.purchaseDate), 'yyyy-MM-dd') : null;
                const formattedDateAcquired = asset.dateAcquired ? format(new Date(asset.dateAcquired), dateCurrentFormat) : null;
                const getAssetStatusValue = assetStatus?.find(e => e.id === asset?.statusValue);
                const employeeAsset = finalEmployeeData?.find(x => x.id === asset?.empId);

                return {
                    ...asset,
                    Rowid: index + 1,
                    categoryName: categoryAsset ? categoryAsset?.categoryName : '',
                    subcategoryName: subcategoryAsset ? subcategoryAsset?.subCategoryName : '',
                    brandName: brandAsset ? brandAsset?.brandName : '',
                    branchName: branchAsset ? branchAsset?.bR_NAME : '',
                    unitName: unitAsset ? unitAsset?.bU_NAME : '',
                    formatedPurchaseDate: formattedDate,
                    filterPurchaseDate: FilterformattedDate,
                    formattedDateAcquired: formattedDateAcquired,
                    assetStatusValue: getAssetStatusValue?.labelAssignedValue,
                    employeeName: employeeAsset ? employeeAsset?.EmployeeName : ''
                };
            }) : [];
            setAssetArray(finalData);
        } catch (error) {
            console.error("Error fetching Asset data:", error);
        }
    };

    const getUnitBranchDetails = async () => {
        const unitData = await getBUData();
        const sortedUnitData = unitData.sort((a, b) => a.bU_NAME.localeCompare(b.bU_NAME, undefined, { sensitivity: 'base' }));
        setUnitArray(sortedUnitData);

        const branchData = await getBRData();
        const sortedBranchData = branchData.sort((a, b) => a.bR_NAME.localeCompare(b.bR_NAME, undefined, { sensitivity: 'base' }));
        setBranchArray(sortedBranchData);
    };

    const getCategoryAndBrandData = async () => {
        const CategoryData = await getCategory();
        setCategoryArray(CategoryData);

        const SubCategoryData = await getSubCategory();
        setSubCategoryArray(SubCategoryData);

        const BrandData = await getBrand();
        setBrandArray(BrandData);
    };

    const getDepartmentData = async () => {
        const data = await getDepartment();
        setDepartmentArray(data);
    };

    const getSupplierData = async () => {
        const SupplierData = await getSupplier();
        setSupplierArray(SupplierData);
    };

    const getEmployeeData = async () => {
        const empData = await getEmployee();
        const finalData = empData.map((Emp, index) => {
            return {
                ...Emp,
                EmployeeName: Emp?.firstName + " " + Emp?.lastName,
            };
        });
        const sortedEmployeeData = finalData.sort((a, b) => a.EmployeeName.localeCompare(b.EmployeeName, undefined, { sensitivity: 'base' }));
        setEmployeeArray(sortedEmployeeData);
    };

    const handleSearchChange = (inputFieldsValue) => {
        if (inputFieldsValue.date == null && inputFieldsValue.unitId == 0 && inputFieldsValue.branchId == 0 && inputFieldsValue.categoryId == 0 && inputFieldsValue.subCategoryId == 0 && inputFieldsValue.departmentId == 0 && inputFieldsValue.empId == 0 && inputFieldsValue.status == 0) {
            toast.error("Please select a field to search Asset.")
        } else {
            let inputDate = null;
            let formattedInputDate = null;
            if (inputFieldsValue.date != null) {
                inputDate = new Date(inputFieldsValue?.date)
                let date = inputDate.getDate();
                let fullDate = date < 10 ? '0' + date : date;
                let month = inputDate.getMonth() + 1;
                let fullMonth = month < 10 ? '0' + month : month
                let year = inputDate.getFullYear();
                formattedInputDate = "" + year + "-" + fullMonth + "-" + fullDate + ""
            }
            let filterData = assetArray.filter(record => {
                return (
                    (formattedInputDate === null || record.filterPurchaseDate === formattedInputDate) &&
                    (inputFieldsValue.unitId === 0 || inputFieldsValue.unitId === null || record.unitId === inputFieldsValue.unitId) &&
                    (inputFieldsValue.branchId === 0 || inputFieldsValue.branchId === null || record.branchId === inputFieldsValue.branchId) &&
                    (inputFieldsValue.categoryId === 0 || inputFieldsValue.categoryId === null || record.categoryId === inputFieldsValue.categoryId) &&
                    (inputFieldsValue.subCategoryId === 0 || inputFieldsValue.subCategoryId === null || record.subCategoryId === inputFieldsValue.subCategoryId) &&
                    (inputFieldsValue.departmentId === 0 || inputFieldsValue.departmentId === null || record.departmentId === inputFieldsValue.departmentId) &&
                    (inputFieldsValue.empId === 0 || inputFieldsValue.empId === null || record.empId === inputFieldsValue.empId) &&
                    (inputFieldsValue.status === 0 || inputFieldsValue.status === null || record.statusValue === inputFieldsValue.status)

                );
            });
            setassetArrayfilter(filterData);
        }

    };

    const handleSearchClear = () => {
        reset();
        setSelectedAssets([]);
        setSelectAll(false)
        setassetArrayfilter([]);
    };

    const handleExportAsset = (format) => {
        exportAssetData(assetArray, format);
    };

    // Asset Section Search and Pagination
    const handleSearchChangeAsset = (event) => {
        setSearchQueryAsset(event.target.value);
        setSearchQueryAsset(event.target.value);
        const idArray = SelectAssetData.map(item => item.id);
        const selectedData = assetArray.filter(asset => !idArray.includes(asset.id));
        const searchTextInput = event.target.value;
        const filteredSelectedAsset = searchTextInput != '' ? selectedData?.filter((asset) =>
            asset?.assetID?.toLowerCase()?.includes(searchTextInput?.toLowerCase()) ||
            asset?.assetName?.toLowerCase()?.includes(searchTextInput?.toLowerCase()) ||
            asset?.assetStatusValue?.toLowerCase()?.includes(searchTextInput?.toLowerCase()) ||
            asset?.employeeName?.toLowerCase()?.includes(searchTextInput?.toLowerCase()) ||
            asset?.branchName?.toLowerCase()?.includes(searchTextInput?.toLowerCase()) ||
            asset?.unitName?.toLowerCase()?.includes(searchTextInput?.toLowerCase())
        ) : selectedData;
        setaddAssetList(filteredSelectedAsset);
    };

    const handleRowsPerPageChangeAsset = (event) => {
        setRowsPerPageAsset(parseInt(event.target.value));
        setPageAsset(0);
    };

    const handleFirstPageAsset = () => {
        handleChangePagePaginationAsset({ selected: 0 });
    };

    const handleChangePagePaginationAsset = ({ selected }) => {
        setPageAsset(selected);
    };

    const handleLastPageAsset = () => {
        handleChangePagePaginationAsset({
            selected: Math.ceil(assetArray?.length / rowsPerPageAsset) - 1,
        });
    };

    const totalEntriesAsset = assetArrayfilter?.length;
    const startEntryAsset = pageAsset * rowsPerPageAsset + 1;
    const endEntryAsset = Math.min((pageAsset + 1) * rowsPerPageAsset, totalEntriesAsset);

    const handleSelectAllChange = () => {
        if (selectAll) {
            setSelectedAssets([]);
        } else {
            setSelectedAssets(assetArrayfilter?.map((asset) => asset.id));
        }
        setSelectAll(!selectAll);
    };
    const handleCheckboxChange = (id) => {
        setSelectedAssets((prevSelected) => {
            const newSelectedAssets = prevSelected.includes(id)
                ? prevSelected.filter((selectedId) => selectedId !== id)
                : [...prevSelected, id];

            if (newSelectedAssets.length !== assetArrayfilter.length) {
                setSelectAll(false);
            } else if (newSelectedAssets.length === assetArrayfilter.length) {
                setSelectAll(true);
            }
            return newSelectedAssets;
        });
    }
    const addToList = () => {
        if (selectedAssets.length > 0) {
            const selectedData = assetArray.filter(asset =>
                selectedAssets.includes(asset.id)
            );
            setSelectAssetData(selectedData)
            setselectAllAssetPending(false);
            setSelectedAssetsPending([]);
            setIsAddtoList(!IsAddtoList)
        } else {
            toast.error("Please select the asset to Add.");
        }

    }
    const removeAsset = () => {
        if (SelectedAssetsPending.length !== SelectAssetData.length) {
            if (SelectedAssetsPending.length > 0) {
                // if (SelectAssetData.length > 1) {
                const selectedData = SelectAssetData.filter(asset =>
                    !SelectedAssetsPending.includes(asset.id)
                );
                setSelectAssetData(selectedData)
                setselectAllAssetPending(false);
                setSelectedAssetsPending([]);
                // } else {
                //     toast.warning("Asset Data must be at least 1.");
                // }
            } else {
                toast.error("Please select the asset to Remove.");
            }

        } else {
            toast.warning("Asset Data must be at least 1.");
        }

    }
    const backAsset = () => {
        setIsAddtoList(!IsAddtoList)
    }
    const handleSelectAllAssetPendingChange = () => {
        if (selectAllAssetPending) {
            setSelectedAssetsPending([]);
        } else {
            setSelectedAssetsPending(SelectAssetData?.map((asset) => asset.id));
        }
        setselectAllAssetPending(!selectAllAssetPending);
    };
    const handleCheckboxAssetPendingChange = (id) => {
        setSelectedAssetsPending((prevSelected) => {
            const newSelectedAssets = prevSelected.includes(id)
                ? prevSelected.filter((selectedId) => selectedId !== id)
                : [...prevSelected, id];

            if (newSelectedAssets.length !== SelectAssetData.length) {
                setselectAllAssetPending(false);
            } else if (newSelectedAssets.length === SelectAssetData.length) {
                setselectAllAssetPending(true);
            }
            return newSelectedAssets;
        });
    }
    const onSubmitCheckOut = async (inputFields) => {
        setIsLoading(true);
        const { checkOutDate, employee, unit, dueDate, assignTo, unitId, branchId, departmentId, checkOutNotes, sendEmail, sendEmailCheck } = inputFields;
        for (let i = 0; i < SelectAssetData.length; i++) {
            const idValue = SelectAssetData[i].id;
            const payload = {
                id: 0,
                checkOutDate: checkOutDate,
                isEmployee: employee,
                isUnit: unit,
                dueDate: dueDate,
                assignTo: assignTo,
                unitId: unitId,
                branchId: branchId,
                departmentId: departmentId,
                checkOutNotes: checkOutNotes,
                sendEmail: sendEmail,
                isSendEmailCheck: sendEmailCheck,
                assetID: parseInt(idValue),
            };
            const response = await createCheckOut(payload);

            if (response === "Inserted") {
                toast.success("CheckOut data Inserted Successfully");
            } else {
                toast.error(response);
                setIsLoading(false);
            }
            setIsLoading(false);
            setTimeout(() => {
                resetCheckOut();
                window.location.reload();
            }, 2000);

        }


    }
    const onCancel = () => {
        resetCheckOut();
        window.location.reload();
    };
    const ClickselectAsset = () => {
        if (SelectAssetData.length > 0) {
            const idArray = SelectAssetData.map(item => item.id);
            const selectedData = assetArray.filter(asset => !idArray.includes(asset.id));
            setSearchQueryAsset('');
            setselectAlladdAsset(false);
            setSelectedAddasset([]);
            setaddAssetList(selectedData);
            setisCheckOut(true);
        } else {
            toast.error("Please select the asset to Add.");
        }
    }
    const handleSelectAllAddAssetChange = () => {
        if (selectAlladdAsset) {
            setSelectedAddasset([]);
        } else {
            setSelectedAddasset(addAssetList?.map((asset) => asset.id));
        }
        setselectAlladdAsset(!selectAlladdAsset);
    };
    const handleCheckboxAddAssetChange = (id) => {
        setSelectedAddasset((prevSelected) => {
            const newSelectedAssets = prevSelected.includes(id)
                ? prevSelected.filter((selectedId) => selectedId !== id)
                : [...prevSelected, id];

            if (newSelectedAssets.length !== addAssetList.length) {
                setselectAlladdAsset(false);
            } else if (newSelectedAssets.length === addAssetList.length) {
                setselectAlladdAsset(true);
            }
            return newSelectedAssets;
        });
    }
    const addAssetPopUp = () => {
        if (SelectedAddasset.length > 0) {
            const selectedData = assetArray.filter(asset =>
                SelectedAddasset.includes(asset.id)
            );
            const updatedSelectAssetData = [...SelectAssetData, ...selectedData];
            setSelectAssetData(updatedSelectAssetData);
            setselectAlladdAsset(false);
            setSelectedAddasset([]);
            setisCheckOut(false);
        } else {
            toast.error("Please select the asset to Add.");
        }
    }
    const handleClose = () => {
        setisCheckOut(false)
    }
    const handleSendmailCheckBox = (e) => {
        if (e.target.checked) {
            setIsSendMail(true);
        } else {
            setIsSendMail(false);
        }
    }
    const isEmployeeChecked = watchCheckOut('employee');
    const isUnitChecked = watchCheckOut('unit');
    return (
        <div style={{ height: "100vh", width: "100%", backgroundColor: '#FAFAFB' }}>
            <ToastContainer theme="colored" />
            {isLoading && <LoadingSpinner />}
            {!isLoading && (

                <div>
                    <div className="pagetitleCategory">
                        <div className="Pageheader fontFamily fontsize24 fontweightSemiBold">Check Out<div><InfoOutlinedIcon /></div></div>
                    </div>
                    {IsAddtoList === false ? (
                        <div className="card mt-4 customScrollbar">
                            <div className="card-body-category">
                                <div className="blackTextColor fontFamily fontsize16 fontweightRegular mLeft20 mTop20 mBottom20">
                                    Easily check out multiple assets at once with our bulk check-out feature.
                                </div>

                                <div className="pLeft30 pRight20">
                                    {/* <div className="blackTextColor fontFamily fontsize16 fontweightRegular mTop20 mBottom20">
                                   Search Fields
                               </div>
                               <input
                                   type="text"
                                   placeholder="Asset ID, Brand, Description, Model, Purchased from etc..."
                                   value={searchQueryAsset}
                                   onChange={handleSearchChangeAsset}
                                   className="searchTextBoxCustom"
                               /> */}

                                    <div className="row" style={{ paddingTop: "24px" }}>
                                        <div className="col-6 col-md-6 col-lg-4 col-sm-12">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                                Date
                                            </div>
                                            <div className="d-flex">
                                                <Controller
                                                    name="date"
                                                    control={control}
                                                    rules={{
                                                        validate: {
                                                            validDate: (value) => {
                                                                // If the value is empty, it's valid because the field is optional
                                                                if (!value) {
                                                                    return true;
                                                                }

                                                                // Check if the value is a valid date object
                                                                const dateValue = new Date(value);
                                                                if (isNaN(dateValue.getTime())) {
                                                                    return "Invalid date value";
                                                                }

                                                                // Format the date and validate it
                                                                const formattedDate = format(dateValue, dateFormat);
                                                                const [day, month, year] = formattedDate.split('/');
                                                                const selectedDate = validateDate(dateFormat, day, month, year);

                                                                // Get today's date for comparison
                                                                const today = new Date();
                                                                today.setHours(0, 0, 0, 0);

                                                                // Check if selected date is in the future
                                                                if (selectedDate > today) {
                                                                    return "Date cannot be in the future";
                                                                }

                                                                // Validate date format using regex
                                                                const regex = getRegexForDateFormat(dateFormat);
                                                                if (!regex.test(formattedDate)) {
                                                                    return "Invalid date format (" + dateFormat + ")";
                                                                }
                                                                return true;
                                                            },
                                                        },
                                                    }}
                                                    render={({ field }) => (
                                                        <DatePicker
                                                            {...field}
                                                            selected={field.value}
                                                            onChange={(date) => { field.onChange(date); }}
                                                            dateFormat={dateFormat}
                                                            className="customDatePicker date-picker-input"
                                                            ref={(input) => field.ref(input?.inputElement)}
                                                        />
                                                    )}
                                                />
                                                <img src={CalendarWithBG} alt="CalendarWithBG" height={45} />
                                            </div>
                                            {errors.date && (
                                                <div className="invalid-feedback" style={{ display: "block" }}>
                                                    {errors.date.message}
                                                </div>
                                            )}
                                        </div>

                                        <div className="col-6 col-md-6 col-lg-4 col-sm-12">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" >
                                                Unit
                                            </div>
                                            <Controller
                                                name="unitId"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        value={unitArray.find((c) => c.bU_ID === field.value) || null}
                                                        onChange={(selectedOption) => {
                                                            field.onChange(selectedOption ? selectedOption.bU_ID : null);
                                                        }}
                                                        options={unitArray}
                                                        getOptionLabel={(option) => option.bU_NAME}
                                                        getOptionValue={(option) => option.bU_NAME}
                                                        placeholder=""
                                                        isClearable
                                                        isSearchable
                                                        styles={customStyles}
                                                    />
                                                )}
                                            />
                                            {errors.unitId && (
                                                <div className="invalid-feedback" style={{ display: "block" }}>
                                                    {errors.unitId.message}
                                                </div>
                                            )}
                                        </div>

                                        <div className="col-6 col-md-6 col-lg-4 col-sm-12">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" >
                                                Branch
                                            </div>
                                            <Controller
                                                name="branchId"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        value={branchArray.find((c) => c.bR_ID === field.value) || null}
                                                        onChange={(selectedOption) => {
                                                            field.onChange(selectedOption ? selectedOption.bR_ID : null);
                                                        }}
                                                        options={branchArray}
                                                        getOptionLabel={(option) => option.bR_NAME}
                                                        getOptionValue={(option) => option.bR_NAME}
                                                        placeholder=""
                                                        isClearable
                                                        isSearchable
                                                        styles={customStyles}
                                                    />
                                                )}
                                            />
                                            {errors.branchId && (
                                                <div className="invalid-feedback" style={{ display: "block" }}>
                                                    {errors.branchId.message}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="row" style={{ paddingTop: "24px" }}>
                                        <div className="col-6 col-md-6 col-lg-4 col-sm-12">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" >
                                                Category
                                            </div>
                                            <Controller
                                                name="categoryId"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        value={categoryArray.find(option => option.id === field.value) || null}
                                                        onChange={(selectedOption) => {
                                                            field.onChange(selectedOption ? selectedOption.id : null);
                                                        }}
                                                        options={categoryArray}
                                                        placeholder=""
                                                        getOptionLabel={(option) => option.categoryName}
                                                        getOptionValue={(option) => option.categoryName}
                                                        isClearable
                                                        isSearchable
                                                        styles={customStyles}
                                                    />
                                                )}
                                            />
                                            {errors.categoryId && (
                                                <div className="invalid-feedback" style={{ display: "block" }}>
                                                    {errors.categoryId.message}
                                                </div>
                                            )}
                                        </div>

                                        <div className="col-6 col-md-6 col-lg-4 col-sm-12">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" >
                                                Sub Category
                                            </div>
                                            <Controller
                                                name="subCategoryId"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        value={subCategoryArray.find(option => option.id === field.value) || null}
                                                        onChange={(selectedOption) => {
                                                            field.onChange(selectedOption ? selectedOption.id : null);
                                                        }}
                                                        options={subCategoryArray}
                                                        placeholder=""
                                                        getOptionLabel={(option) => option.subCategoryName}
                                                        getOptionValue={(option) => option.subCategoryName}
                                                        isClearable
                                                        isSearchable
                                                        styles={customStyles}
                                                    />
                                                )}
                                            />
                                            {errors.subCategoryId && (
                                                <div className="invalid-feedback" style={{ display: "block" }}>
                                                    {errors.subCategoryId.message}
                                                </div>
                                            )}
                                        </div>

                                        <div className="col-6 col-md-6 col-lg-4 col-sm-12">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" >
                                                Department
                                            </div>
                                            <Controller
                                                name="departmentId"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        value={departmentArray.find((c) => c.id === field.value) || null}
                                                        onChange={(selectedOption) => {
                                                            field.onChange(selectedOption ? selectedOption.id : null);
                                                        }}
                                                        options={departmentArray}
                                                        getOptionLabel={(option) => option.departmentName}
                                                        getOptionValue={(option) => option.departmentName}
                                                        placeholder=""
                                                        isClearable
                                                        isSearchable
                                                        styles={customStyles}
                                                    />
                                                )}
                                            />
                                            {errors.departmentId && (
                                                <div className="invalid-feedback" style={{ display: "block" }}>
                                                    {errors.departmentId.message}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="row" style={{ paddingTop: "24px" }}>
                                        <div className="col-6 col-md-6 col-lg-4 col-sm-12">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" >
                                                Employee
                                            </div>
                                            <Controller
                                                name="empId"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        value={employeeArray.find(emp => emp.id === field.value) || null}
                                                        onChange={(selectedOption) => {
                                                            field.onChange(selectedOption ? selectedOption.id : null);
                                                        }}
                                                        options={employeeArray}
                                                        getOptionLabel={(option) => option.EmployeeName}
                                                        getOptionValue={(option) => option.EmployeeName}
                                                        placeholder=""
                                                        isClearable
                                                        isSearchable
                                                        styles={customStyles}
                                                    />
                                                )}
                                            />
                                            {errors.empId && (
                                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                                    {errors.empId.message}
                                                </div>
                                            )}
                                        </div>

                                        <div className="col-6 col-md-6 col-lg-4 col-sm-12">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                                Status
                                            </div>
                                            <Controller
                                                name="status"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        value={assetStatusArray.find(status => status.id === field.value) || null}
                                                        onChange={(selectedOption) => {
                                                            field.onChange(selectedOption ? selectedOption.id : null);
                                                        }}
                                                        options={assetStatusArray}
                                                        getOptionLabel={(option) => option.labelAssignedValue}
                                                        getOptionValue={(option) => option.labelAssignedValue}
                                                        placeholder=""
                                                        isClearable
                                                        isSearchable
                                                        styles={customStyles}
                                                    />
                                                )}
                                            />
                                            {errors.status && (
                                                <div className="invalid-feedback" style={{ display: "block" }}>
                                                    {errors.status.message}
                                                </div>
                                            )}
                                        </div>

                                        <div className="col-6 col-md-6 col-lg-4 col-sm-12">
                                            <div className="d-flex align-items-center mTop35">
                                                <button
                                                    className="fontFamily fontweightRegular fontsize16 coloredButton"
                                                    style={{ marginLeft: "20px" }}
                                                    onClick={handleSubmit(handleSearchChange)}
                                                >
                                                    Search
                                                </button>
                                                <span>
                                                    <button
                                                        className="fontFamily fontweightRegular fontsize16 importBtn"
                                                        onClick={handleSearchClear}
                                                    >
                                                        Clear
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-between align-items-center" style={{ paddingBottom: "8px", paddingTop: "8px" }}>
                                        <div className="custom-container">
                                            <span className="blackTextColor fontFamily fontsize16 fontweightRegular">
                                                <span className="show">Show</span>
                                                <select className="custom-dropdown" value={rowsPerPageAsset} onChange={handleRowsPerPageChangeAsset} >
                                                    {paginateArray.map((n) => (
                                                        <option className="blackTextColor fontFamily fontsize14 fontweightRegular" key={n} value={n} >
                                                            {n}
                                                        </option>
                                                    ))}
                                                </select>
                                                <span className="blackTextColor entries fontFamily fontsize16 fontweightRegular">
                                                    Entries
                                                </span>
                                            </span>
                                        </div>

                                        <button
                                            type="button"
                                            className="fontFamily fontweightRegular fontsize16 coloredButtonAdd"
                                            style={{ height: '37px', width: '108px' }}
                                            onClick={addToList}
                                        >
                                            Add to List
                                        </button>
                                    </div>

                                </div>


                                <div className="CustomBoxShadow" style={{ margin: "20px" }}>
                                    <div className="tableScroll" style={{ height: '350px', overflowY: 'auto', scrollbarWidth: 'thin' }}>
                                        <table className="w-100 mt-0">
                                            <thead>
                                                <tr className="text-left">

                                                    <th className="blackTextColor fontFamily fontweightMedium fontsize16" style={{ backgroundColor: '#F6F6F6', width: "3%", padding: '2px 2px 2px 3%' }}>
                                                        <input
                                                            id="myCheckboxHead"
                                                            type="checkbox"
                                                            checked={selectAll}
                                                            onChange={handleSelectAllChange}
                                                        />
                                                    </th>
                                                    <th className="fontFamily fontweightMedium fontsize16" style={{ backgroundColor: '#F6F6F6', width: "15%", textAlign: 'center' }}>Asset ID</th>
                                                    <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "15%", textAlign: 'center' }}>Description</th>
                                                    <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "15%", textAlign: 'center' }}>Status</th>
                                                    <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "15%", textAlign: 'center' }}>Assigned to</th>
                                                    <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "15%", textAlign: 'center' }}>Branch</th>
                                                    <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "15%", textAlign: 'center' }}>Unit</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {assetArrayfilter.length > 0 ? (
                                                    assetArrayfilter.slice(pageAsset * rowsPerPageAsset, (pageAsset + 1) * rowsPerPageAsset).map((asset, index) => (
                                                        <tr className="text-left" key={index} style={{ height: '59px' }}>
                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ padding: "2px 2px 2px 3%" }}>
                                                                <input
                                                                    id={`checkbox-${asset.id}`}
                                                                    type="checkbox"
                                                                    checked={selectedAssets.includes(asset.id)}
                                                                    onChange={() => handleCheckboxChange(asset.id)}
                                                                />
                                                            </td>
                                                            <td className={`~grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center', color: '#109CF1' }}>{asset?.assetID}</td>
                                                            <td className={`~grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center', color: '#109CF1' }}>{asset?.assetName}</td>
                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{asset?.assetStatusValue}</td>
                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{asset?.employeeName}</td>
                                                            <td className={`~grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center', color: '#109CF1' }}>{asset?.branchName}</td>
                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{asset?.unitName}</td>
                                                        </tr>

                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="7" className="text-center fontFamily fontweightRegular fontsize16">
                                                            <img src={nodataFound} alt="no data found" />
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            {totalEntriesAsset > 0 && (
                                <div>
                                    <div className="" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingTop: "16px", }}>
                                        <div className="text-muted fontFamily fontsize12 fontweightRegular pLeft20">
                                            Showing {startEntryAsset} to {endEntryAsset} of {totalEntriesAsset} entries
                                        </div>
                                        <div>
                                            <div className="pagination-container d-flex align-items-center">
                                                <button className="paginationButton btn btn-link" onClick={handleFirstPageAsset} >
                                                    <FontAwesomeIcon icon={faAngleDoubleLeft} className="icon-color" />
                                                </button>
                                                <ReactPaginate
                                                    previousLabel={<FontAwesomeIcon icon={faChevronLeft} className="icon-color" />}
                                                    nextLabel={<FontAwesomeIcon icon={faChevronRight} className="icon-color" />}
                                                    breakLabel={"..."}
                                                    breakClassName={"break-me"}
                                                    pageCount={Math.ceil(totalEntriesAsset / rowsPerPageAsset)}
                                                    forcePage={pageAsset}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={5}
                                                    onPageChange={handleChangePagePaginationAsset}
                                                    containerClassName={"pagination"}
                                                    subContainerClassName={"pages pagination"}
                                                    activeClassName={"active"}
                                                    renderOnZeroPageCount={null}
                                                />
                                                <button className="paginationButton btn btn-link" onClick={handleLastPageAsset}>
                                                    <FontAwesomeIcon icon={faAngleDoubleRight} className="icon-color" />
                                                </button>
                                            </div>
                                        </div>
                                        <div style={{ padding: "0px 100px" }}></div>
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="card mt-4 customScrollbar">
                            <div className="card-body-category">
                                <div className="blackTextColor fontFamily fontsize16 fontweightRegular mLeft20 mTop20 mBottom20">
                                    Keep track of your assets within your organization and create an even more detailed history of them.
                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="blackTextColor fontFamily fontsize16 fontweightRegular mLeft20 mTop20 mBottom20">
                                        Asset pending Check-Out
                                    </div>
                                    <div className="d-flex align-items-center mRight20">
                                        <div>
                                            {/* <button
                                                type="button"
                                                className="fontFamily fontweightRegular fontsize16 coloredButtonAdd"
                                                style={{ height: '37px', width: 'auto' }}
                                                onClick={backAsset}
                                            >
                                                Back
                                            </button> */}
                                            <button
                                                type="button"
                                                className="fontFamily fontweightRegular fontsize16 coloredButtonAdd"
                                                style={{ height: '37px', width: '135px' }}
                                                onClick={removeAsset}
                                            >
                                                Remove Assets
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                type="button"
                                                className="fontFamily fontweightRegular fontsize16 coloredButtonAdd mLeft20"
                                                style={{ height: '37px', width: '128px' }}
                                                onClick={ClickselectAsset}
                                            >
                                                Select Assets
                                            </button>
                                        </div>
                                    </div>

                                </div>
                                <div className="CustomBoxShadow" style={{ margin: "20px" }}>
                                    <div className="tableScroll" style={{ height: 'auto', overflowY: 'auto', scrollbarWidth: 'thin' }}>
                                        <table className="w-100 mt-0">
                                            <thead>
                                                <tr className="text-left">

                                                    <th className="blackTextColor fontFamily fontweightMedium fontsize16" style={{ backgroundColor: '#F6F6F6', width: "3%", padding: '2px 2px 2px 3%' }}>
                                                        <input
                                                            id="myCheckboxHead"
                                                            type="checkbox"
                                                            checked={selectAllAssetPending}
                                                            onChange={handleSelectAllAssetPendingChange}
                                                        />
                                                    </th>
                                                    <th className="fontFamily fontweightMedium fontsize16" style={{ backgroundColor: '#F6F6F6', width: "15%", textAlign: 'center' }}>Asset ID</th>
                                                    <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "15%", textAlign: 'center' }}>Description</th>
                                                    <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "15%", textAlign: 'center' }}>Status</th>
                                                    <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "15%", textAlign: 'center' }}>Assigned to</th>
                                                    <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "15%", textAlign: 'center' }}>Branch</th>
                                                    <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "15%", textAlign: 'center' }}>Unit</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {SelectAssetData.length > 0 ? (
                                                    // SelectAssetData.slice(pageAsset * rowsPerPageAsset, (pageAsset + 1) * rowsPerPageAsset).map((asset, index) => (
                                                    SelectAssetData.map((asset, index) => (
                                                        <tr className="text-left" key={index} style={{ height: '59px' }}>
                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ padding: "2px 2px 2px 3%" }}>
                                                                <input
                                                                    id={`checkbox-${asset.id}`}
                                                                    type="checkbox"
                                                                    checked={SelectedAssetsPending.includes(asset.id)}
                                                                    onChange={() => handleCheckboxAssetPendingChange(asset.id)}
                                                                />
                                                            </td>
                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{asset?.assetID}</td>
                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{asset?.assetName}</td>
                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{asset?.assetStatusValue}</td>
                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{asset?.employeeName}</td>
                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{asset?.branchName}</td>
                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{asset?.unitName}</td>
                                                        </tr>

                                                    ))
                                                ) : (
                                                    <td colSpan="4" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="blackTextColor fontFamily fontsize16 fontweightRegular mTop20 textAlignRight" style={{ margin: '0% 2%' }}>
                                    Optionally change unit, branch and department of
                                </div>
                                <div style={{ margin: '0% 2%' }}>
                                    <form onSubmit={handleSubmitCheckOut(onSubmitCheckOut)}>
                                        <div className="row">
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                                    Check-out Date
                                                </div>
                                                <div className='position-relative'>
                                                    <Controller
                                                        name="checkOutDate"
                                                        control={controlCheckOut}
                                                        render={({ field }) => (
                                                            <DatePicker
                                                                {...field}
                                                                selected={field.value}
                                                                onChange={(date) => field.onChange(date)}
                                                                dateFormat={dateFormat}
                                                                placeholderText={dateFormat.toUpperCase()}
                                                                className="DatePicker"
                                                                ref={(input) => field.ref(input?.inputElement)}
                                                            />
                                                        )}
                                                    />
                                                    <img className="eyeContent" src={calendarsvgIcon} alt="calendar icon" />
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24 " >
                                                    Unit{isUnitChecked && (<span className="mandatoryCss"> *</span>)}
                                                </div>
                                                <Controller
                                                    name="unitId"
                                                    control={controlCheckOut}
                                                    rules={{
                                                        required: isUnitChecked ? 'Unit is required' : false,
                                                    }}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            value={unitArray.find(unit => unit.bU_ID === field.value) || null}
                                                            onChange={(selectedOption) => { field.onChange(selectedOption ? selectedOption.bU_ID : null); }}
                                                            options={unitArray}
                                                            getOptionLabel={(option) => option.bU_NAME}
                                                            getOptionValue={(option) => option.bU_ID}
                                                            placeholder=""
                                                            isClearable
                                                            isSearchable
                                                            styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                                        />
                                                    )}
                                                />
                                                {errorsCheckOut.unitId && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errorsCheckOut.unitId.message}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-6 col-md-6 col-lg-6">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                                    Check-Out Assets<span className="mandatoryCss"> *</span>
                                                </div>
                                                <div className='d-flex'>
                                                    <div className='d-flex align-items-center mTop12 mBottom10'>
                                                        <Controller
                                                            name="employee"
                                                            control={controlCheckOut}
                                                            rules={{
                                                                validate: (value) => value || watchCheckOut('unit') || "At least one asset must be selected",
                                                            }}
                                                            render={({ field }) => (
                                                                <>
                                                                    <input
                                                                        type="checkbox"
                                                                        {...field}
                                                                        checked={field.value}
                                                                        onChange={(e) => field.onChange(e.target.checked)}
                                                                    />
                                                                    <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight45'>
                                                                        Employee
                                                                    </div>
                                                                </>
                                                            )}
                                                        />
                                                    </div>
                                                    <div className='d-flex pLeft12 align-items-center mTop12 mBottom10'>
                                                        <Controller
                                                            name="unit"
                                                            control={controlCheckOut}
                                                            rules={{
                                                                validate: (value) => value || watchCheckOut('employee') || "At least one asset must be selected",
                                                            }}
                                                            render={({ field }) => (
                                                                <>
                                                                    <input
                                                                        type="checkbox"
                                                                        {...field}
                                                                        checked={field.value}
                                                                        onChange={(e) => field.onChange(e.target.checked)}
                                                                    />
                                                                    <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                        Unit
                                                                    </div>
                                                                </>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                {errorsCheckOut.employee && errorsCheckOut.unit && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errorsCheckOut.employee.message}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24" >
                                                    Branch
                                                </div>
                                                <Controller
                                                    name="branchId"
                                                    control={controlCheckOut}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            value={branchArray.find(branch => branch.bR_ID === field.value) || null}
                                                            onChange={(selectedOption) => { field.onChange(selectedOption ? selectedOption.bR_ID : null); }}
                                                            options={branchArray}
                                                            getOptionLabel={(option) => option.bR_NAME}
                                                            getOptionValue={(option) => option.bR_ID}
                                                            placeholder=""
                                                            isClearable
                                                            isSearchable
                                                            styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='col-6 col-md-6 col-lg-6'>
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24" >
                                                    Assign to{isEmployeeChecked && (<span className="mandatoryCss"> *</span>)}
                                                </div>
                                                <Controller
                                                    name="assignTo"
                                                    control={controlCheckOut}
                                                    rules={{
                                                        required: isEmployeeChecked ? 'Assign to is required' : false,
                                                    }}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            value={employeeArray.find(repo => repo.id === field.value) || null}
                                                            onChange={(selectedOption) => { field.onChange(selectedOption ? selectedOption.id : null); }}
                                                            options={employeeArray}
                                                            getOptionLabel={(option) => option.EmployeeName}
                                                            getOptionValue={(option) => option.id}
                                                            placeholder=""
                                                            isClearable
                                                            isSearchable
                                                            styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                                        />
                                                    )}
                                                />
                                                {errorsCheckOut.assignTo && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errorsCheckOut.assignTo.message}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24" >
                                                    Department
                                                </div>
                                                <Controller
                                                    name="departmentId"
                                                    control={controlCheckOut}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            value={departmentArray.find(dept => dept.id === field.value) || null}
                                                            onChange={(selectedOption) => { field.onChange(selectedOption ? selectedOption.id : null); }}
                                                            options={departmentArray}
                                                            getOptionLabel={(option) => option.departmentName}
                                                            getOptionValue={(option) => option.id}
                                                            placeholder=""
                                                            isClearable
                                                            isSearchable
                                                            styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='col-6 col-md-6 col-lg-6'>
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                                    Due Date
                                                </div>
                                                <div className='position-relative'>
                                                    <Controller
                                                        name="dueDate"
                                                        control={controlCheckOut}
                                                        render={({ field }) => (
                                                            <DatePicker
                                                                {...field}
                                                                selected={field.value}
                                                                onChange={(date) => field.onChange(date)}
                                                                dateFormat={dateFormat}
                                                                placeholderText={dateFormat.toUpperCase()}
                                                                className="DatePicker"
                                                                ref={(input) => field.ref(input?.inputElement)}
                                                            />
                                                        )}
                                                    />
                                                    <img className="eyeContent" src={calendarsvgIcon} alt="calendar icon" />
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">Check-out Notes</div>
                                                <textarea
                                                    placeholder=""
                                                    className="textareaCustom textAeraHeight"
                                                    {...registerCheckOut('checkOutNotes')}
                                                />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-6 col-md-6 col-lg-6 col-sm-12'>

                                            </div>
                                            <div className='col-6 col-md-6 col-lg-6 col-sm-12'>
                                                <div className='d-flex'>
                                                    <div className='d-flex align-items-center grayTextColor'>
                                                        <Controller
                                                            name="sendEmailCheck"
                                                            control={controlCheckOut}
                                                            render={({ field }) => (
                                                                <>
                                                                    <input
                                                                        type="checkbox"
                                                                        {...field}
                                                                        checked={field.value}
                                                                        onChange={(e) => {
                                                                            field.onChange(e.target.checked);
                                                                            handleSendmailCheckBox(e);
                                                                        }}
                                                                    />
                                                                    <div className='grayTextColor fontFamily fontsize16 fontweightRegular pLeft8'>
                                                                        Send Email{IsSendMail && (<span className="mandatoryCss"> *</span>)}
                                                                    </div>
                                                                </>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <input
                                                    className="textBoxNew"
                                                    {...registerCheckOut('sendEmail', {
                                                        required: IsSendMail ? 'Please Enter Email' : false,
                                                        pattern: {
                                                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                                            message: 'Please Enter a Valid Email Address'
                                                        }
                                                    })}
                                                />
                                                {errorsCheckOut.sendEmail && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errorsCheckOut.sendEmail.message}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="mb-2 text-center" style={{ marginTop: "3%" }}>

                                            <button type="submit" className="coloredButtonSave">Check-Out</button>

                                            <button type="button" className="outlineButton" onClick={onCancel} style={{ marginLeft: '18px' }} >Cancel</button>
                                        </div>
                                    </form>
                                </div>
                                <Modal show={isCheckOut} onHide={handleClose} centered size="xl" backdrop="static" keyboard={false}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            <div className="fontFamily fontweightMedium fontsize16 blackTextColor">
                                                Add Assets
                                            </div>
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div>
                                            <div className="CustomBoxShadow" style={{ margin: "20px" }}>
                                                <input
                                                    type="text"
                                                    placeholder="Search..."
                                                    className="searchTextBox"
                                                    value={searchQueryAsset}
                                                    onChange={handleSearchChangeAsset}
                                                    style={{ marginLeft: '0px', marginBottom: '7px' }}
                                                />
                                                <div className="tableScroll" style={{ height: '250px', overflowY: 'auto', scrollbarWidth: 'thin' }}>
                                                    <table className="w-100 mt-0">
                                                        <thead>
                                                            <tr className="text-left">
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16" style={{ backgroundColor: '#F6F6F6', width: "3%", padding: '2px 2px 2px 3%' }}>
                                                                    <input
                                                                        id="myCheckboxHead"
                                                                        type="checkbox"
                                                                        checked={selectAlladdAsset}
                                                                        onChange={handleSelectAllAddAssetChange}
                                                                    />
                                                                </th>
                                                                <th className="fontFamily fontweightMedium fontsize16" style={{ backgroundColor: '#F6F6F6', width: "15%", textAlign: 'center' }}>Asset ID</th>
                                                                <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "15%", textAlign: 'center' }}>Description</th>
                                                                <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "15%", textAlign: 'center' }}>Status</th>
                                                                <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "15%", textAlign: 'center' }}>Assigned to</th>
                                                                <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "15%", textAlign: 'center' }}>Branch</th>
                                                                <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "15%", textAlign: 'center' }}>Unit</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {addAssetList.length > 0 ? (
                                                                // SelectAssetData.slice(pageAsset * rowsPerPageAsset, (pageAsset + 1) * rowsPerPageAsset).map((asset, index) => (
                                                                addAssetList.map((asset, index) => (
                                                                    <tr className="text-left" key={index} style={{ height: '59px' }}>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ padding: "2px 2px 2px 3%" }}>
                                                                            <input
                                                                                id={`checkbox-${asset.id}`}
                                                                                type="checkbox"
                                                                                checked={SelectedAddasset.includes(asset.id)}
                                                                                onChange={() => handleCheckboxAddAssetChange(asset.id)}
                                                                            />
                                                                        </td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{asset?.assetID}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{asset?.assetName}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{asset?.assetStatusValue}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{asset?.employeeName}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{asset?.branchName}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{asset?.unitName}</td>
                                                                    </tr>

                                                                ))
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan="7" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                                                </tr>

                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="mb-2 text-center" style={{ marginTop: "3%" }}>
                                                <button type="button" className="outlineButton" onClick={handleClose} >Cancel</button>
                                                <button type="submit" className="coloredButtonSave" style={{ marginLeft: '18px' }} onClick={addAssetPopUp}>Add To List</button>
                                            </div>
                                        </div>

                                    </Modal.Body>
                                </Modal>
                            </div>
                        </div>
                    )}


                </div>
            )}
        </div>

    );
}
export default CheckOut;