import React, { useState, useEffect, useRef, useCallback } from "react";
import Select from "react-select";
import ApexCharts from 'react-apexcharts';
import DatePicker from "react-datepicker";
import ReactPaginate from "react-paginate";
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import Swal from 'sweetalert2';
import { format } from 'date-fns';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronLeft,
    faChevronRight,
    faAngleDoubleLeft,
    faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";

import "./AssetCreationCss.css";

import ConfirmDelete from "../../../CommomPages/ConfirmDelete";
import LoadingSpinner from "../../../CommomPages/LoadingSpinner";
import { getAllHistory, getDateFormat, getRegexForDateFormat, validateDate, getAssetPadding } from "../../../CommomPages/Config";
import { getSupplier } from "../../../Services/SupplierMasterService";
import { getDocument } from "../../../Services/GeneralSettingsService";
import { getEmployee } from "../../../Services/EmployeeManagementService";
import { getDepartment } from "../../../Services/DepartmentsAndTeamService";
import { getCategory, getSubCategory, getBrand } from "../../../Services/AssetCategoriesAndBrandService";
import { getBUData, getBRData } from "../../../Services/BusinessEntityNewService";
import { getTab, createCheckOut, createCheckIn, createLost, createDisposed, createBroken, createRepair, updateRepair, getRepair, createFound, createDonate, createSell, cloneAsset } from "../../../Services/ActionFormConfigurationService";
import { FoundSection, LostSection, BrokenSection, DisposeSection, CheckOutSection, CheckInSection, RepairSection, DonateSection, SellSection, AssetLinkSection, AssetDepreciationSection, AssetAddSection, CopySection, AssetAttachmentSection, AssetMaintenanceSection, AssetWarrantySection, AssetRequestSection, AssetInsuranceSection } from "../ActionFormPopup/ActionFormFormPopup";
import {
    getAsset,
    createAsset,
    updateAsset,
    getASTSettings,
    getAssetStatus,
    getAssetLink,
    createAssetLink,
    deleteAssetLink,
    getAssetAttachments,
    createAssetAttachment,
    deleteAssetAttachment,
    CreateAssetHistory,
    CreateFinancialTransaction
} from "../../../Services/AssetSettingsService";
import { createProFormMaintenance, getProFormMaintenance, updateProFormMaintenance, createProFormServiceRequest, getProFormServiceRequest, createProFormWarranty, updateProFormWarranty, getProFormWarranty, getProFormInsurance, updateProFormInsurance } from "../../../Services/ProcessFormConfigurationService";
import { getRequestSettings, getRequestCategory, getRequestSubCategory, getDefaultPriority, getRequestTeam } from "../../../Services/RequestService";

import LinkIcon from "../../../assets/icon/Link.svg";
import viewsvgIcon from "../../../assets/icon/Eye.svg";
import editsvgIcon from "../../../assets/icon/Edit.svg";
import redXmark from "../../../assets/icon/RedXmark.svg";
import printIcon from "../../../assets/icon/printer.svg";
import rupeesvgIcon from "../../../assets/icon/Rupee.svg";
import uploadImg from "../../../assets/img/uploadImage.png";
import deletesvgIcon from "../../../assets/icon/Delete.svg";
import unLink from "../../../assets/icon/UnLink.svg";
import pencilEdit from "../../../assets/icon/PencilEdit.svg";
import ExclamationIcon from "../../../assets/icon/ExclamationIcon.svg";
import nodataFound from "../../../assets/img/nodatafound.png";
import greenTickMark from "../../../assets/icon/GreenTickMark.svg";
import calendarsvgIcon from "../../../assets/icon/calendarDate.svg";
import datesvgIcon from "../../../assets/icon/CalendarWithBG.svg";


const customStyles = {
    placeholder: (provided) => ({
        ...provided,
        fontFamily: "Nunito, sans-serif",
        fontWeight: 400,
        fontSize: "14px",
        color: "#B0ACAC",
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 9999,
    }),
    // container: (provided) => ({
    //     ...provided,
    //     marginLeft: '10px',
    //     width: '275px'
    // }),
};


const AssetDetailsView = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const [tabActive, setTabActive] = useState('Details');
    const headerTabs = ['Details', 'Warranty', 'Maintenance', 'Insurance', 'Attachments', 'Request', 'Linking', 'History'];
    const [userName, setUserName] = useState('');
    const [assetNameAndID, setAssetNameAndID] = useState('');
    const [employeeName, setEmployeeName] = useState('UnSelected');
    const [employeeId, setEmployeeId] = useState(0);

    const [assetId, setAssetId] = useState(0);
    const [currentAssetData, setCurrentAssetData] = useState('');
    const [assetRepairData, setAssetRepairData] = useState('');
    const [selectedValue, setSelectedValue] = useState("");
    const [selectedLinkValue, setSelectedLinkValue] = useState("");
    const [depreciationValue, setDepreciationValue] = useState();

    const fileAssetInputRef = useRef(null);
    const [dateFormat, setDateFormat] = useState('');
    const [isAssetView, setIsAssetView] = useState(true);

    const paginateArrayHistory = [5, 10, 15];
    const [pageAssetHistory, setpageAssetHistory] = useState(0);
    const [rowsPerpageAssetHistory, setRowsPerpageAssetHistory] = useState(5);
    const [searchQueryAssetHistory, setSearchQueryAssetHistory] = useState("");

    const [assetArray, setAssetArray] = useState([]);
    const [assetArrayWithoutLinkedAsset, setAssetArrayWithoutLinkedAsset] = useState([]);
    const [supplierArray, setSupplierArray] = useState([]);
    const [employeeArray, setEmployeeArray] = useState([]);
    const [departmentArray, setDepartmentArray] = useState([]);
    const [categoryArray, setCategoryArray] = useState([]);
    const [subCategoryArray, setSubCategoryArray] = useState([]);
    const [brandArray, setBrandArray] = useState([]);
    const [unitArray, setUnitArray] = useState([]);
    const [branchArray, setBranchArray] = useState([]);
    const [finalDepreciationArray, setFinalDepreciationArray] = useState([]);
    const [assetHistoryArray, setAssetHistoryArray] = useState([]);

    const [currentAssetInsuranceArray, setCurrentAssetInsuranceArray] = useState([]);
    const [unlinkedInsuranceArray, setUnlinkedInsuranceArray] = useState([]);

    const [options, setOptions] = useState();
    const [series, setSeries] = useState();
    const [moreOptions, setMoreOptions] = useState([]);

    const [isInsuranceAdd, setIsInsuranceAdd] = useState(false);
    const [isCheckOutView, setIsCheckOutView] = useState(false);
    const [isCheckInView, setIsCheckInView] = useState(false);
    const [isLostView, setIsLostView] = useState(false);
    const [isFoundView, setIsFoundView] = useState(false);
    const [isBrokenView, setIsBrokenView] = useState(false);
    const [isRepairView, setIsRepairView] = useState(false);
    const [isDisposeView, setIsDisposeView] = useState(false);
    const [isDonateView, setIsDonateView] = useState(false);
    const [isSellView, setIsSellView] = useState(false);
    const [isLinkView, setIsLinkView] = useState(false);
    const [isDepreciationView, setIsDepreciationView] = useState(false);
    const [isCopyAssetView, setIsCopyAssetView] = useState(false);
    const [transactAsWhole, setTransactAsWhole] = useState(false);

    const depreciationMethodOptions = [
        { value: 'Declining Balance', label: 'Declining Balance' },
        { value: 'Straight line', label: 'Straight line' },
        { value: 'units of production', label: 'units of production' }
    ];

    const attachmentDropArray = [
        { id: 1, name: 'JPG' },
        { id: 2, name: 'GIF' },
        { id: 3, name: 'PNG' },
        { id: 4, name: 'PDF' },
        { id: 5, name: 'DOCX' },
    ];

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control,
        watch,
    } = useForm({
        defaultValues: {
            assetName: "",
            assetID: "",
            assetTagID: "",
            categoryId: 0,
            subCategoryId: 0,
            brandId: 0,
            modelNo: "",
            serialNo: "",
            cost: "",
            supplierId: 0,
            purchaseDate: null,
            unitId: 0,
            branchId: 0,
            departmentId: 0,
            empId: 0,
            assetImage: "",
            updateDateTime: null,
            updatedBy: "",
        },
    });


    const [assetAttachmentArray, setAssetAttachmentArray] = useState([]);
    const [isAttachmentView, setIsAttachmentView] = useState(false);
    const [isAttachmentAdd, setIsAttachmentAdd] = useState(false);
    const [attachmentCount, setAttachmentCount] = useState(0);
    const [attachmentLimmitCount, setAttachmentLimmitCount] = useState(0);
    const [attachmentLimmitStatus, setAttachmentLimmitStatus] = useState("");
    const [assetLinkArray, setAssetLinkArray] = useState([]);

    // Warranty
    const [assetWarrantyArray, setAssetWarrantyArray] = useState([]);
    const [selectedWarrantyValue, setSelectedWarrantyValue] = useState('');
    const [isWarrantyAdd, setIsWarrantyAdd] = useState(false);
    const [isWarrantyView, setIsWarrantyView] = useState(false);
    const [warrantyDocID, setWarrantyDocID] = useState(0);
    const [warrantyTagID, setWarrantyTagID] = useState('');

    //Maintenance
    const [IsAddMaintenance, setIsAddMaintenance] = useState(false);
    const [IsbtnAddMaintenance, setIsbtnAddMaintenance] = useState(false);
    const [IsMaintenance, setIsMaintenance] = useState(false);
    const [IsMaintenanceView, setIsMaintenanceView] = useState(false);
    const [IsMaintenanceEdit, setIsMaintenanceEdit] = useState(false);
    const [AssetMaintenanceArray, setAssetMaintenanceArray] = useState([]);
    const [SelectedMaintenanceValue, setSelectedMaintenanceValue] = useState('');
    const [MaintenanceStatusValue, setMaintenanceStatusValue] = useState('');

    //Request
    const [IsAddRequest, setIsAddRequest] = useState(false);
    const [IsRequest, setIsRequest] = useState(false);
    const [IsRequestView, setIsRequestView] = useState(false);
    const [IsRequestEdit, setIsRequestEdit] = useState(false);
    const [AssetRequestArray, setAssetRequestArray] = useState([]);
    const [SelectedRequestValue, setSelectedRequestValue] = useState('');


    const [AssetRequestCategoryDropdown, setAssetRequestCategoryDropdown] = useState([]);
    const [AssetRequestSubCategoryDropdown, setAssetRequestSubCategoryDropdown] = useState([]);
    const [AssetRequestPriorityDropdown, setAssetRequestPriorityDropdown] = useState([]);
    const [AssetRequestSettingsValue, setAssetRequestSettingsValue] = useState('');
    const [AssetRequestTeamValue, setAssetRequestTeamValue] = useState('');

    const {
        reset: resetAttachment,
    } = useForm({
        defaultValues: {
            documentType: null,
            documentLink: '',
            documents: '',
        }
    });

    const handleCancel = () => {
        setSelectedValue("");
        window.location.reload();
    };

    // Watch the assetImage value
    const assetImage = watch("assetImage");

    const getQueryParams = (query) => {
        return new URLSearchParams(query);
    };

    let localAssetDate = new Date();
    let offset = localAssetDate.getTimezoneOffset(); // Offset in minutes
    // Adjust for local time
    localAssetDate.setMinutes(localAssetDate.getMinutes() - offset);
    // Convert to ISO format without the 'Z'
    let localISODate = localAssetDate.toISOString().slice(0, -1);

    useEffect(() => {
        const user = localStorage.getItem('UserName');
        setUserName(user);
    }, []);

    useEffect(() => {
        setIsAssetView(true);
        const queryParams = getQueryParams(location.search);
        const idValue = queryParams.get('id');
        if (idValue !== "0") {
            const assetId = parseInt(idValue, 10);
            bindAssetValues(assetId);
            dataInitialization();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const formatDates = (dateString, dateCurrentFormat) => {
        const date = new Date(dateString);
        if (isNaN(date)) {
            return null;
        }
        return format(date, dateCurrentFormat);  // Converts to the desired format
    };

    const getWarrantyDocNo = async () => {
        try {
            const documentData = await getDocument();
            const maxId = documentData.find(doc => doc.id === 6);
            const newId = maxId.documentNo + 1;
            setWarrantyDocID(newId);

            const assetWarrantyPrefix = 'WI';
            const assetPadding = await getAssetPadding();
            const zeros = '0'.repeat(parseInt(assetPadding, 10));
            setWarrantyTagID(`${assetWarrantyPrefix.toUpperCase()}${zeros}${newId}`);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const bindAssetValues = async (assetId) => {
        try {
            setIsLoading(true);
            const dateCurrentFormat = await getDateFormat();
            const tabs = await getTab();
            setMoreOptions(tabs);

            const assetData = await getAsset();
            const linkData = await getAssetLink();
            const insuranceData = await getProFormInsurance();
            const requestSettings = await getRequestSettings();
            const requestCategoryData = await getRequestCategory();
            const requestSubCategoryData = await getRequestSubCategory();
            const DefaultPriority = await getDefaultPriority();
            const requestTeamData = await getRequestTeam();
            const activeRequestCategory = requestCategoryData.filter((e) => e.requestStatus == 'Active')
            const activeRequestSubCategory = requestSubCategoryData.filter((e) => e.requestStatus == 'Active')

            setAssetRequestSettingsValue(requestSettings);
            setAssetRequestCategoryDropdown(activeRequestCategory);
            setAssetRequestSubCategoryDropdown(activeRequestSubCategory);
            setAssetRequestPriorityDropdown(DefaultPriority)?.reverse();
            setAssetRequestTeamValue(requestTeamData);

            const currentAsset = assetData?.find(e => e.id === assetId);

            // Extract assetIds and assetRelationIds from linkData
            const linkedAssetIds = new Set(linkData?.map(link => link.assetId)?.filter(id => id !== null));
            const linkedRelationIds = new Set(linkData?.map(link => link.assetRelationId)?.filter(id => id !== null));

            // Filter assetData to exclude assets that are in linkData
            const withoutLinkedAsset = assetData?.filter(asset =>
                !linkedAssetIds?.has(asset.id) && !linkedRelationIds?.has(asset.id)
            );

            // Update state or perform further actions
            setAssetArrayWithoutLinkedAsset(withoutLinkedAsset);


            const assetStatus = await getAssetStatus();
            if (currentAsset) {
                console.log('currentAsset', currentAsset);
                setAssetId(assetId);
                const brandAsset = brandArray?.find(x => x.id === currentAsset?.brandId);
                const formattedDate = currentAsset?.purchaseDate ? format(new Date(currentAsset.purchaseDate), dateCurrentFormat) : null;
                const formattedDateAcquired = currentAsset?.dateAcquired ? format(new Date(currentAsset.dateAcquired), dateCurrentFormat) : null;
                const getAssetStatusValue = assetStatus?.find(e => e.id === currentAsset?.statusValue);

                const finalData = {
                    ...currentAsset,
                    formattedDateAcquired: formattedDateAcquired,
                    formatedPurchaseDate: formattedDate,
                    brandName: brandAsset ? brandAsset?.brandName : '',
                    assetStatusValue: getAssetStatusValue?.labelAssignedValue
                };
                setCurrentAssetData(finalData);

                // Depreciation Calculation
                if (currentAsset.depreciableAsset === "Yes") {
                    const dateString = currentAsset.dateAcquired;
                    const date = new Date(dateString);
                    const yearOfAsset = date.getFullYear();
                    const noOfYears = Math.round(parseInt(finalData.assetLife) / 12);
                    const depreciationCost = parseInt(currentAsset.depreciableCost);
                    const savageValue = parseInt(currentAsset.salvageValue);
                    const depreciableAmount = depreciationCost - savageValue;
                    const annualDepreciation = depreciableAmount / noOfYears;
                    const finalDepreciation = [];
                    const xaxisData = [];
                    const yaxisData = [];
                    for (let i = 0; i <= noOfYears; i++) {
                        let id = i;
                        let year = '';
                        let yearValue = 0;
                        let depreciationExpenst = '';
                        let accumlatedDepreciation = '';
                        let bookValue = '';
                        let xaxis = '';
                        let yaxis = '';
                        if (finalData.depreciationMethod === 'Straight line') {
                            if (id == 0) {
                                bookValue = Math.round(depreciationCost);
                                xaxis = 'COST';
                                yaxis = bookValue;

                            } else {
                                if (id == 1) {
                                    yearValue = yearOfAsset
                                    year = id + 'st' + '-' + yearValue;
                                    depreciationExpenst = Math.round(annualDepreciation);
                                    accumlatedDepreciation = Math.round(annualDepreciation * id);
                                    bookValue = Math.round(depreciationCost - accumlatedDepreciation);
                                    xaxis = 'FY ' + yearValue;
                                    yaxis = bookValue;
                                } else if (id == 2) {
                                    const yearForDepreciation = id - 1;
                                    yearValue = yearOfAsset + yearForDepreciation;
                                    year = id + 'nd' + '-' + yearValue;
                                    depreciationExpenst = Math.round(annualDepreciation);
                                    accumlatedDepreciation = Math.round(annualDepreciation * id);
                                    bookValue = Math.round(depreciationCost - accumlatedDepreciation);
                                    xaxis = 'FY ' + yearValue;
                                    yaxis = bookValue;
                                } else if (id == 3) {
                                    const yearForDepreciation = id - 1;
                                    yearValue = yearOfAsset + yearForDepreciation;
                                    year = id + 'rd' + '-' + yearValue;
                                    depreciationExpenst = Math.round(annualDepreciation);
                                    accumlatedDepreciation = Math.round(annualDepreciation * id);
                                    bookValue = Math.round(depreciationCost - accumlatedDepreciation);
                                    xaxis = 'FY ' + yearValue;
                                    yaxis = bookValue;
                                } else {
                                    const yearForDepreciation = id - 1;
                                    yearValue = yearOfAsset + yearForDepreciation;
                                    year = id + 'th' + '-' + yearValue;
                                    depreciationExpenst = Math.round(annualDepreciation);
                                    accumlatedDepreciation = Math.round(annualDepreciation * id);
                                    bookValue = Math.round(depreciationCost - accumlatedDepreciation);
                                    xaxis = 'FY ' + yearValue;
                                    yaxis = bookValue;
                                }
                            }
                        } else {

                        }

                        const array = {
                            id: id,
                            year: year,
                            yearValue: yearValue,
                            depreciationExpenst: depreciationExpenst,
                            accumlatedDepreciation: accumlatedDepreciation,
                            bookValue: bookValue
                        }
                        finalDepreciation.push(array);
                        xaxisData.push(xaxis);
                        yaxisData.push(yaxis);
                    }
                    setFinalDepreciationArray(finalDepreciation);
                    chartBind(xaxisData, yaxisData);
                }

                setValue("assetName", currentAsset.assetName);
                setValue("assetID", currentAsset.assetID);
                setValue("assetTagID", currentAsset.assetTagID);
                setValue("categoryId", currentAsset.categoryId);
                setValue("subCategoryId", currentAsset.subCategoryId);
                setValue("brandId", currentAsset.brandId);
                setValue("modelNo", currentAsset.modelNo);
                setValue("serialNo", currentAsset.serialNo);
                setValue("cost", currentAsset.cost);
                setValue("supplierId", currentAsset.supplierId);
                if (currentAsset.purchaseDate != null) {
                    setValue("purchaseDate", currentAsset.purchaseDate.split('T')[0]);
                } else {
                    setValue("purchaseDate", null);
                }

                setValue("status", currentAsset.status);
                setValue("unitId", currentAsset.unitId);
                setValue("branchId", currentAsset.branchId);
                setValue("departmentId", currentAsset.departmentId);
                setValue("empId", currentAsset.empId);
                setValue("assetImage", currentAsset.assetImage);
                setValue("updatedBy", currentAsset.updatedBy || currentAsset.createdBy);

                if (currentAsset.updateDateTime != null) {
                    const formattedDateTime = format(new Date(currentAsset.updateDateTime), dateCurrentFormat);
                    setValue("updateDateTime", formattedDateTime);
                } else if (currentAsset.createDateTime != null) {
                    const formattedDateTime = format(new Date(currentAsset.createDateTime), dateCurrentFormat);
                    setValue("updateDateTime", formattedDateTime);
                }

                const val = {
                    depreciableAsset: currentAsset?.depreciableAsset,
                    depreciableCost: currentAsset?.depreciableCost,
                    salvageValue: currentAsset?.salvageValue,
                    assetLife: currentAsset?.assetLife,
                    depreciationMethod: currentAsset?.depreciationMethod,
                    dateAcquired: currentAsset?.dateAcquired,
                }
                if (getAssetStatusValue?.labelValue === "CheckIn") {
                    const filteredTabs = tabs.filter(tab => !["Check In", "Found", "Repair Complete"].includes(tab.tabName));
                    setMoreOptions(filteredTabs);
                    console.log('if --->Check In', filteredTabs);
                }
                else if (getAssetStatusValue?.labelValue === "CheckOut") {
                    const filteredTabs = tabs.filter(tab => !["Check Out", "Repair"].includes(tab.tabName));
                    setMoreOptions(filteredTabs);
                    console.log('if --->Check Out', filteredTabs);
                }
                else if (getAssetStatusValue?.labelValue === "Lost") {
                    const filteredTabs = tabs.filter(tab => !["Check Out", "Lost", "Repair"].includes(tab.tabName));
                    setMoreOptions(filteredTabs);
                    console.log('else if --->Lost', filteredTabs);
                }
                else if (getAssetStatusValue?.labelValue === "Repair") {
                    const filteredTabs = tabs.filter(tab => !["Check Out", "Repair", "Lost"].includes(tab.tabName));
                    setMoreOptions(filteredTabs);
                    console.log('else if --->Repair', filteredTabs);
                }
                else {
                    const filteredTabs = tabs.filter(tab => !["Check Out", "Found", "Repair Complete"].includes(tab.tabName));
                    setMoreOptions(filteredTabs);
                    console.log('else --->with out Check Out, Found', filteredTabs);
                }
                setDepreciationValue(val);
                setAssetNameAndID(`${currentAsset.assetID} - ${currentAsset.assetName}`);
                setEmployeeId(currentAsset.empId);
                getEmployeeData(currentAsset.empId);
                getInsuranceData(assetId);
                getWarrantyData(assetId);
                getAttachmentData(assetId);
                getLinkData(assetId);
                getRepairData(assetId);
                getMaintenanceData(assetId);
                getRequestData(assetId, activeRequestCategory, activeRequestSubCategory, DefaultPriority);
                getHistoryData(assetId);
            } else {
                setCurrentAssetData('');
            }
        } catch (error) {
            console.error("Error fetching Asset data:", error);
        } finally {
            // setIsLoading(false);
        }
    };

    const getInsuranceData = async (assetId) => {
        const dateCurrentFormat = await getDateFormat();
        const insuranceData = await getProFormInsurance();

        // Filter out insurances where selectedAssetIds (as an array) does not contain the assetId
        const unlinkedInsurances = insuranceData.filter(insurance => {
            const selectedAssetIds = insurance.selectedAssetIds ? JSON.parse(insurance.selectedAssetIds) : [];

            // Ensure selectedAssetIds is a valid array
            if (!Array.isArray(selectedAssetIds) || selectedAssetIds.length === 0) {
                return true; // Keep the record if selectedAssetIds is invalid or empty, since it's "unlinked"
            }

            // Check if assetId is NOT included in the selectedAssetIds array
            return !selectedAssetIds.includes(assetId);
        });

        // Set the filtered data (unlinked insurances) to a new array state
        setUnlinkedInsuranceArray(unlinkedInsurances);

        // Filter insurances that contain the current assetId in selectedAssetIds (as an array)
        const currentInsurances = insuranceData.filter(insurance => {
            const selectedAssetIds = insurance.selectedAssetIds ? JSON.parse(insurance.selectedAssetIds) : [];

            // Ensure selectedAssetIds is a valid array
            if (!Array.isArray(selectedAssetIds) || selectedAssetIds.length === 0) {
                return false;  // Skip records with invalid or empty selectedAssetIds
            }

            // Check if assetId is included in the selectedAssetIds array
            return selectedAssetIds.includes(assetId);
        });

        if (currentInsurances.length > 0) {
            const finalData = currentInsurances.map((insurance, index) => {
                return {
                    ...insurance,
                    Rowid: index + 1,
                    formattedStartDate: formatDates(insurance?.insuranceStartDate, dateCurrentFormat) || '',
                    formattedEndDate: formatDates(insurance?.insuranceEndDate, dateCurrentFormat) || '',
                };
            });
            setCurrentAssetInsuranceArray(finalData.reverse());
        }
    };


    const getEmployeeData = async (empId) => {
        const empData = await getEmployee();
        const currentEmployee = empData.find(e => e.id === empId);
        setEmployeeName(currentEmployee ? `${currentEmployee?.firstName} ${currentEmployee?.lastName}(${currentEmployee?.employeeID})` : "UnSelected");

        const finalData = empData?.map((Emp, index) => {
            return {
                ...Emp,
                EmployeeName: Emp?.firstName + " " + Emp?.lastName,
            };
        });
        const sortedEmployeeData = finalData.sort((a, b) => a.EmployeeName.localeCompare(b.EmployeeName, undefined, { sensitivity: 'base' }));
        setEmployeeArray(sortedEmployeeData);
    };

    const getAttachmentData = async (assetId) => {
        const attachmentData = await getAssetAttachments();
        const settingData = await getASTSettings();
        console.log("settingData:", settingData);
        setAttachmentLimmitStatus(settingData[0]?.docMultiplePhotots)
        setAttachmentLimmitCount(parseInt(settingData[0]?.docMaximumNumberOfPhotos, 10));
        const currentAttachment = attachmentData?.filter(e => e.assetId === assetId);
        setAttachmentCount(currentAttachment?.length);
        const finalData = currentAttachment?.map((attach, index) => {
            return {
                ...attach,
                Rowid: index + 1,
                DocumentType: attachmentDropArray.find(x => x.id === attach?.assetDocumentType)?.name
            };
        });
        setAssetAttachmentArray(finalData);
    };

    const getLinkData = async (assetId) => {
        const linkData = await getAssetLink();
        const currentLink = linkData?.filter(e => e.assetId === assetId);
        if (currentLink.length > 0) {
            setTransactAsWhole(currentLink[0]?.transact);
            const finalData = currentLink?.map((link, index) => {
                return {
                    ...link,
                    Rowid: index + 1,
                };
            });
            setAssetLinkArray(finalData);
        }
    };

    const getWarrantyData = async (assetId) => {
        const dateCurrentFormat = await getDateFormat();
        const warrantyData = await getProFormWarranty();
        const currentWarranty = warrantyData?.filter(e => e.assetId === assetId);
        if (currentWarranty.length > 0) {
            const finalData = currentWarranty?.map((war, index) => {
                const newExpirationDate = new Date(war?.expirationDate);
                const currentDate = new Date();

                // Compare the expiration date with the current date
                const currentStatus = newExpirationDate > currentDate ? true : false;
                return {
                    ...war,
                    Rowid: index + 1,
                    formattedExpirationDate: formatDates(war?.expirationDate, dateCurrentFormat),
                    currentStatus,
                };
            });
            setAssetWarrantyArray(finalData);
        }
    };

    // Define dataInitialization as a regular async function
    const dataInitialization = useCallback(async () => {
        try {
            setIsLoading(true);
            await Promise.all([
                fetchAssetData(),
                getEmployeeData(0),
                getSupplierData(),
                getDepartmentData(),
                getUnitBranchDetails(),
                getCategoryAndBrandData(),
                getWarrantyDocNo(),
            ]);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchAssetData = async () => {
        try {
            const dateCurrentFormat = await getDateFormat();
            setDateFormat(dateCurrentFormat);
            const data = await getAsset();
            const assetStatus = await getAssetStatus();

            const finalData = data?.length > 0 ? data?.map((asset, index) => {
                const brandAsset = brandArray?.find(x => x.id === asset?.brandId);
                const formattedDate = asset.purchaseDate ? format(new Date(asset.purchaseDate), dateCurrentFormat) : null;
                const formattedDateAcquired = asset.dateAcquired ? format(new Date(asset.dateAcquired), dateCurrentFormat) : null;
                const getAssetStatusValue = assetStatus?.find(e => e.id === asset?.statusValue);

                return {
                    ...asset,
                    Rowid: index + 1,
                    brandName: brandAsset ? brandAsset?.brandName : '',
                    formatedPurchaseDate: formattedDate,
                    formattedDateAcquired: formattedDateAcquired,
                    assetStatusValue: getAssetStatusValue?.labelAssignedValue
                };
            }) : [];
            setAssetArray(finalData);
        } catch (error) {
            console.error("Error fetching Asset data:", error);
        }
    };

    const getUnitBranchDetails = async () => {
        const unitData = await getBUData();
        const sortedUnitData = unitData.sort((a, b) => a.bU_NAME.localeCompare(b.bU_NAME, undefined, { sensitivity: 'base' }));
        setUnitArray(sortedUnitData);

        const branchData = await getBRData();
        const sortedBranchData = branchData.sort((a, b) => a.bR_NAME.localeCompare(b.bR_NAME, undefined, { sensitivity: 'base' }));
        setBranchArray(sortedBranchData);
    };

    const getDepartmentData = async () => {
        const data = await getDepartment();
        setDepartmentArray(data);
    };

    const getSupplierData = async () => {
        const SupplierData = await getSupplier();
        setSupplierArray(SupplierData);
    };

    const getCategoryAndBrandData = async () => {
        const CategoryData = await getCategory();
        setCategoryArray(CategoryData);

        const SubCategoryData = await getSubCategory();
        setSubCategoryArray(SubCategoryData);

        const BrandData = await getBrand();
        setBrandArray(BrandData);
    };

    const getRepairData = async (assetId) => {
        const RepairData = await getRepair();
        const currentRepair = RepairData?.find(e => e.assetID === assetId && e.isRepair === true);
        setAssetRepairData(currentRepair);
    };

    const getHistoryData = async (assetId) => {
        const allHistoryData = await getAllHistory();
        console.log("allHistoryData", allHistoryData);
        const currentHistoryData = allHistoryData?.filter(e => e.assetId === assetId);
        setAssetHistoryArray(currentHistoryData.reverse());
    };

    const handleCloseAssetDetailsView = () => {
        setIsAssetView(true);
    };

    const handleAssetSubmit = async (data) => {
        const isUpdating = assetId !== 0;
        try {
            setIsLoading(true);
            const payload = {
                id: assetId || 0,
                assetName: data?.assetName || '',
                assetID: data?.assetID || '',
                assetTagID: data?.assetTagID || '',
                categoryId: data?.categoryId || 0,
                subCategoryId: data?.subCategoryId || 0,
                brandId: data?.brandId || 0,
                modelNo: data?.modelNo || '',
                serialNo: data?.serialNo || '',
                cost: data?.cost || '',
                supplierId: data?.supplierId || 0,
                purchaseDate: data?.purchaseDate ? data.purchaseDate : null,
                unitId: data?.unitId || 0,
                branchId: data?.branchId || 0,
                departmentId: data?.departmentId || 0,
                empId: data?.empId || 0,
                assetImage: data?.assetImage || '',
                depreciableAsset: data?.depreciableAsset || 'No',
                depreciableCost: data?.depreciableCost || '',
                assetLife: data?.assetLife || '',
                salvageValue: data?.salvageValue || '',
                depreciationMethod: data?.depreciationMethod || '',
                dateAcquired: data?.dateAcquired ? data.dateAcquired : null,
                status: data?.status || true,
                updatedBy: userName,
            };

            const response = isUpdating ? await updateAsset(payload) : await createAsset(payload);
            if (response === (isUpdating ? "Updated" : "Inserted")) {
                toast.success(`Asset ${isUpdating ? "Updated" : "Inserted"} Successfully`);

                if (currentAssetData) {
                    const changedFields = [];
                    // Compare each field in the payload with the corresponding field in currentAssetData
                    for (const key in payload) {
                        if (payload.hasOwnProperty(key) && currentAssetData[key] !== payload[key]) {
                            let changedFrom = currentAssetData[key] || '';
                            let changedTo = payload[key] || '';

                            // Check if the key is empId, unitId, branchId, or departmentId
                            if (key === 'empId') {
                                changedFrom = findEmployeeNameById(changedFrom, employeeArray);
                                changedTo = findEmployeeNameById(changedTo, employeeArray);
                            } else if (key === 'unitId') {
                                changedFrom = findUnitNameById(changedFrom, unitArray);
                                changedTo = findUnitNameById(changedTo, unitArray);
                            } else if (key === 'branchId') {
                                changedFrom = findBranchNameById(changedFrom, branchArray);
                                changedTo = findBranchNameById(changedTo, branchArray);
                            } else if (key === 'departmentId') {
                                changedFrom = findDepartmentNameById(changedFrom, departmentArray);
                                changedTo = findDepartmentNameById(changedTo, departmentArray);
                            } else if (key === 'categoryId') {
                                changedFrom = findCategoryNameById(changedFrom, categoryArray);
                                changedTo = findCategoryNameById(changedTo, categoryArray);
                            } else if (key === 'subCategoryId') {
                                changedFrom = findSubCategoryNameById(changedFrom, subCategoryArray);
                                changedTo = findSubCategoryNameById(changedTo, subCategoryArray);
                            } else if (key === 'brandId') {
                                changedFrom = findBrandNameById(changedFrom, brandArray);
                                changedTo = findBrandNameById(changedTo, brandArray);
                            } else if (key === 'supplierId') {
                                changedFrom = findSupplierNameById(changedFrom, supplierArray);
                                changedTo = findSupplierNameById(changedTo, supplierArray);
                            }

                            if (changedFrom !== changedTo) {
                                changedFields.push({
                                    assetField: key,
                                    assetChangedFrom: changedFrom,
                                    assetChangedTo: changedTo
                                });
                            }
                        }
                    }

                    // Loop through each changed field and create history
                    for (const change of changedFields) {
                        const historyPayload = {
                            id: 0,
                            assetId: assetId || 0,
                            assetDate: localISODate,
                            assetEvent: 'Update Asset',
                            assetField: change.assetField,
                            assetChangedFrom: String(change.assetChangedFrom),
                            assetChangedTo: String(change.assetChangedTo),
                            assetActionBy: userName || '',
                        };
                        await CreateAssetHistory(historyPayload);
                    }

                    if (payload.cost) {
                        const transactionPayload = {
                            id: 0,
                            ftAssetId: assetId || 0,
                            ftAssetCodeId: currentAssetData.assetID,
                            ftTransactionDate: localISODate,
                            ftDescription: 'Purchase of Asset',
                            ftTransaction: 'Purchase',
                            ftAmount: payload.cost,
                            ftPaymentType: 'Paid',
                        };
                        await CreateFinancialTransaction(transactionPayload);
                    }

                    if (payload.depreciableAsset === "Yes" && payload.depreciableCost) {
                        const transactionPayload = {
                            id: 0,
                            ftAssetId: assetId || 0,
                            ftAssetCodeId: currentAssetData.assetID,
                            ftTransactionDate: localISODate,
                            ftDescription: 'Depreciation of Asset',
                            ftTransaction: 'Depreciation',
                            ftAmount: payload.depreciableCost,
                            ftPaymentType: 'Paid',
                        };
                        await CreateFinancialTransaction(transactionPayload);
                    }
                    getHistoryData(assetId);
                };

                handleCloseAssetDetailsView();
                bindAssetValues(assetId);
            } else {
                toast.error(response);
            }
        } catch (error) {
            toast.error("Failed to save Asset");
        } finally {
            setIsLoading(false);
        }
    };

    const handleCheckboxChange = (event) => {
        setTransactAsWhole(event.target.checked);
    };

    const handleAssetPrintClick = () => {
        const contentToPrint = document.getElementById('printableContent');
        const styles = Array.from(document.querySelectorAll('style'))?.map(style => style.outerHTML).join('');
        const html = contentToPrint.outerHTML;

        const printWindow = window.open('', '', 'height=600,width=800');

        printWindow.document.write(`
            <html>
                <head>
                    <title>Print</title>
                    ${styles}
                </head>
                <body>
                    ${html}
                </body>
            </html>
        `);

        printWindow.document.close();

        printWindow.onload = () => {
            printWindow.focus();
            printWindow.print();
            printWindow.close();
        };
    };

    const handleAssetImportClick = () => {
        fileAssetInputRef.current.click();
    };

    const fileUpload = (event) => {
        event.preventDefault();
        document.getElementById("uploadImgID").click();
    };

    const handleLink = (link) => {
        setSelectedValue("Asset Link");
        setSelectedLinkValue(link);
    };

    const handleDepreciation = () => {
        setSelectedValue("Depreciation");
    };

    const handleAdd = () => {
        setSelectedValue("Add");
    };

    const handleChange = async (event) => {
        const value = event.target.value;

        const result = await Swal.fire({
            title: 'Are you sure you want to change the selection?',
            text: `This action will update your selection to "${value}".`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, change it',
            cancelButtonText: 'No, keep it'
        });

        if (result.isConfirmed) {
            setSelectedValue(value);
        }
    };

    const handleFileInputChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const validFileTypes = [
                "image/jpeg",
                "image/jpg",
                "image/png",
                "image/gif",
            ];
            if (validFileTypes.includes(file.type)) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    setValue("assetImage", reader.result);
                };
                reader.readAsDataURL(file);
            } else {
                alert("Only JPG, GIF, and PNG formats are allowed.");
                // Clear the file input if the file type is invalid
                e.target.value = null;
            }
        } else {
            return
        }
    };

    const clearImage = (event) => {
        event.preventDefault();
        setValue("assetImage", "");
    };

    // Asset Section Search and Pagination
    const handleSearchChangeAssetHistory = (event) => {
        setSearchQueryAssetHistory(event.target.value);
        setpageAssetHistory(0);
    };

    const filteredAssetHistory = assetHistoryArray?.filter(
        (assetHistory) =>
            assetHistory &&
            (
                assetHistory?.assetDate?.toLowerCase()?.includes(searchQueryAssetHistory?.toLowerCase()) ||
                assetHistory?.assetEvent?.toLowerCase()?.includes(searchQueryAssetHistory?.toLowerCase()) ||
                assetHistory?.assetField?.toLowerCase()?.includes(searchQueryAssetHistory?.toLowerCase()) ||
                assetHistory?.assetChangedFrom?.toLowerCase()?.includes(searchQueryAssetHistory?.toLowerCase()) ||
                assetHistory?.assetChangedTo?.toLowerCase()?.includes(searchQueryAssetHistory?.toLowerCase()) ||
                assetHistory?.assetActionBy?.toLowerCase()?.includes(searchQueryAssetHistory?.toLowerCase())
            )
    );

    const handleRowsPerPageChangeAsset = (event) => {
        setRowsPerpageAssetHistory(parseInt(event.target.value));
        setpageAssetHistory(0);
    };

    const handleFirstpageAssetHistory = () => {
        handleChangePagePaginationAssetHistory({ selected: 0 });
    };

    const handleChangePagePaginationAssetHistory = ({ selected }) => {
        setpageAssetHistory(selected);
    };

    const handleLastpageAssetHistory = () => {
        handleChangePagePaginationAssetHistory({
            selected: Math.ceil(assetHistoryArray?.length / rowsPerpageAssetHistory) - 1,
        });
    };

    const totalEntriesAssetHistory = filteredAssetHistory?.length;
    const startEntryAssetHistory = pageAssetHistory * rowsPerpageAssetHistory + 1;
    const endEntryAssetHistory = Math.min((pageAssetHistory + 1) * rowsPerpageAssetHistory, totalEntriesAssetHistory);


    const chartBind = (xAxisValue, yAxisValue) => {
        const option = {
            chart: {
                id: 'area-chart',
                toolbar: {
                    show: false,
                    tools: {
                        zoom: false,
                        zoomin: false,
                        zoomout: false,
                        pan: false,
                    }
                }
            },
            xaxis: {
                categories: xAxisValue
            },
            yaxis: {
                reverse: true,
                title: {
                    text: ''
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            tooltip: {
                enabled: true
            }
        }
        const series = {
            name: "",
            data: yAxisValue
        }
        setSeries([series]);
        setOptions(option);
    }

    const handleAttachmentUpload = () => {
        if (attachmentLimmitStatus === "Yes" && attachmentCount < attachmentLimmitCount) {
            setIsAttachmentAdd(true);
        } else {
            toast.error("Attachment limit reached or exceeded.");
        }
    };



    const onCancel = () => {
        resetAttachment();
        setIsAttachmentView(false);
        setIsAttachmentAdd(false);
    };

    const onSubmitAttachments = async (data) => {
        setIsLoading(true);
        const payload = {
            id: 0,
            assetId: assetId,
            assetDocumentType: data.documentType,
            assetDocumentLink: data.documentLink,
            assetDocuments: data.documents,
        };

        const response = await createAssetAttachment(payload);
        if (response === "Inserted") {
            const historyPayload = {
                id: 0,
                assetId: assetId || 0,
                assetDate: localISODate,
                assetEvent: "Attachment Insert",
                assetField: "Attachment",
                assetChangedFrom: "",
                assetChangedTo: payload.assetDocumentLink,
                assetActionBy: userName || '',
            };

            await CreateAssetHistory(historyPayload);
            setIsAttachmentAdd(false);
            toast.success("Asset Attachment Inserted Successfully");
            getAttachmentData(assetId);
            getHistoryData(assetId);
            window.location.reload();
        } else {
            setIsAttachmentAdd(false);
            toast.error(response);
        }
        setIsLoading(false);
    };

    const handleDeleteAttachment = async (Attach) => {
        try {
            const isConfirmed = await ConfirmDelete();
            if (isConfirmed) {
                setIsLoading(true);
                const response = await deleteAssetAttachment(Attach.id);
                if (response === "Deleted") {
                    toast.success("Asset Attachment Deleted Successfully");
                    const historyPayload = {
                        id: 0,
                        assetId: assetId || 0,
                        assetDate: localISODate,
                        assetEvent: "Attachment Delete",
                        assetField: "Attachment",
                        assetChangedFrom: Attach.assetDocumentLink,
                        assetChangedTo: "",
                        assetActionBy: userName || '',
                    };

                    await CreateAssetHistory(historyPayload);
                    getAttachmentData(assetId);
                    getHistoryData(assetId);
                    window.location.reload();
                } else {
                    toast.error(response);
                }
                setIsLoading(false);
            }
        } catch (error) {
            console.error("Error deleting asset:", error);
            toast.error(`An error occurred while deleting the asset.`);
        } finally {
            setIsLoading(false);
        }
    };

    const findEmployeeNameById = (id, array) => {
        const record = array?.find(item => item.id === id);
        return record ? String(record.EmployeeName) : '';
    };

    const findUnitNameById = (id, array) => {
        const record = array?.find(item => item.bU_ID === id);
        return record ? String(record.bU_NAME) : '';
    };

    const findBranchNameById = (id, array) => {
        const record = array?.find(item => item.bR_ID === id);
        return record ? String(record.bR_NAME) : '';
    };

    const findDepartmentNameById = (id, array) => {
        const record = array?.find(item => item.id === id);
        return record ? String(record.departmentName) : '';
    };

    const findCategoryNameById = (id, array) => {
        const record = array?.find(item => item.id === id);
        return record ? String(record.categoryName) : '';
    };

    const findSubCategoryNameById = (id, array) => {
        const record = array?.find(item => item.id === id);
        return record ? String(record.subCategoryName) : '';
    };

    const findBrandNameById = (id, array) => {
        const record = array?.find(item => item.id === id);
        return record ? String(record.brandName) : '';
    };

    const findSupplierNameById = (id, array) => {
        const record = array?.find(item => item.id === id);
        return record ? String(record.supplierName) : '';
    };

    //CheckOut
    const onSubmitCheckOut = async (dataCheckOut) => {
        setIsLoading(true);
        const queryParams = getQueryParams(location.search);
        const idValue = queryParams.get('id');
        const { checkOutDate, employee, unit, dueDate, assignTo, unitId, branchId, departmentId, checkOutNotes, sendEmail, sendEmailCheck } = dataCheckOut;
        const payload = {
            id: 0,
            checkOutDate: checkOutDate,
            isEmployee: employee,
            isUnit: unit,
            dueDate: dueDate,
            assignTo: assignTo,
            unitId: unitId,
            branchId: branchId,
            departmentId: departmentId,
            checkOutNotes: checkOutNotes,
            sendEmail: sendEmail,
            isSendEmailCheck: sendEmailCheck,
            assetID: parseInt(idValue),
        };

        const response = await createCheckOut(payload);
        const newPayload = {
            ...payload,
            empId: payload.assignTo,
        }
        if (response === "Inserted") {
            if (currentAssetData) {
                const changedFields = [];
                for (const key in newPayload) {
                    if (newPayload.hasOwnProperty(key) && currentAssetData[key] !== newPayload[key] && key !== 'id' && key !== 'assetID' && key !== 'checkOutDate' && key !== 'isUnit' && key !== 'dueDate' && key !== 'checkOutNotes' && key !== 'sendEmail' && key !== 'isSendEmailCheck') {
                        let changedFrom = currentAssetData[key] || '';
                        let changedTo = newPayload[key] || '';

                        // Check if the key is empId, unitId, branchId, or departmentId
                        if (key === 'empId') {
                            changedFrom = findEmployeeNameById(changedFrom, employeeArray);
                            changedTo = findEmployeeNameById(changedTo, employeeArray);
                        } else if (key === 'unitId') {
                            changedFrom = findUnitNameById(changedFrom, unitArray);
                            changedTo = findUnitNameById(changedTo, unitArray);
                        } else if (key === 'branchId') {
                            changedFrom = findBranchNameById(changedFrom, branchArray);
                            changedTo = findBranchNameById(changedTo, branchArray);
                        } else if (key === 'departmentId') {
                            changedFrom = findDepartmentNameById(changedFrom, departmentArray);
                            changedTo = findDepartmentNameById(changedTo, departmentArray);
                        }

                        if (changedFrom !== changedTo) {
                            changedFields.push({
                                assetField: key,
                                assetChangedFrom: changedFrom,
                                assetChangedTo: changedTo
                            });
                        }
                    }
                }

                // Loop through each changed field and create history
                for (const change of changedFields) {
                    const historyPayload = {
                        id: 0,
                        assetId: assetId || 0,
                        assetDate: localISODate,
                        assetEvent: 'Check Out',
                        assetField: change.assetField,
                        assetChangedFrom: String(change.assetChangedFrom),
                        assetChangedTo: String(change.assetChangedTo),
                        assetActionBy: userName || '',
                    };
                    await CreateAssetHistory(historyPayload);
                    getHistoryData(assetId);
                }
            }
            toast.success("CheckOut data Inserted Successfully");
        } else {
            toast.error(response);
        }
        window.location.reload();
        setIsLoading(false);
    };

    //CheckIn
    const onSubmitCheckIn = async (dataCheckIn) => {
        setIsLoading(true);
        const queryParams = getQueryParams(location.search);
        const idValue = queryParams.get('id');
        const { returnDate, employee, unit, unitId, branchId, departmentId, checkInNotes, sendEmail, sendEmailCheck } = dataCheckIn;
        const payload = {
            id: 0,
            returnDate: returnDate,
            isEmployee: employee,
            isUnit: unit,
            unitId: unitId,
            branchId: branchId,
            departmentId: departmentId,
            checkInNotes: checkInNotes,
            sendEmail: sendEmail,
            isSendEmailCheck: sendEmailCheck,
            assetID: parseInt(idValue),
        };

        const response = await createCheckIn(payload);
        if (response === "Inserted") {
            if (currentAssetData) {
                const changedFields = [];
                for (const key in payload) {
                    if (payload.hasOwnProperty(key) && currentAssetData[key] !== payload[key] && key !== 'id' && key !== 'assetID' && key !== 'returnDate' && key !== 'isUnit' && key !== 'isEmployee' && key !== 'checkInNotes' && key !== 'sendEmail' && key !== 'isSendEmailCheck') {
                        let changedFrom = currentAssetData[key] || '';
                        let changedTo = payload[key] || '';

                        // Check if the key is empId, unitId, branchId, or departmentId
                        if (key === 'unitId') {
                            changedFrom = findUnitNameById(changedFrom, unitArray);
                            changedTo = findUnitNameById(changedTo, unitArray);
                        } else if (key === 'branchId') {
                            changedFrom = findBranchNameById(changedFrom, branchArray);
                            changedTo = findBranchNameById(changedTo, branchArray);
                        } else if (key === 'departmentId') {
                            changedFrom = findDepartmentNameById(changedFrom, departmentArray);
                            changedTo = findDepartmentNameById(changedTo, departmentArray);
                        }

                        if (changedFrom !== changedTo) {
                            changedFields.push({
                                assetField: key,
                                assetChangedFrom: changedFrom,
                                assetChangedTo: changedTo
                            });
                        }
                    }
                }

                // Loop through each changed field and create history
                for (const change of changedFields) {
                    const historyPayload = {
                        id: 0,
                        assetId: assetId || 0,
                        assetDate: localISODate,
                        assetEvent: 'Check In',
                        assetField: change.assetField,
                        assetChangedFrom: String(change.assetChangedFrom),
                        assetChangedTo: String(change.assetChangedTo),
                        assetActionBy: userName || '',
                    };
                    await CreateAssetHistory(historyPayload);
                    getHistoryData(assetId);
                }
            }
            toast.success("CheckIn data Inserted Successfully");
        } else {
            toast.error(response);
        }
        window.location.reload();
        setIsLoading(false);
    };

    //Lost
    const onSubmitLost = async (dataLost) => {
        setIsLoading(true);
        const queryParams = getQueryParams(location.search);
        const idValue = queryParams.get('id');
        const { dateLost, notes } = dataLost;
        const payload = {
            id: 0,
            dateLost: dateLost,
            notes: notes,
            assetID: parseInt(idValue),
        };

        const response = await createLost(payload);
        if (response === "Inserted") {
            const historyPayload = {
                id: 0,
                assetId: assetId || 0,
                assetDate: localISODate,
                assetEvent: "Lost",
                assetField: "Status",
                assetChangedFrom: currentAssetData?.assetStatusValue,
                assetChangedTo: "Lost",
                assetActionBy: userName || '',
            };
            await CreateAssetHistory(historyPayload);
            toast.success("Lost data Inserted Successfully");
            getHistoryData(assetId);
        } else {
            toast.error(response);
        }
        window.location.reload();
        setIsLoading(false);
    };

    //Found
    const onSubmitFound = async (dataFound) => {
        setIsLoading(true);
        const queryParams = getQueryParams(location.search);
        const idValue = queryParams.get('id');
        const { dateFound, notes } = dataFound;
        const payload = {
            id: 0,
            dateFound: dateFound,
            notes: notes,
            assetID: parseInt(idValue),
        };

        const response = await createFound(payload);
        if (response === "Inserted") {
            const historyPayload = {
                id: 0,
                assetId: assetId || 0,
                assetDate: localISODate,
                assetEvent: "Found",
                assetField: "Status",
                assetChangedFrom: currentAssetData?.assetStatusValue,
                assetChangedTo: "Found",
                assetActionBy: userName || '',
            };
            await CreateAssetHistory(historyPayload);
            toast.success("Found data Inserted Successfully");
            getHistoryData(assetId);
        } else {
            toast.error(response);
        }
        window.location.reload();
        setIsLoading(false);
    };

    //Repair
    const onSubmitRepair = async (dataRepair) => {
        setIsLoading(true);
        const queryParams = getQueryParams(location.search);
        const idValue = queryParams.get('id');
        const { scheduleDate, assignTo, repairCompleted, repairCost, notes } = dataRepair;
        const payload = {
            id: 0,
            scheduleDate: scheduleDate,
            assignTo: assignTo,
            repairCompleted: repairCompleted,
            repairCost: `${repairCost}`,
            notes: notes,
            assetID: parseInt(idValue),
        };

        const response = await createRepair(payload);
        if (response === "Inserted") {
            const historyPayload = {
                id: 0,
                assetId: assetId || 0,
                assetDate: localISODate,
                assetEvent: "Repair",
                assetField: "Status",
                assetChangedFrom: currentAssetData?.assetStatusValue,
                assetChangedTo: "Repair",
                assetActionBy: userName || '',
            };
            await CreateAssetHistory(historyPayload);
            // if (payload.repairCost) {
            //     const transactionPayload = {
            //         id: 0,
            //         ftAssetId: assetId || 0,
            //         ftAssetCodeId: currentAssetData.assetID,
            //         ftTransactionDate: localISODate,
            //         ftDescription: 'Repair of Asset',
            //         ftTransaction: 'Repair',
            //         ftAmount: payload.repairCost,
            //         ftPaymentType: 'Paid',
            //     };
            //     await CreateFinancialTransaction(transactionPayload);
            // }
            toast.success("Repair data Inserted Successfully");
            getHistoryData(assetId);
        } else {
            toast.error(response);
        }
        window.location.reload();
        setIsLoading(false);
    };

    const onSubmitRepairCompleted = async (dataRepair) => {
        setIsLoading(true);
        const queryParams = getQueryParams(location.search);
        const idValue = queryParams.get('id');
        const { scheduleDate, assignTo, repairCompleted, repairCost, notes } = dataRepair;
        const payload = {
            id: assetRepairData.id,
            scheduleDate: scheduleDate,
            assignTo: assignTo,
            repairCompleted: repairCompleted,
            repairCost: `${repairCost}`,
            notes: notes,
            assetID: parseInt(idValue),
        };

        const response = await updateRepair(payload);
        if (response === "Updated") {
            const historyPayload = {
                id: 0,
                assetId: assetId || 0,
                assetDate: localISODate,
                assetEvent: "Repair Complete",
                assetField: "Status",
                assetChangedFrom: currentAssetData?.assetStatusValue,
                assetChangedTo: "Repair Complete",
                assetActionBy: userName || '',
            };
            await CreateAssetHistory(historyPayload);
            if (payload.repairCost) {
                const transactionPayload = {
                    id: 0,
                    ftAssetId: assetId || 0,
                    ftAssetCodeId: currentAssetData.assetID,
                    ftTransactionDate: localISODate,
                    ftDescription: 'Repair of Asset',
                    ftTransaction: 'Repair',
                    ftAmount: payload.repairCost,
                    ftPaymentType: 'Paid',
                };
                await CreateFinancialTransaction(transactionPayload);
            }
            toast.success("Repair data Inserted Successfully");
            getHistoryData(assetId);
        } else {
            toast.error(response);
        }
        window.location.reload();
        setIsLoading(false);
    };

    //Broken
    const onSubmitBroken = async (dataBroken) => {
        setIsLoading(true);
        const queryParams = getQueryParams(location.search);
        const idValue = queryParams.get('id');
        const { dateBroken, notes } = dataBroken;
        const payload = {
            id: 0,
            dateBroken: dateBroken,
            notes: notes,
            assetID: parseInt(idValue),
        };

        const response = await createBroken(payload);
        if (response === "Inserted") {
            const historyPayload = {
                id: 0,
                assetId: assetId || 0,
                assetDate: localISODate,
                assetEvent: "Broken",
                assetField: "Status",
                assetChangedFrom: currentAssetData?.assetStatusValue,
                assetChangedTo: "Broken",
                assetActionBy: userName || '',
            };
            await CreateAssetHistory(historyPayload);
            toast.success("Broken data Inserted Successfully");
            getHistoryData(assetId);
        } else {
            toast.error(response);
        }
        window.location.reload();
        setIsLoading(false);
    };

    //Dispose
    const onSubmitDispose = async (dataDispose) => {
        setIsLoading(true);
        const queryParams = getQueryParams(location.search);
        const idValue = queryParams.get('id');
        const { dateDispose, disposeTo, notes } = dataDispose;
        const payload = {
            id: 0,
            dateDispose: dateDispose,
            disposeTo: disposeTo,
            notes: notes,
            assetID: parseInt(idValue),
        };

        const response = await createDisposed(payload);
        if (response === "Inserted") {
            const historyPayload = {
                id: 0,
                assetId: assetId || 0,
                assetDate: localISODate,
                assetEvent: "Dispose",
                assetField: "Status",
                assetChangedFrom: currentAssetData?.assetStatusValue,
                assetChangedTo: "Disposed",
                assetActionBy: userName || '',
            };
            await CreateAssetHistory(historyPayload);
            toast.success("Dispose data Inserted Successfully");
            getHistoryData(assetId);
        } else {
            toast.error(response);
        }
        window.location.reload();
        setIsLoading(false);
    };

    //Donate
    const onSubmitDonate = async (dataDonate) => {
        setIsLoading(true);
        const queryParams = getQueryParams(location.search);
        const idValue = queryParams.get('id');
        const { dateDonate, donateTo, donatedValue, deductible, notes } = dataDonate;
        const payload = {
            id: 0,
            dateDonate: dateDonate,
            donateTo: donateTo,
            donatedValue: `${donatedValue}`,
            deductible: deductible,
            notes: notes,
            assetID: parseInt(idValue),
        };

        const response = await createDonate(payload);
        if (response === "Inserted") {
            const historyPayload = {
                id: 0,
                assetId: assetId || 0,
                assetDate: localISODate,
                assetEvent: "Donate",
                assetField: "Status",
                assetChangedFrom: currentAssetData?.assetStatusValue,
                assetChangedTo: "Donated",
                assetActionBy: userName || '',
            };
            await CreateAssetHistory(historyPayload);
            if (payload.donatedValue) {
                const transactionPayload = {
                    id: 0,
                    ftAssetId: assetId || 0,
                    ftAssetCodeId: currentAssetData.assetID,
                    ftTransactionDate: localISODate,
                    ftDescription: 'Donate of Asset',
                    ftTransaction: 'Donate',
                    ftAmount: payload.donatedValue,
                    ftPaymentType: 'Paid',
                };
                await CreateFinancialTransaction(transactionPayload);
            }
            toast.success("Donate data Inserted Successfully");
            getHistoryData(assetId);
        } else {
            toast.error(response);
        }
        window.location.reload();
        setIsLoading(false);
    };

    //Sell
    const onSubmitSell = async (dataSell) => {
        setIsLoading(true);
        const queryParams = getQueryParams(location.search);
        const idValue = queryParams.get('id');
        const { saleDate, soldTo, saleAmount, notes } = dataSell;
        const payload = {
            id: 0,
            saleDate: saleDate,
            soldTo: soldTo,
            saleAmount: `${saleAmount}`,
            notes: notes,
            assetID: parseInt(idValue),
        };

        const response = await createSell(payload);
        if (response === "Inserted") {
            const historyPayload = {
                id: 0,
                assetId: assetId || 0,
                assetDate: localISODate,
                assetEvent: "Sell",
                assetField: "Status",
                assetChangedFrom: currentAssetData?.assetStatusValue,
                assetChangedTo: "Sell",
                assetActionBy: userName || '',
            };
            await CreateAssetHistory(historyPayload);
            if (payload.saleAmount) {
                const transactionPayload = {
                    id: 0,
                    ftAssetId: assetId || 0,
                    ftAssetCodeId: currentAssetData.assetID,
                    ftTransactionDate: payload.saleDate,
                    ftDescription: 'Sell of Asset',
                    ftTransaction: "Sell",
                    ftAmount: payload.saleAmount,
                    ftPaymentType: 'Received',
                };
                await CreateFinancialTransaction(transactionPayload);
            };
            toast.success("Sell data Inserted Successfully");
            getHistoryData(assetId);
        } else {
            toast.error(response);
        }
        window.location.reload();
        setIsLoading(false);
    };

    //Link
    const onSubmitLink = async (dataLink) => {
        try {
            setIsLoading(true);
            const { selectChildParentAsset, linkValue } = dataLink;

            // Check if the current asset already has a parent asset linked
            const linkData = await getAssetLink();
            let parentLinked = false;
            if (linkValue !== "Child") {
                parentLinked = linkData.some(link => link.assetId === assetId && link.relation === 'Parent');
            }

            if (parentLinked) {
                setIsLoading(false);
                toast.error("You have already linked a parent asset for this asset.");
                return;
            }

            const matchingAsset = assetArray.find(asset => asset.id === assetId);
            const payload = {
                id: 0,
                assetId: assetId,
                assetRelationId: selectChildParentAsset,
                relation: linkValue,
                assetTagID: matchingAsset?.assetID,
                description: matchingAsset?.assetName,
                linkStatus: true,
                transact: true || transactAsWhole,
            };

            const response = await createAssetLink(payload);
            if (response === "Inserted") {
                const historyPayload = {
                    id: 0,
                    assetId: assetId || 0,
                    assetDate: localISODate,
                    assetEvent: "Link",
                    assetField: "Link Asset",
                    assetChangedFrom: "",
                    assetChangedTo: payload.relation + " " + "linked",
                    assetActionBy: userName || '',
                };
                await CreateAssetHistory(historyPayload);
                toast.success("Asset Linked Successfully");
                getLinkData(assetId);
                getHistoryData(assetId);
                window.location.reload();
            } else {
                toast.error(response);
            }
        } catch (error) {
            toast.error("Failed to Link Asset");
        } finally {
            setIsLoading(false);
            setSelectedValue("");
        }
    };

    const handleUnlinkAsset = async (asset) => {
        try {
            const result = await Swal.fire({
                title: 'Are you sure you want to Unlink this asset?',
                text: 'This action cannot be undone.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Unlink it'
            });
            if (result.isConfirmed) {
                setIsLoading(true);
                const response = await deleteAssetLink(asset.id);
                if (response === "Deleted") {
                    const historyPayload = {
                        id: 0,
                        assetId: assetId || 0,
                        assetDate: localISODate,
                        assetEvent: "Unlink",
                        assetField: "Link",
                        assetChangedFrom: asset.relation,
                        assetChangedTo: asset.relation + " " + "Unlinked",
                        assetActionBy: userName || '',
                    };
                    await CreateAssetHistory(historyPayload);
                    toast.success("Asset Unlinked Successfully");
                    getLinkData(assetId);
                    getHistoryData(assetId);
                    window.location.reload();
                } else {
                    toast.error(response);
                }
            }
        } catch (error) {
            console.error("Error deleting asset:", error);
            toast.error(`An error occurred while deleting the asset.`);
        } finally {
            setIsLoading(false);
        }
    };

    //Depreciation
    const onSubmitDepreciation = async (dataDepreciation) => {
        try {
            setIsLoading(true);
            let payload = {
                id: assetId || 0,
                assetName: currentAssetData?.assetName,
                assetID: currentAssetData?.assetID,
                assetTagID: currentAssetData?.assetTagID,
                categoryId: currentAssetData?.categoryId,
                subCategoryId: currentAssetData?.subCategoryId,
                brandId: currentAssetData?.brandId,
                modelNo: currentAssetData?.modelNo,
                serialNo: currentAssetData?.serialNo,
                cost: currentAssetData?.cost,
                supplierId: currentAssetData?.supplierId,
                purchaseDate: currentAssetData?.purchaseDate ? currentAssetData.purchaseDate : null,
                unitId: currentAssetData?.unitId,
                branchId: currentAssetData?.branchId,
                departmentId: currentAssetData?.departmentId,
                empId: currentAssetData?.empId,
                assetImage: currentAssetData?.assetImage,
                status: currentAssetData?.status,
                depreciableAsset: dataDepreciation?.depreciableAsset
            };

            if (dataDepreciation?.depreciableAsset === 'No') {
                payload = {
                    ...payload,
                    depreciableCost: '',
                    assetLife: '',
                    salvageValue: '',
                    depreciationMethod: '',
                    dateAcquired: null,
                };
            } else {
                payload = {
                    ...payload,
                    depreciableCost: dataDepreciation?.depreciableCost,
                    assetLife: dataDepreciation?.assetLife,
                    salvageValue: dataDepreciation?.salvageValue,
                    depreciationMethod: dataDepreciation?.depreciationMethod,
                    dateAcquired: dataDepreciation?.dateAcquired,
                };
            }

            const response = await updateAsset(payload);
            if (response === "Updated") {
                const changedFields = [];
                // Compare each field in the payload with the corresponding field in currentAssetData
                for (const key in payload) {
                    if (payload.hasOwnProperty(key) && currentAssetData[key] !== payload[key]) {
                        // Capture the changes in fields
                        changedFields.push({
                            assetField: key,
                            assetChangedFrom: currentAssetData[key] || '',
                            assetChangedTo: payload[key] || ''
                        });
                    }
                }

                // Loop through each changed field and create history
                for (const change of changedFields) {
                    const historyPayload = {
                        id: 0,
                        assetId: assetId || 0,
                        assetDate: localISODate,
                        assetEvent: 'Update Asset Depreciation',
                        assetField: change.assetField,
                        assetChangedFrom: String(change.assetChangedFrom),
                        assetChangedTo: String(change.assetChangedTo),
                        assetActionBy: userName || '',
                    };
                    await CreateAssetHistory(historyPayload);
                }

                if (dataDepreciation.depreciableAsset === "Yes" && dataDepreciation?.depreciableCost) {
                    const transactionPayload = {
                        id: 0,
                        ftAssetId: assetId || 0,
                        ftAssetCodeId: currentAssetData?.assetID,
                        ftTransactionDate: localISODate,
                        ftDescription: 'Depreciation of Asset',
                        ftTransaction: 'Depreciation',
                        ftAmount: dataDepreciation?.depreciableCost,
                        ftPaymentType: 'Paid',
                    };
                    await CreateFinancialTransaction(transactionPayload);
                }
                toast.success("Depreciation data updated successfully");
                bindAssetValues(assetId);
                getHistoryData(assetId);
                window.location.reload();
            } else {
                toast.error(response);
            }
        } catch (error) {
            toast.error("Failed to Save Asset Depreciation");
        } finally {
            setIsLoading(false);
        }
    };

    //Add
    const onSubmitAdd = async (dataAdd) => {
        try {
            setIsLoading(true);
            const { selectChildParentAsset, linkValue } = dataAdd;
            const payload = {
                id: 0,
                assetId: selectChildParentAsset,
                relation: linkValue,
                assetTagID: currentAssetData?.assetTagID,
                description: "",
                linkStatus: true,
            };

            // const response = await createAssetLink(payload);

            // if (response === "Inserted") {
            //     toast.success("Asset Linked Successfully");
            // } else {
            //     toast.error(response);
            // }
        } catch (error) {
            toast.error("Failed to Add Asset");
        } finally {
            setIsLoading(false);
        }
    };

    //clone
    const onSubmitClone = async (dataClone) => {
        setIsLoading(true);
        const queryParams = getQueryParams(location.search);
        const idValue = parseInt(queryParams.get('id'));
        const { cloneCount } = dataClone;

        const response = await cloneAsset(cloneCount, idValue);
        if (response === "Cloned") {
            toast.success("Asset Cloned Successfully");
            setTimeout(() => navigate("/AssetCreationView"), 1000);
        } else {
            toast.error(response);
        }
        setIsLoading(false);
    };


    // Warranty
    const handleWarrantyAdd = () => {
        setIsWarrantyAdd(true);
        setIsWarrantyView(false);
        setSelectedWarrantyValue('');
    };

    const handleWarrantyViewOrEdit = (selectedValue, viewStatus) => {
        setIsWarrantyAdd(true);
        setIsWarrantyView(viewStatus);
        setSelectedWarrantyValue(selectedValue);
    };

    const onWarrantyClose = () => {
        setIsWarrantyAdd(false);
        setIsWarrantyView(false);
        setSelectedWarrantyValue('');
    };

    const handleWarrantySubmit = async (data) => {
        try {
            setIsLoading(true);
            const { length, expirationDate, notes, selectedNumbers, document, documentLink } = data;
            const payload = {
                id: selectedWarrantyValue == '' ? 0 : selectedWarrantyValue.id,
                assetId: assetId,
                assetTagId: currentAssetData?.assetID,
                warrantyTagId: selectedWarrantyValue == '' ? warrantyTagID : selectedWarrantyValue.warrantyTagId,
                assetName: currentAssetData?.assetName,
                length: length,
                expirationDate: expirationDate,
                notes: notes || '',
                status: true,
                setReminders: selectedNumbers?.join(',') || '',
                document: document || '',
                documentLink: documentLink || '',
                NextID: warrantyDocID
            };

            const response = selectedWarrantyValue == '' ? await createProFormWarranty(payload) : await updateProFormWarranty(payload);
            if (response === (selectedWarrantyValue == '' ? "Inserted" : "Updated")) {
                toast.success(`Warranty ${selectedWarrantyValue == '' ? "Inserted" : "Updated"} Successfully`);
                const changedFields = [];
                // Compare each field in the payload with the corresponding field in currentAssetData
                for (const key in payload) {
                    if (payload.hasOwnProperty(key) && selectedWarrantyValue[key] !== payload[key] && key !== 'assetId' && key !== 'document' && key !== 'NextID') {
                        let changedFrom = selectedWarrantyValue[key] || '';
                        let changedTo = payload[key] || '';
                        if (changedFrom !== changedTo) {
                            changedFields.push({
                                assetField: key,
                                assetChangedFrom: changedFrom,
                                assetChangedTo: changedTo
                            });
                        }
                    }
                }
                for (const change of changedFields) {
                    const historyPayload = {
                        id: 0,
                        assetId: assetId || 0,
                        assetDate: localISODate,
                        assetEvent: "Warranty " + (selectedWarrantyValue == '' ? "Inserted" : "Updated"),
                        assetField: change.assetField,
                        assetChangedFrom: String(change.assetChangedFrom),
                        assetChangedTo: String(change.assetChangedTo),
                        assetActionBy: userName || '',
                    };
                    await CreateAssetHistory(historyPayload);
                };
                getWarrantyData(assetId);
                getHistoryData(assetId);
                window.location.reload();
            } else {
                toast.error(response);
            }
        } catch (error) {
            toast.error("Failed to Save Warranty");
        } finally {
            setIsLoading(false);
            setIsWarrantyAdd(false);
            setIsWarrantyView(false);
            setSelectedWarrantyValue('');
        }
    };


    // Insurance
    const handleInsuranceAdd = () => {
        setIsInsuranceAdd(true);
    };

    const onInsuranceClose = () => {
        setIsInsuranceAdd(false);
    };

    const handleInsuranceSubmit = async (data) => {
        console.log("handleInsuranceSubmit", data);
        const { insuranceTagId } = data;
        console.log("insuranceTagId", insuranceTagId);

        try {
            setIsLoading(true);
            // Find the selected insurance from the unlinkedInsuranceArray
            const selectedInsurance = unlinkedInsuranceArray?.find(insurance => insurance.insuranceTagId === insuranceTagId);

            // Check if selectedInsurance?.selectedAssetIds is a string (i.e., JSON string) and parse it into an array if so.
            const existingSelectedAssetIds = selectedInsurance?.selectedAssetIds
                ? JSON.parse(selectedInsurance.selectedAssetIds)
                : [];

            // Add the currentAssetData.id to the array if it's not already present, and remove duplicates using Set
            const updatedSelectedAssetIds = [...new Set([...existingSelectedAssetIds, currentAssetData.id])];

            // Create the payload
            const payload = {
                id: selectedInsurance.id,
                assetId: currentAssetData.id || 0,
                assetTagId: currentAssetData.assetID || '',
                assetName: currentAssetData.assetName || '',
                insuranceTagId: selectedInsurance?.insuranceTagId,
                insuranceName: selectedInsurance?.insuranceName || '',
                insuranceCompany: selectedInsurance?.insuranceCompany || '',
                contactPerson: selectedInsurance?.contactPerson || '',
                phone: selectedInsurance?.phone || '',
                email: selectedInsurance?.email || '',
                policyNumber: selectedInsurance?.policyNumber || '',
                coverage: selectedInsurance?.coverage || '',
                deductible: selectedInsurance?.Deductible || '',
                premium: selectedInsurance?.premium || '',
                insuranceStartDate: selectedInsurance?.insuranceStartDate || null,
                insuranceEndDate: selectedInsurance?.insuranceEndDate || null,
                document: selectedInsurance?.document || '',
                documentLink: selectedInsurance?.documentLink || '',
                setReminders: selectedInsurance.setReminders?.join(','),
                status: selectedInsurance.status ? "Active" : "Inactive",
                selectedAssetIds: updatedSelectedAssetIds.length > 0 ? JSON.stringify(updatedSelectedAssetIds) : '',
            };

            const response = await updateProFormInsurance(payload);
            if (response === "Updated") {
                setIsInsuranceAdd(false);
                toast.success(`Insurance Attached Successfully`);
                getInsuranceData(assetId);
                const historyPayload = {
                    id: 0,
                    assetId: payload.assetId,
                    assetDate: localISODate,
                    assetEvent: "Insurance Attached",
                    assetField: '',
                    assetChangedFrom: '',
                    assetChangedTo: '',
                    assetActionBy: userName || '',
                };
                await CreateAssetHistory(historyPayload);
                window.location.reload();
            } else {
                toast.error(response);
            }
        } catch (error) {
            toast.error("Failed to Attach Insurance");
        } finally {
            setIsLoading(false);
            setIsInsuranceAdd(false);
        }
    };

    const handleDeleteInsurance = async (insurance) => {
        try {
            const result = await Swal.fire({
                title: 'Are you sure you want to Detach this insurance?',
                text: 'This action cannot be undone.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Detach it'
            });

            if (result.isConfirmed) {
                setIsLoading(true);
                // Use the existing selectedAssetIds as an array or default to an empty array if not present
                const selectedAssetIdsArray = Array.isArray(insurance.selectedAssetIds)
                    ? insurance.selectedAssetIds
                    : [];

                // Filter out the assetId you want to exclude
                const filteredAssetIds = selectedAssetIdsArray.filter(id => id !== assetId);

                const payload = {
                    id: insurance.id,
                    assetId: filteredAssetIds[0] || 0,
                    assetTagId: '',
                    assetName: '',
                    insuranceTagId: insurance?.insuranceTagId,
                    insuranceName: insurance?.insuranceName || '',
                    insuranceCompany: insurance?.insuranceCompany || '',
                    contactPerson: insurance?.contactPerson || '',
                    phone: insurance?.phone || '',
                    email: insurance?.email || '',
                    policyNumber: insurance?.policyNumber || '',
                    coverage: insurance?.coverage || '',
                    deductible: insurance?.Deductible || '',
                    premium: insurance?.premium || '',
                    insuranceStartDate: insurance?.insuranceStartDate || null,
                    insuranceEndDate: insurance?.insuranceEndDate || null,
                    document: insurance?.document || '',
                    documentLink: insurance?.documentLink || '',
                    setReminders: insurance?.setReminders || '',
                    status: insurance.status ? "Active" : "Inactive",
                    selectedAssetIds: filteredAssetIds.length > 0 ? JSON.stringify(filteredAssetIds) : '', // Updated string without the excluded assetId
                };

                const response = await updateProFormInsurance(payload);
                if (response === "Updated") {
                    toast.success("Insurance Detached Successfully");
                    getInsuranceData(assetId);
                    const historyPayload = {
                        id: 0,
                        assetId: insurance.assetId,
                        assetDate: localISODate,
                        assetEvent: "Insurance Detached",
                        assetField: '',
                        assetChangedFrom: '',
                        assetChangedTo: '',
                        assetActionBy: userName || '',
                    };
                    await CreateAssetHistory(historyPayload);
                    window.location.reload();
                } else {
                    toast.error(response);
                }
            }
        } catch (error) {
            console.error("Error detaching insurance:", error);
            toast.error(`An error occurred while detaching the insurance.`);
        } finally {
            setIsLoading(false);
        }
    };


    //Maintenace
    const handleMaintenanceAdd = () => {
        if (!IsbtnAddMaintenance) {
            setIsAddMaintenance(true);
            setIsMaintenance(true);
        } else {
            toast.warning("Please complete the existing maintenance.")
        }

    };

    const onMaintenaceCancel = () => {
        setSelectedMaintenanceValue('');
        setIsAddMaintenance(false);
        setIsMaintenanceView(false);
        setIsMaintenanceEdit(false);
        setIsMaintenance(false);
    };

    const getMaintenanceData = async (assetId) => {
        const MaintenanceData = await getProFormMaintenance();
        const currentMaintenanceData = MaintenanceData?.filter((e) => e.assetId === assetId)
            .map((e) => {
                let statusValue = ''
                let maintenanceByValue = ''
                if (e.maintenanceBy == 'vendor') {
                    maintenanceByValue = 'Vendor'
                } else if (e.maintenanceBy == 'inhouse') {
                    maintenanceByValue = 'In-House'
                }
                if (e.maintenanceStatus == 'schedule') {
                    statusValue = 'Schedule'
                } else if (e.maintenanceStatus == 'inprogress') {
                    statusValue = 'In-Progress'
                } else if (e.maintenanceStatus == 'onhold') {
                    statusValue = 'On-Hold'
                } else if (e.maintenanceStatus == 'cancelled') {
                    statusValue = 'Cancelled'
                } else if (e.maintenanceStatus == 'completed') {
                    statusValue = 'Completed'
                }
                if (e.maintenanceStatus != 'completed') {
                    setIsbtnAddMaintenance(true);
                } else {
                    setIsbtnAddMaintenance(false);
                }
                return {
                    ...e,
                    maintenanceStatusValue: statusValue,
                    maintenanceByValue: maintenanceByValue,
                    formattedScheduleDate: e.scheduleDate == null ? null : e.scheduleDate.split("T")[0]
                }

            });

        const MaintenanceStatusfilterValue = currentMaintenanceData.filter((e) => e.maintenanceStatus != 'completed');
        if (currentMaintenanceData.length == 0) {
            setMaintenanceStatusValue('');
        } else {
            setMaintenanceStatusValue(MaintenanceStatusfilterValue.length == 0 ? 'Completed' : MaintenanceStatusfilterValue[0].maintenanceStatusValue);
        }
        setAssetMaintenanceArray(currentMaintenanceData);
    };

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        return date.toISOString().slice(0, 19);
    };

    const onSubmitMaintenance = async (inputFields) => {
        try {
            setIsLoading(true);
            const documentData = await getDocument();
            const maxId = documentData.find(doc => doc.id === 5);
            const newId = maxId.documentNo;
            const assetPrefix = "MI"
            const assetPadding = AssetRequestSettingsValue?.padding;
            const zeros = '0'.repeat(parseInt(assetPadding, 10));
            const maintenanceId = `${assetPrefix}${zeros}${newId}`;
            const payload = {
                id: SelectedMaintenanceValue == '' ? 0 : SelectedMaintenanceValue.id,
                title: inputFields.maintenanceTitle,
                details: inputFields.maintenanceDetail,
                scheduleDate: inputFields.maintenanceScheduleDate ? formatDate(inputFields.maintenanceScheduleDate) : null,
                maintenanceBy: inputFields.maintenanceBy,
                maintenanceStatus: inputFields.maintenanceStatus,
                dateCompleted: inputFields.maintenanceDateCompleted ? formatDate(inputFields.maintenanceDateCompleted) : null,
                maintenanceCost: inputFields.maintenanceCost,
                assetId: currentAssetData.id,
                estimatedReturnDate: inputFields.maintenanceEstimateReturnDate ? formatDate(inputFields.maintenanceEstimateReturnDate) : null,
                maintenanceId: maintenanceId
            }

            if (inputFields.maintenanceCost != 0 && inputFields.maintenanceStatus == 'completed') {
                const transactionPayload = {
                    id: 0,
                    ftAssetId: assetId || 0,
                    ftAssetCodeId: currentAssetData.assetID,
                    ftTransactionDate: inputFields.maintenanceDateCompleted ? formatDate(inputFields.maintenanceDateCompleted) : null,
                    ftDescription: 'Maintenance of Asset',
                    ftTransaction: 'Maintenance',
                    ftAmount: inputFields.maintenanceCost,
                    ftPaymentType: 'Paid',
                };
                await CreateFinancialTransaction(transactionPayload);
            }
            const response = SelectedMaintenanceValue == '' ? await createProFormMaintenance(payload) : await updateProFormMaintenance(payload);
            if (response === "Inserted") {
                getMaintenanceData(assetId);
                toast.success("Maintenance added Successfully");
                onMaintenaceCancel();
            } else if (response === "Updated") {
                getMaintenanceData(assetId);
                toast.success("Maintenance updated Successfully");
                onMaintenaceCancel();
            } else {
                toast.error(response);
            }
        } catch (error) {
            SelectedMaintenanceValue == '' ? toast.error("Failed to create Maintenance") : toast.error("Failed to update Maintenance");
        } finally {
            setIsLoading(false);
        }
    }

    const btnViewMaintenance = (selectedValue) => {
        setSelectedMaintenanceValue(selectedValue);
        setIsMaintenanceView(true);
        setIsMaintenance(true);
    }

    const editViewMaintenance = (selectedValue) => {
        setSelectedMaintenanceValue(selectedValue);
        setIsMaintenanceEdit(true);
        setIsMaintenance(true);
    }


    //Request
    const CreateRequestformatDate = (originalDate) => {
        const dateObject = new Date(originalDate);
        const day = String(dateObject.getDate()).padStart(2, '0');
        const month = String(dateObject.getMonth() + 1).padStart(2, '0');
        const year = dateObject.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const getRequestData = async (assetId, RequestCategoryDropdown, RequestSubCategoryDropdown, RequestPriorityDropdown) => {
        const RequestData = await getProFormServiceRequest();
        const currentRequestData = RequestData?.filter((e) => e.assetID === assetId)
            .map((e) => {
                let requestCategoryValue = RequestCategoryDropdown.find((x) => x.id == e.requestCategory)
                let requestSubCategoryValue = RequestSubCategoryDropdown.find((y) => y.id == e.requestSubCategory)
                let requestPriorityValue = RequestPriorityDropdown.find((z) => z.id == e.requestPriority)
                let statusValue = '';
                if (e.status == 'assigned') {
                    statusValue = 'Assigned';
                } else if (e.status = 'unassigned') {
                    statusValue = 'Unassigned'
                } else if (e.status = 'hold') {
                    statusValue = 'Hold'
                } else if (e.status = 'completed') {
                    statusValue = 'Completed'
                } else if (e.status = 'reopened') {
                    statusValue = 'Reopened'
                }
                return {
                    ...e,
                    requestCategoryValue: requestCategoryValue.requestCategoryName,
                    requestSubCategoryValue: requestSubCategoryValue.requestSubCategoryName,
                    requestPriorityValue: requestPriorityValue.name,
                    formattedCreatedDate: CreateRequestformatDate(e.createdDate),
                    requestStatusValue: statusValue
                }
            });
        setAssetRequestArray(currentRequestData);
    };

    const handleRegisterAdd = () => {
        setIsAddRequest(true);
        setIsRequest(true);
    };

    const onRequestCancel = () => {
        setSelectedRequestValue('');
        setIsAddRequest(false);
        setIsRequestView(false);
        setIsRequestEdit(false);
        setIsRequest(false);
    };

    const onSubmitRequest = async (inputFields) => {
        try {
            setIsLoading(true);
            const documentData = await getDocument();
            const maxId = documentData.find(doc => doc.id === 9);
            const newId = maxId.documentNo;
            const assetPrefix = "SR"
            const assetPadding = AssetRequestSettingsValue?.padding;
            const zeros = '0'.repeat(parseInt(assetPadding, 10));
            const requestID = `${assetPrefix}${zeros}${newId}`;
            const requestValue = AssetRequestTeamValue.find((e) => e.requestCategoryId == inputFields.requestCategory && e.requestSubCategoryId == inputFields.requestSubCategory && e.requestStatus == 'Active');
            const requestTeam = requestValue.teamId;
            const requestUser = requestValue.empId;
            const userID = parseInt(localStorage.getItem('UserEmpID'), 10);
            const payload = {
                id: 0,
                requestID: requestID,
                requestCategory: inputFields.requestCategory,
                requestSubCategory: inputFields.requestSubCategory,
                requestPriority: inputFields.requestPriority,
                subject: inputFields.requestSubject,
                explainYourRequestDetails: inputFields.requestExplain,
                assetID: currentAssetData.id,
                documentName: inputFields.requestDocumentName,
                document: JSON.stringify(inputFields.requestDocument),
                status: 'assigned',
                isVisible: true,
                empIds: requestUser,
                teamIds: requestTeam,
                createdById: userID
            }
            const response = await createProFormServiceRequest(payload);
            if (response === "Inserted") {
                getRequestData(assetId, AssetRequestCategoryDropdown, AssetRequestSubCategoryDropdown, AssetRequestPriorityDropdown);
                toast.success("Service Request added Successfully");
                onRequestCancel();
            } else if (response === "Updated") {
                getRequestData(assetId);
                toast.success("Service Request updated Successfully");
                onRequestCancel();
            } else {
                toast.error(response);
            }
        } catch (error) {
            toast.error("Failed to create Service Request")
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <div className="bacgroundStyle">
            <ToastContainer theme="colored" />
            {isLoading && <LoadingSpinner />}
            {!isLoading && (
                <div>
                    <div className="pagetitleAsset">
                        <div className="Pageheader fontFamily fontsize24 fontweightSemiBold">
                            View Asset
                            <div>
                                <InfoOutlinedIcon />
                            </div>
                        </div>
                    </div>
                    <div className="card mt-4">
                        <div className="cardBodySupplierMaster">
                            <div id="printableContent">
                                <div className="" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <div className="" style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", }}>
                                        <div className="blackTextColor fontFamily fontsize20 fontweightMedium">
                                            {assetNameAndID}
                                        </div>
                                    </div>
                                    <div className="" style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", }}>
                                        <a
                                            href={`/AssetCreationAdd?id=0&view=0`}
                                            type="button"
                                            className="AddBoxShadow">
                                            <div className="blackTextColor fontFamily fontsize24 fontweightBold">+</div>
                                        </a>
                                        <button
                                            type="button"
                                            className="blackTextColor fontFamily fontweightRegular fontsize16 importBtn AddBoxShadow"
                                            onClick={handleAssetPrintClick}
                                        >
                                            <img src={printIcon} alt="printIcon" height={20} style={{ marginRight: "5px" }} />
                                            Print
                                        </button>
                                        <button
                                            type="button"
                                            className="blackTextColor fontFamily fontweightRegular fontsize16 importBtn AddBoxShadow"
                                            onClick={() => setIsAssetView(false)}
                                        >
                                            <img src={pencilEdit} alt="pencilEdit" height={20} style={{ marginRight: "5px" }} />
                                            Edit Asset
                                        </button>
                                        <select
                                            className="fontFamily fontweightRegular fontsize16 exportBtn"
                                            name="language"
                                            id="language"
                                            defaultValue=""
                                            onChange={handleChange}
                                        >
                                            <option value="" hidden={true}>More Action</option>
                                            {moreOptions?.map((option) => (
                                                <option key={option.tabName} value={option.tabName}>
                                                    {option.tabName}
                                                </option>
                                            ))}
                                            <option key='Clone' value='Clone'>
                                                Clone
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div className="CustomBoxShadow" style={{ marginTop: "20px" }}>
                                    <div className="" style={{ textAlign: 'center', margin: '12px 0px', display: 'flex', justifyContent: 'space-between' }}>
                                        <div>
                                            <div className="grayTextColor fontFamily fontsize16 fontweightMedium">
                                                Status
                                            </div>
                                            <div className="blackTextColor fontFamily fontsize16 fontweightMedium mTop12">
                                                {currentAssetData?.assetStatusValue}
                                            </div>
                                        </div>

                                        <div>
                                            <div className="grayTextColor fontFamily fontsize16 fontweightMedium">
                                                Assignment
                                            </div>
                                            <div className="blackTextColor fontFamily fontsize16 fontweightMedium mTop12">
                                                {employeeName}
                                            </div>
                                        </div>

                                        <div>
                                            <div className="grayTextColor fontFamily fontsize16 fontweightMedium">
                                                Warranty
                                            </div>
                                            <div className="mTop12">
                                                {assetWarrantyArray?.some(warranty => warranty.currentStatus === true) ? (
                                                    <img src={greenTickMark} alt="greenTickMark" height={15} />
                                                ) : (
                                                    <img src={redXmark} alt="redXmark" height={15} />
                                                )}
                                            </div>
                                        </div>

                                        <div>
                                            <div className="grayTextColor fontFamily fontsize16 fontweightMedium">
                                                Contract
                                            </div>
                                            <div className="mTop12">
                                                <img src={redXmark} alt="redXmark" height={15} />
                                            </div>
                                        </div>

                                        <div>
                                            <div className="grayTextColor fontFamily fontsize16 fontweightMedium">
                                                Maintenance
                                            </div>
                                            <div className="blackTextColor fontFamily fontsize16 fontweightMedium mTop12">
                                                {MaintenanceStatusValue}
                                            </div>
                                        </div>

                                        <div>
                                            <div className="grayTextColor fontFamily fontsize16 fontweightMedium">
                                                Attachments
                                            </div>
                                            <div className="blackTextColor fontFamily fontsize16 fontweightMedium mTop12">
                                                {attachmentCount}
                                            </div>
                                        </div>

                                        <div>
                                            <div className="grayTextColor fontFamily fontsize16 fontweightMedium">
                                                Purchase Date
                                            </div>
                                            <div className="blackTextColor fontFamily fontsize16 fontweightMedium mTop12">
                                                {currentAssetData?.formatedPurchaseDate}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="customScrollbarAsset">
                                    <div className="CustomBoxShadow" style={{ marginTop: "20px" }}>
                                        <div className="HeaderTab">
                                            {headerTabs.concat(currentAssetData?.depreciableAsset === 'Yes' ? ['Depreciation'] : [])?.map(tab => (
                                                <a
                                                    key={tab}
                                                    href={`#${tab.replace(' ', '')}`}
                                                    className={`${tabActive === tab ? 'activeTab' : 'InactiveTab'}`}
                                                    onClick={() => setTabActive(tab)}
                                                >
                                                    <div className="fontFamily fontweightRegular fontsize20">
                                                        {tab}
                                                    </div>
                                                </a>
                                            ))}
                                        </div>

                                        {tabActive === 'Details' && (
                                            <>
                                                <div className="CustomBoxShadow" style={{ marginTop: "20px" }}>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", }}>
                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", }}>
                                                            {assetImage ? (<img src={assetImage} alt="assetImage" height={150} />) : (<img src={uploadImg} alt="uploadImg" height={150} />)}
                                                            <div className="mLeft20" style={{}}>
                                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "start", }}>
                                                                    <div className="col-5 col-md-5 col-lg-5 col-sm-12" style={{ paddingTop: "30px" }}>
                                                                        <Controller
                                                                            name="assetImage"
                                                                            control={control}
                                                                            render={({ field }) => (
                                                                                <input
                                                                                    type="file"
                                                                                    id="uploadImgID"
                                                                                    accept=".jpg,.jpeg,.png,.gif"
                                                                                    style={{ display: "none" }}
                                                                                    disabled={isAssetView}
                                                                                    onChange={(e) => {
                                                                                        handleFileInputChange(e);
                                                                                        field.onChange(e.target.files[0]);
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        />
                                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", }}>
                                                                            {assetImage ? (
                                                                                <button type="button" className="fontFamily fontweightRegular fontsize16 coloredButton" onClick={clearImage}>
                                                                                    Clear
                                                                                </button>
                                                                            ) : (
                                                                                <button
                                                                                    type="button"
                                                                                    style={{ cursor: isAssetView ? "not-allowed" : "pointer", }}
                                                                                    disabled={isAssetView}
                                                                                    className="coloredButtonSave fontFamily fontweightRegular fontsize16"
                                                                                    onClick={fileUpload}
                                                                                >
                                                                                    Browse
                                                                                </button>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "10px", }}>
                                                                    <div className="fontFamily fontweightRegular fontsize16 blackTextColor">
                                                                        Only (JPG, GIF, PNG) are allowed
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-6 col-md-6 col-lg-3 col-sm-12">
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" >
                                                                Asset Tag ID: (Bar Code / QR Code Mapping)
                                                            </div>
                                                            <input
                                                                type="text"
                                                                className="textinputCustomAsset"
                                                                placeholder=""
                                                                {...register("assetTagID")}
                                                                disabled={isAssetView}
                                                            />
                                                            {errors.assetTagID && (
                                                                <div className="invalid-feedback" style={{ display: "block" }}>
                                                                    {errors.assetTagID.message}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="row" style={{ paddingTop: "24px" }}>
                                                        <div className="col-6 col-md-6 col-lg-4 col-sm-12">
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" >
                                                                Unit
                                                            </div>
                                                            <Controller
                                                                name="unitId"
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        value={unitArray.find((c) => c.bU_ID === field.value) || null}
                                                                        onChange={(selectedOption) => {
                                                                            field.onChange(selectedOption ? selectedOption.bU_ID : null);
                                                                        }}
                                                                        options={unitArray}
                                                                        getOptionLabel={(option) => option.bU_NAME}
                                                                        getOptionValue={(option) => option.bU_ID}
                                                                        placeholder=""
                                                                        isClearable
                                                                        isSearchable
                                                                        isDisabled={isAssetView}
                                                                        styles={customStyles}
                                                                    />
                                                                )}
                                                            />
                                                            {errors.unitId && (
                                                                <div className="invalid-feedback" style={{ display: "block" }}>
                                                                    {errors.unitId.message}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="col-6 col-md-6 col-lg-4 col-sm-12">
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" >
                                                                Branch
                                                            </div>
                                                            <Controller
                                                                name="branchId"
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        value={branchArray.find((c) => c.bR_ID === field.value) || null}
                                                                        onChange={(selectedOption) => {
                                                                            field.onChange(selectedOption ? selectedOption.bR_ID : null);
                                                                        }}
                                                                        options={branchArray}
                                                                        getOptionLabel={(option) => option.bR_NAME}
                                                                        getOptionValue={(option) => option.bR_ID}
                                                                        placeholder=""
                                                                        isClearable
                                                                        isSearchable
                                                                        isDisabled={isAssetView}
                                                                        styles={customStyles}
                                                                    />
                                                                )}
                                                            />
                                                            {errors.branchId && (
                                                                <div className="invalid-feedback" style={{ display: "block" }}>
                                                                    {errors.branchId.message}
                                                                </div>
                                                            )}
                                                        </div>

                                                        <div className="col-6 col-md-6 col-lg-4 col-sm-12">
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" >
                                                                Department
                                                            </div>
                                                            <Controller
                                                                name="departmentId"
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        value={departmentArray.find((c) => c.id === field.value) || null}
                                                                        onChange={(selectedOption) => {
                                                                            field.onChange(selectedOption ? selectedOption.id : null);
                                                                        }}
                                                                        options={departmentArray}
                                                                        getOptionLabel={(option) => option.departmentName}
                                                                        getOptionValue={(option) => option.id}
                                                                        placeholder=""
                                                                        isClearable
                                                                        isSearchable
                                                                        isDisabled={isAssetView}
                                                                        styles={customStyles}
                                                                    />
                                                                )}
                                                            />
                                                            {errors.departmentId && (
                                                                <div className="invalid-feedback" style={{ display: "block" }}>
                                                                    {errors.departmentId.message}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="row" style={{ paddingTop: "24px" }}>
                                                        <div className="col-6 col-md-6 col-lg-4 col-sm-12">
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" >
                                                                Category
                                                            </div>
                                                            <Controller
                                                                name="categoryId"
                                                                control={control}
                                                                rules={{
                                                                    required: 'Asset Category is required',
                                                                    validate: {
                                                                        validDate: (value) => {
                                                                            if (!value) return "Asset Category is required";
                                                                            return true;
                                                                        }
                                                                    }
                                                                }}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        value={categoryArray.find(option => option.id === field.value) || null}
                                                                        onChange={(selectedOption) => {
                                                                            field.onChange(selectedOption ? selectedOption.id : null);
                                                                        }}
                                                                        options={categoryArray}
                                                                        placeholder=""
                                                                        getOptionLabel={(option) => option.categoryName}
                                                                        getOptionValue={(option) => option.id}
                                                                        isClearable
                                                                        isSearchable
                                                                        isDisabled={isAssetView}
                                                                        styles={customStyles}
                                                                    />
                                                                )}
                                                            />
                                                            {errors.categoryId && (
                                                                <div className="invalid-feedback" style={{ display: "block" }}>
                                                                    {errors.categoryId.message}
                                                                </div>
                                                            )}
                                                        </div>

                                                        <div className="col-6 col-md-6 col-lg-4 col-sm-12">
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" >
                                                                Sub Category
                                                            </div>
                                                            <Controller
                                                                name="subCategoryId"
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        value={subCategoryArray.find(option => option.id === field.value) || null}
                                                                        onChange={(selectedOption) => {
                                                                            field.onChange(selectedOption ? selectedOption.id : null);
                                                                        }}
                                                                        options={subCategoryArray}
                                                                        placeholder=""
                                                                        getOptionLabel={(option) => option.subCategoryName}
                                                                        getOptionValue={(option) => option.id}
                                                                        isClearable
                                                                        isSearchable
                                                                        isDisabled={isAssetView}
                                                                        styles={customStyles}
                                                                    />
                                                                )}
                                                            />
                                                            {errors.subCategoryId && (
                                                                <div className="invalid-feedback" style={{ display: "block" }}>
                                                                    {errors.subCategoryId.message}
                                                                </div>
                                                            )}
                                                        </div>

                                                        <div className="col-6 col-md-6 col-lg-4 col-sm-12">
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                                                Supplier
                                                            </div>
                                                            <Controller
                                                                name="supplierId"
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        value={supplierArray.find((c) => c.id === field.value) || null}
                                                                        onChange={(selectedOption) => {
                                                                            field.onChange(selectedOption ? selectedOption.id : null);
                                                                        }}
                                                                        options={supplierArray}
                                                                        getOptionLabel={(option) => option.supplierName}
                                                                        getOptionValue={(option) => option.id}
                                                                        placeholder=""
                                                                        isClearable
                                                                        isSearchable
                                                                        isDisabled={isAssetView}
                                                                        styles={customStyles}
                                                                    />
                                                                )}
                                                            />
                                                            {errors.supplierId && (
                                                                <div className="invalid-feedback" style={{ display: "block" }}>
                                                                    {errors.supplierId.message}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="row" style={{ paddingTop: "24px" }}>
                                                        <div className="col-6 col-md-6 col-lg-4 col-sm-12 ">
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" >
                                                                Brand
                                                            </div>
                                                            <Controller
                                                                name="brandId"
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        value={brandArray.find(option => option.id === field.value) || null}
                                                                        onChange={(selectedOption) => {
                                                                            field.onChange(selectedOption ? selectedOption.id : null);
                                                                        }}
                                                                        options={brandArray}
                                                                        placeholder=""
                                                                        getOptionLabel={(option) => option.brandName}
                                                                        getOptionValue={(option) => option.id}
                                                                        isClearable
                                                                        isSearchable
                                                                        isDisabled={isAssetView}
                                                                        styles={customStyles}
                                                                    />
                                                                )}
                                                            />
                                                            {errors.brandId && (
                                                                <div className="invalid-feedback" style={{ display: "block" }}>
                                                                    {errors.brandId.message}
                                                                </div>
                                                            )}
                                                        </div>

                                                        <div className="col-6 col-md-6 col-lg-4 col-sm-12 ">
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                                                Model No.
                                                            </div>
                                                            <input
                                                                type="text"
                                                                className="textinputCustomAsset"
                                                                placeholder=""
                                                                {...register("modelNo")}
                                                                disabled={isAssetView}
                                                            />
                                                            {errors.modelNo && (
                                                                <div className="invalid-feedback" style={{ display: "block" }}>
                                                                    {errors.modelNo.message}
                                                                </div>
                                                            )}
                                                        </div>

                                                        <div className="col-6 col-md-6 col-lg-4 col-sm-12">
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                                                Serial No.
                                                            </div>
                                                            <input
                                                                type="text"
                                                                className="textinputCustomAsset"
                                                                placeholder=""
                                                                {...register("serialNo")}
                                                                disabled={isAssetView}
                                                            />
                                                            {errors.serialNo && (
                                                                <div className="invalid-feedback" style={{ display: "block" }}>
                                                                    {errors.serialNo.message}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="row" style={{ paddingTop: "24px" }}>
                                                        <div className="col-6 col-md-6 col-lg-4 col-sm-12">
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                                                Cost
                                                            </div>
                                                            <input
                                                                type="number"
                                                                className="textinputCustomAsset"
                                                                placeholder=""
                                                                {...register("cost")}
                                                                disabled={isAssetView}
                                                            />
                                                            {errors.cost && (
                                                                <div className="invalid-feedback" style={{ display: "block" }}>
                                                                    {errors.cost.message}
                                                                </div>
                                                            )}
                                                        </div>

                                                        <div className="col-6 col-md-6 col-lg-4 col-sm-12">
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                                                Purchase Date
                                                            </div>
                                                            <div className="d-flex">
                                                                <Controller
                                                                    name="purchaseDate"
                                                                    control={control}
                                                                    rules={{
                                                                        validate: {
                                                                            validDate: (value) => {
                                                                                // If the value is empty, it's valid because the field is optional
                                                                                if (!value) {
                                                                                    return true;
                                                                                }

                                                                                // Check if the value is a valid date object
                                                                                const dateValue = new Date(value);
                                                                                if (isNaN(dateValue.getTime())) {
                                                                                    return "Invalid date value";
                                                                                }

                                                                                // Format the date and validate it
                                                                                const formattedDate = format(dateValue, dateFormat);
                                                                                const [day, month, year] = formattedDate?.split('/');
                                                                                const selectedDate = validateDate(dateFormat, day, month, year);

                                                                                // Get today's date for comparison
                                                                                const today = new Date();
                                                                                today.setHours(0, 0, 0, 0);

                                                                                // Check if selected date is in the future
                                                                                if (selectedDate > today) {
                                                                                    return "Purchase Date cannot be in the future";
                                                                                }

                                                                                // Validate date format using regex
                                                                                const regex = getRegexForDateFormat(dateFormat);
                                                                                if (!regex.test(formattedDate)) {
                                                                                    return "Invalid date format (" + dateFormat + ")";
                                                                                }

                                                                                return true;
                                                                            },
                                                                        },
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <DatePicker
                                                                            {...field}
                                                                            selected={field.value}
                                                                            onChange={(date) => field.onChange(date)}
                                                                            dateFormat={dateFormat}
                                                                            className={isAssetView ? "customDatePickerDisable date-picker-input" : "customDatePicker date-picker-input"}
                                                                            disabled={isAssetView}
                                                                            ref={(input) => field.ref(input?.inputElement)}
                                                                        />
                                                                    )}
                                                                />
                                                                <img src={datesvgIcon} alt="datesvgIcon" height={45} />
                                                            </div>
                                                            {errors.purchaseDate && (
                                                                <div className="invalid-feedback" style={{ display: "block" }}>
                                                                    {errors.purchaseDate.message}
                                                                </div>
                                                            )}
                                                        </div>

                                                        <div className="col-6 col-md-6 col-lg-4 col-sm-12">
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" >
                                                                Employee
                                                            </div>
                                                            <Controller
                                                                name="empId"
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        value={employeeArray.find(emp => emp.id === field.value) || null}
                                                                        onChange={(selectedOption) => {
                                                                            field.onChange(selectedOption ? selectedOption.id : null);
                                                                        }}
                                                                        options={employeeArray}
                                                                        getOptionLabel={(option) => option.EmployeeName}
                                                                        getOptionValue={(option) => option.id}
                                                                        placeholder=""
                                                                        isClearable
                                                                        isSearchable
                                                                        isDisabled={isAssetView}
                                                                        styles={customStyles}
                                                                    />
                                                                )}
                                                            />
                                                            {errors.empId && (
                                                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                                                    {errors.empId.message}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>

                                                {!isAssetView ? (
                                                    <div style={{ marginTop: "8%", display: "flex", justifyContent: "center", paddingTop: 15, paddingBottom: 10, }}>
                                                        <button type="button" className="fontFamily fontweightRegular fontsize16 coloredButtonSave ButtonSpace" onClick={handleSubmit(handleAssetSubmit)}>
                                                            Save
                                                        </button>
                                                        <button
                                                            type="button"
                                                            id="btncloseModal"
                                                            onClick={handleCloseAssetDetailsView}
                                                            className="fontFamily fontweightRegular fontsize16 outlineButton"
                                                            style={{ marginLeft: "5px" }}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <div className="CustomBoxShadow" style={{ marginTop: "20px" }}>
                                                        <div className="" style={{ textAlign: 'center', margin: '12px 0px', display: 'flex', justifyContent: 'space-between' }}>
                                                            <div className="blackTextColor fontFamily fontsize20 fontweightMedium">
                                                                Managed by
                                                            </div>

                                                            <div className="col-6 col-md-6 col-lg-3 col-sm-12">
                                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                                                    Last Updated Time Stamp
                                                                </div>
                                                                <input
                                                                    type="text"
                                                                    className="textinputCustomAsset textAlignCenter"
                                                                    {...register("updateDateTime")}
                                                                    disabled={true}
                                                                />
                                                            </div>

                                                            <div className="col-6 col-md-6 col-lg-3 col-sm-12">
                                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                                                    Updated by
                                                                </div>
                                                                <input
                                                                    type="text"
                                                                    className="textinputCustomAsset textAlignCenter"
                                                                    {...register("updatedBy")}
                                                                    disabled={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        )}

                                        {tabActive === 'Warranty' && (
                                            <div id="printableContent">
                                                <div className="mTop20 flexEnd">
                                                    <button
                                                        type="button"
                                                        style={{ cursor: isAssetView ? "not-allowed" : "pointer", }}
                                                        disabled={isAssetView}
                                                        className="coloredButtonSave fontFamily fontweightRegular fontsize16"
                                                        onClick={handleWarrantyAdd}
                                                    >
                                                        + Add New
                                                    </button>
                                                </div>

                                                <AssetWarrantySection
                                                    IsWarranty={isWarrantyView || isWarrantyAdd}
                                                    onSubmit={handleWarrantySubmit}
                                                    IsWarrantyView={isWarrantyView}
                                                    IsWarrantyAdd={isWarrantyAdd}
                                                    SelectedWarrantyValue={selectedWarrantyValue}
                                                    dateFormat={dateFormat}
                                                    purchaseDate={currentAssetData?.purchaseDate}
                                                    onClose={onWarrantyClose}
                                                />

                                                <div className="" style={{ padding: "10px" }}>
                                                    <table className="w-100 mt-2">
                                                        <thead>
                                                            <tr className="text-left empTableHeight">
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg" style={{ width: "15%", padding: "2px 2px 2px 3%", }} > Active </th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg" style={{ width: "15%" }}> Expiration Date </th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg" style={{ width: "15%" }}> Length </th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg" style={{ width: "20%" }}> Notes </th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg" style={{ width: "10%" }} > Action </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {assetWarrantyArray.length > 0 ? (
                                                                assetWarrantyArray?.map((warranty, index) => (
                                                                    <tr key={warranty.Rowid} style={{ height: "59px" }}>
                                                                        <td className={`${index % 2 === 0 ? "" : "tablerowBackgroundColor"} `} style={{ padding: "2px 2px 2px 3%" }}>
                                                                            {warranty.currentStatus ? (
                                                                                <img src={greenTickMark} alt="greenTickMark" height={15} />
                                                                            ) : (
                                                                                <img src={redXmark} alt="redXmark" height={15} />
                                                                            )}
                                                                        </td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} >{warranty.formattedExpirationDate}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} >{warranty.length + " Months"}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`}>
                                                                            {warranty.notes ? warranty.notes : (<img src={ExclamationIcon} alt="ExclamationIcon" height={15} />)}
                                                                        </td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 ~p-2 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} >
                                                                            <span style={{ cursor: "pointer" }}><img style={{ width: "18px", height: "15px", }} src={viewsvgIcon} alt="viewsvgIcon" onClick={() => handleWarrantyViewOrEdit(warranty, true)} /></span>
                                                                            <span style={{ marginLeft: "10px", cursor: "pointer", }}><img style={{ width: "18px", height: "15px", }} src={editsvgIcon} alt="editsvgIcon" onClick={() => handleWarrantyViewOrEdit(warranty, false)} /></span>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <td colSpan="5" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        )}

                                        {tabActive === 'Insurance' && (
                                            <div id="printableContent">
                                                <div className="mTop20 flexEnd">
                                                    <button
                                                        type="button"
                                                        style={{ cursor: isAssetView ? "not-allowed" : "pointer", }}
                                                        disabled={isAssetView}
                                                        className="coloredButtonSave fontFamily fontweightRegular fontsize16"
                                                        onClick={handleInsuranceAdd}
                                                    >
                                                        <img src={LinkIcon} alt="LinkIcon" height={15} style={{ marginRight: "8px" }} />Link Insurance
                                                    </button>
                                                </div>

                                                <AssetInsuranceSection
                                                    IsInsurance={isInsuranceAdd}
                                                    onSubmit={handleInsuranceSubmit}
                                                    insuranceArray={unlinkedInsuranceArray}
                                                    onClose={onInsuranceClose}
                                                />

                                                <div className="" style={{ padding: "10px" }}>
                                                    <table className="w-100 mt-2">
                                                        <thead>
                                                            <tr className="text-left empTableHeight">
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg" style={{ width: "15%", padding: "2px 2px 2px 3%", }}> Insurance Name </th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg" style={{ width: "15%" }}> Insurance Co. </th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg" style={{ width: "12%" }}> Contact Person </th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg" style={{ width: "12%" }}> Start Date </th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg" style={{ width: "12%" }}> End Date </th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg" style={{ width: "8%" }}> Action </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {currentAssetInsuranceArray.length > 0 ? (
                                                                currentAssetInsuranceArray?.map((insurance, index) => (
                                                                    <tr key={insurance.Rowid} style={{ height: "59px" }}>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} style={{ padding: "2px 2px 2px 3%" }}>{insurance.insuranceName}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} >{insurance.insuranceCompany}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`}>
                                                                            {insurance.contactPerson ? insurance.contactPerson : (<img src={ExclamationIcon} alt="ExclamationIcon" height={15} />)}
                                                                        </td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} >{insurance.formattedStartDate}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} >{insurance.formattedEndDate}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 ~p-2 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} >
                                                                            <span style={{ cursor: "pointer", color: '#FF0000' }} onClick={() => handleDeleteInsurance(insurance)}> Detach </span>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <td colSpan="5" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        )}

                                        {tabActive === 'Maintenance' && (
                                            <div id="printableContent">
                                                <div className="mTop20 flexEnd">
                                                    <button
                                                        type="button"
                                                        style={{ cursor: isAssetView ? "not-allowed" : "pointer", }}
                                                        disabled={isAssetView}
                                                        className="coloredButtonSave fontFamily fontweightRegular fontsize16"
                                                        onClick={handleMaintenanceAdd}
                                                    >
                                                        Add Maintenance
                                                    </button>
                                                </div>

                                                <AssetMaintenanceSection
                                                    onSubmit={onSubmitMaintenance}
                                                    IsMaintenanceView={IsMaintenanceView}
                                                    IsMaintenanceEdit={IsMaintenanceEdit}
                                                    electedMaintenanceValue={SelectedMaintenanceValue}
                                                    dateFormat={dateFormat}
                                                    onCancel={onMaintenaceCancel}
                                                    IsAddMaintenance={IsAddMaintenance}
                                                    IsMaintenance={IsMaintenance}
                                                    calendarsvgIcon={calendarsvgIcon}
                                                />

                                                <div className="" style={{ padding: "10px" }}>
                                                    <table className="w-100 mt-2">
                                                        <thead>
                                                            <tr className="text-left empTableHeight">
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg textAlignCenter" style={{ width: "15%", padding: "2px 2px 2px 3%", }} > Schedule date </th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg textAlignCenter" style={{ width: "30%" }}> Title </th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg textAlignCenter" style={{ width: "30%" }}> Maintenance By </th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg textAlignCenter" style={{ width: "15%" }}> Status </th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg ~p-2 textAlignCenter" style={{ width: "10%" }} > Action </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {AssetMaintenanceArray.length > 0 ? (
                                                                AssetMaintenanceArray?.map((Maintenance, index) => (
                                                                    <tr key={Maintenance.id} style={{ height: "59px" }}>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"} textAlignCenter`} style={{ padding: "2px 2px 2px 4%" }} >{Maintenance.formattedScheduleDate}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"} textAlignCenter`} >{Maintenance.title}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"} textAlignCenter`} >{Maintenance.maintenanceByValue}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"} textAlignCenter`} >{Maintenance.maintenanceStatusValue}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 ~p-2 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"} textAlignCenter`} >
                                                                            <span style={{ cursor: "pointer" }}><img style={{ width: "18px", height: "15px", }} src={viewsvgIcon} alt="viewsvgIcon" onClick={() => btnViewMaintenance(Maintenance)} /></span>
                                                                            {Maintenance.maintenanceStatus != 'completed' && (
                                                                                < span style={{ marginLeft: '10px', cursor: 'pointer' }}><img src={editsvgIcon} alt="editsvgIcon" onClick={() => editViewMaintenance(Maintenance)} /></span>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <td colSpan="5" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        )}

                                        {tabActive === 'Attachments' && (
                                            <div id="printableContent">
                                                <div className="mTop20 flexEnd">
                                                    <button
                                                        type="button"
                                                        style={{ cursor: isAssetView ? "not-allowed" : "pointer", }}
                                                        disabled={isAssetView}
                                                        className="coloredButtonSave fontFamily fontweightRegular fontsize16"
                                                        onClick={handleAttachmentUpload}
                                                    >
                                                        Upload
                                                    </button>
                                                </div>

                                                <AssetAttachmentSection
                                                    onSubmit={onSubmitAttachments}
                                                    isAttachmentView={isAttachmentView}
                                                    attachmentDropArray={attachmentDropArray}
                                                    onCancel={onCancel}
                                                    isAttachment={isAttachmentAdd}
                                                />

                                                <div className="" style={{ padding: "10px" }}>
                                                    <table className="w-100 mt-2">
                                                        <thead>
                                                            <tr className="text-left empTableHeight">
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg  " style={{ width: "12%", padding: "2px 2px 2px 3%", }} > SI.No </th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg pLeft40" > Type </th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg pRight150" > File Name </th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 " style={{ width: "10%" }} > Action </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {assetAttachmentArray.length > 0 ? (
                                                                assetAttachmentArray?.map((Attach, index) => (
                                                                    <tr key={Attach.Rowid} style={{ height: "59px" }}>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} style={{ padding: "2px 2px 2px 4%" }} >{Attach.Rowid}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 pLeft40 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} >{Attach.DocumentType}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} >{Attach.assetDocumentLink}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} >
                                                                            <span style={{ cursor: "pointer" }}><a href="#viewAssetAttachment" onClick={() => setIsAttachmentView(true)}><img style={{ width: "18px", height: "15px", }} src={viewsvgIcon} alt="viewsvgIcon" /></a></span>
                                                                            <span style={{ marginLeft: "10px", cursor: "pointer", }}><a href="#deleteAssetAttachment" onClick={() => handleDeleteAttachment(Attach)}><img src={deletesvgIcon} alt="deletesvgIcon" /></a></span>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <td colSpan="4" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        )}

                                        {tabActive === 'Linking' && (
                                            <div id="printableContent">
                                                <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-start", flexDirection: "column" }}>
                                                    <div style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                                                        <input
                                                            type="checkbox"
                                                            id="transactAsWhole"
                                                            style={{ marginRight: "8px" }}
                                                            // value={transactAsWhole}
                                                            checked={transactAsWhole}
                                                            onChange={handleCheckboxChange}
                                                            disabled={isAssetView}
                                                        />
                                                        <label
                                                            htmlFor="transactAsWhole"
                                                            className="blackTextColor fontFamily fontsize16 fontweightMedium"
                                                        >
                                                            Transact as a whole
                                                        </label>
                                                    </div>

                                                    <div className="fontFamily fontweightRegular fontsize14 grayTextColor mTop20" >
                                                        Select this checkbox to indicate that this asset and its linking asset should be audited/check-out/checked-in,etc., as a group.
                                                    </div>

                                                    <div style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                                                        <button
                                                            type="button"
                                                            style={{ cursor: isAssetView ? "not-allowed" : "pointer", }}
                                                            disabled={isAssetView}
                                                            className="coloredButtonSave fontFamily fontweightRegular fontsize16 mRight20"
                                                            onClick={() => handleLink("Parent")}
                                                        >
                                                            <img src={LinkIcon} alt="LinkIcon" height={15} style={{ marginRight: "8px" }} />Link Parent Asset
                                                        </button>
                                                        <button
                                                            type="button"
                                                            style={{ cursor: isAssetView ? "not-allowed" : "pointer", }}
                                                            disabled={isAssetView}
                                                            className="coloredButtonSave fontFamily fontweightRegular fontsize16"
                                                            onClick={() => handleLink("Child")}
                                                        >
                                                            <img src={LinkIcon} alt="LinkIcon" height={15} style={{ marginRight: "8px" }} />Link Child Asset
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="" style={{ padding: "10px" }}>
                                                    <table className="w-100 mt-2">
                                                        <thead>
                                                            <tr className="text-left empTableHeight">
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg" style={{ width: "12%", padding: "2px 2px 2px 3%" }}></th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2" style={{ width: "15%" }}>Relation</th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2" style={{ width: "15%" }}>Asset ID</th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2" style={{ width: "15%" }}>Description</th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2" style={{ width: "10%" }}></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {assetLinkArray.length > 0 ? (
                                                                assetLinkArray?.map((link, index) => {
                                                                    // Define colors
                                                                    const grayTextColor = "#888787";
                                                                    const blackTextColor = "#000000";
                                                                    const parentTextColor = "#2ED47A";
                                                                    const childTextColor = "#605BFF";

                                                                    // Determine text color based on the relationship
                                                                    const textColor = link.relation === "Parent" ? parentTextColor :
                                                                        link.relation === "Child" ? childTextColor :
                                                                            grayTextColor;

                                                                    return (
                                                                        <tr key={index} style={{ height: "59px" }}>
                                                                            <td className={`fontFamily fontweightRegular fontsize14 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`}
                                                                                style={{ padding: "2px 2px 2px 4%", color: grayTextColor }}>
                                                                                {link.assetId === assetId && link.relation === "Parent" ? "this Asset >" : ""}
                                                                            </td>
                                                                            <td className={`fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`}
                                                                                style={{ color: textColor }}>{link.relation}</td>
                                                                            <td className={`fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`}
                                                                                style={{ color: textColor }}>{link.assetTagID}</td>
                                                                            <td className={`fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`}
                                                                                style={{ color: textColor }}>{link.description}</td>
                                                                            <td className={`fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`}
                                                                                style={{ color: blackTextColor }}>
                                                                                {link.relation === "Child" && (
                                                                                    <span style={{ marginLeft: "10px", cursor: "pointer" }}>
                                                                                        <a href="#unlinkAsset" onClick={() => handleUnlinkAsset(link)}>
                                                                                            <img src={unLink} alt="unLink" style={{ marginRight: "8px" }} />
                                                                                            UnLink
                                                                                        </a>
                                                                                    </span>
                                                                                )}
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan="5" className="text-center fontFamily fontweightRegular fontsize16">
                                                                        <img src={nodataFound} alt="no data found" />
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        )}

                                        {tabActive === 'Depreciation' && (
                                            <div id="printableContent">
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "20px" }}>
                                                    <div className="CustomBoxShadow" >
                                                        <div className="grayTextColor fontFamily fontsize16 fontweightMedium textAlignCenter">
                                                            Date Acquired
                                                        </div>
                                                        <div className="blackTextColor fontFamily fontsize16 fontweightMedium mTop12 textAlignCenter" >
                                                            {currentAssetData?.formattedDateAcquired}
                                                        </div>
                                                    </div>

                                                    <div className="CustomBoxShadow" >
                                                        <div className="grayTextColor fontFamily fontsize16 fontweightMedium textAlignCenter">
                                                            Depreciable Cost
                                                        </div>
                                                        <div className="blackTextColor fontFamily fontsize16 fontweightMedium mTop12 textAlignCenter">
                                                            ₹ {currentAssetData?.depreciableCost}
                                                        </div>
                                                    </div>

                                                    <div className="CustomBoxShadow" >
                                                        <div className="grayTextColor fontFamily fontsize16 fontweightMedium textAlignCenter">
                                                            Salvage Value
                                                        </div>
                                                        <div className="blackTextColor fontFamily fontsize16 fontweightMedium mTop12 textAlignCenter">
                                                            ₹ {currentAssetData?.salvageValue}
                                                        </div>
                                                    </div>

                                                    <div className="CustomBoxShadow" >
                                                        <div className="grayTextColor fontFamily fontsize16 fontweightMedium textAlignCenter">
                                                            Asset Life(moths)
                                                        </div>
                                                        <div className="blackTextColor fontFamily fontsize16 fontweightMedium mTop12 textAlignCenter">
                                                            {currentAssetData?.assetLife} months
                                                        </div>
                                                    </div>

                                                    <div className="CustomBoxShadow" >
                                                        <div className="grayTextColor fontFamily fontsize16 fontweightMedium textAlignCenter">
                                                            Depr. Method
                                                        </div>
                                                        <div className="blackTextColor fontFamily fontsize16 fontweightMedium mTop12 textAlignCenter">
                                                            {currentAssetData?.depreciationMethod}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="mTop20 flexEnd">
                                                    <button
                                                        type="button"
                                                        style={{ cursor: isAssetView ? "not-allowed" : "pointer", }}
                                                        disabled={isAssetView}
                                                        className="coloredButtonSave fontFamily fontweightRegular fontsize16"
                                                        onClick={handleDepreciation}
                                                    >
                                                        + Manage
                                                    </button>
                                                </div>
                                                <div className="CustomBoxShadow" >
                                                    <div className="fontFamily fontsize16 fontweightRegular"> Depreciation yearly starts</div>
                                                    <div>
                                                        <ApexCharts
                                                            options={options}
                                                            series={series}
                                                            type="area"
                                                            height={350}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="CustomBoxShadow" >
                                                    <div className="" style={{ padding: "10px" }}>
                                                        <table className="w-100 mt-2">
                                                            <thead>
                                                                <tr className="text-left empTableHeight">
                                                                    <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg  " style={{ width: "10%", padding: "2px 2px 2px 3%", }} > Year </th>
                                                                    <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 " style={{ width: "10%" }} > Depreciation expense </th>
                                                                    <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 " style={{ width: "15%" }} > Accumulated depreciation at year-end </th>
                                                                    <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 " style={{ width: "10%" }} > Book value at year-end </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {finalDepreciationArray.length > 0 ? (
                                                                    finalDepreciationArray?.map((Asset, index) => (
                                                                        <tr key={Asset.Rowid} style={{ height: "59px" }}>
                                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} style={{ padding: "2px 2px 2px 4%" }} >{Asset.year}</td>
                                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} > {index != 0 ? `₹ ${Asset.depreciationExpenst}` : Asset.depreciationExpenst}</td>
                                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} >{index != 0 ? `₹ ${Asset.accumlatedDepreciation}` : Asset.accumlatedDepreciation}</td>
                                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} >₹ {Asset.bookValue}</td>
                                                                        </tr>
                                                                    ))
                                                                ) : (
                                                                    <td colSpan="4" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                            </div>
                                        )}

                                        {tabActive === 'Request' && (
                                            <div id="printableContent">
                                                <div className="mTop20 flexEnd">
                                                    <button
                                                        type="button"
                                                        style={{ cursor: isAssetView ? "not-allowed" : "pointer", }}
                                                        disabled={isAssetView}
                                                        className="coloredButtonSave fontFamily fontweightRegular fontsize16"
                                                        onClick={handleRegisterAdd}
                                                    >
                                                        Add Request
                                                    </button>
                                                </div>

                                                <AssetRequestSection
                                                    onSubmit={onSubmitRequest}
                                                    IsRequestView={IsRequestView}
                                                    IsRequestEdit={IsRequestEdit}
                                                    SelectedRequestValue={SelectedRequestValue}

                                                    AssetRequestCategoryDropdown={AssetRequestCategoryDropdown}
                                                    AssetRequestSubCategoryDropdown={AssetRequestSubCategoryDropdown}
                                                    AssetRequestPriorityDropdown={AssetRequestPriorityDropdown}
                                                    AssetRequestSettingsValue={AssetRequestSettingsValue}

                                                    onCancel={onRequestCancel}
                                                    IsAddRequest={IsAddRequest}
                                                    IsRequest={IsRequest}
                                                />

                                                <div className="" style={{ padding: "10px" }}>
                                                    <table className="w-100 mt-2">
                                                        <thead>
                                                            <tr className="text-left empTableHeight">
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg textAlignCenter" style={{ width: "8%", padding: "2px 2px 2px 3%", }} > S.No </th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg textAlignCenter" style={{ width: "15%" }}> Request ID </th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg textAlignCenter" style={{ width: "15%" }}> Request Category </th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg textAlignCenter" style={{ width: "20%" }}> Description </th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg ~p-2 textAlignCenter" style={{ width: "15%" }} > Created On </th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg ~p-2 textAlignCenter" style={{ width: "10%" }} > Priority </th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg ~p-2 textAlignCenter" style={{ width: "10%" }} > Status </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {AssetRequestArray.length > 0 ? (
                                                                AssetRequestArray.map((Request, index) => (
                                                                    <tr key={Request.id} style={{ height: "59px" }}>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"} textAlignCenter`} style={{ padding: "2px 2px 2px 4%" }} >{index + 1}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"} textAlignCenter`} >{Request.requestID}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"} textAlignCenter`} >{Request.requestCategoryValue}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"} textAlignCenter`} >{Request.subject}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"} textAlignCenter`} >{Request.formattedCreatedDate}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"} textAlignCenter`} >{Request.requestPriorityValue}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"} textAlignCenter`} style={{ color: '#109CF1' }} >{Request.requestStatusValue}</td>
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <td colSpan="7" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        )}

                                        {tabActive === 'History' && (
                                            <div id="printableContent">
                                                <div className="d-flex justify-content-between align-items-center" style={{ paddingBottom: "8px" }}>
                                                    <div className="custom-container">
                                                        <span className="blackTextColor fontFamily fontsize16 fontweightRegular">
                                                            <span className="show">Show</span>
                                                            <select className="custom-dropdown" value={rowsPerpageAssetHistory} onChange={handleRowsPerPageChangeAsset} >
                                                                {paginateArrayHistory?.map((n) => (
                                                                    <option className="blackTextColor fontFamily fontsize14 fontweightRegular" key={n} value={n} >
                                                                        {n}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            <span className="blackTextColor entries fontFamily fontsize16 fontweightRegular">
                                                                Entries
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <div className="mTop20 flexEnd">
                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                            <input
                                                                type="text"
                                                                placeholder="Search..."
                                                                value={searchQueryAssetHistory}
                                                                onChange={handleSearchChangeAssetHistory}
                                                                className="searchTextBox mRight20"
                                                            />
                                                            <button
                                                                type="button"
                                                                style={{ cursor: isAssetView ? "not-allowed" : "pointer", }}
                                                                disabled={isAssetView}
                                                                className="coloredButtonSave fontFamily fontweightRegular fontsize16"
                                                                onClick={handleAdd}
                                                            >
                                                                Add Comments
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-1">
                                                    <div className="" style={{ padding: "10px" }}>
                                                        <table className="w-100 mt-2">
                                                            <thead>
                                                                <tr className="text-left empTableHeight">
                                                                    <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg  " style={{ width: "12%", padding: "2px 2px 2px 3%", }} > Date </th>
                                                                    <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 " style={{ width: "15%" }} > Event </th>
                                                                    <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 " style={{ width: "15%" }} > Field </th>
                                                                    <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 " style={{ width: "15%" }} > Changed from </th>
                                                                    <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 " style={{ width: "15%" }} > Changed to </th>
                                                                    <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 " style={{ width: "10%" }} > Action by </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {filteredAssetHistory.length > 0 ? (
                                                                    filteredAssetHistory.slice(pageAssetHistory * rowsPerpageAssetHistory, (pageAssetHistory + 1) * rowsPerpageAssetHistory)?.map((history, index) => (
                                                                        <tr key={history.Rowid} style={{ height: "59px" }}>
                                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} >{history.assetDate}</td>
                                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} >{history.assetEvent}</td>
                                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} >{history.assetField}</td>
                                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} >{history.assetChangedFrom}</td>
                                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} >{history.assetChangedTo}</td>
                                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} >{history.assetActionBy}</td>
                                                                        </tr>
                                                                    ))
                                                                ) : (
                                                                    <td colSpan="4" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    {totalEntriesAssetHistory > 0 && (
                                                        <div>
                                                            <div className="" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingTop: "16px", }}>
                                                                <div className="text-muted fontFamily fontsize12 fontweightRegular">
                                                                    Showing {startEntryAssetHistory} to {endEntryAssetHistory} of {totalEntriesAssetHistory} entries
                                                                </div>
                                                                <div>
                                                                    <div className="pagination-container d-flex align-items-center">
                                                                        <button className="paginationButton btn btn-link" onClick={handleFirstpageAssetHistory} >
                                                                            <FontAwesomeIcon icon={faAngleDoubleLeft} className="icon-color" />
                                                                        </button>
                                                                        <ReactPaginate
                                                                            previousLabel={<FontAwesomeIcon icon={faChevronLeft} className="icon-color" />}
                                                                            nextLabel={<FontAwesomeIcon icon={faChevronRight} className="icon-color" />}
                                                                            breakLabel={"..."}
                                                                            breakClassName={"break-me"}
                                                                            pageCount={Math.ceil(totalEntriesAssetHistory / rowsPerpageAssetHistory)}
                                                                            forcePage={pageAssetHistory}
                                                                            marginPagesDisplayed={2}
                                                                            pageRangeDisplayed={5}
                                                                            onPageChange={handleChangePagePaginationAssetHistory}
                                                                            containerClassName={"pagination"}
                                                                            subContainerClassName={"pages pagination"}
                                                                            activeClassName={"active"}
                                                                            renderOnZeroPageCount={null}
                                                                        />
                                                                        <button className="paginationButton btn btn-link" onClick={handleLastpageAssetHistory}>
                                                                            <FontAwesomeIcon icon={faAngleDoubleRight} className="icon-color" />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div style={{ padding: "0px 100px" }}></div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}

                                        {selectedValue === "Check Out" && (
                                            <CheckOutSection
                                                onSubmit={onSubmitCheckOut}
                                                isCheckOutView={isCheckOutView}
                                                dateFormat={dateFormat}
                                                calendarsvgIcon={calendarsvgIcon}
                                                onCancel={handleCancel}
                                                isCheckOut={true}
                                                employeeArray={employeeArray}
                                                unitArray={unitArray}
                                                branchArray={branchArray}
                                                departmentArray={departmentArray}
                                            />
                                        )}

                                        {selectedValue === "Check In" && (
                                            <CheckInSection
                                                onSubmit={onSubmitCheckIn}
                                                isCheckInView={isCheckInView}
                                                dateFormat={dateFormat}
                                                calendarsvgIcon={calendarsvgIcon}
                                                onCancel={handleCancel}
                                                isCheckIn={true}
                                                unitArray={unitArray}
                                                branchArray={branchArray}
                                                departmentArray={departmentArray}
                                            />
                                        )}

                                        {selectedValue === "Lost" && (
                                            <LostSection
                                                onSubmit={onSubmitLost}
                                                isLostView={isLostView}
                                                dateFormat={dateFormat}
                                                calendarsvgIcon={calendarsvgIcon}
                                                onCancel={handleCancel}
                                                isLost={true}
                                            />
                                        )}

                                        {selectedValue === "Found" && (
                                            <FoundSection
                                                onSubmit={onSubmitFound}
                                                isFoundView={isFoundView}
                                                dateFormat={dateFormat}
                                                calendarsvgIcon={calendarsvgIcon}
                                                onCancel={handleCancel}
                                                isFound={true}
                                            />
                                        )}

                                        {selectedValue === "Repair" && (
                                            <RepairSection
                                                onSubmit={onSubmitRepair}
                                                isRepairView={isRepairView}
                                                dateFormat={dateFormat}
                                                calendarsvgIcon={calendarsvgIcon}
                                                onCancel={handleCancel}
                                                isRepair={true}
                                                currentEmployeeId={employeeId}
                                                employeeArray={employeeArray}
                                                isRepairUpdate={false}
                                            />
                                        )}

                                        {selectedValue === "Repair Complete" && (
                                            <RepairSection
                                                onSubmit={onSubmitRepairCompleted}
                                                isRepairView={isRepairView}
                                                dateFormat={dateFormat}
                                                calendarsvgIcon={calendarsvgIcon}
                                                onCancel={handleCancel}
                                                isRepair={true}
                                                employeeArray={employeeArray}
                                                isRepairUpdate={true}
                                                existingRepairData={assetRepairData}
                                            />
                                        )}

                                        {selectedValue === "Broken" && (
                                            <BrokenSection
                                                onSubmit={onSubmitBroken}
                                                isBrokenView={isBrokenView}
                                                dateFormat={dateFormat}
                                                calendarsvgIcon={calendarsvgIcon}
                                                onCancel={handleCancel}
                                                isBroken={true}
                                            />
                                        )}

                                        {selectedValue === "Dispose" && (
                                            <DisposeSection
                                                onSubmit={onSubmitDispose}
                                                isDisposeView={isDisposeView}
                                                dateFormat={dateFormat}
                                                calendarsvgIcon={calendarsvgIcon}
                                                onCancel={handleCancel}
                                                isDispose={true}
                                            />
                                        )}

                                        {selectedValue === "Donate" && (
                                            <DonateSection
                                                onSubmit={onSubmitDonate}
                                                isDonateView={isDonateView}
                                                dateFormat={dateFormat}
                                                calendarsvgIcon={calendarsvgIcon}
                                                onCancel={handleCancel}
                                                isDonate={true}
                                            />
                                        )}

                                        {selectedValue === "Sell" && (
                                            <SellSection
                                                onSubmit={onSubmitSell}
                                                isSellView={isSellView}
                                                dateFormat={dateFormat}
                                                calendarsvgIcon={calendarsvgIcon}
                                                onCancel={handleCancel}
                                                isSell={true}
                                                rupeesvgIcon={rupeesvgIcon}
                                            />
                                        )}

                                        {selectedValue === "Asset Link" && (
                                            <AssetLinkSection
                                                onSubmit={onSubmitLink}
                                                isLinkView={isLinkView}
                                                selectedLinkValue={selectedLinkValue}
                                                assetNameAndID={assetNameAndID}
                                                childDropArray={assetArrayWithoutLinkedAsset}
                                                onCancel={handleCancel}
                                                isLink={true}
                                            />
                                        )}

                                        {selectedValue === "Depreciation" && (
                                            <AssetDepreciationSection
                                                onSubmit={onSubmitDepreciation}
                                                isDepreciationView={isDepreciationView}
                                                DepreciationValue={depreciationValue}
                                                depreciationMethodOptions={depreciationMethodOptions}
                                                onCancel={handleCancel}
                                                dateFormat={dateFormat}
                                                isDepreciation={true}
                                            />
                                        )}

                                        {selectedValue === "Add" && (
                                            <AssetAddSection
                                                onSubmit={onSubmitAdd}
                                                onCancel={handleCancel}
                                                isAdd={true}
                                            />
                                        )}

                                        {selectedValue === "Clone" && (
                                            <CopySection
                                                onSubmit={onSubmitClone}
                                                isCopyAssetView={isCopyAssetView}
                                                onCancel={handleCancel}
                                                isCopyAsset={true}
                                                assetID={currentAssetData.assetID}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div >
    );
};

export default AssetDetailsView;
