import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import ReactPaginate from 'react-paginate';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ThreeCircles } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import Switch from '@mui/material/Switch';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';

import ConfirmDelete from '../../../CommomPages/ConfirmDelete';
import LoadingSpinner from '../../../CommomPages/LoadingSpinner';
import { exportDataUnit, exportDataBranch } from './ExportData';
import { getBUData, insertBUData, updateBUData, deleteBUData, getBEData, updateBEData, getBRData, setBranchStatus, insertBRData, updateBRData, deleteBRData } from '../../../Services/BusinessEntityNewService';
import { getTeam } from '../../../Services/DepartmentsAndTeamService';
import { getEmployee } from '../../../Services/EmployeeManagementService';
import { getCheckIn, getCheckOut } from '../../../Services/ActionFormConfigurationService';
import { getASTSettings } from '../../../Services/AssetSettingsService';
import { getCountriesList, getStatesList, getCitiesList } from '../../LocationData/LocationData';

import './BusinessEntityNew.css';

import viewsvgIcon from '../../../assets/icon/Eye.svg';
import editsvgIcon from '../../../assets/icon/Edit.svg';
import deletesvgIcon from '../../../assets/icon/Delete.svg';
import uploadImg from '../../../assets/img/uploadImage.png';
import nodataFound from '../../../assets/img/nodatafound.png';


const BusinessEntityNew = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [tabActive, setTabActive] = useState('BussinessEntity');
    const [tabStatusActive, setTabStatusActive] = useState('Bussiness Entity');
    const [countryArray, setCountryArray] = useState([]);
    const [stateArray, setStateArray] = useState([]);
    const [cityArray, setCityArray] = useState([]);
    const [countryOption, setCountryOption] = useState([]);
    const [stateOption, setStateOption] = useState([]);
    const [cityOption, setCityOption] = useState([]);
    const [pageUnit, setPageUnit] = useState(0);
    const [rowsUnitPerPage, setRowsUnitPerPage] = useState(5);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [pageBranch, setPageBranch] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    // input field variables for Business Unit.
    const [countryUnitOption, setCountryUnitOption] = useState([]);
    const [stateUnitOption, setStateUnitOption] = useState([]);
    const [cityUnitOption, setCityUnitOption] = useState([]);
    const [businessUnitArray, setBusinessUnitArray] = useState([]);
    const [selectedUnitID, setSelectedUnitID] = useState(0);
    const [unitName, setUnitName] = useState('');
    const [unitImage, setUnitImage] = useState('');
    const [unitMobileNumber, setUnitMobileNumber] = useState('');
    const [unitStreetAddress, setUnitStreetAddress] = useState('');
    const [countrySelectedOption, setCountrySelectedOption] = useState(null);
    const [stateSelectedOption, setStateSelectedOption] = useState(null);
    const [citySelectedOption, setCitySelectedOption] = useState(null);
    const [unitPostalCode, setUnitPostalCode] = useState('');
    const [unitFaxNumber, setUnitFaxNumber] = useState('');
    const [unitEmail, setUnitEmail] = useState('');
    const [unitWebsite, setUnitWebsite] = useState('');
    const [unitLandline, setUnitLandline] = useState('');
    const [unitTINnumber, setUnitTINnumber] = useState('');
    const [unitGSTNumber, setUnitGSTNumber] = useState('');
    const [unitRegisterDate, setUnitRegisterDate] = useState(new Date());
    const [unitIsSubmit, setUnitIsSubmit] = useState(false);
    const [unitIsSave, setUnitIsSave] = useState(false);
    const [isUnitView, setIsUnitView] = useState(false);
    const [previewUnitImg, setPreviewUnitImg] = useState(uploadImg);

    // inpurt field variables for Business Entity.
    const [selectedEntityID, setSelectedEntityID] = useState(1);
    const [entityName, setEntityName] = useState('');
    const [previewEntityImg, setPreviewEntityImg] = useState(uploadImg);
    const [entityImg, setEntityImg] = useState('');
    const [entityMobileNumber, setEntityMobileNumber] = useState('');
    const [entityStreetAddress, setEntityStreetAddress] = useState('');
    const [countrySelectedEntityOption, setCountrySelectedEntityOption] = useState(null);
    const [stateSelectedEntityOption, setStateSelectedEntityOption] = useState(null);
    const [citySelectedEntityOption, setCitySelectedEntityOption] = useState(null);
    const [entityPostalCode, setEntityPostalCode] = useState('');
    const [entityFaxNumber, setEntityFaxNumber] = useState('');
    const [entityEmail, setEntityEmail] = useState('');
    const [entityWebsite, setEntityWebsite] = useState('');
    const [entityLandline, setEntityLandline] = useState('');
    const [entityTINnumber, setEntityTINnumber] = useState('');
    const [entityGSTNumber, setEntityGSTNumber] = useState('');
    const [entityRegisterDate, setEntityRegisterDate] = useState(new Date());
    const [entityIsSubmit, setEntityIsSubmit] = useState(false);

    // Email field validations.
    const [unitEmailValidation, setUnitEmailValidation] = useState(false);
    const [entityEmailValidation, setEntityEmailValidation] = useState(false);
    const [branchEmailValidation, setBranchEmailValidation] = useState(false);

    // Input field variables for Business Branch.
    const [countryBranchOption, setCountryBranchOption] = useState([]);
    const [stateBranchOption, setStateBranchOption] = useState([]);
    const [cityBranchOption, setCityBranchOption] = useState([]);
    const [businessBranchArray, setBusinessBranchArray] = useState([]);
    const [unitOption, setUnitOption] = useState([]);
    const [unitSelectedOption, setUnitSelectedOption] = useState(null);
    const [branchIsSubmit, setBranchIsSubmit] = useState(false);
    const [branchIsSave, setBranchIsSave] = useState(false);
    const [selectedBranchID, setSelectedBranchID] = useState(0);
    const [branchName, setBranchName] = useState('');
    const [branchImage, setBranchImage] = useState('');
    const [branchMobileNumber, setBranchMobileNumber] = useState('');
    const [branchStreetAddress, setBranchStreetAddress] = useState('');
    const [countrySelectedBranchOption, setCountrySelectedBranchOption] = useState(null);
    const [stateSelectedBranchOption, setStateSelectedBranchOption] = useState(null);
    const [citySelectedBranchOption, setCitySelectedBranchOption] = useState(null);
    const [branchPostalCode, setBranchPostalCode] = useState('');
    const [branchFaxNumber, setBranchFaxNumber] = useState('');
    const [branchEmail, setBranchEmail] = useState('');
    const [branchWebsite, setBranchWebsite] = useState('');
    const [branchLandline, setBranchLandline] = useState('');
    const [branchTINnumber, setBranchTINnumber] = useState('');
    const [branchGSTNumber, setBranchGSTNumber] = useState('');
    const [branchRegisterDate, setBranchRegisterDate] = useState(new Date());
    const [previewBranchImg, setPreviewBranchImg] = useState(uploadImg);
    const [isBranchView, setIsBranchView] = useState(false);
    const [searchQueryUnit, setSearchQueryUnit] = useState('');
    const [searchQueryBranch, setSearchQueryBranch] = useState('');
    const [websiteEntityError, setWebsiteEntityError] = useState('');
    const [websiteBranchError, setWebsiteBranchError] = useState('');
    const [websiteUnitError, setWebsiteUnitError] = useState('');

    useEffect(() => {
        const queryParams = getQueryParams(location.search);
        const tabName = queryParams.get('tab');
        selectedTab(tabName);
        getCountriesList();
        getStatesList();
        getCitiesList();
        getCountry();
        getState();
        getCity();
        refresh();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getQueryParams = (query) => {
        return new URLSearchParams(query);
    }

    const handleUnitSearchChange = (event) => {
        setSearchQueryUnit(event.target.value);
        setPageUnit(0);
    };

    const handleBranchSearchChange = (event) => {
        setSearchQueryBranch(event.target.value);
        setPageBranch(0);
    };

    const filteredUnits = businessUnitArray.filter((unit) =>
        unit && (
            unit?.bU_NAME?.toLowerCase()?.includes(searchQueryUnit?.toLowerCase()) ||
            unit?.bU_ADDR?.toLowerCase()?.includes(searchQueryUnit?.toLowerCase()) ||
            unit?.city?.toLowerCase()?.includes(searchQueryUnit?.toLowerCase()) ||
            unit?.bU_TEL?.toLowerCase()?.includes(searchQueryUnit?.toLowerCase()) ||
            unit?.bU_EMAIL?.toLowerCase()?.includes(searchQueryUnit?.toLowerCase())
        )
    );

    const filteredBranches = businessBranchArray.filter((branch) =>
        branch && (
            branch?.unitName?.toLowerCase()?.includes(searchQueryBranch?.toLowerCase()) ||
            branch?.bR_NAME?.toLowerCase()?.includes(searchQueryBranch?.toLowerCase()) ||
            branch?.bR_ADDR?.toLowerCase()?.includes(searchQueryBranch?.toLowerCase()) ||
            branch?.city?.toLowerCase()?.includes(searchQueryBranch?.toLowerCase()) ||
            branch?.bR_TEL?.toLowerCase()?.includes(searchQueryBranch?.toLowerCase()) ||
            branch?.bR_EMAIL?.toLowerCase()?.includes(searchQueryBranch?.toLowerCase())
        )
    );

    const getBusinessEntity = async () => {
        const countryArrayData = JSON.parse(localStorage.getItem('CountryDropDown'));
        const stateArrayData = JSON.parse(localStorage.getItem('StateDropDown'));
        const CityArrayData = JSON.parse(localStorage.getItem('CityDropDown'));
        const BusinessEntityData = await getBEData(selectedEntityID);
        setEntityName(BusinessEntityData.bE_NAME);
        setPreviewEntityImg(BusinessEntityData.bE_LOGO === '' ? uploadImg : BusinessEntityData.bE_LOGO);
        setEntityImg(BusinessEntityData.bE_LOGO === '' ? uploadImg : BusinessEntityData.bE_LOGO);
        setSelectedEntityID(BusinessEntityData.bE_ID);
        setEntityMobileNumber(BusinessEntityData.bE_MOB);
        setEntityStreetAddress(BusinessEntityData.bE_ADDR);
        const countryValue = countryArrayData.find(x => x.id === BusinessEntityData.bE_CNTRY_ID);
        setCountrySelectedEntityOption(countryValue);
        handleCountrySelectEntity(countryValue);
        const stateValue = stateArrayData.find(x => x.id === BusinessEntityData.bE_STATE_ID);
        setStateSelectedEntityOption(stateValue);
        handleStateSelectEntity(stateValue);
        const cityValue = CityArrayData.find(x => x.id === BusinessEntityData.bE_CITY_ID);
        setCitySelectedEntityOption(cityValue);
        setEntityPostalCode(BusinessEntityData.bE_PO_BOX);
        setEntityFaxNumber(BusinessEntityData.bE_FAX);
        setEntityEmail(BusinessEntityData.bE_EMAIL);
        setEntityWebsite(BusinessEntityData.bE_WEBSITE);
        setEntityLandline(BusinessEntityData.bE_TEL);
        setEntityTINnumber(BusinessEntityData.bE_TIN_NO);
        setEntityGSTNumber(BusinessEntityData.bE_VAT_NO);
        const registerDate = new Date(BusinessEntityData.bE_VAT_DOR);
        const formattedregisterDate = registerDate.toString();
        setEntityRegisterDate(formattedregisterDate);
    }

    const getBusinessUnit = async () => {
        const CityListData = JSON.parse(localStorage.getItem('CityDropDown'));
        const data = await getBUData();
        const finalData = data.map((unit, index) => {
            const cityLabel = CityListData?.find(x => x?.id === unit?.bU_CITY_ID)?.label;
            const cityName = cityLabel?.charAt(0)?.toUpperCase() + cityLabel?.slice(1)?.toLowerCase();
            return {
                ...unit,
                Rowid: index + 1,
                city: cityName
            };
        });
        const unitOptionArray = data.map((unit, index) => ({
            id: unit.bU_ID,
            label: unit.bU_NAME,
            value: unit.bU_NAME
        }))
        setUnitOption(unitOptionArray);
        await getBusinessBranch(finalData);
        setBusinessUnitArray(finalData);
        setIsLoading(false);
    }

    const getBusinessBranch = async (unitArray) => {
        const CityListData = JSON.parse(localStorage.getItem('CityDropDown'));
        const data = await getBRData();
        const finalData = data.map((branch, index) => {
            const cityLabel = CityListData.find(x => x.id === branch.bR_CITY_ID)?.label;
            const cityName = cityLabel.charAt(0).toUpperCase() + cityLabel.slice(1).toLowerCase();
            const unitLabel = unitArray.find(x => x.bU_ID === branch.bR_BU)?.bU_NAME;
            const unitName = unitLabel.charAt(0).toUpperCase() + unitLabel.slice(1).toLowerCase();
            return {
                ...branch,
                Rowid: index + 1,
                city: cityName,
                unitName: unitName
            };
        });
        setBusinessBranchArray(finalData);
        setIsLoading(false);
    };

    const clearFieldInput = () => {
        setUnitName('');
        setPreviewUnitImg(uploadImg);
        setUnitMobileNumber('');
        setUnitStreetAddress('');
        setCountrySelectedOption(null);
        setStateSelectedOption(null);
        setCitySelectedOption(null);
        setUnitPostalCode('');
        setUnitFaxNumber('');
        setUnitEmail('');
        setUnitWebsite('');
        setUnitLandline('');
        setUnitTINnumber('');
        setUnitGSTNumber('');
        setUnitRegisterDate(new Date());

        setBranchName('');
        setPreviewBranchImg(uploadImg);
        setBranchMobileNumber('');
        setBranchStreetAddress('');
        setUnitSelectedOption(null);
        setCountrySelectedBranchOption(null);
        setStateSelectedBranchOption(null);
        setCitySelectedBranchOption(null);
        setBranchPostalCode('');
        setBranchFaxNumber('');
        setBranchEmail('');
        setBranchWebsite('');
        setBranchLandline('');
        setBranchTINnumber('');
        setBranchGSTNumber('');
        setBranchRegisterDate(new Date());
    };

    const getCountry = () => {
        const CountryListData = JSON.parse(localStorage.getItem('CountryDropDown'));
        setCountryArray(CountryListData);
        setCountryOption(CountryListData);
        setCountryUnitOption(CountryListData);
        setCountryBranchOption(CountryListData);
    };

    const getState = () => {
        const StateListData = JSON.parse(localStorage.getItem('StateDropDown'));
        setStateArray(StateListData);
    };

    const getCity = () => {
        const CityListData = JSON.parse(localStorage.getItem('CityDropDown'));
        setCityArray(CityListData);
    };

    const selectedTab = (tabValue) => {
        setTabActive(tabValue);
        if (tabValue === "BussinessEntity") {
            setTabStatusActive("Bussiness Entity");
            setSelectedEntityID(1);

        }
        else if (tabValue === "BussinessUnit") {
            setTabStatusActive("Bussiness Unit");
        }
        else if (tabValue === "BussinessBranch") {
            setTabStatusActive("Bussiness Branch");
        }
    };

    const handleCountrySelect = (option) => {
        setStateUnitOption([]);
        setCityUnitOption([]);
        setStateSelectedOption(null);
        setCitySelectedOption(null);
        setCountrySelectedOption(option);
        if (option != null) {
            const stateOptionArray = stateArray.filter(x => x.countryId === option.id)
            setStateUnitOption(stateOptionArray);
        }
    };

    const handleUnitSelect = (option) => {
        setUnitSelectedOption(option);
    };

    const handleStateSelect = (option) => {
        setStateSelectedOption(null);
        setCityUnitOption([]);
        setCitySelectedOption(null);
        if (option != null) {
            setStateSelectedOption(option);
            const cityOptionArray = cityArray.filter(x => x.stateId === option.id)
            setCityUnitOption(cityOptionArray);
        }
    };

    const handleCountrySelectEntity = (option) => {
        setStateOption([]);
        setCityOption([]);
        setStateSelectedEntityOption(null);
        setCitySelectedEntityOption(null);
        setCountrySelectedEntityOption(option);
        if (option != null) {
            const stateOptionArray = stateArray.filter(x => x.countryId === option.id)
            setStateOption(stateOptionArray);
        }
    };

    const handleStateSelectEntity = (option) => {
        setStateSelectedEntityOption(null);
        setCityOption([]);
        setCitySelectedEntityOption(null);
        if (option != null) {
            setStateSelectedEntityOption(option);
            const cityOptionArray = cityArray.filter(x => x.stateId === option.id)
            setCityOption(cityOptionArray);
        }
    };

    const handleCitySelectEntity = (option) => {
        setCitySelectedEntityOption(option);
    };

    const handleCitySelect = (option) => {
        setCitySelectedOption(option);
    };

    const handleCountrySelectBranch = (option) => {
        setStateBranchOption([]);
        setCityBranchOption([]);
        setStateSelectedBranchOption(null);
        setCitySelectedBranchOption(null);
        setCountrySelectedBranchOption(option);
        if (option != null) {
            const stateOptionArray = stateArray.filter(x => x.countryId === option.id)
            setStateBranchOption(stateOptionArray);
        }
    };

    const handleStateSelectBranch = (option) => {
        setStateSelectedBranchOption(null);
        setCityBranchOption([]);
        setCitySelectedBranchOption(null);
        if (option != null) {
            setStateSelectedBranchOption(option);
            const cityOptionArray = cityArray.filter(x => x.stateId === option.id)
            setCityBranchOption(cityOptionArray);
        }
    };

    const handleCitySelectBranch = (option) => {
        setCitySelectedBranchOption(option);
    };

    const fileUpload = (event) => {
        event.preventDefault();
        document.getElementById('uploadImgID').click();
    };

    const EntityfileUpload = (event) => {
        event.preventDefault();
        document.getElementById('EntityuploadImgID').click();
    };

    const BranchfileUpload = (event) => {
        event.preventDefault();
        document.getElementById('BranchuploadImgID').click();
    };

    const handleFileInputChange = (e) => {
        const file = e.target.files[0];
        let previewImg = '';
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                previewImg = reader.result;
                if (previewImg !== '' && previewImg != null) {
                    setPreviewUnitImg(previewImg);
                    setUnitImage(previewImg);
                } else {
                    setPreviewUnitImg(uploadImg);
                    setUnitImage('');
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const handleEntityFileInputChange = (e) => {
        const file = e.target.files[0];
        let previewImg = '';
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                previewImg = reader.result;
                if (previewImg !== '' && previewImg != null) {
                    setPreviewEntityImg(previewImg);
                    setEntityImg(previewImg);
                } else {
                    setPreviewEntityImg(uploadImg);
                    setEntityImg('');
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const handleBranchFileInputChange = (e) => {
        const file = e.target.files[0];
        let previewImg = '';
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                previewImg = reader.result;
                if (previewImg !== '' && previewImg != null) {
                    setPreviewBranchImg(previewImg);
                    setBranchImage(previewImg);
                } else {
                    setPreviewBranchImg(uploadImg);
                    setBranchImage('');
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const clearImage = (event) => {
        event.preventDefault();
        setPreviewUnitImg(uploadImg);
    };

    const clearImageEntity = (event) => {
        event.preventDefault();
        setPreviewEntityImg(uploadImg);
    };

    const clearImageBranch = (event) => {
        event.preventDefault();
        setPreviewBranchImg(uploadImg);
    };

    const validateUnit = () => {
        const validations = [
            unitName === '',
            unitMobileNumber === '',
            unitStreetAddress === '',
            countrySelectedOption == null,
            stateSelectedOption == null,
            citySelectedOption == null,
            unitPostalCode === '',
            unitEmail === '',
            unitMobileNumber.length < 10 && unitMobileNumber !== '',
            unitPostalCode.length < 6 && unitPostalCode !== '',
            unitFaxNumber.length < 10 && unitFaxNumber !== '',
            unitWebsite && websiteUnitError !== '',
            unitLandline.length < 10 && unitLandline !== '',
            unitTINnumber.length < 10 && unitTINnumber !== '',
            unitGSTNumber.length < 15 && unitGSTNumber !== '',
            unitEmailValidation
        ];
        return validations.some(validation => validation);
    };

    const validateCommonFields = (fields) => {
        const urlPattern = /^(https?:\/\/)?([a-z0-9]+[.])?[a-z0-9-]+\.[a-z]{2,7}(\/[a-z0-9#]+\/?)*$/i;
        for (const field of fields) {
            if (field.required) {
                if (!field.value || (field.minLength && field.value.length < field.minLength)) {
                    return true;
                }
            } else if (!field.required) {
                if (field.value && field.minLength && field.value.length < field.minLength) {
                    return true;
                }
            }
            if (field.value && field === entityWebsite && !urlPattern.test(field.value)) {
                return true;
            }
            if (field.value && field === branchWebsite && !urlPattern.test(field.value)) {
                return true;
            }
        }
        return false;
    };

    const validateEntity = () => {
        const entityFields = [
            { value: entityName, required: true },
            { value: entityMobileNumber, minLength: 10, required: true },
            { value: entityStreetAddress, required: true },
            { value: entityPostalCode, minLength: 6, required: true },
            { value: entityEmail, required: true },
            { value: entityWebsite, required: false },
            { value: entityFaxNumber, minLength: 10, required: false },
            { value: entityLandline, minLength: 10, required: false },
            { value: entityTINnumber, minLength: 10, required: false },
            { value: entityGSTNumber, minLength: 15, required: false },
        ];
        return !!(websiteEntityError !== '' || countrySelectedEntityOption == null || stateSelectedEntityOption == null || citySelectedEntityOption == null || validateCommonFields(entityFields) || entityEmailValidation);
    };

    const validateBranch = () => {
        const branchFields = [
            { value: branchName, required: true },
            { value: branchMobileNumber, minLength: 10, required: true },
            { value: branchStreetAddress, required: true },
            { value: branchPostalCode, minLength: 6, required: true },
            { value: branchEmail, required: true },
            { value: entityWebsite, required: false },
            { value: branchFaxNumber, minLength: 10, required: false },
            { value: branchLandline, minLength: 10, required: false },
            { value: branchTINnumber, minLength: 10, required: false },
            { value: branchGSTNumber, minLength: 15, required: false },
        ];
        return !!(websiteBranchError !== '' || unitSelectedOption == null || countrySelectedBranchOption == null || stateSelectedBranchOption == null || citySelectedBranchOption == null || validateCommonFields(branchFields) || branchEmailValidation);
    };

    const submitUnit = async (event) => {
        setUnitIsSubmit(true);
        setUnitIsSave(true);
        setIsUnitView(false);
        const validation = validateUnit();
        if (!validation) {
            const date = new Date(unitRegisterDate);
            const formattedDate = date.toISOString().split('T')[0];
            const param = {
                BU_BE: 1,
                BU_ID: selectedUnitID,
                BU_NAME: unitName,
                BU_ADDR: unitStreetAddress,
                BU_PO_BOX: unitPostalCode,
                BU_CNTRY_ID: countrySelectedOption?.id,
                BU_STATE_ID: stateSelectedOption?.id,
                BU_CITY_ID: citySelectedOption?.id,
                BU_TEL: unitMobileNumber,
                BU_FAX: unitFaxNumber,
                BU_EMAIL: unitEmail,
                BU_WEBSITE: unitWebsite,
                BU_TIN_NO: unitTINnumber,
                BU_VAT_NO: unitGSTNumber,
                BU_VAT_DOR: formattedDate,
                BU_LOGO: unitImage,
                BU_MANAGER: 'User',
                BU_ZONE_MANAGER: 'User',
                BU_LANDLINE: unitLandline
            }
            if (selectedUnitID === 0) {
                const response = await insertBUData(param);
                if (response === 'Inserted') {
                    setIsLoading(false);
                    setUnitIsSubmit(false);
                    setUnitIsSave(false);
                    toast.success('Business Unit Saved Successfully');
                    closeUnitModel();

                } else {
                    setIsLoading(false);
                    setUnitIsSubmit(false);
                    setUnitIsSave(false);
                    toast.error('Something Went Wrong While Saving Business Unit');
                    closeUnitModel();
                }
            } else {
                const response = await updateBUData(param);
                if (response === 'Updated') {
                    setIsLoading(false);
                    setUnitIsSubmit(false);
                    setUnitIsSave(false);
                    toast.success('Business Unit Updated Successfully');
                    closeUnitModel();
                } else {
                    setIsLoading(false);
                    setUnitIsSubmit(false);
                    setUnitIsSave(false);
                    toast.error('Something Went Wrong While Saving Business Unit');
                    closeUnitModel()
                }
            }
        } else {
            toast.error('Fill the Required field');
        }
    };

    const submitBranch = async (event) => {
        setBranchIsSubmit(true);
        setBranchIsSave(true);
        const validation = validateBranch();
        if (!validation) {
            const date = new Date(branchRegisterDate);
            const formattedDate = date.toISOString().split('T')[0];
            const param = {
                BR_BE: 1,
                BR_BU: unitSelectedOption?.id,
                BR_ID: selectedBranchID,
                BR_NAME: branchName,
                BR_ADDR: branchStreetAddress,
                BR_PO_BOX: branchPostalCode,
                BR_CNTRY_ID: countrySelectedBranchOption?.id,
                BR_STATE_ID: stateSelectedBranchOption?.id,
                BR_CITY_ID: citySelectedBranchOption?.id,
                BR_TEL: branchMobileNumber,
                BR_FAX: branchFaxNumber,
                BR_EMAIL: branchEmail,
                BR_WEBSITE: branchWebsite,
                BR_TIN_NO: branchTINnumber,
                BR_VAT_NO: branchGSTNumber,
                BR_VAT_DOR: formattedDate,
                BR_LOGO: branchImage,
                BR_MANAGER: 'User',
                BR_ZONE_MANAGER: 'User',
                BR_LANDLINE: branchLandline
            }
            if (selectedBranchID === 0) {
                const response = await insertBRData(param);
                if (response === 'Inserted') {
                    setIsLoading(false);
                    setBranchIsSubmit(false);
                    setBranchIsSave(false);
                    toast.success('Business Branch Saved Successfully');
                    closeBranchModel();
                } else {
                    setIsLoading(false);
                    setBranchIsSubmit(false);
                    setBranchIsSave(false);
                    toast.error('Something Went Wrong While Saving Business Branch');
                    closeBranchModel();
                }
            } else {
                const response = await updateBRData(param);
                if (response === 'Updated') {
                    setIsLoading(false);
                    setBranchIsSubmit(false);
                    setBranchIsSave(false);
                    toast.success('Business Branch Updated Successfully');
                    closeBranchModel();
                } else {
                    setIsLoading(false);
                    setBranchIsSubmit(false);
                    setBranchIsSave(false);
                    toast.error('Something Went Wrong While Saving Business Branch');
                    closeBranchModel();
                }
            }
        } else {
            toast.error('Fill the Required field');
        }
    };

    const submitEntity = async (event) => {
        setEntityIsSubmit(true);
        const validation = validateEntity();
        if (!validation) {
            const date = new Date(entityRegisterDate);
            const formattedDate = date.toISOString().split('T')[0];
            const param = {
                BE_ID: 1,
                BE_NAME: entityName,
                BE_MOB: entityMobileNumber,
                BE_ADDR: entityStreetAddress,
                BE_CNTRY_ID: countrySelectedEntityOption?.id,
                BE_STATE_ID: stateSelectedEntityOption?.id,
                BE_CITY_ID: citySelectedEntityOption?.id,
                BE_PO_BOX: entityPostalCode,
                BE_TEL: entityLandline,
                BE_FAX: entityFaxNumber,
                BE_EMAIL: entityEmail,
                BE_WEBSITE: entityWebsite,
                BE_TIN_NO: entityTINnumber,
                BE_VAT_NO: entityGSTNumber,
                BE_VAT_DOR: date,
                BE_VAT_DOR_DATE: formattedDate || null,
                BE_LOGO: entityImg
            }
            setIsLoading(true);
            const response = await updateBEData(param);
            if (response === 'Updated') {
                setIsLoading(false);
                setEntityIsSubmit(false);
                toast.success('Business Entity Updated Successfully');
                getBusinessEntity();
            } else {
                setIsLoading(false);
                setEntityIsSubmit(false);
                toast.error('Something Went Wrong While Saving Business Entity');
            }
        }
        else {
            toast.error('Fill the Required field');
        }
    };

    const closeEntity = (event) => {
        navigate('/Dashboard');
    };

    const editUnit = (unit) => {
        document.getElementById('BtnaddUnit').click();
        setIsUnitView(false);
        setSelectedUnitID(unit.bU_ID);
        setUnitName(unit.bU_NAME);
        setPreviewUnitImg(unit.bU_LOGO === '' ? uploadImg : unit.bU_LOGO);
        setUnitImage(unit.bU_LOGO === '' ? uploadImg : unit.bU_LOGO);
        setUnitMobileNumber(unit.bU_TEL);
        setUnitStreetAddress(unit.bU_ADDR);
        const countryValue = countryArray.find(x => x.id === unit.bU_CNTRY_ID);
        setCountrySelectedOption(countryValue);
        handleCountrySelect(countryValue);
        const stateValue = stateArray.find(x => x.id === unit.bU_STATE_ID);
        setStateSelectedOption(stateValue);
        handleStateSelect(stateValue);
        const cityValue = cityArray.find(x => x.id === unit.bU_CITY_ID);
        setCitySelectedOption(cityValue);
        setUnitPostalCode(unit.bU_PO_BOX);
        setUnitFaxNumber(unit.bU_FAX);
        setUnitEmail(unit.bU_EMAIL);
        setUnitWebsite(unit.bU_WEBSITE);
        setUnitLandline(unit.bU_LANDLINE);
        setUnitTINnumber(unit.bU_TIN_NO);
        setUnitGSTNumber(unit.bU_VAT_NO);
        const registerDate = new Date(unit.bU_VAT_DOR);
        const formattedregisterDate = registerDate.toString();
        setUnitRegisterDate(formattedregisterDate);
    };

    const viewUnit = (unit) => {
        document.getElementById('BtnaddUnit').click();
        setIsUnitView(true);
        setSelectedUnitID(unit.bU_ID);
        setUnitName(unit.bU_NAME);
        setPreviewUnitImg(unit.bU_LOGO === '' ? uploadImg : unit.bU_LOGO);
        setUnitImage(unit.bU_LOGO === '' ? uploadImg : unit.bU_LOGO);
        setUnitMobileNumber(unit.bU_TEL);
        setUnitStreetAddress(unit.bU_ADDR);
        const countryValue = countryArray.find(x => x.id === unit.bU_CNTRY_ID);
        setCountrySelectedOption(countryValue);
        handleCountrySelect(countryValue);
        const stateValue = stateArray.find(x => x.id === unit.bU_STATE_ID);
        setStateSelectedOption(stateValue);
        handleStateSelect(stateValue);
        const cityValue = cityArray.find(x => x.id === unit.bU_CITY_ID);
        setCitySelectedOption(cityValue);
        setUnitPostalCode(unit.bU_PO_BOX);
        setUnitFaxNumber(unit.bU_FAX);
        setUnitEmail(unit.bU_EMAIL);
        setUnitWebsite(unit.bU_WEBSITE);
        setUnitLandline(unit.bU_LANDLINE);
        setUnitTINnumber(unit.bU_TIN_NO);
        setUnitGSTNumber(unit.bU_VAT_NO);
        const registerDate = new Date(unit.bU_VAT_DOR);
        const formattedregisterDate = registerDate.toString();
        setUnitRegisterDate(formattedregisterDate);
    };

    const viewBranch = (Branch) => {
        let addbranchBtn = document.getElementById('BtnaddBranch');
        addbranchBtn.click();
        setIsBranchView(true);
        setSelectedBranchID(Branch.bR_ID);
        const unitValue = unitOption.find(x => x.id === Branch.bR_BU);
        setUnitSelectedOption(unitValue);
        setBranchName(Branch.bR_NAME);
        setPreviewBranchImg(Branch.bR_LOGO === '' ? uploadImg : Branch.bR_LOGO);
        setBranchImage(Branch.bR_LOGO === '' ? uploadImg : Branch.bR_LOGO);
        setBranchMobileNumber(Branch.bR_TEL);
        setBranchStreetAddress(Branch.bR_ADDR);
        const countryValue = countryArray.find(x => x.id === Branch.bR_CNTRY_ID);
        setCountrySelectedBranchOption(countryValue);
        handleCountrySelectBranch(countryValue);
        const stateValue = stateArray.find(x => x.id === Branch.bR_STATE_ID);
        setStateSelectedBranchOption(stateValue);
        handleStateSelectBranch(stateValue);
        const cityValue = cityArray.find(x => x.id === Branch.bR_CITY_ID);
        setCitySelectedBranchOption(cityValue);
        setBranchPostalCode(Branch.bR_PO_BOX);
        setBranchFaxNumber(Branch.bR_FAX);
        setBranchEmail(Branch.bR_EMAIL);
        setBranchWebsite(Branch.bR_WEBSITE);
        setBranchLandline(Branch.bR_LANDLINE);
        setBranchTINnumber(Branch.bR_TIN_NO);
        setBranchGSTNumber(Branch.bR_VAT_NO);
        const registerDate = new Date(Branch.bR_VAT_DOR);
        const formattedregisterDate = registerDate.toString();
        setBranchRegisterDate(formattedregisterDate);
    };

    const editBranch = (Branch) => {
        let addbranchBtn = document.getElementById('BtnaddBranch');
        addbranchBtn.click();
        setIsBranchView(false);
        setSelectedBranchID(Branch.bR_ID);
        const unitValue = unitOption.find(x => x.id === Branch.bR_BU);
        setUnitSelectedOption(unitValue);
        setBranchName(Branch.bR_NAME);
        setPreviewBranchImg(Branch.bR_LOGO === '' ? uploadImg : Branch.bR_LOGO);
        setBranchImage(Branch.bR_LOGO === '' ? uploadImg : Branch.bR_LOGO);
        setBranchMobileNumber(Branch.bR_TEL);
        setBranchStreetAddress(Branch.bR_ADDR);
        const countryValue = countryArray.find(x => x.id === Branch.bR_CNTRY_ID);
        setCountrySelectedBranchOption(countryValue);
        handleCountrySelectBranch(countryValue);
        const stateValue = stateArray.find(x => x.id === Branch.bR_STATE_ID);
        setStateSelectedBranchOption(stateValue);
        handleStateSelectBranch(stateValue);
        const cityValue = cityArray.find(x => x.id === Branch.bR_CITY_ID);
        setCitySelectedBranchOption(cityValue);
        setBranchPostalCode(Branch.bR_PO_BOX);
        setBranchFaxNumber(Branch.bR_FAX);
        setBranchEmail(Branch.bR_EMAIL);
        setBranchWebsite(Branch.bR_WEBSITE);
        setBranchLandline(Branch.bR_LANDLINE);
        setBranchTINnumber(Branch.bR_TIN_NO);
        setBranchGSTNumber(Branch.bR_VAT_NO);
        const registerDate = new Date(Branch.bR_VAT_DOR);
        const formattedregisterDate = registerDate.toString();
        setBranchRegisterDate(formattedregisterDate);
    };

    const deleteUnit = async (unitId) => {
        const branchData = await getBRData();
        const teamData = await getTeam();
        const empData = await getEmployee();
        const checkInData = await getCheckIn();
        const checkOutData = await getCheckOut();

        for (let branch of branchData) {
            if (branch.bR_BU === unitId.bU_ID) {
                toast.error(`This Business Unit is associated with Business Branch. You can't delete it.`);
                return;
            }
        }

        for (let emp of empData) {
            if (emp.unitId === unitId.bU_ID) {
                toast.error(`This Business Unit is associated with Employee. You can't delete it.`);
                return;
            }
        }

        for (let cIn of checkInData) {
            if (cIn.unitId === unitId.bU_ID) {
                toast.error(`This Business Unit is associated with ActionFormConfiguration - checkIn. You can't delete it.`);
                return;
            }
        }

        for (let cOut of checkOutData) {
            if (cOut.unitId === unitId.bU_ID) {
                toast.error(`This Business Unit is associated with ActionFormConfiguration - checkOut. You can't delete it.`);
                return;
            }
        }

        for (let team of teamData) {
            const teamIds = team.unitId.split(',');
            if (teamIds.includes(unitId.bU_ID)) {
                toast.error(`This  Business Unit is associated with teams. You can't delete it.`);
                return;
            }
        }

        const AssetData = await getASTSettings();
        const val = AssetData.find(r => r.id === 1);
        if (val.unitId === unitId.bU_ID) {
            toast.error(`This Business Unit is associated with AssetSettings. You can't delete it.`);
            return;
        }

        const isConfirmed = await ConfirmDelete();
        if (isConfirmed) {
            setIsLoading(true);
            const payload = {
                BU_BE: 0,
                BU_ID: unitId.bU_ID,
                BU_NAME: '',
                BU_ADDR: '',
                BU_PO_BOX: '',
                BU_CITY_ID: 0,
                BU_STATE_ID: 0,
                BU_CNTRY_ID: 0,
                BU_TEL: '',
                BU_FAX: '',
                BU_EMAIL: '',
                BU_WEBSITE: '',
                BU_TIN_NO: '',
                BU_VAT_NO: '',
                BU_VAT_DOR: unitId.bU_VAT_DOR || "2024-05-31",
                BU_LOGO: '',
                BU_MANAGER: '',
                BU_ZONE_MANAGER: '',
                BU_LANDLINE: ''
            };
            const deleteResponse = await deleteBUData(payload);
            if (deleteResponse === 'Deleted') {
                setIsLoading(false);
                toast.success('Business Unit Deleted Successfully');
                refresh();
                if (businessUnitArray.length === 6) {
                    setPageUnit(0)
                }
            } else {
                setIsLoading(false);
                toast.error(deleteResponse);
            }
        }
    };

    const deleteBranch = async (branchId) => {
        const checkInData = await getCheckIn();
        const checkOutData = await getCheckOut();
        const empData = await getEmployee();
        for (let emp of empData) {
            if (emp.branchId === branchId) {
                toast.error(`This Business Branch is associated with Employee. You can't delete it.`);
                return;
            }
        }

        for (let cIn of checkInData) {
            if (cIn.unitId === branchId) {
                toast.error(`This Business Branch is associated with ActionFormConfiguration - checkIn. You can't delete it.`);
                return;
            }
        }

        for (let cOut of checkOutData) {
            if (cOut.unitId === branchId) {
                toast.error(`This Business Branch is associated with ActionFormConfiguration - checkOut. You can't delete it.`);
                return;
            }
        }

        const AssetData = await getASTSettings();
        const val = AssetData.find(r => r.id === 1);
        if (val.branchId === branchId) {
            toast.error(`This Business Branch is associated with AssetSettings. You can't delete it.`);
            return;
        }

        const isConfirmed = await ConfirmDelete();
        if (isConfirmed) {
            setIsLoading(true);
            const payload = {
                BR_BE: 0,
                BR_BU: 0,
                BR_ID: branchId,
                BR_NAME: '',
                BR_ADDR: '',
                BR_PO_BOX: '',
                BR_CITY_ID: 0,
                BR_STATE_ID: 0,
                BR_CNTRY_ID: 0,
                BR_TEL: '',
                BR_FAX: '',
                BR_EMAIL: '',
                BR_WEBSITE: '',
                BR_TIN_NO: '',
                BR_VAT_NO: '',
                BR_VAT_DOR: "2024-05-31",
                BR_LOGO: '',
                BR_MANAGER: '',
                BR_ZONE_MANAGER: '',
                BR_LANDLINE: ''
            };
            const deleteResponse = await deleteBRData(payload);
            if (deleteResponse === 'Deleted') {
                setIsLoading(false);
                toast.success('Business Branch Deleted Successfully');
                refresh();
                if (businessBranchArray.length === 6) {
                    setPageBranch(0);
                }
            } else {
                setIsLoading(false);
                toast.error(deleteResponse);
            }
        }
    };

    const handleAddButtonClick = () => {
        setUnitIsSubmit(false);
        setIsUnitView(false);
        clearFieldInput();
    };

    const closeUnitModel = () => {
        document.getElementById('btncloseModal').click();
        refresh();
    };

    const refresh = () => {
        getBusinessEntity();
        getBusinessUnit();
    };

    const closeBranchModel = () => {
        document.getElementById('btncloseModalBranch').click();
        refresh();
    };


    const numberValidation = (value, screen, field) => {
        const isValidNumber = /^\d*$/.test(value);
        if (screen === 'BusinessUnit' && field === 'MobileNumber') {
            if (isValidNumber) {
                setUnitMobileNumber(value);
            }
        } else if (screen === 'BusinessUnit' && field === 'PostalCode') {
            if (isValidNumber) {
                setUnitPostalCode(value);
            }
        } else if (screen === 'BusinessUnit' && field === 'FaxNumber') {
            if (isValidNumber) {
                setUnitFaxNumber(value);
            }
        } else if (screen === 'BusinessUnit' && field === 'LandlineNumber') {
            if (isValidNumber) {
                setUnitLandline(value);
            }
        } else if (screen === 'BusinessEntity' && field === 'MobileNumber') {
            if (isValidNumber) {
                setEntityMobileNumber(value);
            }
        } else if (screen === 'BusinessEntity' && field === 'PostalCode') {
            if (isValidNumber) {
                setEntityPostalCode(value);
            }

        } else if (screen === 'BusinessEntity' && field === 'FaxNumber') {
            if (isValidNumber) {
                setEntityFaxNumber(value);
            }
        } else if (screen === 'BusinessEntity' && field === 'LandlineNumber') {
            if (isValidNumber) {
                setEntityLandline(value);
            }
        } else if (screen === 'BusinessBranch' && field === 'MobileNumber') {
            if (isValidNumber) {
                setBranchMobileNumber(value);
            }
        } else if (screen === 'BusinessBranch' && field === 'PostalCode') {
            if (isValidNumber) {
                setBranchPostalCode(value);
            }
        } else if (screen === 'BusinessBranch' && field === 'FaxNumber') {
            if (isValidNumber) {
                setBranchFaxNumber(value);
            }
        } else if (screen === 'BusinessBranch' && field === 'LandlineNumber') {
            if (isValidNumber) {
                setBranchLandline(value);
            }
        }
    };

    const handleWebsiteChange = (e) => {
        const urlPattern = /^(https?:\/\/)?([a-z0-9]+[.])?[a-z0-9-]+\.[a-z]{2,7}(\/[a-z0-9#]+\/?)*$/i;
        const value = e.target.value;
        setEntityWebsite(value);
        if (value && !urlPattern.test(value)) {
            setWebsiteEntityError('Please enter a valid URL');
        } else {
            setWebsiteEntityError('');
        }
    };

    const handleWebsiteBranchChange = (e) => {
        const urlPattern = /^(https?:\/\/)?([a-z0-9]+[.])?[a-z0-9-]+\.[a-z]{2,7}(\/[a-z0-9#]+\/?)*$/i;
        const value = e.target.value;
        setBranchWebsite(value);
        if (value && !urlPattern.test(value)) {
            setWebsiteBranchError('Please enter a valid URL');
        } else {
            setWebsiteBranchError('');
        }
    };

    const handleWebsiteUnitChange = (e) => {
        const urlPattern = /^(https?:\/\/)?([a-z0-9]+[.])?[a-z0-9-]+\.[a-z]{2,7}(\/[a-z0-9#]+\/?)*$/i;
        const value = e.target.value;
        setUnitWebsite(value);
        if (value && !urlPattern.test(value)) {
            setWebsiteUnitError('Please enter a valid URL');
        } else {
            setWebsiteUnitError('');
        }
    };

    const EmailValidation = (value, screen) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
        if (screen === 'BusinessUnit') {
            setUnitEmail(value)
            if (emailRegex) {
                setUnitEmailValidation(false);
            } else {
                setUnitEmailValidation(true);
            }
        } else if (screen === 'BusinessEntity') {
            setEntityEmail(value)
            if (emailRegex) {
                setEntityEmailValidation(false);
            } else {
                setEntityEmailValidation(true);
            }
        } else if (screen === 'BusinessBranch') {
            setBranchEmail(value)
            if (emailRegex) {
                setBranchEmailValidation(false);
            } else {
                setBranchEmailValidation(true);
            }
        }
    };

    const handleToggleActive = async (branch) => {
        if (branch.bR_ISACTIVE) {
            setIsLoading(true);
            const result = await setBranchStatus(branch.bR_ID, false);
            if (result === 'Updated Successfully') {
                setIsLoading(false);
                toast.success('Business Branch Deactivated Successfully');
                refresh();
            } else {
                setIsLoading(false);
                toast.error('Some Thing Wentwrong When DeActivating The Branch');
            }
        } else {
            setIsLoading(true);
            const result = await setBranchStatus(branch.bR_ID, true);
            if (result === 'Updated Successfully') {
                refresh();
                setIsLoading(false);
                toast.success('Business Branch Activated Successfully');
            } else {
                setIsLoading(false);
                toast.error('Some Thing Wentwrong When Activating The Branch');
            }
        }
    };

    const exportBranch = (event) => {
        const selectedOption = event.target.value;
        if (selectedOption !== '') {
            handleExportBranch(selectedOption);
            event.target.value = '';
        }
    };

    const handleExportBranch = (format) => {
        exportDataBranch(businessBranchArray, format, countryArray, stateArray, cityArray, businessUnitArray);
    };

    const exportUnit = (event) => {
        const selectedOption = event.target.value;
        if (selectedOption !== '') {
            handleExportUnit(selectedOption);
            event.target.value = '';
        }
    };

    const handleExportUnit = (format) => {
        exportDataUnit(businessUnitArray, format, countryArray, stateArray, cityArray);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsUnitPerPage(parseInt(event.target.value));
        setPageUnit(0);
    };

    const handleRowsPerPageBranchChange = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPageBranch(0);
    };

    const handleUnitFirstPage = () => {
        handleUnitChangePagePagination({ selected: 0 });
    };

    const handleUnitLastPage = () => {
        handleUnitChangePagePagination({ selected: Math.ceil(filteredUnits.length / rowsUnitPerPage) - 1 });
    };

    const handleUnitChangePagePagination = ({ selected }) => {
        setPageUnit(selected);
    };

    const totalUnit = filteredUnits.length;
    const startUnitEntry = pageUnit * rowsUnitPerPage + 1;
    const endUnitEntry = Math.min((pageUnit + 1) * rowsUnitPerPage, totalUnit);

    const handleBranchFirstPage = () => {
        handleBranchChangePagePagination({ selected: 0 });
    };

    const handleBranchLastPage = () => {
        handleBranchChangePagePagination({ selected: Math.ceil(filteredBranches.length / rowsPerPage) - 1 });
    };

    const handleBranchChangePagePagination = ({ selected }) => {
        setPageBranch(selected);
    };

    const totalBranch = filteredBranches.length;
    const startBranchEntry = pageBranch * rowsPerPage + 1;
    const endBranchEntry = Math.min((pageBranch + 1) * rowsPerPage, totalBranch);

    return (
        <div style={{ height: "100vh", width: "100%", backgroundColor: '#FAFAFB' }}>
            <ToastContainer theme="colored" />
            {isLoading && <LoadingSpinner />}

            {!isLoading && (
                <div>
                    <section className="section">
                        <div className="pagetitleEntity">
                            <div className="Pageheader fontFamily fontsize24 fontweightSemiBold">Account Details - {tabStatusActive}<div><InfoOutlinedIcon /></div></div>
                        </div>
                        <div className="card mt-4 ">
                            <div className="card-body">
                                <div className="HeaderTab">
                                    <a href="#BusinessEntity" className={`${tabActive === 'BussinessEntity' ? 'activeTab' : 'InactiveTab'}`} onClick={() => selectedTab('BussinessEntity')}>
                                        <div className="fontFamily fontweightRegular fontsize20">
                                            Business Entity
                                        </div>
                                    </a>
                                    <a href="#BusinessUnit" className={`${tabActive === 'BussinessUnit' ? 'activeTab' : 'InactiveTab'}`} onClick={() => selectedTab('BussinessUnit')}>
                                        <div className="fontFamily fontweightRegular fontsize20">
                                            Business Unit
                                        </div>
                                    </a>
                                    <a href="#BusinessBranch" className={`${tabActive === 'BussinessBranch' ? 'activeTab' : 'InactiveTab'}`} onClick={() => selectedTab('BussinessBranch')}>
                                        <div className="fontFamily fontweightRegular fontsize20">
                                            Business Branch
                                        </div>
                                    </a>
                                </div>
                                {tabActive === 'BussinessEntity' && (
                                    <div>
                                        <div className="form-container yaxisScroller" style={{ maxHeight: '400px', margin: '15px 0px' }}>
                                            <form>
                                                <div className="row">
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Entity Name<span className="mandatoryCss"> *</span></div>
                                                        <input type="text" value={entityName} placeholder="Entity Name" onChange={(e) => setEntityName(e.target.value)} maxLength={500} style={{ paddingLeft: '12px', backgroundColor: '#FFFFFF', border: '#00000014 1px solid', borderRadius: '5px', fontFamily: '"Nunito", sans-serif', fontWeight: '400', fontSize: '16px', width: '100%', outline: 'none', height: '45px' }} />
                                                        {entityIsSubmit && entityName.trim() === '' && (
                                                            <div className="fontFamily fontweightRegular fontsize16 validationError">Please Enter Entity Name</div>
                                                        )}
                                                    </div>
                                                    <div className="col-6 col-md-6 col-lg-6">
                                                        <div className="fontFamily fontweightRegular grayTextColor">Upload Image</div>
                                                        <div className="text-center">
                                                            <img src={previewEntityImg} alt="previewEntityImg" width="139" height="88" />
                                                            <div className="mt-3">
                                                                <input type="file" id="EntityuploadImgID"
                                                                    style={{ width: '102px', margin: '8px 0px', display: 'none' }}
                                                                    onChange={handleEntityFileInputChange}
                                                                />
                                                                <button className="fontFamily fontweightRegular fontsize16 coloredButton" onClick={EntityfileUpload}>Upload Image</button><span style={{ marginLeft: '7px' }}><button className="fontFamily fontweightRegular fontsize16 coloredButton " onClick={clearImageEntity}>Clear</button></span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="row pt-3">
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Mobile Number<span className="mandatoryCss"> *</span></div>
                                                        <input type="text" value={entityMobileNumber} placeholder="Mobile Number" maxLength={10} onChange={(e) => numberValidation(e.target.value, 'BusinessEntity', 'MobileNumber')} style={{
                                                            paddingLeft: '12px', backgroundColor: '#FFFFFF', border: '#00000014 1px solid', borderRadius: '5px', fontFamily: '"Nunito", sans-serif', fontWeight: '400', fontSize: '16px', width: '100%', outline: 'none', height: '45px'
                                                        }} />
                                                        {entityMobileNumber.length < 10 && entityMobileNumber !== '' && (
                                                            <div className="fontFamily fontweightRegular fontsize16 validationError">Please Enter 10-digit Mobile Number</div>
                                                        )}
                                                        {entityIsSubmit && entityMobileNumber === '' && (
                                                            <div className="fontFamily fontweightRegular fontsize16 validationError">Please Enter Mobile Number</div>
                                                        )}

                                                    </div>
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Street Address<span className="mandatoryCss"> *</span></div>
                                                        <textarea value={entityStreetAddress} placeholder="Street Address" onChange={(e) => setEntityStreetAddress(e.target.value)} style={{ paddingLeft: '12px', backgroundColor: '#FFFFFF', border: '#00000014 1px solid', borderRadius: '5px', fontFamily: '"Nunito", sans-serif', fontWeight: '400', fontSize: '16px', width: '100%', outline: 'none', height: '100px', resize: 'none' }}>
                                                        </textarea>
                                                        {entityIsSubmit && entityStreetAddress === '' && (
                                                            <div className="fontFamily fontweightRegular fontsize16 validationError">Please Enter Street Address</div>
                                                        )}

                                                    </div>
                                                </div>
                                                <div className="row pt-3">
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Select Country<span className="mandatoryCss"> *</span></div>
                                                        <Select
                                                            value={countrySelectedEntityOption}
                                                            onChange={handleCountrySelectEntity}
                                                            options={countryOption}
                                                            placeholder="Select a country..."
                                                            isClearable={true}
                                                        />
                                                        {entityIsSubmit && countrySelectedEntityOption == null && (
                                                            <div className="fontFamily fontweightRegular fontsize16 validationError">Please Select Country</div>
                                                        )}

                                                    </div>
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12 whiteBackground">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Select State<span className="mandatoryCss"> *</span></div>
                                                        <Select
                                                            value={stateSelectedEntityOption}
                                                            onChange={handleStateSelectEntity}
                                                            options={stateOption}
                                                            placeholder="Select a State..."
                                                            isClearable={true}
                                                        />
                                                        {entityIsSubmit && stateSelectedEntityOption == null && (
                                                            <div className="fontFamily fontweightRegular fontsize16 validationError">Please Select State</div>
                                                        )}

                                                    </div>
                                                </div>
                                                <div className="row pt-3">
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Select City<span className="mandatoryCss"> *</span></div>
                                                        <Select
                                                            value={citySelectedEntityOption}
                                                            onChange={handleCitySelectEntity}
                                                            options={cityOption}
                                                            placeholder="Select a City..."
                                                            isClearable={true}
                                                        />
                                                        {entityIsSubmit && citySelectedEntityOption == null && (
                                                            <div className="fontFamily fontweightRegular fontsize16 validationError">Please Select City</div>
                                                        )}

                                                    </div>
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Postal Code<span className="mandatoryCss"> *</span></div>
                                                        <input type="text" maxLength={6} onChange={(e) => numberValidation(e.target.value, 'BusinessEntity', 'PostalCode')} value={entityPostalCode} placeholder="Postal Code" style={{ paddingLeft: '12px', backgroundColor: '#FFFFFF', border: '#00000014 1px solid', borderRadius: '5px', fontFamily: '"Nunito", sans-serif', fontWeight: '400', fontSize: '16px', width: '100%', outline: 'none', height: '45px' }} />
                                                        {entityPostalCode.length < 6 && entityPostalCode !== '' && (
                                                            <div className="fontFamily fontweightRegular fontsize16 validationError">Please Enter 6-digit Postal Code</div>
                                                        )}
                                                        {entityIsSubmit && entityPostalCode === '' && (
                                                            <div className="fontFamily fontweightRegular fontsize16 validationError">Please Enter Postal Code</div>
                                                        )}

                                                    </div>
                                                </div>
                                                <div className="row pt-3">
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Fax Number</div>
                                                        <input maxLength={10} onChange={(e) => numberValidation(e.target.value, 'BusinessEntity', 'FaxNumber')} value={entityFaxNumber} placeholder="Fax Number" type="text" style={{ paddingLeft: '12px', backgroundColor: '#FFFFFF', border: '#00000014 1px solid', borderRadius: '5px', fontFamily: '"Nunito", sans-serif', fontWeight: '400', fontSize: '16px', width: '100%', outline: 'none', height: '45px' }} />
                                                        {entityFaxNumber.length < 10 && entityFaxNumber !== '' && (
                                                            <div className="fontFamily fontweightRegular fontsize16 validationError">Please Enter 10-digit FAX Number</div>
                                                        )}
                                                    </div>
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Email Address<span className="mandatoryCss"> *</span></div>
                                                        <input value={entityEmail} onChange={(e) => EmailValidation(e.target.value, 'BusinessEntity')} placeholder="Email Address" type="text" style={{ paddingLeft: '12px', backgroundColor: '#FFFFFF', border: '#00000014 1px solid', borderRadius: '5px', fontFamily: '"Nunito", sans-serif', fontWeight: '400', fontSize: '16px', width: '100%', outline: 'none', height: '45px' }} />
                                                        {entityEmailValidation && entityEmail !== '' && (
                                                            <div className="fontFamily fontweightRegular fontsize16 validationError">Please Enter Valid Email Address</div>
                                                        )}

                                                        {entityIsSubmit && entityEmail === '' && (
                                                            <div className="fontFamily fontweightRegular fontsize16 validationError">Please Enter Email Address</div>
                                                        )}

                                                    </div>
                                                </div>
                                                <div className="row pt-3">
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Website</div>
                                                        <input value={entityWebsite} onChange={handleWebsiteChange} type="text" placeholder="Website" style={{ paddingLeft: '12px', backgroundColor: '#FFFFFF', border: '#00000014 1px solid', borderRadius: '5px', fontFamily: '"Nunito", sans-serif', fontWeight: '400', fontSize: '16px', width: '100%', outline: 'none', height: '45px' }} />
                                                        {websiteEntityError !== '' && (
                                                            <div className="fontFamily fontweightRegular fontsize16 validationError">
                                                                Please enter a valid URL
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Landline Number</div>
                                                        <input maxLength={10} onChange={(e) => numberValidation(e.target.value, 'BusinessEntity', 'LandlineNumber')} value={entityLandline} type="text" placeholder="Landline Number" style={{ paddingLeft: '12px', backgroundColor: '#FFFFFF', border: '#00000014 1px solid', borderRadius: '5px', fontFamily: '"Nunito", sans-serif', fontWeight: '400', fontSize: '16px', width: '100%', outline: 'none', height: '45px' }} />
                                                        {entityLandline.length < 10 && entityLandline !== '' && (
                                                            <div className="fontFamily fontweightRegular fontsize16 validationError">Please Enter 10-digit Landline Number</div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row pt-3">
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor">TIN Number</div>
                                                        <input value={entityTINnumber} type="text" maxLength={10} onChange={(e) => setEntityTINnumber(e.target.value)} placeholder="TIN Number" style={{ paddingLeft: '12px', backgroundColor: '#FFFFFF', border: '#00000014 1px solid', borderRadius: '5px', fontFamily: '"Nunito", sans-serif', fontWeight: '400', fontSize: '16px', width: '100%', outline: 'none', height: '45px' }} />
                                                        {entityTINnumber.length < 10 && entityTINnumber !== '' && (
                                                            <div className="fontFamily fontweightRegular fontsize16 validationError">Please Enter 10-digit TIN Number</div>
                                                        )}
                                                    </div>
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor">GST/VAT Number</div>
                                                        <input value={entityGSTNumber} type="text" maxLength={15} onChange={(e) => setEntityGSTNumber(e.target.value)} placeholder="GST/VAT Number" style={{ paddingLeft: '12px', backgroundColor: '#FFFFFF', border: '#00000014 1px solid', borderRadius: '5px', fontFamily: '"Nunito", sans-serif', fontWeight: '400', fontSize: '16px', width: '100%', outline: 'none', height: '45px' }} />
                                                        {entityGSTNumber.length < 15 && entityGSTNumber !== '' && (
                                                            <div className="fontFamily fontweightRegular fontsize16 validationError">Please Enter 15-digit GST Number</div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row pt-3">
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Registration Date</div>
                                                        <DatePicker
                                                            selected={entityRegisterDate}
                                                            onChange={(date) => setEntityRegisterDate(date)}
                                                            dateFormat="dd-MM-yyyy"
                                                            className="customDatePicker"
                                                        />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="d-flex justify-content-center" style={{ margin: '10px' }}>
                                            <button type="button" className="fontFamily fontweightRegular fontsize16 coloredButtonSave" onClick={(event) => submitEntity(event)}>
                                                Save
                                            </button>
                                            <button type="button" id="btncloseModal" className="fontFamily fontweightRegular fontsize16 outlineButton" style={{ marginLeft: '18px' }} onClick={(event) => closeEntity(event)}>Close</button>
                                        </div>
                                    </div>
                                )}
                                {tabActive === 'BussinessUnit' && (
                                    <div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="custom-container">
                                                <span className="blackTextColor fontFamily fontsize16 fontweightRegular" >
                                                    <span className="show">Show</span>
                                                    <select className="custom-dropdown" value={rowsUnitPerPage} onChange={handleRowsPerPageChange}>
                                                        {[5, 10, 15].map((n) => (
                                                            <option className="blackTextColor fontFamily fontsize14 fontweightRegular" key={n} value={n}>
                                                                {n}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <span className="blackTextColor entries fontFamily fontsize16 fontweightRegular" >
                                                        Entries
                                                    </span>
                                                    <input
                                                        type="text"
                                                        placeholder="Search..."
                                                        className="searchTextBox"
                                                        value={searchQueryUnit}
                                                        onChange={handleUnitSearchChange}
                                                        style={{ marginLeft: '28px' }}
                                                    />
                                                </span>
                                            </div>
                                            <div className="addbuttondiv">
                                                <button type="button" id="BtnaddUnit" className="fontFamily fontweightRegular fontsize16 coloredButtonAdd" style={{ marginLeft: "20px" }} data-bs-toggle="modal" data-bs-target="#addUnit" onClick={handleAddButtonClick}>Add New</button>
                                                <select className="fontFamily fontweightRegular fontsize16 exportBtn" name="language" id="language" defaultValue="" onChange={exportUnit}>
                                                    <option value="" hidden={true}>Export</option>
                                                    <option value="excel">Excel</option>
                                                    <option value="csv">CSV</option>
                                                    <option value="pdf" >Pdf</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="tableBox" style={{ height: '280px', overflowY: 'auto', scrollbarWidth: 'thin' }}>
                                            <table className="w-100 mt-2">
                                                <thead>
                                                    <tr className="text-left">
                                                        <th className="fontFamily fontweightMedium fontsize16 p-2 text-center" style={{ backgroundColor: '#F6F6F6', border: 'none' }}>SI.No</th>
                                                        <th className="fontFamily fontweightMedium fontsize16 p-2 " style={{ backgroundColor: '#F6F6F6', border: 'none' }}>Unit Name</th>
                                                        <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', border: 'none' }}>Address</th>
                                                        <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', border: 'none' }}>City</th>
                                                        <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', border: 'none' }}>Mobile</th>
                                                        <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', border: 'none' }}>E-Mail</th>
                                                        <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', border: 'none' }}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredUnits.length > 0 ? (
                                                        filteredUnits.slice(pageUnit * rowsUnitPerPage, (pageUnit + 1) * rowsUnitPerPage).map((unit, index) => (
                                                            <tr key={unit.Rowid} className="text-left">
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 text-center`} style={{ backgroundColor: index % 2 === 0 ? '#ffffff' : '#FBFBFB', border: 'none' }}>{unit.Rowid}</td>
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2`} style={{ backgroundColor: index % 2 === 0 ? '#ffffff' : '#FBFBFB', border: 'none' }}>{unit.bU_NAME}</td>
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2`} style={{ backgroundColor: index % 2 === 0 ? '#ffffff' : '#FBFBFB', border: 'none' }}>{unit.bU_ADDR}</td>
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2`} style={{ backgroundColor: index % 2 === 0 ? '#ffffff' : '#FBFBFB', border: 'none' }}>{unit.city}</td>
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2`} style={{ backgroundColor: index % 2 === 0 ? '#ffffff' : '#FBFBFB', border: 'none' }}>{unit.bU_TEL}</td>
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2`} style={{ backgroundColor: index % 2 === 0 ? '#ffffff' : '#FBFBFB', border: 'none' }}>{unit.bU_EMAIL}</td>
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2`} style={{ backgroundColor: index % 2 === 0 ? '#ffffff' : '#FBFBFB', border: 'none' }}>
                                                                    <span style={{ marginRight: '10px', cursor: 'pointer' }}>
                                                                        <a href="#viewUnit" onClick={() => viewUnit(unit)}> <img style={{ width: '18px', height: '15px' }} alt="viewsvgIcon" src={viewsvgIcon} /></a>
                                                                    </span>
                                                                    <span style={{ cursor: 'pointer' }}>
                                                                        <a href="#editUnit" onClick={() => editUnit(unit)}><img src={editsvgIcon} alt="editsvgIcon" /></a>
                                                                    </span>
                                                                    <span style={{ marginLeft: '10px', cursor: 'pointer' }}>
                                                                        <a href="#deleteUnit" onClick={() => deleteUnit(unit)}><img src={deletesvgIcon} alt="deletesvgIcon" /></a>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <td colSpan="9" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                            <div >
                                                <div className="grayTextColor fontFamily fontsize16 fontweightMedium">
                                                    {`Showing ${startUnitEntry} to ${endUnitEntry} of ${totalUnit} entries`}
                                                </div>
                                            </div>
                                            <div >
                                                <div className="pagination-container d-flex align-items-center">
                                                    <button onClick={handleUnitFirstPage} className="btn btn-link">
                                                        <FontAwesomeIcon icon={faAngleDoubleLeft} className="icon-color" />
                                                    </button>
                                                    <ReactPaginate
                                                        previousLabel={<FontAwesomeIcon icon={faChevronLeft} className="icon-color" />}
                                                        nextLabel={<FontAwesomeIcon icon={faChevronRight} className="icon-color" />}
                                                        breakLabel={'...'}
                                                        breakClassName={'break-me'}
                                                        pageCount={Math.ceil(filteredUnits.length / rowsUnitPerPage)}
                                                        forcePage={pageUnit}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={5}
                                                        onPageChange={handleUnitChangePagePagination}
                                                        containerClassName={'pagination'}
                                                        subContainerClassName={'pages pagination'}
                                                        activeClassName={'active'}
                                                        renderOnZeroPageCount={null}
                                                    />
                                                    <button onClick={handleUnitLastPage} className="btn btn-link">
                                                        <FontAwesomeIcon icon={faAngleDoubleRight} className="icon-color" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div style={{ padding: "0px 100px" }}>

                                            </div>
                                        </div>
                                    </div>
                                )}
                                {tabActive === 'BussinessBranch' && (
                                    <div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="custom-container">
                                                <span className="blackTextColor fontFamily fontsize16 fontweightRegular" >
                                                    <span className="show">Show</span>
                                                    <select className="custom-dropdown" value={rowsPerPage} onChange={handleRowsPerPageBranchChange}>
                                                        {[5, 10, 15].map((n) => (
                                                            <option className="blackTextColor fontFamily fontsize14 fontweightRegular" key={n} value={n}>
                                                                {n}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <span className="blackTextColor entries fontFamily fontsize16 fontweightRegular" >
                                                        Entries
                                                    </span>
                                                    <input
                                                        type="text"
                                                        placeholder="Search..."
                                                        className="searchTextBox"
                                                        value={searchQueryBranch}
                                                        onChange={handleBranchSearchChange}
                                                        style={{ marginLeft: '28px' }}
                                                    />
                                                </span>
                                            </div>
                                            <div className="addbuttondiv">
                                                <button type="button" id="BtnaddBranch" className="fontFamily fontweightRegular fontsize16 coloredButtonAdd" style={{ marginLeft: "20px" }} data-bs-toggle="modal" data-bs-target="#addBranch" onClick={handleAddButtonClick}>Add New</button>
                                                <select className="fontFamily fontweightRegular fontsize16 exportBtn" name="language" id="language" defaultValue="" onChange={exportBranch}>
                                                    <option value="" hidden={true}>Export</option>
                                                    <option value="excel">Excel</option>
                                                    <option value="csv">CSV</option>
                                                    <option value="pdf" >Pdf</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="tableBox" style={{ height: '280px', overflowY: 'auto', scrollbarWidth: 'thin' }}>
                                            <table className="w-100 mt-2">
                                                <thead>
                                                    <tr className="text-left">
                                                        <th className="fontFamily fontweightMedium fontsize16 p-2 text-center" style={{ backgroundColor: '#F6F6F6', border: 'none' }}> SI.No </th>
                                                        <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', border: 'none' }}> Unit </th>
                                                        <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', border: 'none' }}> Branch Name </th>
                                                        <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', border: 'none' }}> Address </th>
                                                        <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', border: 'none' }}> City </th>
                                                        <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', border: 'none' }}> Mobile </th>
                                                        <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', border: 'none' }}> E-Mail </th>
                                                        <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', border: 'none' }}> Active </th>
                                                        <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', border: 'none' }}> Action </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredBranches.length > 0 ? (
                                                        filteredBranches.slice(pageBranch * rowsPerPage, (pageBranch + 1) * rowsPerPage).map((branch, index) => (
                                                            <tr key={branch.Rowid} className="text-left">
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 text-center`}>{branch.Rowid}</td>
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2`}>{branch.unitName}</td>
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2`}>{branch.bR_NAME}</td>
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2`}>{branch.bR_ADDR}</td>
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2`}>{branch.city}</td>
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2`}>{branch.bR_TEL}</td>
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2`}>{branch.bR_EMAIL}</td>
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2`}><Switch color="primary" checked={branch.bR_ISACTIVE} sx={{
                                                                    '& .MuiSwitch-switchBase.Mui-checked': {
                                                                        color: '#605bff', // color of thumb when checked
                                                                        '& + .MuiSwitch-track': {
                                                                            backgroundColor: '#605bff', // color of track when checked
                                                                        },
                                                                    },
                                                                }} onChange={() => handleToggleActive(branch)} /></td>
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2`}><span style={{ marginRight: '10px', cursor: 'pointer' }}><a href="#viewBranch" onClick={() => viewBranch(branch)} ><img style={{ width: '18px', height: '15px' }} src={viewsvgIcon} alt="viewsvgIcon" /></a></span><span style={{ cursor: 'pointer' }}><a href="#editBranch" onClick={() => editBranch(branch)} ><img src={editsvgIcon} alt="editsvgIcon" /></a></span><span style={{ marginLeft: '10px', cursor: 'pointer' }}><a href="#deleteBranch" onClick={() => deleteBranch(branch.bR_ID)} ><img src={deletesvgIcon} alt="deletesvgIcon" /></a></span></td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <td colSpan="9" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                            <div >
                                                <div className="grayTextColor fontFamily fontsize16 fontweightMedium">
                                                    {`Showing ${startBranchEntry} to ${endBranchEntry} of ${totalBranch} entries`}
                                                </div>
                                            </div>
                                            <div >
                                                <div className="pagination-container d-flex align-items-center">
                                                    <button onClick={handleBranchFirstPage} className="btn btn-link">
                                                        <FontAwesomeIcon icon={faAngleDoubleLeft} className="icon-color" />
                                                    </button>
                                                    <ReactPaginate
                                                        previousLabel={<FontAwesomeIcon icon={faChevronLeft} className="icon-color" />}
                                                        nextLabel={<FontAwesomeIcon icon={faChevronRight} className="icon-color" />}
                                                        breakLabel={'...'}
                                                        breakClassName={'break-me'}
                                                        pageCount={Math.ceil(filteredBranches.length / rowsPerPage)}
                                                        forcePage={pageBranch}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={5}
                                                        onPageChange={handleBranchChangePagePagination}
                                                        containerClassName={'pagination'}
                                                        activeClassName={'active'}
                                                        renderOnZeroPageCount={null}
                                                    />
                                                    <button onClick={handleBranchLastPage} className="btn btn-link">
                                                        <FontAwesomeIcon icon={faAngleDoubleRight} className="icon-color" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div style={{ padding: "0px 100px" }}>

                                            </div>
                                        </div>
                                    </div>
                                )}

                            </div>
                        </div>
                        {/* <!-- AddUnitModal --> */}
                        <div className="modal fade modal-lg" id="addUnit" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="addUnitLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <div className="fontFamily fontweightMedium fontsize16">Add Business Unit</div>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="InsidecardBorder">
                                            <div className="form-container yaxisScroller" style={{ maxHeight: '400px' }}>
                                                <form className="p-2">
                                                    <div className="row">
                                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Unit Name<span className="mandatoryCss"> *</span></div>
                                                            <input type="text" placeholder="Unit Name" value={unitName} onChange={(e) => setUnitName(e.target.value)} style={{ backgroundColor: isUnitView ? '#FAFAFB' : '#FFFFFF', border: '#00000014 1px solid', borderRadius: '5px', fontFamily: '"Nunito", sans-serif', fontWeight: '400', fontSize: '16px', width: '100%', outline: 'none', height: '45px', padding: '0px 12px' }} disabled={isUnitView} />
                                                            {unitIsSubmit && unitName === '' && (
                                                                <div className="fontFamily fontweightRegular fontsize16 validationError">Please Enter Unit Name</div>
                                                            )}

                                                        </div>
                                                        <div className="col-6 col-md-6 col-lg-6">
                                                            <div className="fontFamily fontweightRegular grayTextColor">Upload Image</div>
                                                            <div className="text-center">
                                                                <img src={previewUnitImg} alt="previewUnitImg" width="139" height="88" />
                                                                <div className="mt-3">
                                                                    <input type="file" id="uploadImgID"
                                                                        style={{ width: '102px', margin: '8px 0px', display: 'none' }} disabled={isUnitView}
                                                                        onChange={handleFileInputChange}
                                                                    />
                                                                    <button className="fontFamily fontweightRegular fontsize16 coloredButton" hidden={isUnitView} onClick={fileUpload}>Upload Image</button><span style={{ marginLeft: '7px' }}><button className="fontFamily fontweightRegular fontsize16 coloredButton" hidden={isUnitView} onClick={clearImage}>Clear</button></span>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="row pt-3">
                                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Mobile Number<span className="mandatoryCss"> *</span></div>
                                                            <input disabled={isUnitView} type="text" value={unitMobileNumber} placeholder="Mobile Number" onChange={(e) => numberValidation(e.target.value, 'BusinessUnit', 'MobileNumber')} maxLength={10} style={{ backgroundColor: isUnitView ? '#FAFAFB' : '#FFFFFF', border: '#00000014 1px solid', borderRadius: '5px', fontFamily: '"Nunito", sans-serif', fontWeight: '400', fontSize: '16px', width: '100%', outline: 'none', height: '45px', padding: '0px 12px' }} />
                                                            {unitIsSubmit && unitMobileNumber === '' && (
                                                                <div className="fontFamily fontweightRegular fontsize16 validationError">Please Enter Mobile Number</div>
                                                            )}
                                                            {unitMobileNumber !== '' && unitMobileNumber.length < 10 && (
                                                                <div className="fontFamily fontweightRegular fontsize16 validationError">Please Enter 10-digit Mobile Number</div>
                                                            )}

                                                        </div>
                                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Street Address<span className="mandatoryCss"> *</span></div>
                                                            <textarea disabled={isUnitView} value={unitStreetAddress} placeholder="Street Address" onChange={(e) => setUnitStreetAddress(e.target.value)} style={{ backgroundColor: isUnitView ? '#FAFAFB' : '#FFFFFF', border: '#00000014 1px solid', borderRadius: '5px', fontFamily: '"Nunito", sans-serif', fontWeight: '400', fontSize: '16px', width: '100%', outline: 'none', height: '100px', resize: 'none', padding: '0px 12px' }}>
                                                            </textarea>
                                                            {unitIsSubmit && unitStreetAddress === '' && (
                                                                <div className="fontFamily fontweightRegular fontsize16 validationError">Please Enter Street Address</div>
                                                            )}

                                                        </div>
                                                    </div>
                                                    <div className="row €">
                                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Select Country<span className="mandatoryCss"> *</span></div>
                                                            <Select
                                                                value={countrySelectedOption}
                                                                onChange={handleCountrySelect}
                                                                options={countryUnitOption}
                                                                isDisabled={isUnitView}
                                                                placeholder="Select a country..."
                                                                isClearable={true}
                                                            />
                                                            {unitIsSubmit && countrySelectedOption == null && (
                                                                <div className="fontFamily fontweightRegular fontsize16 validationError">Please Select Country</div>
                                                            )}

                                                        </div>
                                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Select State<span className="mandatoryCss"> *</span></div>
                                                            <Select
                                                                value={stateSelectedOption}
                                                                onChange={handleStateSelect}
                                                                options={stateUnitOption}
                                                                isDisabled={isUnitView}
                                                                placeholder="Select a State..."
                                                                isClearable={true}
                                                            />
                                                            {unitIsSubmit && stateSelectedOption == null && (
                                                                <div className="fontFamily fontweightRegular fontsize16 validationError">Please Select State</div>
                                                            )}

                                                        </div>
                                                    </div>
                                                    <div className="row pt-3">
                                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Select City<span className="mandatoryCss"> *</span></div>
                                                            <Select
                                                                value={citySelectedOption}
                                                                onChange={handleCitySelect}
                                                                options={cityUnitOption}
                                                                isDisabled={isUnitView}
                                                                placeholder="Select a City..."
                                                                isClearable={true}
                                                            />
                                                            {unitIsSubmit && citySelectedOption == null && (
                                                                <div className="fontFamily fontweightRegular fontsize16 validationError">Please Select City</div>
                                                            )}

                                                        </div>
                                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Postal Code<span className="mandatoryCss"> *</span></div>
                                                            <input disabled={isUnitView} type="text" value={unitPostalCode} onChange={(e) => numberValidation(e.target.value, 'BusinessUnit', 'PostalCode')} maxLength={6} placeholder="Postal Code" style={{ backgroundColor: isUnitView ? '#FAFAFB' : '#FFFFFF', border: '#00000014 1px solid', borderRadius: '5px', fontFamily: '"Nunito", sans-serif', fontWeight: '400', fontSize: '16px', width: '100%', outline: 'none', height: '45px', padding: '0px 12px' }} />
                                                            {unitPostalCode.length < 6 && unitPostalCode !== '' && (
                                                                <div className="fontFamily fontweightRegular fontsize16 validationError">Please Enter 6-digit Postal Code</div>
                                                            )}
                                                            {unitIsSubmit && unitPostalCode === '' && (
                                                                <div className="fontFamily fontweightRegular fontsize16 validationError">Please Enter Postal Code</div>
                                                            )}

                                                        </div>
                                                    </div>
                                                    <div className="row pt-3">
                                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Fax Number</div>
                                                            <input disabled={isUnitView} onChange={(e) => numberValidation(e.target.value, 'BusinessUnit', 'FaxNumber')} maxLength={10} value={unitFaxNumber} placeholder="Fax Number" type="text" style={{ backgroundColor: isUnitView ? '#FAFAFB' : '#FFFFFF', border: '#00000014 1px solid', borderRadius: '5px', fontFamily: '"Nunito", sans-serif', fontWeight: '400', fontSize: '16px', width: '100%', outline: 'none', height: '45px', padding: '0px 12px' }} />
                                                            {unitFaxNumber !== '' && unitFaxNumber.length < 10 && (
                                                                <div className="fontFamily fontweightRegular fontsize16 validationError">Please Enter 10-digit FAX Number</div>
                                                            )}
                                                        </div>
                                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                            <div disabled={isUnitView} className="fontFamily fontweightRegular fontsize16 grayTextColor">Email Address<span className="mandatoryCss"> *</span></div>
                                                            <input disabled={isUnitView} value={unitEmail} onChange={(e) => EmailValidation(e.target.value, 'BusinessUnit')} placeholder="Email Address" type="text" style={{ backgroundColor: isUnitView ? '#FAFAFB' : '#FFFFFF', border: '#00000014 1px solid', borderRadius: '5px', fontFamily: '"Nunito", sans-serif', fontWeight: '400', fontSize: '16px', width: '100%', outline: 'none', height: '45px', padding: '0px 12px' }} />
                                                            {unitEmailValidation && unitEmail.length !== 0 && (
                                                                <div className="fontFamily fontweightRegular fontsize16 validationError">Please Enter Valid Mail ID.</div>
                                                            )}
                                                            {unitIsSubmit && unitEmail === '' && (
                                                                <div className="fontFamily fontweightRegular fontsize16 validationError">Please Enter Email Address</div>
                                                            )}

                                                        </div>
                                                    </div>
                                                    <div className="row pt-3">
                                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Website</div>
                                                            <input disabled={isUnitView} value={unitWebsite} onChange={handleWebsiteUnitChange} type="text" placeholder="Website" style={{ backgroundColor: isUnitView ? '#FAFAFB' : '#FFFFFF', border: '#00000014 1px solid', borderRadius: '5px', fontFamily: '"Nunito", sans-serif', fontWeight: '400', fontSize: '16px', width: '100%', outline: 'none', height: '45px', padding: '0px 12px' }} />
                                                            {websiteUnitError !== '' && (
                                                                <div className="fontFamily fontweightRegular fontsize16 validationError">
                                                                    Please enter a valid URL
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Landline Number</div>
                                                            <input disabled={isUnitView} onChange={(e) => numberValidation(e.target.value, 'BusinessUnit', 'LandlineNumber')} maxLength={10} value={unitLandline} type="text" placeholder="Landline Number" style={{ backgroundColor: isUnitView ? '#FAFAFB' : '#FFFFFF', border: '#00000014 1px solid', borderRadius: '5px', fontFamily: '"Nunito", sans-serif', fontWeight: '400', fontSize: '16px', width: '100%', outline: 'none', height: '45px', padding: '0px 12px' }} />
                                                            {unitLandline !== '' && unitLandline.length < 10 && (
                                                                <div className="fontFamily fontweightRegular fontsize16 validationError">Please Enter 10-digit Landline Number</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="row pt-3">
                                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor">TIN Number</div>
                                                            <input disabled={isUnitView} value={unitTINnumber} type="text" onChange={(e) => setUnitTINnumber(e.target.value)} placeholder="TIN Number" maxLength={10} style={{ backgroundColor: isUnitView ? '#FAFAFB' : '#FFFFFF', border: '#00000014 1px solid', borderRadius: '5px', fontFamily: '"Nunito", sans-serif', fontWeight: '400', fontSize: '16px', width: '100%', outline: 'none', height: '45px', padding: '0px 12px' }} />
                                                            {unitTINnumber !== '' && unitTINnumber.length < 10 && (
                                                                <div className="fontFamily fontweightRegular fontsize16 validationError">Please Enter 10-digit Landline Number</div>
                                                            )}
                                                        </div>
                                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor">GST/VAT Number</div>
                                                            <input disabled={isUnitView} value={unitGSTNumber} type="text" onChange={(e) => setUnitGSTNumber(e.target.value)} maxLength={15} placeholder="GST/VAT Number" style={{ backgroundColor: isUnitView ? '#FAFAFB' : '#FFFFFF', border: '#00000014 1px solid', borderRadius: '5px', fontFamily: '"Nunito", sans-serif', fontWeight: '400', fontSize: '16px', width: '100%', outline: 'none', height: '45px', padding: '0px 12px' }} />
                                                            {unitGSTNumber !== '' && unitGSTNumber.length < 15 && (
                                                                <div className="fontFamily fontweightRegular fontsize16 validationError">Please Enter 15-digit GST Number</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="row pt-3">
                                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Registration Date</div>
                                                            <DatePicker
                                                                selected={unitRegisterDate}
                                                                onChange={(date) => setUnitRegisterDate(date)}
                                                                dateFormat="dd-MM-yyyy"
                                                                className={`${isUnitView ? 'customDatePickerDisable' : 'customDatePicker'}`}
                                                                disabled={isUnitView}
                                                            />
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    {!isUnitView && (
                                        <div className="d-flex justify-content-center" style={{ margin: '10px' }}>
                                            <button type="button" className="fontFamily fontweightRegular fontsize16 coloredButton" onClick={(event) => submitUnit(event)}>
                                                {unitIsSave ? (
                                                    <ThreeCircles
                                                        visible={true}
                                                        height="25"
                                                        width="47"
                                                        color="White"
                                                        ariaLabel="three-circles-loading"
                                                        wrapperStyle={{}}
                                                        wrapperClass=""
                                                    />
                                                ) : (
                                                    'Save'
                                                )}
                                            </button>
                                            <button type="button" id="btncloseModal" className="fontFamily fontweightRegular fontsize16 outlineButton" data-bs-dismiss="modal" style={{ marginLeft: '5px' }}>Close</button>

                                        </div>
                                    )}
                                    {isUnitView && (
                                        <div className="d-flex justify-content-center" style={{ margin: '10px' }}>
                                            <button type="button" id="btncloseModal" className="fontFamily fontweightRegular fontsize16 outlineButton" data-bs-dismiss="modal" style={{ marginLeft: '5px' }}>Close</button>
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                        {/* AddBranchModal */}
                        <div className="modal fade modal-lg" id="addBranch" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="addBranchLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <div className="fontFamily fontweightMedium fontsize16">Add Business Branch</div>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="InsidecardBorder">
                                            <div className="form-container yaxisScroller" style={{ maxHeight: '400px' }}>
                                                <form className="p-2">
                                                    <div className="row">
                                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Select Unit<span className="mandatoryCss"> *</span></div>
                                                            <Select
                                                                value={unitSelectedOption}
                                                                onChange={handleUnitSelect}
                                                                options={unitOption}
                                                                isDisabled={isBranchView}
                                                                placeholder="Select a unit..."
                                                                isClearable={true}
                                                            />
                                                            {branchIsSubmit && unitSelectedOption == null && (
                                                                <div className="fontFamily fontweightRegular fontsize16 validationError">Please Select Unit</div>
                                                            )}
                                                        </div>
                                                        <div className="col-6 col-md-6 col-lg-6">
                                                            <div className="fontFamily fontweightRegular grayTextColor">Upload Image</div>
                                                            <div className="text-center">
                                                                <img src={previewBranchImg} alt="previewBranchImg" width="139" height="88" />
                                                                <div className="mt-3">
                                                                    <input type="file" id="BranchuploadImgID"
                                                                        style={{ width: '102px', margin: '8px 0px', display: 'none' }}
                                                                        disabled={isBranchView}
                                                                        onChange={handleBranchFileInputChange}
                                                                    />
                                                                    <button className="fontFamily fontweightRegular fontsize16 coloredButton" hidden={isBranchView} onClick={BranchfileUpload}>Upload Image</button><span style={{ marginLeft: '7px' }}><button className="fontFamily fontweightRegular fontsize16 coloredButton " hidden={isBranchView} onClick={clearImageBranch}>Clear</button></span>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="row pt-3">
                                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Branch Name<span className="mandatoryCss"> *</span></div>
                                                            <input type="text" placeholder="Branch Name" value={branchName} onChange={(e) => setBranchName(e.target.value)} style={{ backgroundColor: isBranchView ? '#FAFAFB' : '#FFFFFF', border: '#00000014 1px solid', borderRadius: '5px', fontFamily: '"Nunito", sans-serif', fontWeight: '400', fontSize: '16px', width: '100%', outline: 'none', height: '45px', padding: '0px 12px' }} disabled={isBranchView} />
                                                            {branchIsSubmit && branchName === '' && (
                                                                <div className="fontFamily fontweightRegular fontsize16 validationError">Please Enter Branch Name</div>
                                                            )}
                                                        </div>
                                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Mobile Number<span className="mandatoryCss"> *</span></div>
                                                            <input type="text" value={branchMobileNumber} placeholder="Mobile Number" onChange={(e) => numberValidation(e.target.value, 'BusinessBranch', 'MobileNumber')} maxLength={10} style={{ backgroundColor: isBranchView ? '#FAFAFB' : '#FFFFFF', border: '#00000014 1px solid', borderRadius: '5px', fontFamily: '"Nunito", sans-serif', fontWeight: '400', fontSize: '16px', width: '100%', outline: 'none', height: '45px', padding: '0px 12px' }} disabled={isBranchView} />
                                                            {branchIsSubmit && branchMobileNumber === '' && (
                                                                <div className="fontFamily fontweightRegular fontsize16 validationError">Please Enter Mobile Number</div>
                                                            )}
                                                            {branchMobileNumber !== '' && branchMobileNumber.length < 10 && (
                                                                <div className="fontFamily fontweightRegular fontsize16 validationError">Please Enter 10-digit Mobile Number</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="row pt-3">
                                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Street Address<span className="mandatoryCss"> *</span></div>
                                                            <textarea disabled={isBranchView} value={branchStreetAddress} placeholder="Street Address" onChange={(e) => setBranchStreetAddress(e.target.value)} style={{ backgroundColor: isBranchView ? '#FAFAFB' : '#FFFFFF', border: '#00000014 1px solid', borderRadius: '5px', fontFamily: '"Nunito", sans-serif', fontWeight: '400', fontSize: '16px', width: '100%', outline: 'none', height: '100px', resize: 'none', padding: '0px 12px' }}>
                                                            </textarea>
                                                            {branchIsSubmit && branchStreetAddress === '' && (
                                                                <div className="fontFamily fontweightRegular fontsize16 validationError">Please Enter Street Address</div>
                                                            )}
                                                        </div>
                                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Select Country<span className="mandatoryCss"> *</span></div>
                                                            <Select
                                                                value={countrySelectedBranchOption}
                                                                onChange={handleCountrySelectBranch}
                                                                options={countryBranchOption}
                                                                placeholder="Select a country..."
                                                                isDisabled={isBranchView}
                                                                isClearable={true}
                                                            />
                                                            {branchIsSubmit && countrySelectedBranchOption == null && (
                                                                <div className="fontFamily fontweightRegular fontsize16 validationError">Please Select Country</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="row pt-3">
                                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Select State<span className="mandatoryCss"> *</span></div>
                                                            <Select
                                                                value={stateSelectedBranchOption}
                                                                onChange={handleStateSelectBranch}
                                                                options={stateBranchOption}
                                                                placeholder="Select a State..."
                                                                isDisabled={isBranchView}
                                                                isClearable={true}
                                                            />
                                                            {branchIsSubmit && stateSelectedBranchOption == null && (
                                                                <div className="fontFamily fontweightRegular fontsize16 validationError">Please Select State</div>
                                                            )}
                                                        </div>
                                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Select City<span className="mandatoryCss"> *</span></div>
                                                            <Select
                                                                value={citySelectedBranchOption}
                                                                onChange={handleCitySelectBranch}
                                                                options={cityBranchOption}
                                                                placeholder="Select a City..."
                                                                isDisabled={isBranchView}
                                                                isClearable={true}
                                                            />
                                                            {branchIsSubmit && citySelectedBranchOption == null && (
                                                                <div className="fontFamily fontweightRegular fontsize16 validationError">Please Select City</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="row pt-3">
                                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Postal Code<span className="mandatoryCss"> *</span></div>
                                                            <input disabled={isBranchView} type="text" value={branchPostalCode} onChange={(e) => numberValidation(e.target.value, 'BusinessBranch', 'PostalCode')} maxLength={6} placeholder="Postal Code" style={{
                                                                backgroundColor: isBranchView ? '#FAFAFB' : '#FFFFFF', border: '#00000014 1px solid', borderRadius: '5px', fontFamily: '"Nunito", sans-serif', fontWeight: '400', fontSize: '16px', width: '100%', outline: 'none', height: '45px',
                                                                padding: '0px 12px'
                                                            }} />
                                                            {branchPostalCode.length < 6 && branchPostalCode !== '' && (
                                                                <div className="fontFamily fontweightRegular fontsize16 validationError">Please Enter 6-digit Postal Code</div>
                                                            )}
                                                            {branchIsSubmit && branchPostalCode === '' && (
                                                                <div className="fontFamily fontweightRegular fontsize16 validationError">Please Enter Postal Code</div>
                                                            )}
                                                        </div>

                                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Fax Number</div>
                                                            <input onChange={(e) => numberValidation(e.target.value, 'BusinessBranch', 'FaxNumber')} maxLength={10} value={branchFaxNumber} placeholder="Fax Number" type="text" style={{
                                                                backgroundColor: '#FFFFFF', border: '#00000014 1px solid', borderRadius: '5px', fontFamily: '"Nunito", sans-serif', fontWeight: '400', fontSize: '16px', width: '100%', outline: 'none', height: '45px',
                                                                padding: '0px 12px'
                                                            }} disabled={isBranchView} />
                                                            {branchFaxNumber !== '' && branchFaxNumber.length < 10 && (
                                                                <div className="fontFamily fontweightRegular fontsize16 validationError">Please Enter 10-digit FAX Number</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="row pt-3">
                                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Email Address<span className="mandatoryCss"> *</span></div>
                                                            <input disabled={isBranchView} value={branchEmail} onChange={(e) => EmailValidation(e.target.value, 'BusinessBranch')} placeholder="Email Address" type="text" style={{ backgroundColor: isBranchView ? '#FAFAFB' : '#FFFFFF', border: '#00000014 1px solid', borderRadius: '5px', fontFamily: '"Nunito", sans-serif', fontWeight: '400', fontSize: '16px', width: '100%', outline: 'none', height: '45px', padding: '0px 12px' }} />
                                                            {branchEmailValidation && branchEmail.length !== 0 && (
                                                                <div className="fontFamily fontweightRegular fontsize16 validationError">Please Enter Valid Mail ID.</div>
                                                            )}
                                                            {branchIsSubmit && branchEmail === '' && (
                                                                <div className="fontFamily fontweightRegular fontsize16 validationError">Please Enter Email Address</div>
                                                            )}
                                                        </div>

                                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Website</div>
                                                            <input value={branchWebsite} onChange={handleWebsiteBranchChange} type="text" placeholder="Website" style={{ backgroundColor: isBranchView ? '#FAFAFB' : '#FFFFFF', border: '#00000014 1px solid', borderRadius: '5px', fontFamily: '"Nunito", sans-serif', fontWeight: '400', fontSize: '16px', width: '100%', outline: 'none', height: '45px', padding: '0px 12px' }} disabled={isBranchView} />
                                                            {websiteBranchError !== '' && (
                                                                <div className="fontFamily fontweightRegular fontsize16 validationError">
                                                                    Please enter a valid URL
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="row pt-3">
                                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Landline Number</div>
                                                            <input disabled={isBranchView} onChange={(e) => numberValidation(e.target.value, 'BusinessBranch', 'LandlineNumber')} maxLength={10} value={branchLandline} type="text" placeholder="Landline Number" style={{ backgroundColor: isBranchView ? '#FAFAFB' : '#FFFFFF', border: '#00000014 1px solid', borderRadius: '5px', fontFamily: '"Nunito", sans-serif', fontWeight: '400', fontSize: '16px', width: '100%', outline: 'none', height: '45px', padding: '0px 12px' }} />
                                                            {branchLandline !== '' && branchLandline.length < 10 && (
                                                                <div className="fontFamily fontweightRegular fontsize16 validationError">Please Enter 10-digit Landline Number</div>
                                                            )}
                                                        </div>
                                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor">TIN Number</div>
                                                            <input disabled={isBranchView} value={branchTINnumber} type="text" onChange={(e) => setBranchTINnumber(e.target.value)} placeholder="TIN Number" maxLength={10} style={{ backgroundColor: isBranchView ? '#FAFAFB' : '#FFFFFF', border: '#00000014 1px solid', borderRadius: '5px', fontFamily: '"Nunito", sans-serif', fontWeight: '400', fontSize: '16px', width: '100%', outline: 'none', height: '45px', padding: '0px 12px' }} />
                                                            {branchTINnumber !== '' && branchTINnumber.length < 10 && (
                                                                <div className="fontFamily fontweightRegular fontsize16 validationError">Please Enter 10-digit Landline Number</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="row pt-3">
                                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor">GST/VAT Number</div>
                                                            <input disabled={isBranchView} value={branchGSTNumber} type="text" onChange={(e) => setBranchGSTNumber(e.target.value)} maxLength={15} placeholder="GST/VAT Number" style={{ backgroundColor: isBranchView ? '#FAFAFB' : '#FFFFFF', border: '#00000014 1px solid', borderRadius: '5px', fontFamily: '"Nunito", sans-serif', fontWeight: '400', fontSize: '16px', width: '100%', outline: 'none', height: '45px', padding: '0px 12px' }} />
                                                            {branchGSTNumber !== '' && branchGSTNumber.length < 15 && (
                                                                <div className="fontFamily fontweightRegular fontsize16 validationError">Please Enter 15-digit GST Number</div>
                                                            )}
                                                        </div>
                                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Registration Date</div>
                                                            <DatePicker
                                                                selected={branchRegisterDate}
                                                                onChange={(date) => setBranchRegisterDate(date)}
                                                                dateFormat="dd-MM-yyyy"
                                                                className={`${isBranchView ? 'customDatePickerDisable' : 'customDatePicker'}`}
                                                            />
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                    {!isBranchView && (
                                        <div className="d-flex justify-content-center" style={{ margin: '10px' }}>
                                            <button type="button" className="fontFamily fontweightRegular fontsize16 coloredButton" onClick={(event) => submitBranch(event)}>{branchIsSave ? (
                                                <ThreeCircles
                                                    visible={true}
                                                    height="25"
                                                    width="47"
                                                    color="White"
                                                    ariaLabel="three-circles-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                />
                                            ) : (
                                                'Save'
                                            )}</button>
                                            <button type="button" id="btncloseModalBranch" className="fontFamily fontweightRegular fontsize16 outlineButton" data-bs-dismiss="modal" style={{ marginLeft: '5px' }}>Close</button>

                                        </div>
                                    )}
                                    {isBranchView && (
                                        <div className="d-flex justify-content-center" style={{ margin: '10px' }}>
                                            <button type="button" id="btncloseModalBranch" className="fontFamily fontweightRegular fontsize16 outlineButton" data-bs-dismiss="modal" style={{ marginLeft: '5px' }}>Close</button>

                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            )}


        </div>

    )
};


export default BusinessEntityNew;