import React, { useState, useEffect, useRef, useCallback } from "react";
import ReactPaginate from "react-paginate";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';
import { parse, format } from 'date-fns';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronLeft,
    faChevronRight,
    faAngleDoubleLeft,
    faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";

import ConfirmDelete from "../../../CommomPages/ConfirmDelete";
import LoadingSpinner from "../../../CommomPages/LoadingSpinner";
import { exportAssetData, downloadSampleExcelAsset } from "./ExportAssetCreation";
import { getSupplier } from "../../../Services/SupplierMasterService";
import { getDocument } from "../../../Services/GeneralSettingsService";
import { getEmployee } from "../../../Services/EmployeeManagementService";
import { getDepartment } from "../../../Services/DepartmentsAndTeamService";
import { getBUData, getBRData } from "../../../Services/BusinessEntityNewService";
import { getCategory, getSubCategory, getBrand } from "../../../Services/AssetCategoriesAndBrandService";
import {
    getAsset,
    createAsset,
    deleteAsset,
    getAssetStatus,
    getAssetLink,
    createAssetLink,
    deleteAssetLink,
} from "../../../Services/AssetSettingsService";
import BulkUpdatePopup from "../ActionFormPopup/BulkUpdatePopup";
import { getAssetPrefix, getAssetPadding, getUnitID, getBranchID, getDepartmentID, getAssetDepreciation, getDepreciationMethod, getDateFormat, getRegexForDateFormat, validateDate } from "../../../CommomPages/Config";
import { getTab, createCheckOut, createCheckIn, createLost, createDisposed, createBroken, createRepair, createFound, createDonate, createSell } from "../../../Services/ActionFormConfigurationService";
import { FoundSection, LostSection, BrokenSection, DisposeSection, CheckOutSection, CheckInSection, RepairSection, DonateSection, SellSection, AssetLinkSection, AssetDepreciationSection, AssetAddSection, SampleExcelDownload } from "../ActionFormPopup/ActionFormFormPopup";
import "./AssetCreationCss.css";

import viewsvgIcon from "../../../assets/icon/Eye.svg";
import editsvgIcon from "../../../assets/icon/Edit.svg";
import rupeesvgIcon from "../../../assets/icon/Rupee.svg";
import deletesvgIcon from "../../../assets/icon/Delete.svg";
import nodataFound from "../../../assets/img/nodatafound.png";
import DropdownArrow from "../../../assets/icon/Dropdown.svg";
import calendarsvgIcon from "../../../assets/icon/calendarDate.svg";


const AssetCreationView = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const fileAssetInputRef = useRef(null);
    const paginateArray = [5, 10, 15];
    const [pageAsset, setPageAsset] = useState(0);
    const [rowsPerPageAsset, setRowsPerPageAsset] = useState(5);
    const [searchQueryAsset, setSearchQueryAsset] = useState("");

    const [assetArray, setAssetArray] = useState([]);
    const [showUpdatePopup, setshowUpdatePopup] = useState(false);
    const [supplierArray, setSupplierArray] = useState([]);
    const [employeeArray, setEmployeeArray] = useState([]);
    const [departmentArray, setDepartmentArray] = useState([]);
    const [categoryArray, setCategoryArray] = useState([]);
    const [subCategoryArray, setSubCategoryArray] = useState([]);
    const [brandArray, setBrandArray] = useState([]);
    const [unitArray, setUnitArray] = useState([]);
    const [branchArray, setBranchArray] = useState([]);
    const [assetArrayWithoutLinkedAsset, setAssetArrayWithoutLinkedAsset] = useState([]);

    const [selectAll, setSelectAll] = useState(false);
    const [moreOptions, setMoreOptions] = useState([]);
    const [selectedAssets, setSelectedAssets] = useState([]);
    const [dateFormat, setDateFormat] = useState("");
    const [selectedValue, setSelectedValue] = useState("");
    const [selectedLinkValue, setSelectedLinkValue] = useState("");
    const [depreciationValue, setDepreciationValue] = useState("");

    const [isCheckOutView, setIsCheckOutView] = useState(false);
    const [isCheckInView, setIsCheckInView] = useState(false);
    const [isLostView, setIsLostView] = useState(false);
    const [isFoundView, setIsFoundView] = useState(false);
    const [isBrokenView, setIsBrokenView] = useState(false);
    const [isRepairView, setIsRepairView] = useState(false);
    const [isDisposeView, setIsDisposeView] = useState(false);
    const [isDonateView, setIsDonateView] = useState(false);
    const [isSellView, setIsSellView] = useState(false);
    const [isLinkView, setIsLinkView] = useState(false);
    const [isDepreciationView, setIsDepreciationView] = useState(false);
    const [showSampleExcelDownload, setShowSampleExcelDownload] = useState(false);
    const depreciationMethodOptions = [
        { value: 'Declining Balance', label: 'Declining Balance' },
        { value: 'Straight line', label: 'Straight line' },
        { value: 'units of production', label: 'units of production' }
    ];

    // Define dataInitialization as a regular async function
    const dataInitialization = useCallback(async () => {
        try {
            setIsLoading(true);
            await Promise.all([
                fetchAssetData(),
                getEmployeeData(),
                getSupplierData(),
                getDepartmentData(),
                getUnitBranchDetails(),
                getCategoryAndBrandData(),
            ]);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dataInitialization();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchAssetData = async () => {
        try {
            setIsLoading(true);
            const dateCurrentFormat = await getDateFormat();
            console.log("setting date format", dateCurrentFormat);
            setDateFormat(dateCurrentFormat);
            const assetData = await getAsset();
            const assetStatus = await getAssetStatus();
            const brandArray = await getBrand();
            const tabs = await getTab();
            setMoreOptions(tabs);

            const linkData = await getAssetLink();
            console.log("linkData", linkData);

            // Extract assetIds and assetRelationIds from linkData
            const linkedAssetIds = new Set(linkData?.map(link => link.assetId)?.filter(id => id !== null));
            const linkedRelationIds = new Set(linkData?.map(link => link.assetRelationId)?.filter(id => id !== null));
            // Filter assetData to exclude assets that are in linkData
            const withoutLinkedAsset = assetData?.filter(asset =>
                !linkedAssetIds?.has(asset.id) && !linkedRelationIds?.has(asset.id)
            );
            console.log("withoutLinkedAsset", withoutLinkedAsset);
            // Update state or perform further actions
            setAssetArrayWithoutLinkedAsset(withoutLinkedAsset);

            const finalData = assetData?.length > 0 ? assetData.map((asset, index) => {
                const brandAsset = brandArray?.find(x => x.id === asset?.brandId);
                const formattedDate = asset?.purchaseDate ? format(new Date(asset.purchaseDate), dateCurrentFormat) : null;
                const getAssetStatusValue = assetStatus?.find(e => e.id === asset?.statusValue);
                // if (getAssetStatusValue?.labelValue === "CheckIn") {
                //     const filteredTabs = tabs?.filter(tab => !["Check In", "Found"]?.includes(tab?.tabName));
                //     setMoreOptions(filteredTabs);
                //     console.log('if --->Check In', filteredTabs);
                // }
                // else if (getAssetStatusValue?.labelValue === "Lost") {
                //     const filteredTabs = tabs?.filter(tab => !["Check Out", "Lost"]?.includes(tab?.tabName));
                //     setMoreOptions(filteredTabs);
                //     console.log('else if --->Lost', filteredTabs);
                // }
                // else {
                //     const filteredTabs = tabs?.filter(tab => !["Check Out", "Found"]?.includes(tab?.tabName));
                //     setMoreOptions(filteredTabs);
                //     console.log('else --->with out Check Out, Found', filteredTabs);
                // }
                return {
                    ...asset,
                    Rowid: index + 1,
                    formatedPurchaseDate: formattedDate,
                    brandName: brandAsset ? brandAsset.brandName : '',
                    assetStatusValue: getAssetStatusValue?.labelAssignedValue
                };
            }) : [];
            setAssetArray(finalData);
        } catch (error) {
            console.error("Error fetching Asset data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const getEmployeeData = async (empId) => {
        const empData = await getEmployee();

        const finalData = empData.map((Emp, index) => {
            return {
                ...Emp,
                EmployeeName: Emp?.firstName + " " + Emp?.lastName,
            };
        });
        const sortedEmployeeData = finalData.sort((a, b) => a.EmployeeName.localeCompare(b.EmployeeName, undefined, { sensitivity: 'base' }));
        setEmployeeArray(sortedEmployeeData);
    };

    const getUnitBranchDetails = async () => {
        const unitData = await getBUData();
        const sortedUnitData = unitData?.sort((a, b) => a.bU_NAME.localeCompare(b.bU_NAME, undefined, { sensitivity: 'base' }));
        setUnitArray(sortedUnitData);

        const branchData = await getBRData();
        const sortedBranchData = branchData?.sort((a, b) => a.bR_NAME.localeCompare(b.bR_NAME, undefined, { sensitivity: 'base' }));
        setBranchArray(sortedBranchData);
    };

    const getDepartmentData = async () => {
        const data = await getDepartment();
        const finalData = data?.map((dept, index) => {
            return {
                ...dept,
                Rowid: index + 1,
            };
        });
        setDepartmentArray(finalData);
    };

    const getSupplierData = async () => {
        const SupplierData = await getSupplier();
        setSupplierArray(SupplierData);
    };

    const getCategoryAndBrandData = async () => {
        const CategoryData = await getCategory();
        setCategoryArray(CategoryData);

        const SubCategoryData = await getSubCategory();
        setSubCategoryArray(SubCategoryData);

        const BrandData = await getBrand();
        setBrandArray(BrandData);
    };

    const handleAddAssetCreation = async () => {
        navigate('/AssetCreationAdd?id=0&view=0');
    };

    const handleDeleteAsset = async (asset) => {
        try {
            const linkData = await getAssetLink();
            const isAssociatedLink = linkData.some(link =>
                link.assetId === asset.id || link.assetRelationId === asset.id
            );

            if (isAssociatedLink) {
                toast.error(`Cannot delete the Asset as it is linked with some Another Asset.`);
                return;
            }

            const isConfirmed = await ConfirmDelete();
            if (isConfirmed) {
                setIsLoading(true);
                const response = await deleteAsset(asset.id);
                if (response === "Deleted") {
                    toast.success("Asset Deleted Successfully");
                    fetchAssetData();
                } else {
                    toast.error(response);
                }
                setIsLoading(false);
            }
        } catch (error) {
            console.error("Error deleting asset:", error);
            toast.error(`An error occurred while deleting the asset.`);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAssetPrintClick = () => {
        const contentToPrint = document.getElementById('printableContent');
        const styles = Array.from(document.querySelectorAll('style')).map(style => style.outerHTML).join('');
        const html = contentToPrint.outerHTML;

        const printWindow = window.open('', '', 'height=600,width=800');

        printWindow.document.write(`
            <html>
                <head>
                    <title>Print</title>
                    ${styles}
                </head>
                <body>
                    ${html}
                </body>
            </html>
        `);

        printWindow.document.close();

        printWindow.onload = () => {
            printWindow.focus();
            printWindow.print();
            printWindow.close();
        };
    };

    const handleAssetImportClick = () => {
        setShowSampleExcelDownload(true);
    };

    const closeSampleExcelDownload = () => {
        setShowSampleExcelDownload(false);
    };

    const handleDownloadSampleExcel = () => {
        downloadSampleExcelAsset();
    };

    const onClickFileAssetInputRef = () => {
        fileAssetInputRef.current.click();
    };

    const handleAssetFileChange = async (event) => {
        const file = event.target.files[0];
        const fileType = file?.name.split('.').pop();

        if (file && (fileType === "xlsx" || fileType === "xls")) {
            const data = await file.arrayBuffer();
            const workbook = XLSX.read(data);
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { defval: "" });

            // Function to trim both the keys (headers) and their corresponding values
            const trimHeadersAndValues = (obj) => {
                const trimmedObj = {};
                Object.keys(obj).forEach(key => {
                    const trimmedKey = key.trim(); // Trim spaces in header names
                    const trimmedValue = typeof obj[key] === 'string' ? obj[key].trim() : obj[key];
                    trimmedObj[trimmedKey] = trimmedValue; // Assign trimmed key and value
                });
                return trimmedObj;
            };

            // Map through jsonData and apply trimHeadersAndValues to each row
            const trimmedJsonData = jsonData.map(trimHeadersAndValues);

            let assetArrayData = [];
            let assetErrorArrayCount = [];

            // Process each row of trimmed data
            trimmedJsonData.forEach((e, index) => {
                const rowIndex = index + 1;
                const assetName = e?.['Asset Name']?.trim();
                const assetTagId = e?.['Asset Tag ID']?.trim();

                if (!assetName && assetTagId) {
                    toast.error(`Asset Name is Required in row ${rowIndex} with Asset Tag ID: ${assetTagId}.`);
                    assetErrorArrayCount.push(e);
                } else if (!assetName) {
                    toast.error(`Asset Name is Required in row ${rowIndex}.`);
                    assetErrorArrayCount.push(e);
                } else {
                    assetArrayData.push(e);
                }
            });

            event.target.value = null;
            if (assetArrayData.length > 0) {
                uploadAssetData(assetArrayData);
            }
            if (assetErrorArrayCount.length > 0) {
                toast.error(`${assetErrorArrayCount.length} records failed to upload.`);
            }
        } else {
            toast.error("Invalid file type. Please upload an Excel file.");
        }
    };

    const uploadAssetData = async (data) => {
        try {
            setIsLoading(true);
            let successUploadCount = 0;
            let assetUploadErrorArrayCount = [];

            const unitIdConfig = await getUnitID();
            const branchIdConfig = await getBranchID();
            const departmentIdConfig = await getDepartmentID();
            const depreciationMethod = await getDepreciationMethod();
            const depreciableAssetValue = await getAssetDepreciation();

            const assetPrefix = await getAssetPrefix();
            const assetPadding = await getAssetPadding();
            const zeros = '0'.repeat(parseInt(assetPadding, 10));
            const documentData = await getDocument();
            const maxId = documentData.find(doc => doc.id === 3);
            let newId = maxId.documentNo + 1;

            const uploadPromises = data.map(async (e, index) => {
                try {
                    const rowIndex = index + 1;
                    const assetID = `${assetPrefix.toUpperCase()}${zeros}${newId}`;
                    const assetName = e?.['Asset Name']?.trim();
                    const assetTagId = e?.['Asset Tag ID']?.trim();
                    const categoryId = findCategoryIdByName(categoryArray, e?.Category, rowIndex);
                    const subCategoryId = findSubCategoryIdByName(subCategoryArray, e?.['Sub Category'], rowIndex);
                    const brandId = findBrandIdByName(brandArray, e?.Brand, rowIndex);
                    const supplierId = findSupplierIdByName(supplierArray, e?.['Purchased from'], rowIndex);
                    const unitId = findUnitIdByName(unitArray, e?.Unit, rowIndex) || unitIdConfig;
                    const branchId = findBranchIdByName(branchArray, e?.Branch, rowIndex) || branchIdConfig;
                    const departmentId = findDepartmentIdByName(departmentArray, e?.Department, rowIndex) || departmentIdConfig;
                    const empId = findEmployeeIdByName(employeeArray, e?.['Assigned to person'], rowIndex);
                    const purchaseDate = e?.['Purchase Date'] ? formatDateToISO(e['Purchase Date']) : null;
                    const dateAcquired = e?.['Date Acquired'] ? formatDateToISO(e['Date Acquired']) : null;

                    const payload = {
                        id: 0,
                        assetName: assetName || "",
                        assetID: assetID || "",
                        assetTagID: assetTagId || "",
                        categoryId: categoryId,
                        subCategoryId: subCategoryId,
                        brandId: brandId,
                        modelNo: e?.Model || "",
                        serialNo: e?.['Serial No'] || "",
                        cost: e?.Cost || "",
                        supplierId: supplierId,
                        purchaseDate: purchaseDate,
                        unitId: unitId,
                        branchId: branchId,
                        departmentId: departmentId,
                        empId: empId,
                        assetImage: "",
                        depreciableAsset: e?.['Depreciable Asset']?.toString().toLowerCase() === "true" ? "Yes" : "No" || depreciableAssetValue,
                        depreciableCost: e?.['Depreciable Cost'] || "",
                        assetLife: e?.['Asset Life (months)'] || "",
                        salvageValue: e?.['Salvage Value'] || "",
                        depreciationMethod: e?.['Depreciation Method'] || depreciationMethod,
                        dateAcquired: dateAcquired,
                        status: true,
                        NextID: newId
                    };

                    await createAsset(payload);
                    successUploadCount++;
                    newId++; // Increment newId only when the asset is successfully uploaded
                } catch (error) {
                    assetUploadErrorArrayCount.push(e);

                    const rowIndex = index + 1;
                    const assetName = e?.['Asset Name']?.trim();
                    const assetTagId = e?.['Asset Tag ID']?.trim();
                    if (assetName && assetTagId) {
                        toast.error(`Failed to upload asset in row ${rowIndex} with Asset Name: "${assetName}", Asset Tag ID: "${assetTagId}".`);
                    } else {
                        toast.error(`Failed to upload asset in row ${rowIndex} with Asset Name: "${assetName}".`);
                    }
                }
            });

            await Promise.all(uploadPromises);
            if (assetUploadErrorArrayCount.length > 0) {
                toast.error(`${assetUploadErrorArrayCount.length} assets failed to upload.`);
            } else {
                toast.success(`${successUploadCount} assets uploaded successfully.`);
            }
            setIsLoading(false);
            setPageAsset(0);
        } catch (error) {
            toast.error("An error occurred while uploading data.");
        } finally {
            setIsLoading(false);
            fetchAssetData();
        }
    };

    // Function to reformat the date
    const formatDateToISO = (dateString) => {
        // List of possible date formats
        const possibleFormats = ['MM/dd/yyyy', 'dd/MM/yyyy', 'yyyy/MM/dd'];

        let parsedDate;

        // Try each format until one successfully parses the date
        for (const formatString of possibleFormats) {
            try {
                parsedDate = parse(dateString, formatString, new Date());

                // Check if the parsed date is valid
                if (parsedDate instanceof Date && !isNaN(parsedDate)) {
                    return format(parsedDate, 'yyyy-MM-dd'); // Return ISO formatted date
                }
            } catch (error) {
                // Ignore the error and try the next format
            }
        }

        // Return null or throw an error if no format matched
        return null;
    };

    const findCategoryIdByName = (array, categoryName, rowIndex) => {
        const record = array?.find(item => item.categoryName === categoryName);
        if (!record) {
            toast.error(`${categoryName} does not exists. check in row ${rowIndex}.`);
        };
        return record ? record.id : 0;
    };

    const findSubCategoryIdByName = (array, subCategoryName, rowIndex) => {
        const record = array?.find(item => item.subCategoryName === subCategoryName);
        if (!record) {
            toast.error(`${subCategoryName} does not exists. check in row ${rowIndex}.`);
        };
        return record ? record.id : 0;
    };

    const findBrandIdByName = (array, brandName, rowIndex) => {
        const record = array?.find(item => item.brandName === brandName);
        if (!record) {
            toast.error(`${brandName} does not exists. check in row ${rowIndex}.`);
        };
        return record ? record.id : 0;
    };

    const findSupplierIdByName = (array, supplierName, rowIndex) => {
        const record = array?.find(item => item.supplierName === supplierName);
        if (!record) {
            toast.error(`${supplierName} does not exists. check in row ${rowIndex}.`);
        };
        return record ? record.id : 0;
    };

    const findUnitIdByName = (array, bU_NAME, rowIndex) => {
        const record = array?.find(item => item.bU_NAME === bU_NAME);
        if (!record) {
            toast.error(`${bU_NAME} does not exists. check in row ${rowIndex}.`);
        };
        return record ? record.bU_ID : null;
    };

    const findBranchIdByName = (array, bR_NAME, rowIndex) => {
        const record = array?.find(item => item.bR_NAME === bR_NAME);
        if (!record) {
            toast.error(`${bR_NAME} does not exists. check in row ${rowIndex}.`);
        };
        return record ? record.bR_ID : null;
    };

    const findDepartmentIdByName = (array, departmentName, rowIndex) => {
        const record = array?.find(item => item.departmentName === departmentName);
        if (!record) {
            toast.error(`${departmentName} does not exists. check in row ${rowIndex}.`);
        };
        return record ? record.id : null;
    };

    const findEmployeeIdByName = (array, EmployeeName, rowIndex) => {
        const record = array?.find(item => item.EmployeeName === EmployeeName);
        if (!record) {
            toast.error(`${EmployeeName} does not exists. check in row ${rowIndex}.`);
        };
        return record ? record.id : 0;
    };

    const exportAsset = (event) => {
        const selectedOption = event.target.value;
        if (selectedOption !== "") {
            handleExportAsset(selectedOption);
            event.target.value = "";
        }
    };

    const handleExportAsset = (format) => {
        exportAssetData(assetArray, format);
    };

    // Asset Section Search and Pagination
    const handleSearchChangeAsset = (event) => {
        setSearchQueryAsset(event.target.value);
        setPageAsset(0);
    };

    const filteredAsset = assetArray?.filter(
        (asset) =>
            asset &&
            (
                asset?.assetID?.toLowerCase()?.includes(searchQueryAsset?.toLowerCase()) ||
                asset?.AssetName?.toLowerCase()?.includes(searchQueryAsset?.toLowerCase()) ||
                asset?.brandName?.toLowerCase()?.includes(searchQueryAsset?.toLowerCase()) ||
                asset?.formatedPurchaseDate?.toLowerCase()?.includes(searchQueryAsset?.toLowerCase()) ||
                asset?.cost?.toLowerCase()?.includes(searchQueryAsset?.toLowerCase()) ||
                asset?.assetStatusValue?.toLowerCase()?.includes(searchQueryAsset?.toLowerCase())
            )
    );

    const handleRowsPerPageChangeAsset = (event) => {
        setRowsPerPageAsset(parseInt(event.target.value));
        setPageAsset(0);
    };

    const handleFirstPageAsset = () => {
        handleChangePagePaginationAsset({ selected: 0 });
    };

    const handleChangePagePaginationAsset = ({ selected }) => {
        setPageAsset(selected);
    };

    const handleLastPageAsset = () => {
        handleChangePagePaginationAsset({
            selected: Math.ceil(assetArray?.length / rowsPerPageAsset) - 1,
        });
    };

    const totalEntriesAsset = filteredAsset?.length;
    const startEntryAsset = pageAsset * rowsPerPageAsset + 1;
    const endEntryAsset = Math.min((pageAsset + 1) * rowsPerPageAsset, totalEntriesAsset);


    const closeModal = () => {
        setshowUpdatePopup(false);
    };

    const handleAssetBulkUpdateClick = () => {
        setshowUpdatePopup(true);
    }

    const handleCancel = () => {
        setSelectedValue("");
        window.location.reload();
    };

    const handleChange = async (event) => {
        const value = event.target.value;

        const result = await Swal.fire({
            title: 'Are you sure you want to change the selection?',
            text: `This action will update your selection to "${value}".`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, change it',
            cancelButtonText: 'No, keep it'
        });

        if (result.isConfirmed) {
            setSelectedValue(value);
            console.log('Selected value:', value);
        } else {
            console.log('Selection change canceled.');
        }
    };

    const handleSelectAllChange = () => {
        if (selectAll) {
            setSelectedAssets([]);
        } else {
            setSelectedAssets(filteredAsset?.map((asset) => asset.id));
        }
        setSelectAll(!selectAll);
    };

    const handleCheckboxChange = (id) => {
        setSelectedAssets((prevSelected) => {
            const newSelectedAssets = prevSelected.includes(id)
                ? prevSelected.filter((selectedId) => selectedId !== id)
                : [...prevSelected, id];

            if (newSelectedAssets.length !== filteredAsset.length) {
                setSelectAll(false);
            } else if (newSelectedAssets.length === filteredAsset.length) {
                setSelectAll(true);
            }
            return newSelectedAssets;
        });
    };

    const handleSelectClick = (e) => {
        if (selectedAssets.length === 0) {
            e.preventDefault();
            // toast.warning("Please select at least one asset before take action.");
        }
    };

    //CheckOut
    const onSubmitCheckOut = async (dataCheckOut) => {
        setIsLoading(true);

        const {
            checkOutDate, employee, unit, dueDate, assignTo,
            unitId, branchId, departmentId, checkOutNotes,
            sendEmail, sendEmailCheck
        } = dataCheckOut;

        for (const assetID of selectedAssets) {
            const payload = {
                id: 0,
                checkOutDate: checkOutDate,
                isEmployee: employee,
                isUnit: unit,
                dueDate: dueDate,
                assignTo: assignTo,
                unitId: unitId,
                branchId: branchId,
                departmentId: departmentId,
                checkOutNotes: checkOutNotes,
                sendEmail: sendEmail,
                isSendEmailCheck: sendEmailCheck,
                assetID: assetID,
            };

            try {
                const response = await createCheckOut(payload);
                if (response === "Inserted") {
                    toast.success(`CheckOut for Asset ${assetID} Inserted Successfully`);
                } else {
                    toast.error(`Failed to check out Asset ${assetID}: ${response}`);
                }
            } catch (error) {
                toast.error(`Error checking out Asset ${assetID}: ${error.message}`);
            }
        }
        setIsLoading(false);
        window.location.reload();
    };


    //CheckIn
    const onSubmitCheckIn = async (dataCheckIn) => {
        setIsLoading(true);

        const {
            returnDate, employee, unit, unitId,
            branchId, departmentId, checkInNotes,
            sendEmail, sendEmailCheck
        } = dataCheckIn;

        for (const assetID of selectedAssets) {
            const payload = {
                id: 0,
                returnDate: returnDate,
                isEmployee: employee,
                isUnit: unit,
                unitId: unitId,
                branchId: branchId,
                departmentId: departmentId,
                checkInNotes: checkInNotes,
                sendEmail: sendEmail,
                isSendEmailCheck: sendEmailCheck,
                assetID: assetID,
            };

            try {
                const response = await createCheckIn(payload);
                if (response === "Inserted") {
                    toast.success(`CheckIn for Asset ${assetID} Inserted Successfully`);
                } else {
                    toast.error(`Failed to check in Asset ${assetID}: ${response}`);
                }
            } catch (error) {
                toast.error(`Error checking in Asset ${assetID}: ${error.message}`);
            }
        }
        setIsLoading(false);
        window.location.reload();
    };


    //Lost
    const onSubmitLost = async (dataLost) => {
        setIsLoading(true);

        const { dateLost, notes } = dataLost;

        for (const assetID of selectedAssets) {
            const payload = {
                id: 0,
                dateLost: dateLost,
                notes: notes,
                assetID: assetID,
            };

            console.log('Submitting Lost payload:', payload);

            try {
                const response = await createLost(payload);
                if (response === "Inserted") {
                    toast.success(`Lost data for Asset ${assetID} Inserted Successfully`);
                } else {
                    toast.error(`Failed to mark Asset ${assetID} as Lost: ${response}`);
                }
            } catch (error) {
                toast.error(`Error marking Asset ${assetID} as Lost: ${error.message}`);
            }
        }
        setIsLoading(false);
        window.location.reload();
    };


    //Found
    const onSubmitFound = async (dataFound) => {
        setIsLoading(true);

        const { dateFound, notes } = dataFound;

        for (const assetID of selectedAssets) {
            const payload = {
                id: 0,
                dateFound: dateFound,
                notes: notes,
                assetID: assetID,
            };

            try {
                const response = await createFound(payload);
                if (response === "Inserted") {
                    toast.success(`Found data for Asset ${assetID} Inserted Successfully`);
                } else {
                    toast.error(`Failed to mark Asset ${assetID} as Found: ${response}`);
                }
            } catch (error) {
                toast.error(`Error marking Asset ${assetID} as Found: ${error.message}`);
            }
        }
        setIsLoading(false);
        window.location.reload();
    };


    //Repair
    const onSubmitRepair = async (dataRepair) => {
        setIsLoading(true);
        const { scheduleDate, assignTo, repairCompleted, repairCost, notes } = dataRepair;

        for (const assetID of selectedAssets) {
            const payload = {
                id: 0,
                scheduleDate: scheduleDate,
                assignTo: assignTo,
                repairCompleted: repairCompleted,
                repairCost: `${repairCost}`,
                notes: notes,
                assetID: assetID,
            };

            try {
                const response = await createRepair(payload);
                if (response === "Inserted") {
                    toast.success(`Repair data for Asset ${assetID} Inserted Successfully`);
                } else {
                    toast.error(`Failed to submit repair for Asset ${assetID}: ${response}`);
                }
            } catch (error) {
                toast.error(`Error submitting repair for Asset ${assetID}: ${error.message}`);
            }
        }
        setIsLoading(false);
        window.location.reload();
    };

    const onSubmitBroken = async (dataBroken) => {
        setIsLoading(true);
        const { dateBroken, notes } = dataBroken;

        for (const assetID of selectedAssets) {
            const payload = {
                id: 0,
                dateBroken: dateBroken,
                notes: notes,
                assetID: assetID,
            };

            try {
                const response = await createBroken(payload);
                if (response === "Inserted") {
                    toast.success(`Broken data for Asset ${assetID} Inserted Successfully`);
                } else {
                    toast.error(`Failed to submit broken status for Asset ${assetID}: ${response}`);
                }
            } catch (error) {
                toast.error(`Error submitting broken status for Asset ${assetID}: ${error.message}`);
            }
        }
        setIsLoading(false);
        window.location.reload();
    };


    const onSubmitDispose = async (dataDispose) => {
        setIsLoading(true);
        const { dateDispose, disposeTo, notes } = dataDispose;

        for (const assetID of selectedAssets) {
            const payload = {
                id: 0,
                dateDispose: dateDispose,
                disposeTo: disposeTo,
                notes: notes,
                assetID: assetID,
            };

            try {
                const response = await createDisposed(payload);
                if (response === "Inserted") {
                    toast.success(`Dispose data for Asset ${assetID} Inserted Successfully`);
                } else {
                    toast.error(`Failed to submit dispose for Asset ${assetID}: ${response}`);
                }
            } catch (error) {
                toast.error(`Error submitting dispose for Asset ${assetID}: ${error.message}`);
            }
        }
        setIsLoading(false);
        window.location.reload();
    };


    const onSubmitDonate = async (dataDonate) => {
        setIsLoading(true);
        const { dateDonate, donateTo, donatedValue, deductible, notes } = dataDonate;

        for (const assetID of selectedAssets) {
            const payload = {
                id: 0,
                dateDonate: dateDonate,
                donateTo: donateTo,
                donatedValue: `${donatedValue}`,
                deductible: deductible,
                notes: notes,
                assetID: assetID,
            };

            try {
                const response = await createDonate(payload);
                if (response === "Inserted") {
                    toast.success(`Donate data for Asset ${assetID} Inserted Successfully`);
                } else {
                    toast.error(`Failed to submit donation for Asset ${assetID}: ${response}`);
                }
            } catch (error) {
                toast.error(`Error submitting donation for Asset ${assetID}: ${error.message}`);
            }
        }
        setIsLoading(false);
        window.location.reload();
    };


    const onSubmitSell = async (dataSell) => {
        setIsLoading(true);
        const { saleDate, soldTo, saleAmount, notes } = dataSell;

        for (const assetID of selectedAssets) {
            const payload = {
                id: 0,
                saleDate: saleDate,
                soldTo: soldTo,
                saleAmount: `${saleAmount}`,
                notes: notes,
                assetID: assetID,
            };

            try {
                const response = await createSell(payload);
                if (response === "Inserted") {
                    toast.success(`Sell data for Asset ${assetID} Inserted Successfully`);
                } else {
                    toast.error(`Failed to submit sale for Asset ${assetID}: ${response}`);
                }
            } catch (error) {
                toast.error(`Error submitting sale for Asset ${assetID}: ${error.message}`);
            }
        }
        setIsLoading(false);
        window.location.reload();
    };


    const onSubmitLink = async (dataLink) => {
        try {
            setIsLoading(true);
            const { selectChildParentAsset, linkValue } = dataLink;

            for (const assetID of selectedAssets) {
                // Check if the current asset already has a parent asset linked
                const linkData = await getAssetLink();
                const parentLinked = linkData.some(link => link.assetId === assetID && link.relation === 'Parent');

                if (parentLinked) {
                    toast.error(`Asset ${assetID} already has a parent asset linked.`);
                    continue;  // Skip this asset and move to the next one
                }

                const matchingAsset = assetArray.find(asset => asset.id === assetID);
                const payload = {
                    id: 0,
                    assetId: assetID,
                    assetRelationId: selectChildParentAsset,
                    relation: linkValue,
                    assetTagID: matchingAsset?.assetID,
                    description: matchingAsset?.assetName,
                    linkStatus: true,
                    transact: true,
                };
                console.log('Link Payload:', payload);

                const response = await createAssetLink(payload);
                if (response === "Inserted") {
                    toast.success(`Asset ${assetID} Linked Successfully`);
                } else {
                    toast.error(`Failed to link Asset ${assetID}: ${response}`);
                }
            }
        } catch (error) {
            toast.error("Failed to Link Assets");
        } finally {
            setIsLoading(false);
            window.location.reload();
        }
    };


    const onSubmitDepreciation = async (dataDepreciation) => {
        try {
            setIsLoading(true);
            const { selectChildParentAsset, assetName, linkValue } = dataDepreciation;

            for (const assetID of selectedAssets) {
                const matchingAsset = assetArray.find(asset => asset.id === assetID);
                const payload = {
                    id: 0,
                    assetId: assetID,
                    relation: linkValue,
                    assetTagID: matchingAsset?.assetTagID,
                    description: assetName,
                    linkStatus: true,
                };
                console.log('Depreciation Payload:', payload);

                // const response = await createAssetDepreciation(payload);
                // if (response === "Inserted") {
                //     toast.success(`Depreciation for Asset ${assetID} Created Successfully`);
                // } else {
                //     toast.error(`Failed to create Depreciation for Asset ${assetID}: ${response}`);
                // }
            }
        } catch (error) {
            toast.error("Failed to Create Depreciation");
        } finally {
            setIsLoading(false);
            window.location.reload();
        }
    };


    const onSubmitAdd = async (dataAdd) => {
        try {
            setIsLoading(true);
            const { selectChildParentAsset, assetName, linkValue } = dataAdd;

            for (const assetID of selectedAssets) {
                const matchingAsset = assetArray.find(asset => asset.id === assetID);
                const payload = {
                    id: 0,
                    assetId: assetID,
                    relation: linkValue,
                    assetTagID: matchingAsset?.assetTagID,
                    description: assetName,
                    linkStatus: true,
                };
                console.log('Add Payload:', payload);

                // const response = await createAssetAdd(payload);
                // if (response === "Inserted") {
                //     toast.success(`Asset ${assetID} Added Successfully`);
                // } else {
                //     toast.error(`Failed to Add Asset ${assetID}: ${response}`);
                // }
            }
        } catch (error) {
            toast.error("Failed to Add Assets");
        } finally {
            setIsLoading(false);
            window.location.reload();
        }
    };


    return (
        <div className="bacgroundStyle">
            <ToastContainer theme="colored" />
            {isLoading && <LoadingSpinner />}
            {!isLoading && (
                <div>
                    <div className="pagetitleAsset">
                        <div className="Pageheader fontFamily fontsize24 fontweightSemiBold">View Asset<div><InfoOutlinedIcon /></div></div>
                    </div>
                    <div className="">
                        <div className="card mt-4">
                            <div className="cardBodySupplierMaster">
                                <div id="printableContent">
                                    <div className="d-flex justify-content-between align-items-center" style={{ paddingBottom: "8px" }}>
                                        <div className="custom-container">
                                            <span className="blackTextColor fontFamily fontsize16 fontweightRegular">
                                                <span className="show">Show</span>
                                                <select className="custom-dropdown" value={rowsPerPageAsset} onChange={handleRowsPerPageChangeAsset} >
                                                    {paginateArray.map((n) => (
                                                        <option className="blackTextColor fontFamily fontsize14 fontweightRegular" key={n} value={n} >
                                                            {n}
                                                        </option>
                                                    ))}
                                                </select>
                                                <span className="blackTextColor entries fontFamily fontsize16 fontweightRegular">
                                                    Entries
                                                </span>
                                            </span>
                                            <input
                                                type="text"
                                                placeholder="Search..."
                                                value={searchQueryAsset}
                                                onChange={handleSearchChangeAsset}
                                                className="searchTextBox"
                                            />
                                        </div>

                                        <div className="addbuttondiv">
                                            <input
                                                type="file"
                                                ref={fileAssetInputRef}
                                                style={{ display: 'none' }}
                                                accept=".xlsx"
                                                onChange={handleAssetFileChange}
                                            />
                                            <a href="#moreAction" onClick={handleSelectClick} disabled={selectedAssets.length === 0}>
                                                <select
                                                    className="fontFamily fontweightRegular fontsize16 exportBtn mRight12"
                                                    name="language"
                                                    id="language"
                                                    value={selectedValue}
                                                    onChange={handleChange}
                                                >
                                                    <option value="" hidden={true}>More Action</option>
                                                    {moreOptions.map((option) => (
                                                        <option key={option.tabName} value={option.tabName} className="textAlignLeft">
                                                            {option.tabName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </a>
                                            <button
                                                type="button"
                                                id="BtnaddUnit"
                                                className="buttonAddNew fontFamily fontweightRegular fontsize16 coloredButtonAdd"
                                                onClick={handleAddAssetCreation}
                                            >
                                                Add New
                                            </button>
                                            <button
                                                type="button"
                                                className="fontFamily fontweightRegular fontsize16 importBtn"
                                                onClick={handleAssetBulkUpdateClick}
                                            >
                                                Bulk Update
                                            </button>
                                            <button
                                                type="button"
                                                className="fontFamily fontweightRegular fontsize16 importBtn"
                                                onClick={handleAssetPrintClick}
                                            >
                                                Print
                                            </button>
                                            <button
                                                type="button"
                                                className="fontFamily fontweightRegular fontsize16 importBtn"
                                                onClick={handleAssetImportClick}
                                            >
                                                Import
                                            </button>
                                            <select
                                                className="buttonExport fontFamily fontweightRegular fontsize16 exportBtn"
                                                name="language"
                                                id="language"
                                                defaultValue=""
                                                onChange={exportAsset}
                                            >
                                                <option value="" hidden={true}>
                                                    Export
                                                </option>
                                                <option value="csv">CSV</option>
                                                <option value="excel">Excel</option>
                                                <option value="pdf">Pdf</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="p-1">
                                        <div className="EmpBoxShadow">
                                            <div className="" style={{ padding: "10px" }}>
                                                <table className="w-100 mt-2">
                                                    <thead>
                                                        <tr className="text-left empTableHeight">
                                                            <th className="blackTextColor fontFamily fontweightMedium fontsize16" style={{ backgroundColor: '#F6F6F6', width: "10%", padding: '2px 2px 2px 3%' }}>
                                                                <input
                                                                    id="myCheckboxHead"
                                                                    type="checkbox"
                                                                    checked={selectAll}
                                                                    onChange={handleSelectAllChange}
                                                                />
                                                            </th>
                                                            <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 " style={{ width: "15%" }} > Asset ID </th>
                                                            <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 " style={{ width: "15%" }} > Asset Name </th>
                                                            <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 " style={{ width: "12%" }} > Brand </th>
                                                            <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 " style={{ width: "14%" }} > Purchase Date </th>
                                                            <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 " style={{ width: "11%" }} > Cost </th>
                                                            <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 " style={{ width: "13%" }} > Status </th>
                                                            <th className="blackTextColor fontFamily fontweightMedium fontsize16 empHeaderBg p-2 " style={{ width: "15%" }} > Action </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {filteredAsset.length > 0 ? (
                                                            filteredAsset.slice(pageAsset * rowsPerPageAsset, (pageAsset + 1) * rowsPerPageAsset).map((Asset, index) => (
                                                                <tr key={Asset.Rowid} style={{ height: "59px" }}>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`} style={{ padding: "2px 2px 2px 3%" }}>
                                                                        <input
                                                                            id={`checkbox-${Asset.id}`}
                                                                            type="checkbox"
                                                                            checked={selectedAssets.includes(Asset.id)}
                                                                            onChange={() => handleCheckboxChange(Asset.id)}
                                                                        />
                                                                    </td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} ><a href={`/AssetDetailsView?id=${Asset.id}`}><span style={{ color: "#605BFF" }}>{Asset.assetID}</span></a></td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} >{Asset.assetName}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} >{Asset.brandName}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} >{Asset.formatedPurchaseDate}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} >{Asset.cost}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`}
                                                                        style={{ color: Asset.status === true ? '#2ED47A' : '#03ABFF' }}>{Asset.assetStatusValue}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tablerowBackgroundColor"}`} >
                                                                        <span style={{ cursor: "pointer" }}><a href={`/AssetCreationAdd?id=${Asset.id}&view=1`}><img style={{ width: "18px", height: "15px", }} src={viewsvgIcon} alt="viewsvgIcon" /></a></span>
                                                                        <span style={{ marginLeft: "10px", cursor: "pointer", }}><a href={`/AssetCreationAdd?id=${Asset.id}&view=0`}><img src={editsvgIcon} alt="editsvgIcon" /></a></span>
                                                                        <span style={{ marginLeft: "10px", cursor: "pointer", }}><a href="#deleteAsset" onClick={() => handleDeleteAsset(Asset)}><img src={deletesvgIcon} alt="deletesvgIcon" /></a></span>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <td colSpan="4" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        {totalEntriesAsset > 0 && (
                                            <div>
                                                <div className="" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingTop: "16px", }}>
                                                    <div className="text-muted fontFamily fontsize12 fontweightRegular">
                                                        Showing {startEntryAsset} to {endEntryAsset} of {totalEntriesAsset} entries
                                                    </div>
                                                    <div>
                                                        <div className="pagination-container d-flex align-items-center">
                                                            <button className="paginationButton btn btn-link" onClick={handleFirstPageAsset} >
                                                                <FontAwesomeIcon icon={faAngleDoubleLeft} className="icon-color" />
                                                            </button>
                                                            <ReactPaginate
                                                                previousLabel={<FontAwesomeIcon icon={faChevronLeft} className="icon-color" />}
                                                                nextLabel={<FontAwesomeIcon icon={faChevronRight} className="icon-color" />}
                                                                breakLabel={"..."}
                                                                breakClassName={"break-me"}
                                                                pageCount={Math.ceil(totalEntriesAsset / rowsPerPageAsset)}
                                                                forcePage={pageAsset}
                                                                marginPagesDisplayed={2}
                                                                pageRangeDisplayed={5}
                                                                onPageChange={handleChangePagePaginationAsset}
                                                                containerClassName={"pagination"}
                                                                subContainerClassName={"pages pagination"}
                                                                activeClassName={"active"}
                                                                renderOnZeroPageCount={null}
                                                            />
                                                            <button className="paginationButton btn btn-link" onClick={handleLastPageAsset}>
                                                                <FontAwesomeIcon icon={faAngleDoubleRight} className="icon-color" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div style={{ padding: "0px 100px" }}></div>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    {showSampleExcelDownload && (
                                        <SampleExcelDownload
                                            showModal={showSampleExcelDownload}
                                            closeModal={closeSampleExcelDownload}
                                            handleDownloadSampleExcel={handleDownloadSampleExcel}
                                            onClickFileAssetInputRef={onClickFileAssetInputRef}
                                        />
                                    )}

                                    {showUpdatePopup && <BulkUpdatePopup showModal={showUpdatePopup} closeModal={closeModal} selectedAssets={selectedAssets} />}


                                    {selectedValue === "Check Out" && (
                                        <CheckOutSection
                                            onSubmit={onSubmitCheckOut}
                                            isCheckOutView={isCheckOutView}
                                            dateFormat={dateFormat}
                                            calendarsvgIcon={calendarsvgIcon}
                                            onCancel={handleCancel}
                                            isCheckOut={true}
                                            employeeArray={employeeArray}
                                            unitArray={unitArray}
                                            branchArray={branchArray}
                                            departmentArray={departmentArray}
                                        />
                                    )}

                                    {selectedValue === "Check In" && (
                                        <CheckInSection
                                            onSubmit={onSubmitCheckIn}
                                            isCheckInView={isCheckInView}
                                            dateFormat={dateFormat}
                                            calendarsvgIcon={calendarsvgIcon}
                                            onCancel={handleCancel}
                                            isCheckIn={true}
                                            unitArray={unitArray}
                                            branchArray={branchArray}
                                            departmentArray={departmentArray}
                                        />
                                    )}

                                    {selectedValue === "Lost" && (
                                        <LostSection
                                            onSubmit={onSubmitLost}
                                            isLostView={isLostView}
                                            dateFormat={dateFormat}
                                            calendarsvgIcon={calendarsvgIcon}
                                            onCancel={handleCancel}
                                            isLost={true}
                                        />
                                    )}

                                    {selectedValue === "Found" && (
                                        <FoundSection
                                            onSubmit={onSubmitFound}
                                            isFoundView={isFoundView}
                                            dateFormat={dateFormat}
                                            calendarsvgIcon={calendarsvgIcon}
                                            onCancel={handleCancel}
                                            isFound={true}
                                        />
                                    )}

                                    {selectedValue === "Repair" && (
                                        <RepairSection
                                            onSubmit={onSubmitRepair}
                                            isRepairView={isRepairView}
                                            dateFormat={dateFormat}
                                            calendarsvgIcon={calendarsvgIcon}
                                            onCancel={handleCancel}
                                            isRepair={true}
                                            employeeArray={employeeArray}
                                        />
                                    )}

                                    {selectedValue === "Broken" && (
                                        <BrokenSection
                                            onSubmit={onSubmitBroken}
                                            isBrokenView={isBrokenView}
                                            dateFormat={dateFormat}
                                            calendarsvgIcon={calendarsvgIcon}
                                            onCancel={handleCancel}
                                            isBroken={true}
                                        />
                                    )}

                                    {selectedValue === "Dispose" && (
                                        <DisposeSection
                                            onSubmit={onSubmitDispose}
                                            isDisposeView={isDisposeView}
                                            dateFormat={dateFormat}
                                            calendarsvgIcon={calendarsvgIcon}
                                            onCancel={handleCancel}
                                            isDispose={true}
                                        />
                                    )}

                                    {selectedValue === "Donate" && (
                                        <DonateSection
                                            onSubmit={onSubmitDonate}
                                            isDonateView={isDonateView}
                                            dateFormat={dateFormat}
                                            calendarsvgIcon={calendarsvgIcon}
                                            onCancel={handleCancel}
                                            isDonate={true}
                                        />
                                    )}

                                    {selectedValue === "Sell" && (
                                        <SellSection
                                            onSubmit={onSubmitSell}
                                            isSellView={isSellView}
                                            dateFormat={dateFormat}
                                            calendarsvgIcon={calendarsvgIcon}
                                            onCancel={handleCancel}
                                            isSell={true}
                                            rupeesvgIcon={rupeesvgIcon}
                                        />
                                    )}

                                    {selectedValue === "Asset Link" && selectedAssets.length > 0 && (
                                        <AssetLinkSection
                                            onSubmit={onSubmitLink}
                                            isLinkView={isLinkView}
                                            selectedLinkValue={selectedLinkValue}
                                            assetName={assetArray.find(asset => asset.id === selectedAssets[0])?.assetName}
                                            childDropArray={assetArrayWithoutLinkedAsset}
                                            onCancel={handleCancel}
                                            isLink={true}
                                        />
                                    )}

                                    {selectedValue === "Depreciation" && (
                                        <AssetDepreciationSection
                                            onSubmit={onSubmitDepreciation}
                                            isDepreciationView={isDepreciationView}
                                            DepreciationValue={depreciationValue}
                                            depreciationMethodOptions={depreciationMethodOptions}
                                            onCancel={handleCancel}
                                            dateFormat={dateFormat}
                                            isDepreciation={true}
                                        />
                                    )}

                                    {selectedValue === "Add" && (
                                        <AssetAddSection
                                            onSubmit={onSubmitAdd}
                                            onCancel={handleCancel}
                                            isAdd={true}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AssetCreationView;
