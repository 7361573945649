import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import ReactPaginate from 'react-paginate';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { format } from 'date-fns';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faAngleDoubleLeft, faAngleDoubleRight, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import ConfirmDelete from '../../../../CommomPages/ConfirmDelete';
import LoadingSpinner from '../../../../CommomPages/LoadingSpinner';
import CustomCheckBox from '../../RoleManagement/RoleCreation/CustomCheckBox';
import { exportAttachmentData, exportRoleData, exportTeamData } from './ExportEmployeeAdd';
import { getRoleData } from '../../../../Services/RoleManagementService';
import { getDocument } from "../../../../Services/GeneralSettingsService";
import { getCountry, getState } from '../../../../Services/CountryStateCityService';
import { getBUData, getBRData } from '../../../../Services/BusinessEntityNewService';
import { getDepartment, getTeam } from '../../../../Services/DepartmentsAndTeamService';
import { getUserAccessPermission } from '../../../../Services/UserAccessPermissionService';
import { getEmployee, createEmployee, updateEmployee, getAttachments, createAttachments, updateAttachments, deleteAttachments, getDesignation, createDesignation } from '../../../../Services/EmployeeManagementService';

import './EmployeeManagementAdd.css';

import viewsvgIcon from '../../../../assets/icon/Eye.svg';
import editsvgIcon from '../../../../assets/icon/Edit.svg';
import XMarksvgIcon from '../../../../assets/icon/X mark.svg';
import deletesvgIcon from '../../../../assets/icon/Delete.svg';
import uploadImg from '../../../../assets/img/UploadPhoto.png';
import nodataFound from '../../../../assets/img/nodatafound.png';
import datesvgIcon from '../../../../assets/icon/calendarDate.svg';


const EmployeeManagementAdd = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [tabActive, setTabActive] = useState('User Info');
    const [employeeArray, setEmployeeArray] = useState([]);
    const [countryArray, setCountryArray] = useState([]);
    const [stateArray, setStateArray] = useState([]);
    const [country1Array, setCountry1Array] = useState([]);
    const [state1Array, setState1Array] = useState([]);
    const [allStateArray, setAllStateArray] = useState([]);
    const [allState1Array, setAllState1Array] = useState([]);
    const paginateArray = [5, 10, 15];
    const [attachmentDropArray, setAttachmentDropArray] = useState([
        { id: 1, name: 'Profile picture' },
        { id: 2, name: 'pdf' },
        { id: 3, name: 'jpj' },
    ]);
    const [attachmentArray, setAttachmentArray] = useState([]);
    const [designationArray, setDesignationArray] = useState([]);
    const [employeeID, setEmployeeID] = useState(0);
    const [passwordVisibleConfirmPassword, setPasswordVisibleConfirmPassword] = useState(false);
    const [passwordVisiblePassword, setPasswordVisiblePassword] = useState(false);
    const [previewImg, setPreviewImg] = useState(uploadImg);
    const [isEmployeeView, setIsEmployeeView] = useState(false);
    const [departmentArray, setDepartmentArray] = useState([]);
    const [documentArray, setDocumentArray] = useState([]);
    const [isAttachmentView, setIsAttachmentView] = useState(false);
    const [attachmentID, setAttachmentID] = useState(0);
    const [rowsAttachmentPerPage, setRowsAttachmentPerPage] = useState(5);
    const [pageAttachment, setPageAttachment] = useState(0);
    const [userRoleArray, setUserRoleArray] = useState([]);
    const [rowsUserRolePerPage, setRowsUserRolePerPage] = useState(5);
    const [pageUserRole, setPageUserRole] = useState(0);
    const [searchQueryUserRole, setSearchQueryUserRole] = useState('');
    const [teamArray, setTeamArray] = useState([]);
    const [rowsTeamPerPage, setRowsTeamPerPage] = useState(5);
    const [pageTeam, setPageTeam] = useState(0);
    const [searchQueryTeam, setSearchQueryTeam] = useState('');
    const [departmentSelectID, setDepartmentSelectID] = useState(null);
    const [unitArray, setUnitArray] = useState([]);
    const [unitSelectID, setUnitSelectID] = useState(null);
    const [branchArray, setBranchArray] = useState([]);
    const [branchSelectID, setBranchSelectID] = useState(null);
    const [designationSelectID, setDesignationSelectID] = useState(null);
    const [employeeSelectID, setEmployeeSelectID] = useState(null);
    const [countrySelectID, setCountrySelectID] = useState(null);
    const [stateSelectID, setStateSelectID] = useState(null);
    const [country1SelectID, setCountry1SelectID] = useState(null);
    const [state1SelectID, setState1SelectID] = useState(null);
    const [attachmentSelectID, setAttachmentSelectID] = useState(null);
    const [searchQueryAttachment, setSearchQueryAttachment] = useState('');
    const [addDesignationTab, setAddDesignationTab] = useState(false);
    const [addAttachmentTab, setAddAttachmentTab] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedAttachments, setSelectedAttachments] = useState([]);

    const {
        register: registerDesignation,
        handleSubmit: handleSubmitDesignation,
        formState: { errors: errorsDesignation },
        reset: resetDesignation
    } = useForm({
        defaultValues: {
            designationName: '',
        }
    });

    const {
        register: registerAttachment,
        handleSubmit: handleSubmitAttachment,
        setValue: setValueAttachment,
        reset: resetAttachment,
        control: controlAttachment
    } = useForm({
        defaultValues: {
            documentType: null,
            documentLink: '',
            documents: '',
            EmpID: 0,
        }
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
        watch,
        control
    } = useForm({
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            employeeImage: '',
            designation: null,
            password: '',
            confirmPassword: '',
            unit: null,
            branch: null,
            department: null,
            reportingOfficer: null,
            employmentStartDate: null,
            employmentEndDate: null,
            phoneNumber: '',
            emergencyContactNumber: '',
            employeeId: '',
            address1A1: '',
            address2A1: '',
            cityA1: '',
            stateA1: '',
            countryA1: null,
            zipCodeA1: '',
            phoneNumberA1: '',
            emailIDA1: '',
            address1A2: '',
            address2A2: '',
            cityA2: '',
            stateA2: '',
            countryA2: null,
            zipCodeA2: '',
            phoneNumberA2: '',
            emailIDA2: '',
            status: true,
        }
    });

    const statusWatchEmployee = watch('status');

    useEffect(() => {
        const queryParams = getQueryParams(location.search);
        const idValue = queryParams.get('id');
        const viewValue = queryParams.get('view');
        fetchData();
        if (idValue !== "0") {
            initializeEmployeeData(idValue, viewValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchData = async () => {
        const queryParams = getQueryParams(location.search);
        const idValue = queryParams.get('id');
        const empId = parseInt(idValue, 10);
        try {
            setAddDesignationTab(false);
            setIsLoading(true);

            const departmentData = await getDepartment();
            const sortedDepartmentData = departmentData.sort((a, b) => a.departmentName.localeCompare(b.departmentName, undefined, { sensitivity: 'base' }));
            setDepartmentArray(sortedDepartmentData);

            const documentData = await getDocument();
            setDocumentArray(documentData);

            const countryData = await getCountry();
            const sortedCountyData = countryData.sort((a, b) => a.cntrY_NAME.localeCompare(b.cntrY_NAME, undefined, { sensitivity: 'base' }));
            setCountryArray(sortedCountyData);
            setCountry1Array(sortedCountyData);


            const stateData = await getState();
            const sortedStatementData = stateData.sort((a, b) => a.statE_NAME.localeCompare(b.statE_NAME, undefined, { sensitivity: 'base' }));
            setAllStateArray(sortedStatementData);
            setAllState1Array(sortedStatementData);
            // eslint-disable-next-line eqeqeq
            if (empId !== 0) {
                setStateArray(sortedStatementData);
                setState1Array(sortedStatementData);
                await getTeamDetails();
                getAttachmentDetails();
            }
            await getEmployeeDetails();

            await getUserRoleDetails();

            const designationData = await getDesignation();
            const sortedDesignationData = designationData.sort((a, b) => a.designationName.localeCompare(b.designationName, undefined, { sensitivity: 'base' }));
            setDesignationArray(sortedDesignationData);

            const unitData = await getBUData();
            const sortedUnitData = unitData.sort((a, b) => a.bU_NAME.localeCompare(b.bU_NAME, undefined, { sensitivity: 'base' }));
            setUnitArray(sortedUnitData);

            const branchData = await getBRData();
            const sortedBranchData = branchData.sort((a, b) => a.bR_NAME.localeCompare(b.bR_NAME, undefined, { sensitivity: 'base' }));
            setBranchArray(sortedBranchData);

        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const getQueryParams = (query) => {
        return new URLSearchParams(query);
    }

    const initializeEmployeeData = async (idValue, viewValue) => {
        const EmployeeData = await getEmployee();
        const empValue = EmployeeData.find(e => e.id === parseInt(idValue, 10));
        const { id, firstName, lastName, email, employeeImage, designation, password, confirmPassword, unitId, branchId, departmentId, reportingOfficerId, employmentStartDate, employmentEndDate, phoneNumber, emergencyContactNumber, primaryAddress, secondaryAddress, status } = empValue;
        const primaryAddressValue = JSON.parse(primaryAddress);
        const secondaryAddressValue = JSON.parse(secondaryAddress);
        const empViewStatus = viewValue === "1";
        setIsEmployeeView(empViewStatus);
        setDepartmentSelectID(departmentId ?? null);
        setBranchSelectID(branchId ?? null);
        setUnitSelectID(unitId ?? null);
        setEmployeeSelectID(reportingOfficerId ?? null);
        setDesignationSelectID(designation ?? null);
        setEmployeeID(id || 0);
        setValue("firstName", firstName ?? '');
        setValue("lastName", lastName ?? '');
        setValue("email", email ?? '');
        setValue("department", departmentId ?? null);
        setValue("branch", branchId ?? null);
        setValue("unit", unitId ?? null);
        setValue("reportingOfficer", reportingOfficerId ?? null);
        setValue("designation", designation ?? null);
        setValue("password", password ?? '');
        setValue("confirmPassword", confirmPassword ?? '');
        setValue("employmentStartDate", employmentStartDate ? new Date(employmentStartDate) : null);
        setValue("employmentEndDate", employmentEndDate ? new Date(employmentEndDate) : null);
        setValue("phoneNumber", phoneNumber ?? '');
        setValue("emergencyContactNumber", emergencyContactNumber ?? '');
        setValue("status", status ?? false);
        setValue("employeeImage", employeeImage ?? uploadImg);
        setPreviewImg(employeeImage || uploadImg);
        setValue("address1A1", primaryAddressValue.address1 ?? '');
        setValue("address2A1", primaryAddressValue.address2 ?? '');
        setValue("cityA1", primaryAddressValue.city ?? '');
        setValue("zipCodeA1", primaryAddressValue.zipCode ?? '');
        setValue("phoneNumberA1", primaryAddressValue.phoneNumber ?? '');
        setValue("emailIDA1", primaryAddressValue.emailID ?? '');
        setValue("address1A2", secondaryAddressValue.address1 ?? '');
        setValue("address2A2", secondaryAddressValue.address2 ?? '');
        setValue("cityA2", secondaryAddressValue.city ?? '');
        setValue("zipCodeA2", secondaryAddressValue.zipCode ?? '');
        setValue("phoneNumberA2", secondaryAddressValue.phoneNumber ?? '');
        setValue("emailIDA2", secondaryAddressValue.emailID ?? '');
        setCountrySelectID(primaryAddressValue.country ?? null);
        setValue("countryA1", primaryAddressValue.country ?? '');
        setValue("stateA1", primaryAddressValue.state ?? '');
        setStateSelectID(primaryAddressValue.state ?? null);
        setCountry1SelectID(secondaryAddressValue.country ?? null);
        setValue("countryA2", secondaryAddressValue.country ?? '');
        setValue("stateA2", secondaryAddressValue.state ?? '');
        setState1SelectID(secondaryAddressValue.state ?? null);
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (countrySelectID !== null) {
            setStateArray(allStateArray.filter(state => state.cntrY_ID === countrySelectID));
        } else {
            setStateArray([]);
        }
        // setStateSelectID(null); // Clear selected state when country changes
    }, [allStateArray, countrySelectID]);

    useEffect(() => {
        if (country1SelectID !== null) {
            setState1Array(allState1Array.filter(state1 => state1.cntrY_ID === country1SelectID));
        } else {
            setState1Array([]);
        }
        // setState1SelectID(null); // Clear selected state when country changes
    }, [allState1Array, country1SelectID]);

    const getEmployeeDetails = async () => {
        const EmployeeData = await getEmployee();
        const finalData = EmployeeData.map((Emp, index) => {
            return {
                ...Emp,
                Rowid: index + 1,
                EmployeeName: Emp.firstName + " " + Emp.lastName,
                EmployeeStatus: Emp.status === true ? "Active" : "Inactive"
            };
        });
        const sortedEmployeeData = finalData.sort((a, b) => a.EmployeeName.localeCompare(b.EmployeeName, undefined, { sensitivity: 'base' }));
        setEmployeeArray(sortedEmployeeData);
    };

    const getUserRoleDetails = async () => {
        const queryParams = getQueryParams(location.search);
        const idValue = queryParams.get('id');
        const UserData = await getUserAccessPermission();
        const EmployeeData = await getEmployee();
        const EmpFilter = EmployeeData.find(e => e.id === parseInt(idValue, 10));
        const filterUser = UserData.filter(user => user.employeeId === parseInt(idValue, 10))
        const userRoleData = await Promise.all(filterUser.map(async (Use, index) => {
            const roleName = await RoleDataFinder(Use.roleId);
            return {
                ...Use,
                Rowid: index + 1,
                UserRoleName: roleName,
                EmployeeStartDate: format(new Date(EmpFilter.employmentStartDate), 'dd-MM-yyyy'),
                EmployeeEndDate: format(new Date(EmpFilter.employmentEndDate), 'dd-MM-yyyy')
            };
        }));
        setUserRoleArray(userRoleData);
    };

    const RoleDataFinder = async (val) => {
        const dataRole = await getRoleData();
        const roleIds = val.split(',').map(id => parseInt(id, 10));
        const roles = roleIds.map(roleId => {
            const found = dataRole.find(option => option.rolE_ID === roleId);
            return found ? found.rolE_User_Role : 'Unknown';
        });
        return roles.join(', ');
    }

    const getTeamDetails = async () => {
        const queryParams = getQueryParams(location.search);
        const idValue = queryParams.get('id');
        const EmployeeData = await getEmployee();
        const EmpFilter = EmployeeData.find(e => e.id === parseInt(idValue, 10));

        const UserData = await getTeam();
        const filterTeam = UserData.filter(team => {
            const empIds = team.empId.split(',').map(id => id.trim());
            return empIds.includes(EmpFilter.employeeID);
        });

        const userRoleData = await Promise.all(filterTeam.map(async (team, index) => {
            let teamLevel;

            const levelOneIds = team.levelOne.split(',').map(id => id.trim());
            const levelTwoIds = team.levelTwo.split(',').map(id => id.trim());
            const levelThreeIds = team.levelThree.split(',').map(id => id.trim());

            if (levelOneIds.includes(EmpFilter.employeeID)) {
                teamLevel = "Level 1";
            } else if (levelTwoIds.includes(EmpFilter.employeeID)) {
                teamLevel = "Level 2";
            } else if (levelThreeIds.includes(EmpFilter.employeeID)) {
                teamLevel = "Level 3";
            }

            return {
                ...team,
                Rowid: index + 1,
                Level: teamLevel,
                EmployeeStartDate: format(new Date(EmpFilter.employmentStartDate), 'dd-MM-yyyy'),
                EmployeeEndDate: format(new Date(EmpFilter.employmentEndDate), 'dd-MM-yyyy')
            };
        }));

        setTeamArray(userRoleData);
    };

    const getDocumentTypeName = (id) => {
        const found = attachmentDropArray?.find(option => option.id === id);
        return found ? found.name : 'Unknown';
    };

    const exportAttachment = (event) => {
        const selectedOption = event.target.value;
        if (selectedOption !== '') {
            handleExportAttachment(selectedOption);
            event.target.value = '';
        }
    };

    const handleExportAttachment = (format) => {
        exportAttachmentData(attachmentArray, format);
    };

    const exportTeam = (event) => {
        const selectedOption = event.target.value;
        if (selectedOption !== '') {
            handleExportTeam(selectedOption);
            event.target.value = '';
        }
    };

    const handleExportTeam = (format) => {
        exportTeamData(teamArray, format);
    };

    const exportRole = (event) => {
        const selectedOption = event.target.value;
        if (selectedOption !== '') {
            handleExportRole(selectedOption);
            event.target.value = '';
        }
    };

    const handleExportRole = (format) => {
        exportRoleData(userRoleArray, format);
    };

    const getAttachmentDetails = async () => {
        const Data = await getAttachments();
        const queryParams = getQueryParams(location.search);
        const idValue = queryParams.get('id');
        const empId = parseInt(idValue, 10);

        const filteredData = Data.filter(Attach => {
            return Attach.empID === parseInt(empId, 10);
        });

        const finalData = filteredData.map((Attach, index) => {
            return {
                ...Attach,
                Rowid: index + 1,
                DocumentTypeName: getDocumentTypeName(Attach.documentType)
            };
        });
        setAttachmentArray(finalData);
    };


    const closeDesignation = () => {
        setAddDesignationTab(false);
        resetDesignation();
        fetchData();
    };

    const closeEmp = () => {
        setAddDesignationTab(false);
        setIsEmployeeView(false);
        reset();
        setPreviewImg(uploadImg);
        setDepartmentSelectID(null);
        setBranchSelectID(null);
        setUnitSelectID(null);
        setEmployeeSelectID(null);
        setDesignationSelectID(null);
        navigate('/EmployeeManagement');
    };

    const handleFileInputChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImg(reader.result);
                setValue('employeeImage', reader.result);
            };
            reader.readAsDataURL(file);
        }
        else {
            setPreviewImg(uploadImg);
        }
    };

    const AddDesignation = () => {
        setAddDesignationTab(true);
    };

    const CheckAvailability = (email) => {
        // Regular expression to validate email format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!email) {
            toast.error("Please Enter Email.");
            return;
        }

        if (!emailRegex.test(email)) {
            toast.error("Invalid email format.");
            return;
        }

        const employeeExists = employeeArray.some(x => x.email === email);
        const isUpdating = employeeID !== 0;

        if (isUpdating) {
            const currentEmployee = employeeArray.find(x => x.id === employeeID);
            if (employeeExists && currentEmployee.email !== email) {
                toast.error("Employee name already exists.");
                return;
            }
        } else if (employeeExists) {
            toast.error("Employee name already exists.");
            return;
        } else if (!employeeExists) {
            toast.success("Email doesn't exist");
            return;
        }
    };

    const fileUpload = (event) => {
        event.preventDefault();
        document.getElementById('uploadImgID').click();
    };

    const togglePasswordVisibilityPassword = () => {
        setPasswordVisiblePassword(!passwordVisiblePassword);
    };

    const togglePasswordVisibilityConfirm = () => {
        setPasswordVisibleConfirmPassword(!passwordVisibleConfirmPassword);
    };

    const clearImage = (event) => {
        event.preventDefault();
        setPreviewImg(uploadImg);
        setValue('employeeImage', '');
    };

    const handleDesignationSubmit = async (desData) => {
        const { designationName } = desData;
        setIsLoading(true);
        const payload = {
            id: 0,
            designationName: designationName,
            designationId: ""
        };
        const response = await createDesignation(payload);
        if (response === "Inserted") {
            fetchData();
            setIsLoading(false);
            toast.success("Designation Inserted Sucessfully");
            closeDesignation();
        } else {
            setIsLoading(false);
            toast.error(response);
        }
    }

    const handleAttachmentSubmit = async (desData) => {
        const { documentType, documentLink, documents } = desData;
        setIsLoading(true);
        const payload = {
            id: attachmentID || 0,
            documentType: documentType,
            documentLink: documentLink,
            documents: documents,
            empID: employeeID
        };

        const response = attachmentID ? await updateAttachments(payload) : await createAttachments(payload);

        if (attachmentID ? "Updated" : "Inserted") {
            toast.success(`Attachment ${attachmentID ? "Updated" : "Inserted"} Successfully`);
            fetchData();
            closeAttachment();
        } else {
            toast.error(response);
        }
        setIsLoading(false);
    }

    const handleEmployeeId = async (data) => {
        const EmployeeData = await getEmployee();
        const empId = EmployeeData.find(x => x.employeeID === data);
        setEmployeeID(empId.id);
    }

    const handleEmployeeSubmit = async (data) => {
        const { firstName, lastName, email, employeeImage, designation, password, confirmPassword, unit, branch, department, reportingOfficer, employmentStartDate, employmentEndDate, phoneNumber, emergencyContactNumber, status, employeeId, address1A1, address2A1, cityA1, stateA1, countryA1, zipCodeA1, phoneNumberA1, emailIDA1, address1A2, address2A2, cityA2, stateA2, countryA2, zipCodeA2, phoneNumberA2, emailIDA2 } = data;
        const maxId = documentArray.find(doc => doc.id === 1);
        const newId = maxId.documentNo + 1;
        const newEmpId = `EMPO${newId}`;
        const employeeExists = employeeArray.some(x => x.email === email);
        const isUpdating = employeeID !== 0;
        if (isUpdating) {
            const currentEmployee = employeeArray.find(x => x.id === employeeID);
            if (employeeExists && currentEmployee.email !== email) {
                toast.error("Employee email Id already exists.");
                return;
            }
        } else if (employeeExists) {
            toast.error("Employee email Id already exists.");
            return;
        }
        setIsLoading(true);

        const PrimaryAddress = {
            address1: address1A1,
            address2: address2A1,
            country: countryA1,
            state: stateA1,
            city: cityA1,
            zipCode: zipCodeA1,
            phoneNumber: phoneNumberA1,
            emailID: emailIDA1
        }

        const secondaryAddress = {
            address1: address1A2,
            address2: address2A2,
            country: countryA2,
            state: stateA2,
            city: cityA2,
            zipCode: zipCodeA2,
            phoneNumber: phoneNumberA2,
            emailID: emailIDA2
        }

        const payload = {
            id: employeeID || 0,
            firstName: firstName,
            lastName: lastName,
            email: email,
            password: password,
            confirmPassword: confirmPassword,
            designation: designation,
            unitId: unit,
            branchId: branch,
            departmentId: department,
            reportingOfficerId: reportingOfficer,
            employmentStartDate: employmentStartDate,
            employmentEndDate: employmentEndDate,
            primaryAddress: JSON.stringify(PrimaryAddress),
            secondaryAddress: JSON.stringify(secondaryAddress),
            phoneNumber: phoneNumber,
            emergencyContactNumber: emergencyContactNumber,
            status: status,
            employeeImage: employeeImage,
            employeeID: employeeId || newEmpId,
            NextID: employeeId || newId
        };

        const response = isUpdating ? await updateEmployee(payload) : await createEmployee(payload);

        if (response === (isUpdating ? "Updated" : "Inserted")) {
            await handleEmployeeId(employeeId || newEmpId);
            fetchData();
            navigate('/EmployeeManagement');
            toast.success(`Employee ${isUpdating ? "Updated" : "Inserted"} Successfully`);
        } else {
            toast.error(response);
        }
        setIsLoading(false);
    };

    // UserRole Pagination
    const handleUserRoleSearchChange = (event) => {
        setSearchQueryUserRole(event.target.value);
        setPageUserRole(0);
    };

    const filteredUserRole = userRoleArray.filter((userRole) =>
        userRole && (
            userRole?.UserRoleName?.toLowerCase()?.includes(searchQueryUserRole?.toLowerCase()) ||
            userRole?.status?.toLowerCase()?.includes(searchQueryUserRole?.toLowerCase())
        )
    );

    const handleRowsPerPageUserRoleChange = (event) => {
        setRowsUserRolePerPage(parseInt(event.target.value));
        setPageUserRole(0);
    };

    const handleUserRoleFirstPage = () => {
        handleUserRoleChangePagePagination({ selected: 0 });
    };

    const handleUserRoleLastPage = () => {
        handleUserRoleChangePagePagination({ selected: Math.ceil(filteredUserRole.length / rowsUserRolePerPage) - 1 });
    };

    const handleUserRoleChangePagePagination = ({ selected }) => {
        setPageUserRole(selected);
    };

    const totalUserRole = filteredUserRole.length;
    const startUserRoleEntry = pageUserRole * rowsUserRolePerPage + 1;
    const endUserRoleEntry = Math.min((pageUserRole + 1) * rowsUserRolePerPage, totalUserRole);


    // Team Pagination
    const handleTeamSearchChange = (event) => {
        setSearchQueryTeam(event.target.value);
        setPageTeam(0);
    };

    const filteredTeam = teamArray.filter((team) =>
        team && (
            team?.teamName?.toLowerCase()?.includes(searchQueryTeam?.toLowerCase()) ||
            team?.Level?.toLowerCase()?.includes(searchQueryTeam?.toLowerCase()) ||
            team?.teamDescription?.toLowerCase()?.includes(searchQueryTeam?.toLowerCase())
        )
    );

    const handleRowsPerPageTeamChange = (event) => {
        setRowsTeamPerPage(parseInt(event.target.value));
        setPageTeam(0);
    };

    const handleTeamFirstPage = () => {
        handleTeamChangePagePagination({ selected: 0 });
    };

    const handleTeamLastPage = () => {
        handleTeamChangePagePagination({ selected: Math.ceil(filteredTeam.length / rowsTeamPerPage) - 1 });
    };

    const handleTeamChangePagePagination = ({ selected }) => {
        setPageTeam(selected);
    };

    const totalTeam = filteredTeam.length;
    const startTeamEntry = pageTeam * rowsTeamPerPage + 1;
    const endTeamEntry = Math.min((pageTeam + 1) * rowsTeamPerPage, totalTeam);

    // Attachment Pagination
    const handleAttachmentSearchChange = (event) => {
        setSearchQueryAttachment(event.target.value);
        setPageAttachment(0);
    };

    const filteredAttachment = attachmentArray.filter((Attachment) =>
        Attachment && (
            Attachment?.DocumentTypeName?.toLowerCase()?.includes(searchQueryAttachment?.toLowerCase()) ||
            Attachment?.documentLink?.toLowerCase()?.includes(searchQueryAttachment?.toLowerCase())
        )
    );

    const handleRowsPerPageAttachmentChange = (event) => {
        setRowsAttachmentPerPage(parseInt(event.target.value));
        setPageAttachment(0);
    };

    const handleAttachmentFirstPage = () => {
        handleAttachmentChangePagePagination({ selected: 0 });
    };

    const handleAttachmentLastPage = () => {
        handleAttachmentChangePagePagination({ selected: Math.ceil(filteredAttachment.length / rowsAttachmentPerPage) - 1 });
    };

    const handleAttachmentChangePagePagination = ({ selected }) => {
        setPageAttachment(selected);
    };

    const totalAttachment = filteredAttachment.length;
    const startAttachmentEntry = pageAttachment * rowsAttachmentPerPage + 1;
    const endAttachmentEntry = Math.min((pageAttachment + 1) * rowsAttachmentPerPage, totalAttachment);

    const viewAttachment = (AttachmentID) => {
        setAddAttachmentTab(true);
        setValueAttachment('documentLink', AttachmentID.documentLink);
        setValueAttachment('documentType', AttachmentID.documentType);
        setValueAttachment('documents', AttachmentID.documents);
        setAttachmentSelectID(AttachmentID.documentType);
        setIsAttachmentView(true);
        setAttachmentID(AttachmentID.id);
    };

    const editAttachment = (AttachmentID) => {
        setAddAttachmentTab(true);
        setValueAttachment('documentLink', AttachmentID.documentLink);
        setValueAttachment('documentType', AttachmentID.documentType);
        setValueAttachment('documents', AttachmentID.documents);
        setAttachmentSelectID(AttachmentID.documentType);
        setIsAttachmentView(false);
        setAttachmentID(AttachmentID.id);
    };

    const deleteAttachment = async (AttachmentID) => {
        const result = await ConfirmDelete();
        if (result.isConfirmed) {
            setIsLoading(true);
            const response = await deleteAttachments(AttachmentID);
            if (response === "Deleted") {
                fetchData();
                setIsLoading(false);
                toast.success("Attachment Deleted Sucessfully");
            } else {
                fetchData();
                setIsLoading(false);
                toast.error(response);
            }
        }
    };

    const addnewAttachment = () => {
        setAddAttachmentTab(true);
        setIsAttachmentView(false);
        setAttachmentID(0);
    };

    const closeAttachment = () => {
        setAddAttachmentTab(false);
        setIsAttachmentView(false);
        resetAttachment();
        setAttachmentSelectID(null)
        setPageAttachment(0);
    };

    const handleCheckboxChange = (id) => {
        setSelectedAttachments((prevSelected) => {
            if (prevSelected.includes(id)) {
                return prevSelected.filter((selectedId) => selectedId !== id);
            } else {
                return [...prevSelected, id];
            }
        });
    };

    const handleSelectAllChange = () => {
        if (selectAll) {
            setSelectedAttachments([]);
        } else {
            setSelectedAttachments(filteredAttachment.map((attachment) => attachment.Rowid));
        }
        setSelectAll(!selectAll);
    };


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > 2 * 1024 * 1024) {
                alert('Maximum allowed file size is 2MB');
                event.target.value = null;
                return;
            }

            if (!['application/pdf', 'image/jpeg', 'image/png'].includes(file.type)) {
                alert('Only JPG, JPEG, PNG, and PDF files are allowed');
                event.target.value = null;
                return;
            }

            const fileName = file.name;
            const fileSize = (file.size / 1024).toFixed(0); // Size in KB
            const fileDisplayText = `${fileName} (${fileSize}k)`;
            // Read file as base64 string
            const reader = new FileReader();
            reader.onload = (e) => {
                const base64String = e.target.result.split(',')[1]; // Get base64 part
                setValueAttachment('documents', base64String);
                setValueAttachment('documentLink', fileDisplayText);
            };
            reader.readAsDataURL(file); // Read file as data URL
        }
    };

    const clearFileInput = () => {
        setValueAttachment('documentLink', '');
        document.getElementById('fileInput').value = null;
    };

    return (
        <div className="bacgroundStyle">
            <ToastContainer theme="colored" />
            {isLoading && <LoadingSpinner />}
            {!isLoading && (
                <div>
                    <div className="pagetitleEmployee">
                        <div className="Pageheader fontFamily fontsize24 fontweightSemiBold">Employee<div><InfoOutlinedIcon /></div></div>
                    </div>
                    <div className="customScrollbar">
                        <div className="card mt-4">
                            <div className="card-body">
                                <div className="d-flex align-items-left">
                                    <div className="blackTextColor fontFamily fontsize20 fontweightMedium">Add Employee</div>
                                </div>
                                <div className="HeaderTab">
                                    {['User Info', 'Activities', 'Contact', 'Role', 'Attachments', 'Team', 'Assigned Asset'].map(tab => (
                                        <a
                                            key={tab}
                                            href={`#${tab.replace(' ', '')}`}
                                            className={`${tabActive === tab ? 'activeTab' : 'InactiveTab'}`}
                                            onClick={() => setTabActive(tab)}
                                        >
                                            <div className="fontFamily fontweightRegular fontsize20">
                                                {tab}
                                            </div>
                                        </a>
                                    ))}
                                </div>

                                {tabActive === 'User Info' && (
                                    <div>
                                        <form className="p-2" noValidate onSubmit={handleSubmit(handleEmployeeSubmit)}>
                                            <div className="row">
                                                <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                    <div>
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor paddinTopFirstName">First Name<span className="mandatoryCss"> *</span></div>
                                                        <input
                                                            placeholder="Enter First Name"
                                                            {...register('firstName', { required: 'Please Enter First Name' })}
                                                            className={isEmployeeView ? "textBoxNewView" : "textBoxNew"}
                                                            disabled={isEmployeeView}
                                                        />
                                                        {errors.firstName && (
                                                            <div className="invalid-feedback" style={{ display: 'block' }} >
                                                                {errors.firstName.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div>
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">Last Name<span className="mandatoryCss"> *</span></div>
                                                        <input
                                                            placeholder="Enter Last Name"
                                                            {...register('lastName', { required: 'Please Enter Last Name' })}
                                                            className={isEmployeeView ? "textBoxNewView" : "textBoxNew"}
                                                            disabled={isEmployeeView}
                                                        />
                                                        {errors.lastName && (
                                                            <div className="invalid-feedback" style={{ display: 'block' }}  >
                                                                {errors.lastName.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-6">
                                                    <div className="imgDiv">
                                                        <img src={previewImg} alt="Preview Img" width="159" height="163" />
                                                        <div className="mt-3">
                                                            <Controller
                                                                name="employeeImage"
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <input
                                                                        type="file"
                                                                        id="uploadImgID"
                                                                        style={{ display: 'none' }}
                                                                        disabled={isEmployeeView}
                                                                        onChange={(e) => {
                                                                            handleFileInputChange(e);
                                                                            field.onChange(e.target.files[0]); // Update the field value
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                            <button
                                                                className="fontFamily fontweightRegular fontsize16 coloredButton"
                                                                style={{ marginLeft: "20px" }}
                                                                hidden={isEmployeeView}
                                                                onClick={fileUpload}
                                                            >
                                                                Upload Image
                                                            </button>
                                                            <span style={{ marginLeft: '7px' }}>
                                                                <button
                                                                    className="fontFamily fontweightRegular fontsize16 coloredButton"
                                                                    hidden={isEmployeeView}
                                                                    onClick={clearImage}
                                                                >
                                                                    Clear
                                                                </button>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                    <div className="linkLableStyle">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor" >
                                                            Email<span className="mandatoryCss"> *</span>
                                                        </div>
                                                        <div className="fontFamily fontweightRegular fontsize16 linkColor">
                                                            <a href="#CheckAvailability" className="underline" onClick={(e) => {
                                                                e.preventDefault();
                                                                const email = document.querySelector('input[type="email"]').value;
                                                                CheckAvailability(email);
                                                            }}>Check Availability</a>
                                                        </div>
                                                    </div>
                                                    <input
                                                        placeholder="Enter Email"
                                                        type="email"
                                                        {...register('email', {
                                                            required: 'Please Enter Email',
                                                            pattern: {
                                                                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                                                message: 'Please Enter a Valid Email Address'
                                                            }
                                                        })}
                                                        className={isEmployeeView ? "textBoxNewView" : "textBoxNew"}
                                                        disabled={isEmployeeView}
                                                    />
                                                    {errors.email && (
                                                        <div className="invalid-feedback" style={{ display: 'block' }}  >
                                                            {errors.email.message}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-6">
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor linkLableStyle">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor" >
                                                            Designation
                                                        </div>
                                                        <div className="fontFamily fontweightRegular fontsize16 linkColor">
                                                            <a href="#AddDesignation" className="underline" onClick={AddDesignation}>Add new</a>
                                                        </div>
                                                    </div>
                                                    <Controller
                                                        name="designation"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                value={designationArray.find(des => des.id === designationSelectID) || null}
                                                                onChange={(selectedOption) => {
                                                                    setDesignationSelectID(selectedOption ? selectedOption.id : null);
                                                                    field.onChange(selectedOption ? selectedOption.id : null);
                                                                }}
                                                                options={designationArray}
                                                                getOptionLabel={(option) => option.designationName}
                                                                getOptionValue={(option) => option.id}
                                                                placeholder="Select Designation"
                                                                isClearable
                                                                isSearchable
                                                                isDisabled={isEmployeeView}
                                                                styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                                        Password<span className="mandatoryCss"> *</span>
                                                    </div>
                                                    <div style={{ position: 'relative' }}>
                                                        <input
                                                            type={passwordVisiblePassword ? "text" : "password"}
                                                            placeholder="Enter Password"
                                                            {...register('password', {
                                                                required: 'Please Enter Password',
                                                                minLength: {
                                                                    value: 8,
                                                                    message: 'Password must be at least 8 characters'
                                                                }
                                                            })}
                                                            className={isEmployeeView ? "textBoxNewView" : "textBoxNew"}
                                                            disabled={isEmployeeView}
                                                        />
                                                        <FontAwesomeIcon
                                                            icon={passwordVisiblePassword ? faEye : faEyeSlash}
                                                            onClick={togglePasswordVisibilityPassword}
                                                            className="eyeContent"
                                                        />
                                                    </div>
                                                    {errors.password && (
                                                        <div className="invalid-feedback" style={{ display: 'block' }}  >
                                                            {errors.password.message}
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                                        Confirm Password<span className="mandatoryCss"> *</span>
                                                    </div>
                                                    <div style={{ position: 'relative' }}>
                                                        <input
                                                            type={passwordVisibleConfirmPassword ? "text" : "password"}
                                                            placeholder="Enter Confirm Password"
                                                            {...register('confirmPassword', {
                                                                required: 'Please Enter Confirm Password',
                                                                minLength: {
                                                                    value: 8,
                                                                    message: 'Password must be at least 8 characters'
                                                                },
                                                                validate: (value) =>
                                                                    value === watch('password') || 'Passwords do not match'
                                                            })}
                                                            className={isEmployeeView ? "textBoxNewView" : "textBoxNew"}
                                                            disabled={isEmployeeView}
                                                        />
                                                        <FontAwesomeIcon
                                                            icon={passwordVisibleConfirmPassword ? faEye : faEyeSlash}
                                                            onClick={togglePasswordVisibilityConfirm}
                                                            className="eyeContent"
                                                        />
                                                    </div>
                                                    {errors.confirmPassword && (
                                                        <div className="invalid-feedback" style={{ display: 'block' }}  >
                                                            {errors.confirmPassword.message}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24 " >
                                                        Unit
                                                    </div>
                                                    <Controller
                                                        name="unit"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                value={unitArray.find(unit => unit.bU_ID === unitSelectID) || null}
                                                                onChange={(selectedOption) => {
                                                                    setUnitSelectID(selectedOption ? selectedOption.bU_ID : null);
                                                                    field.onChange(selectedOption ? selectedOption.bU_ID : null);
                                                                }}
                                                                options={unitArray}
                                                                getOptionLabel={(option) => option.bU_NAME}
                                                                getOptionValue={(option) => option.bU_ID}
                                                                placeholder="Select Unit"
                                                                isClearable
                                                                isSearchable
                                                                isDisabled={isEmployeeView}
                                                                styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24" >
                                                        Branch
                                                    </div>
                                                    <Controller
                                                        name="branch"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                value={branchArray.find(branch => branch.bR_ID === branchSelectID) || null}
                                                                onChange={(selectedOption) => {
                                                                    setBranchSelectID(selectedOption ? selectedOption.bR_ID : null);
                                                                    field.onChange(selectedOption ? selectedOption.bR_ID : null);
                                                                }}
                                                                options={branchArray}
                                                                getOptionLabel={(option) => option.bR_NAME}
                                                                getOptionValue={(option) => option.bR_ID}
                                                                placeholder="Select Branch"
                                                                isClearable
                                                                isSearchable
                                                                isDisabled={isEmployeeView}
                                                                styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24" >
                                                        Department
                                                    </div>
                                                    <Controller
                                                        name="department"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                value={departmentArray.find(dept => dept.id === departmentSelectID) || null}
                                                                onChange={(selectedOption) => {
                                                                    setDepartmentSelectID(selectedOption ? selectedOption.id : null);
                                                                    field.onChange(selectedOption ? selectedOption.id : null);
                                                                }}
                                                                options={departmentArray}
                                                                getOptionLabel={(option) => option.departmentName}
                                                                getOptionValue={(option) => option.id}
                                                                placeholder="Select Department"
                                                                isClearable
                                                                isSearchable
                                                                isDisabled={isEmployeeView}
                                                                styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24" >
                                                        Reporting Officer
                                                    </div>
                                                    <Controller
                                                        name="reportingOfficer"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                value={employeeArray.find(repo => repo.id === employeeSelectID) || null}
                                                                onChange={(selectedOption) => {
                                                                    setEmployeeSelectID(selectedOption ? selectedOption.id : null);
                                                                    field.onChange(selectedOption ? selectedOption.id : null);
                                                                }}
                                                                options={employeeArray}
                                                                getOptionLabel={(option) => option.EmployeeName}
                                                                getOptionValue={(option) => option.id}
                                                                placeholder="Select Reporting Officer"
                                                                isClearable
                                                                isSearchable
                                                                isDisabled={isEmployeeView}
                                                                styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24" >
                                                        Employment Start Date
                                                    </div>
                                                    <div className='position-relative'>
                                                        <Controller
                                                            name="employmentStartDate"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <DatePicker
                                                                    {...field}
                                                                    selected={field.value}
                                                                    onChange={(date) => field.onChange(date)}
                                                                    dateFormat="dd-MM-yyyy"
                                                                    placeholderText="Select Start Date"
                                                                    className={isEmployeeView ? "DatePickerDisable date-picker-input" : "DatePicker date-picker-input"}
                                                                    disabled={isEmployeeView}
                                                                />
                                                            )}
                                                        />
                                                        <img className="eyeContent" src={datesvgIcon} alt="datesvgIcon" />
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24" >
                                                        Employment End Date
                                                    </div>
                                                    <div className='position-relative'>
                                                        <Controller
                                                            name="employmentEndDate"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <DatePicker
                                                                    {...field}
                                                                    selected={field.value}
                                                                    onChange={(date) => field.onChange(date)}
                                                                    dateFormat="dd-MM-yyyy"
                                                                    placeholderText="Select End Date"
                                                                    className={isEmployeeView ? "DatePickerDisable date-picker-input" : "DatePicker date-picker-input"}
                                                                    disabled={isEmployeeView}
                                                                />
                                                            )}
                                                        />
                                                        <img className="eyeContent" src={datesvgIcon} alt="datesvgIcon" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24" >
                                                        Phone Number<span className="mandatoryCss"> *</span>
                                                    </div>
                                                    <input
                                                        type="tel"
                                                        placeholder="Enter Phone Number"
                                                        {...register('phoneNumber', {
                                                            required: 'Please enter your phone number',
                                                            validate: (value) => /^\d{10}$/.test(value) || 'Invalid phone number, must be 10 digits'
                                                        })}
                                                        onInput={(e) => {
                                                            e.target.value = e.target.value.replace(/\D/g, '');
                                                        }}
                                                        className={isEmployeeView ? "textBoxNewView" : "textBoxNew"}
                                                        disabled={isEmployeeView}
                                                    />
                                                    {errors.phoneNumber && (
                                                        <div className="invalid-feedback" style={{ display: 'block' }}  >
                                                            {errors.phoneNumber.message}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24" >
                                                        Emergency Contact Number<span className="mandatoryCss"> *</span>
                                                    </div>
                                                    <input
                                                        type="tel"
                                                        placeholder="Enter Phone Number"
                                                        {...register('emergencyContactNumber', {
                                                            required: 'Please enter your phone number',
                                                            validate: (value) => /^\d{10}$/.test(value) || 'Invalid phone number, must be 10 digits'
                                                        })}
                                                        onInput={(e) => {
                                                            e.target.value = e.target.value.replace(/\D/g, '');
                                                        }}
                                                        className={isEmployeeView ? "textBoxNewView" : "textBoxNew"}
                                                        disabled={isEmployeeView}
                                                    />
                                                    {errors.emergencyContactNumber && (
                                                        <div className="invalid-feedback" style={{ display: 'block' }}  >
                                                            {errors.emergencyContactNumber.message}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                    <div>
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24" >
                                                            Status
                                                        </div>
                                                        <div className="d-flex align-items-center justify-content-start">
                                                            <div
                                                                className="switch-container"
                                                                onClick={!isEmployeeView ? () => setValue('status', !statusWatchEmployee) : undefined}
                                                                onKeyDown={!isEmployeeView ? (e) => {
                                                                    if (e.key === 'Enter' || e.key === ' ') {
                                                                        setValue('status', !statusWatchEmployee);
                                                                    }
                                                                } : undefined}
                                                                tabIndex={isEmployeeView ? -1 : 0}
                                                                style={{ cursor: isEmployeeView ? 'not-allowed' : 'pointer' }}
                                                                role="switch"
                                                                aria-checked={statusWatchEmployee}
                                                            >
                                                                <div className={`switch ${statusWatchEmployee ? 'active' : ''}`}>
                                                                    <div className="handle"></div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="fontFamily fontweightRegular fontsize16 grayTextColor"
                                                                style={{ paddingLeft: '20px' }}
                                                            >
                                                                {statusWatchEmployee ? 'Active' : 'Inactive'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                </div>
                                            </div>

                                            <div className="text-center buttonSpace">
                                                {!isEmployeeView && (
                                                    <button type="submit" className="coloredButtonSave">{isLoading ? 'Loading...' : 'Save'}</button>
                                                )}
                                                <button type="button" className="outlineButton" onClick={closeEmp}>Cancel</button>
                                            </div>
                                        </form>
                                    </div>
                                )}

                                {tabActive === 'Contact' && (
                                    <div>
                                        <form noValidate onSubmit={handleSubmit(handleEmployeeSubmit)}>
                                            <div className="fontFamily fontsize16 fontweightMedium" style={{ padding: "20px 0px" }}>
                                                Primary Address
                                            </div>
                                            <div className="row">
                                                <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                    <div>
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Address 1</div>
                                                        <input
                                                            placeholder="Enter the Address Line 1"
                                                            {...register('address1A1')}
                                                            className={isEmployeeView || employeeID === 0 ? "textBoxNewView" : "textBoxNew"}
                                                            disabled={isEmployeeView || employeeID === 0}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                    <div>
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Address 2</div>
                                                        <input
                                                            placeholder="Enter the Address Line 2"
                                                            {...register('address2A1')}
                                                            className={isEmployeeView || employeeID === 0 ? "textBoxNewView" : "textBoxNew"}
                                                            disabled={isEmployeeView || employeeID === 0}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                    <div>
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24" >County</div>
                                                        <Controller
                                                            name="countryA1"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    value={countryArray.find(repo => repo.id === countrySelectID) || null}
                                                                    onChange={(selectedOption) => {
                                                                        setCountrySelectID(selectedOption ? selectedOption.id : null);
                                                                        field.onChange(selectedOption ? selectedOption.id : null);
                                                                    }}
                                                                    options={countryArray}
                                                                    getOptionLabel={(option) => option.cntrY_NAME}
                                                                    getOptionValue={(option) => option.ID}
                                                                    placeholder="Select Country"
                                                                    isClearable
                                                                    isSearchable
                                                                    isDisabled={isEmployeeView || employeeID === 0}
                                                                    styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                    <div>
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24" >State</div>
                                                        <Controller
                                                            name="stateA1"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    value={stateArray.find(repo => repo.id === stateSelectID) || null}
                                                                    onChange={(selectedOption) => {
                                                                        setStateSelectID(selectedOption ? selectedOption.id : null);
                                                                        field.onChange(selectedOption ? selectedOption.id : null);
                                                                    }}
                                                                    options={stateArray}
                                                                    getOptionLabel={(option) => option.statE_NAME}
                                                                    getOptionValue={(option) => option.Id}
                                                                    placeholder="Select State"
                                                                    isClearable
                                                                    isSearchable
                                                                    isDisabled={isEmployeeView || employeeID === 0}
                                                                    styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                    <div>
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24" >City</div>
                                                        <input
                                                            placeholder="Enter City"
                                                            {...register('cityA1')}
                                                            className={isEmployeeView || employeeID === 0 ? "textBoxNewView" : "textBoxNew"}
                                                            disabled={isEmployeeView || employeeID === 0}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                    <div>
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24" >Zip Code</div>
                                                        <input
                                                            placeholder="Enter Zip Code"
                                                            {...register('zipCodeA1', {
                                                                pattern: {
                                                                    value: /^\d{6}$/,
                                                                    message: 'Invalid zip code format',
                                                                },
                                                            })}
                                                            onInput={(e) => {
                                                                e.target.value = e.target.value.replace(/\D/g, '');
                                                            }}
                                                            className={isEmployeeView || employeeID === 0 ? "textBoxNewView" : "textBoxNew"}
                                                            disabled={isEmployeeView || employeeID === 0}
                                                        />
                                                        {errors.zipCodeA1 && (
                                                            <div className="invalid-feedback" style={{ display: 'block' }}  >
                                                                {errors.zipCodeA1.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                    <div>
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24" >Phone Number</div>
                                                        <input
                                                            type="tel"
                                                            placeholder="Enter Phone Number"
                                                            {...register('phoneNumberA1', {
                                                                validate: (value) => {
                                                                    if (value === '') return true; // Allow empty value
                                                                    return /^\d{10}$/.test(value) || 'Invalid phone number, must be 10 digits';
                                                                }
                                                            })}
                                                            onInput={(e) => {
                                                                e.target.value = e.target.value.replace(/\D/g, '');
                                                            }}
                                                            className={isEmployeeView || employeeID === 0 ? "textBoxNewView" : "textBoxNew"}
                                                            disabled={isEmployeeView || employeeID === 0}
                                                        />
                                                        {errors.phoneNumberA1 && (
                                                            <div className="invalid-feedback" style={{ display: 'block' }}  >
                                                                {errors.phoneNumberA1.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                    <div>
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24" >Email ID</div>
                                                        <input
                                                            placeholder="Enter Email"
                                                            type="email"
                                                            {...register('emailIDA1', {
                                                                pattern: {
                                                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                                                    message: 'Please Enter a Valid Email Address'
                                                                }
                                                            })}
                                                            className={isEmployeeView || employeeID === 0 ? "textBoxNewView" : "textBoxNew"}
                                                            disabled={isEmployeeView || employeeID === 0}
                                                        />
                                                        {errors.emailIDA1 && (
                                                            <div className="invalid-feedback" style={{ display: 'block' }}  >
                                                                {errors.emailIDA1.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Secondary Address */}
                                            <div className="fontFamily fontsize16 fontweightMedium" style={{ padding: "20px 0px" }}>
                                                Secondary Address
                                            </div>
                                            <div className="row">
                                                <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                    <div>
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Address 1</div>
                                                        <input
                                                            placeholder="Enter the Address Line 1"
                                                            {...register('address1A2')}
                                                            className={isEmployeeView || employeeID === 0 ? "textBoxNewView" : "textBoxNew"}
                                                            disabled={isEmployeeView || employeeID === 0}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                    <div>
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Address 2</div>
                                                        <input
                                                            placeholder="Enter the Address Line 2"
                                                            {...register('address2A2')}
                                                            className={isEmployeeView || employeeID === 0 ? "textBoxNewView" : "textBoxNew"}
                                                            disabled={isEmployeeView || employeeID === 0}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                    <div>
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24" >County</div>
                                                        <Controller
                                                            name="countryA2"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    value={country1Array.find(repo => repo.id === country1SelectID) || null}
                                                                    onChange={(selectedOption) => {
                                                                        setCountry1SelectID(selectedOption ? selectedOption.id : null);
                                                                        field.onChange(selectedOption ? selectedOption.id : null);
                                                                    }}
                                                                    options={country1Array}
                                                                    getOptionLabel={(option) => option.cntrY_NAME}
                                                                    getOptionValue={(option) => option.ID}
                                                                    placeholder="Select Country"
                                                                    isClearable
                                                                    isSearchable
                                                                    isDisabled={isEmployeeView || employeeID === 0}
                                                                    styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                                                />
                                                            )}
                                                        />
                                                    </div>

                                                </div>
                                                <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                    <div>
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24" >State</div>
                                                        <Controller
                                                            name="stateA2"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    value={state1Array.find(repo => repo.id === state1SelectID) || null}
                                                                    onChange={(selectedOption) => {
                                                                        setState1SelectID(selectedOption ? selectedOption.id : null);
                                                                        field.onChange(selectedOption ? selectedOption.id : null);
                                                                    }}
                                                                    options={state1Array}
                                                                    getOptionLabel={(option) => option.statE_NAME}
                                                                    getOptionValue={(option) => option.Id}
                                                                    placeholder="Select State"
                                                                    isClearable
                                                                    isSearchable
                                                                    isDisabled={isEmployeeView || employeeID === 0}
                                                                    styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                    <div>
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24" >City</div>
                                                        <input
                                                            placeholder="Enter City"
                                                            {...register('cityA2')}
                                                            className={isEmployeeView || employeeID === 0 ? "textBoxNewView" : "textBoxNew"}
                                                            disabled={isEmployeeView || employeeID === 0}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                    <div>
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24" >Zip Code</div>
                                                        <input
                                                            placeholder="Enter Zip Code"
                                                            {...register('zipCodeA2', {
                                                                pattern: {
                                                                    value: /^\d{6}$/,
                                                                    message: 'Invalid zip code format',
                                                                },
                                                            })}
                                                            onInput={(e) => {
                                                                e.target.value = e.target.value.replace(/\D/g, '');
                                                            }}
                                                            className={isEmployeeView || employeeID === 0 ? "textBoxNewView" : "textBoxNew"}
                                                            disabled={isEmployeeView || employeeID === 0}
                                                        />
                                                        {errors.zipCodeA2 && (
                                                            <div className="invalid-feedback" style={{ display: 'block' }}  >
                                                                {errors.zipCodeA2.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                    <div>
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24" >Phone Number</div>
                                                        <input
                                                            type="tel"
                                                            placeholder="Enter Phone Number"
                                                            {...register('phoneNumberA2', {
                                                                validate: (value) => {
                                                                    if (value === '') return true; // Allow empty value
                                                                    return /^\d{10}$/.test(value) || 'Invalid phone number, must be 10 digits';
                                                                }
                                                            })}
                                                            onInput={(e) => {
                                                                e.target.value = e.target.value.replace(/\D/g, '');
                                                            }}
                                                            className={isEmployeeView || employeeID === 0 ? "textBoxNewView" : "textBoxNew"}
                                                            disabled={isEmployeeView || employeeID === 0}
                                                        />
                                                        {errors.phoneNumberA2 && (
                                                            <div className="invalid-feedback" style={{ display: 'block' }}  >
                                                                {errors.phoneNumberA2.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                    <div>
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24" >Email ID</div>
                                                        <input
                                                            placeholder="Enter Email"
                                                            type="email"
                                                            {...register('emailIDA2', {
                                                                pattern: {
                                                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                                                    message: 'Please Enter a Valid Email Address'
                                                                }
                                                            })}
                                                            className={isEmployeeView || employeeID === 0 ? "textBoxNewView" : "textBoxNew"}
                                                            disabled={isEmployeeView || employeeID === 0}
                                                        />
                                                        {errors.emailIDA2 && (
                                                            <div className="invalid-feedback" style={{ display: 'block' }}  >
                                                                {errors.emailIDA2.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="text-center buttonSpace">
                                                {((!isEmployeeView && employeeID !== 0)) && (
                                                    <button type="submit" className="coloredButtonSave">{isLoading ? 'Loading...' : 'Save'}</button>
                                                )}
                                                <button type="button" className="outlineButton" onClick={closeEmp}>Cancel</button>
                                            </div>
                                        </form>
                                    </div>
                                )}

                                {tabActive === 'Attachments' && (
                                    <div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="custom-container">
                                                <span className="blackTextColor fontFamily fontsize16 fontweightRegular" >
                                                    <span className="show">Show</span>
                                                    <select className="custom-dropdown" value={rowsAttachmentPerPage} onChange={handleRowsPerPageAttachmentChange}>
                                                        {paginateArray.map((n) => (
                                                            <option className="blackTextColor fontFamily fontsize14 fontweightRegular" key={n} value={n}>
                                                                {n}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <span className="blackTextColor entries fontFamily fontsize16 fontweightRegular" >
                                                        Entries
                                                    </span>
                                                    <input
                                                        type="text"
                                                        placeholder="Search..."
                                                        className="searchTextBox"
                                                        value={searchQueryAttachment}
                                                        onChange={handleAttachmentSearchChange}
                                                    />
                                                </span>
                                            </div>
                                            <div className="addbuttondiv">
                                                <button type="button" className="fontFamily fontweightRegular fontsize16 coloredButtonAdd" style={{ cursor: isEmployeeView || employeeID === 0 ? 'not-allowed' : 'pointer', marginLeft: "20px" }} disabled={isEmployeeView || employeeID === 0} onClick={() => addnewAttachment()}> Add New </button>
                                                <select className="fontFamily fontweightRegular fontsize16 exportBtn" name="language" id="language" defaultValue="" style={{ cursor: isEmployeeView || employeeID === 0 ? 'not-allowed' : 'pointer' }} disabled={isEmployeeView || employeeID === 0} onChange={exportAttachment}>
                                                    <option value="" hidden={true}>Export</option>
                                                    <option value="csv">CSV</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="tableScroll" >
                                            <table className="w-100 mt-2">
                                                <thead>
                                                    <tr className="text-left">
                                                        <th className="fontFamily fontweightMedium fontsize16" style={{ backgroundColor: '#F6F6F6', width: "5%", padding: "2px 2px 2px 3%" }}>
                                                            <input
                                                                id="myCheckboxHead"
                                                                type="checkbox"
                                                                checked={selectAll}
                                                                onChange={handleSelectAllChange}
                                                            />
                                                        </th>
                                                        <th className="fontFamily fontweightMedium fontsize16 " style={{ backgroundColor: '#F6F6F6', width: "20%", padding: "2px 2px 2px 3%" }}>
                                                            SI.No</th>
                                                        <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "30%" }}>Document Type</th>
                                                        <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "30%" }}>Document Link</th>
                                                        <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "10%" }}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredAttachment.length > 0 ? (
                                                        filteredAttachment.slice(pageAttachment * rowsAttachmentPerPage, (pageAttachment + 1) * rowsAttachmentPerPage).map((Attachment, index) => (
                                                            <tr key={Attachment.Rowid} >
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`} style={{ padding: "2px 2px 2px 3%" }}>
                                                                    <input
                                                                        id="myCheckbox"
                                                                        type="checkbox"
                                                                        checked={selectedAttachments.includes(Attachment.id)}
                                                                        onChange={() => handleCheckboxChange(Attachment.id)}
                                                                    />
                                                                </td>
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14  ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`} style={{ padding: "2px 2px 2px 4%" }}>{Attachment.Rowid}</td>
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>{Attachment.DocumentTypeName}</td>
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}><span className="linkColor">{Attachment.documentLink}</span></td>
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>
                                                                    <span style={{ cursor: 'pointer' }}><a href="#viewAttachment" onClick={() => viewAttachment(Attachment)}><img height={15} width={18} src={viewsvgIcon} alt="viewsvgIcon" /></a></span>
                                                                    <span style={{ marginLeft: '10px', cursor: 'pointer' }}><a href="#editAttachment" style={{ cursor: isEmployeeView ? 'not-allowed' : 'pointer' }} onClick={isEmployeeView ? null : () => editAttachment(Attachment)}><img src={editsvgIcon} alt="editsvgIcon" /></a></span>
                                                                    <span style={{ marginLeft: '10px', cursor: 'pointer' }}><a href="#DeleteAttachment" style={{ cursor: isEmployeeView ? 'not-allowed' : 'pointer' }} onClick={isEmployeeView ? null : () => deleteAttachment(Attachment.id)}><img src={deletesvgIcon} alt="deletesvgIcon" /></a></span>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <td colSpan="9" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between paddingTopShowing">
                                            <div >
                                                <div className="grayTextColor fontFamily fontsize16 fontweightMedium">
                                                    {`Showing ${startAttachmentEntry} to ${endAttachmentEntry} of ${totalAttachment} entries`}
                                                </div>
                                            </div>
                                            <div >
                                                <div className="pagination-container d-flex align-items-center">
                                                    <button onClick={handleAttachmentFirstPage} className="btn btn-link">
                                                        <FontAwesomeIcon icon={faAngleDoubleLeft} className="icon-color" />
                                                    </button>
                                                    <ReactPaginate
                                                        previousLabel={<FontAwesomeIcon icon={faChevronLeft} className="icon-color" />}
                                                        nextLabel={<FontAwesomeIcon icon={faChevronRight} className="icon-color" />}
                                                        breakLabel={'...'}
                                                        breakClassName={'break-me'}
                                                        pageCount={Math.ceil(filteredAttachment.length / rowsAttachmentPerPage)}
                                                        forcePage={pageAttachment}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={5}
                                                        onPageChange={handleAttachmentChangePagePagination}
                                                        containerClassName={'pagination'}
                                                        subContainerClassName={'pages pagination'}
                                                        activeClassName={'active'}
                                                        renderOnZeroPageCount={null}
                                                    />
                                                    <button onClick={handleAttachmentLastPage} className="btn btn-link">
                                                        <FontAwesomeIcon icon={faAngleDoubleRight} className="icon-color" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div style={{ padding: "0px 100px" }}>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {tabActive === 'Activities' && (
                                    <div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="custom-container">
                                                <span className="blackTextColor fontFamily fontsize16 fontweightRegular" >
                                                    <span className="show">Show</span>
                                                    <select className="custom-dropdown" value={rowsAttachmentPerPage} onChange={handleRowsPerPageAttachmentChange}>
                                                        {paginateArray.map((n) => (
                                                            <option className="blackTextColor fontFamily fontsize14 fontweightRegular" key={n} value={n}>
                                                                {n}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <span className="blackTextColor entries fontFamily fontsize16 fontweightRegular" >
                                                        Entries
                                                    </span>
                                                    <input
                                                        type="text"
                                                        placeholder="Search..."
                                                        className="searchTextBox"
                                                        value={searchQueryAttachment}
                                                        onChange={handleAttachmentSearchChange}
                                                    />
                                                </span>
                                            </div>
                                            <div className="addbuttondiv">
                                                <select className="fontFamily fontweightRegular fontsize16 exportBtn" name="language" id="language" defaultValue="" >
                                                    <option value="" hidden={true}>Export</option>
                                                    <option value="excel">Excel</option>
                                                    <option value="csv">CSV</option>
                                                    <option value="pdf" >Pdf</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="tableScroll" >
                                            <table className="w-100 mt-2">
                                                <thead>
                                                    <tr className="text-left">
                                                        <th className="fontFamily fontweightMedium fontsize16" style={{ backgroundColor: '#F6F6F6', width: "5%", padding: "2px 2px 2px 3%" }}>
                                                            <CustomCheckBox
                                                                tab="Screens"
                                                                item="selectAll"
                                                                option=""
                                                                handleCheckboxChange={handleSelectAllChange}
                                                                selectAll={selectAll}
                                                            />
                                                        </th>
                                                        <th className="fontFamily fontweightMedium fontsize16 " style={{ backgroundColor: '#F6F6F6', width: "20%", padding: "2px 2px 2px 3%" }}>
                                                            SI.No</th>
                                                        <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "30%" }}>Date</th>
                                                        <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "30%" }}>Event</th>
                                                        <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "10%" }}>Note</th>
                                                        <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "10%" }}>View</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredAttachment.length > 0 ? (
                                                        filteredAttachment.slice(pageAttachment * rowsAttachmentPerPage, (pageAttachment + 1) * rowsAttachmentPerPage).map((Attachment, index) => (
                                                            <tr key={Attachment.Rowid} >
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14  ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`} style={{ padding: "2px 2px 2px 4%" }}>{Attachment.Rowid}</td>
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>{Attachment.countryName}</td>
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>{Attachment.statE_NAME}</td>
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>{Attachment.statE_NAME}</td>
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>
                                                                    <span style={{ cursor: 'pointer' }}><a href="#viewAttachment" onClick={() => viewAttachment(Attachment.id)}><img width={18} height={15} src={viewsvgIcon} alt="viewsvgIcon" /></a></span>
                                                                    <span style={{ marginLeft: '10px', cursor: 'pointer' }}><a href="#editAttachment" onClick={() => editAttachment(Attachment)}><img src={editsvgIcon} alt="editsvgIcon" /></a></span>
                                                                    <span style={{ marginLeft: '10px', cursor: 'pointer' }}><a href="#DeleteAttachment" ><img src={deletesvgIcon} alt="deletesvgIcon" /></a></span>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <td colSpan="9" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between paddingTopShowing">
                                            <div >
                                                <div className="grayTextColor fontFamily fontsize16 fontweightMedium">
                                                    {`Showing ${startAttachmentEntry} to ${endAttachmentEntry} of ${totalAttachment} entries`}
                                                </div>
                                            </div>
                                            <div >
                                                <div className="pagination-container d-flex align-items-center">
                                                    <button onClick={handleAttachmentFirstPage} className="btn btn-link">
                                                        <FontAwesomeIcon icon={faAngleDoubleLeft} className="icon-color" />
                                                    </button>
                                                    <ReactPaginate
                                                        previousLabel={<FontAwesomeIcon icon={faChevronLeft} className="icon-color" />}
                                                        nextLabel={<FontAwesomeIcon icon={faChevronRight} className="icon-color" />}
                                                        breakLabel={'...'}
                                                        breakClassName={'break-me'}
                                                        pageCount={Math.ceil(filteredAttachment.length / rowsAttachmentPerPage)}
                                                        forcePage={pageAttachment}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={5}
                                                        onPageChange={handleAttachmentChangePagePagination}
                                                        containerClassName={'pagination'}
                                                        subContainerClassName={'pages pagination'}
                                                        activeClassName={'active'}
                                                        renderOnZeroPageCount={null}
                                                    />
                                                    <button onClick={handleAttachmentLastPage} className="btn btn-link">
                                                        <FontAwesomeIcon icon={faAngleDoubleRight} className="icon-color" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div style={{ padding: "0px 100px" }}>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {tabActive === 'Role' && (
                                    <div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="custom-container">
                                                <span className="blackTextColor fontFamily fontsize16 fontweightRegular" >
                                                    <span className="show">Show</span>
                                                    <select className="custom-dropdown" value={rowsUserRolePerPage} onChange={handleRowsPerPageUserRoleChange}>
                                                        {paginateArray.map((n) => (
                                                            <option className="blackTextColor fontFamily fontsize14 fontweightRegular" key={n} value={n}>
                                                                {n}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <span className="blackTextColor entries fontFamily fontsize16 fontweightRegular" >
                                                        Entries
                                                    </span>
                                                    <input
                                                        type="text"
                                                        placeholder="Search..."
                                                        className="searchTextBox"
                                                        value={searchQueryUserRole}
                                                        onChange={handleUserRoleSearchChange}
                                                    />
                                                </span>
                                            </div>
                                            <div className="addbuttondiv">
                                                <select className="fontFamily fontweightRegular fontsize16 exportBtn" name="language" id="language" defaultValue="" style={{ cursor: isEmployeeView ? 'not-allowed' : 'pointer' }} disabled={isEmployeeView} onChange={exportRole}>
                                                    <option value="" hidden={true}>Export</option>
                                                    <option value="csv">CSV</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="tableScroll" >
                                            <table className="w-100 mt-2">
                                                <thead>
                                                    <tr className="text-left">
                                                        <th className="fontFamily fontweightMedium fontsize16 " style={{ backgroundColor: '#F6F6F6', width: "10%", padding: "2px 2px 2px 3%" }}>
                                                            SI.No</th>
                                                        <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "20%" }} >Role</th>
                                                        <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "20%" }}>Start Date</th>
                                                        <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "20%" }} >End Date</th>
                                                        <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "10%" }} >Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredUserRole.length > 0 ? (
                                                        filteredUserRole.slice(pageUserRole * rowsUserRolePerPage, (pageUserRole + 1) * rowsUserRolePerPage).map((UserRole, index) => (
                                                            <tr key={UserRole.Rowid} >
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14  ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`} style={{ padding: "2px 2px 2px 4%" }}>{UserRole.Rowid}</td>
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`} style={{ maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', cursor: 'pointer' }} title={UserRole.UserRoleName}>{UserRole.UserRoleName}</td>
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>{UserRole.EmployeeStartDate}</td>
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>{UserRole.EmployeeEndDate}</td>
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`} style={{ color: UserRole.status === 'Active' ? '#2ED47A' : '#EC2020' }}>{UserRole.status}</td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <td colSpan="9" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between paddingTopShowing">
                                            <div >
                                                <div className="grayTextColor fontFamily fontsize16 fontweightMedium">
                                                    {`Showing ${startUserRoleEntry} to ${endUserRoleEntry} of ${totalUserRole} entries`}
                                                </div>
                                            </div>
                                            <div >
                                                <div className="pagination-container d-flex align-items-center">
                                                    <button onClick={handleUserRoleFirstPage} className="btn btn-link">
                                                        <FontAwesomeIcon icon={faAngleDoubleLeft} className="icon-color" />
                                                    </button>
                                                    <ReactPaginate
                                                        previousLabel={<FontAwesomeIcon icon={faChevronLeft} className="icon-color" />}
                                                        nextLabel={<FontAwesomeIcon icon={faChevronRight} className="icon-color" />}
                                                        breakLabel={'...'}
                                                        breakClassName={'break-me'}
                                                        pageCount={Math.ceil(filteredUserRole.length / rowsUserRolePerPage)}
                                                        forcePage={pageUserRole}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={5}
                                                        onPageChange={handleUserRoleChangePagePagination}
                                                        containerClassName={'pagination'}
                                                        subContainerClassName={'pages pagination'}
                                                        activeClassName={'active'}
                                                        renderOnZeroPageCount={null}
                                                    />
                                                    <button onClick={handleUserRoleLastPage} className="btn btn-link">
                                                        <FontAwesomeIcon icon={faAngleDoubleRight} className="icon-color" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div style={{ padding: "0px 100px" }}>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {tabActive === 'Team' && (
                                    <div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="custom-container">
                                                <span className="blackTextColor fontFamily fontsize16 fontweightRegular" >
                                                    <span className="show">Show</span>
                                                    <select className="custom-dropdown" value={rowsTeamPerPage} onChange={handleRowsPerPageTeamChange}>
                                                        {paginateArray.map((n) => (
                                                            <option className="blackTextColor fontFamily fontsize14 fontweightRegular" key={n} value={n}>
                                                                {n}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <span className="blackTextColor entries fontFamily fontsize16 fontweightRegular" >
                                                        Entries
                                                    </span>
                                                    <input
                                                        type="text"
                                                        placeholder="Search..."
                                                        className="searchTextBox"
                                                        value={searchQueryTeam}
                                                        onChange={handleTeamSearchChange}
                                                    />
                                                </span>
                                            </div>
                                            <div className="addbuttondiv">
                                                <select className="fontFamily fontweightRegular fontsize16 exportBtn" name="language" id="language" defaultValue="" style={{ cursor: isEmployeeView ? 'not-allowed' : 'pointer' }} disabled={isEmployeeView} onChange={exportTeam}>
                                                    <option value="" hidden={true}>Export</option>
                                                    <option value="csv">CSV</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="tableScroll" >
                                            <table className="w-100 mt-2">
                                                <thead>
                                                    <tr className="text-left">
                                                        <th className="fontFamily fontweightMedium fontsize16 " style={{ backgroundColor: '#F6F6F6', width: "20%", padding: "2px 2px 2px 3%" }}>
                                                            SI.No</th>
                                                        <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6' }}>Mapped Team</th>
                                                        <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6' }}>Team Description</th>
                                                        <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6' }}>Type</th>
                                                        <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6' }}>From Date</th>
                                                        <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6' }}>To Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredTeam.length > 0 ? (
                                                        filteredTeam.slice(pageTeam * rowsTeamPerPage, (pageTeam + 1) * rowsTeamPerPage).map((Team, index) => (
                                                            <tr key={Team.Rowid} >
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14  ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`} style={{ padding: "2px 2px 2px 4%" }}>{Team.Rowid}</td>
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`} style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', cursor: 'pointer' }} title={Team.teamName}>{Team.teamName}</td>
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`} style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', cursor: 'pointer' }} title={Team.teamDescription}>{Team.teamDescription}</td>
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`} style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', cursor: 'pointer' }} title={Team.Level}>{Team.Level}</td>
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>{Team.EmployeeStartDate}</td>
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>{Team.EmployeeEndDate}</td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <td colSpan="9" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between paddingTopShowing">
                                            <div >
                                                <div className="grayTextColor fontFamily fontsize16 fontweightMedium">
                                                    {`Showing ${startTeamEntry} to ${endTeamEntry} of ${totalTeam} entries`}
                                                </div>
                                            </div>
                                            <div >
                                                <div className="pagination-container d-flex align-items-center">
                                                    <button onClick={handleTeamFirstPage} className="btn btn-link">
                                                        <FontAwesomeIcon icon={faAngleDoubleLeft} className="icon-color" />
                                                    </button>
                                                    <ReactPaginate
                                                        previousLabel={<FontAwesomeIcon icon={faChevronLeft} className="icon-color" />}
                                                        nextLabel={<FontAwesomeIcon icon={faChevronRight} className="icon-color" />}
                                                        breakLabel={'...'}
                                                        breakClassName={'break-me'}
                                                        pageCount={Math.ceil(filteredTeam.length / rowsTeamPerPage)}
                                                        forcePage={pageTeam}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={5}
                                                        onPageChange={handleTeamChangePagePagination}
                                                        containerClassName={'pagination'}
                                                        subContainerClassName={'pages pagination'}
                                                        activeClassName={'active'}
                                                        renderOnZeroPageCount={null}
                                                    />
                                                    <button onClick={handleTeamLastPage} className="btn btn-link">
                                                        <FontAwesomeIcon icon={faAngleDoubleRight} className="icon-color" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div style={{ padding: "0px 100px" }}>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {tabActive === 'Assigned Asset' && (
                                    <div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="custom-container">
                                                <span className="blackTextColor fontFamily fontsize16 fontweightRegular" >
                                                    <span className="show">Show</span>
                                                    <select className="custom-dropdown" value={rowsAttachmentPerPage} onChange={handleRowsPerPageAttachmentChange}>
                                                        {paginateArray.map((n) => (
                                                            <option className="blackTextColor fontFamily fontsize14 fontweightRegular" key={n} value={n}>
                                                                {n}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <span className="blackTextColor entries fontFamily fontsize16 fontweightRegular" >
                                                        Entries
                                                    </span>
                                                    <input
                                                        type="text"
                                                        placeholder="Search..."
                                                        className="searchTextBox"
                                                        value={searchQueryAttachment}
                                                        onChange={handleAttachmentSearchChange}
                                                    />
                                                </span>
                                            </div>
                                            <div className="addbuttondiv">
                                                <select className="fontFamily fontweightRegular fontsize16 exportBtn" name="language" id="language" defaultValue="" >
                                                    <option value="" hidden={true}>Export</option>
                                                    <option value="excel">Excel</option>
                                                    <option value="csv">CSV</option>
                                                    <option value="pdf" >Pdf</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="tableScroll" >
                                            <table className="w-100 mt-2">
                                                <thead>
                                                    <tr className="text-left">
                                                        <th className="fontFamily fontweightMedium fontsize16" style={{ backgroundColor: '#F6F6F6', width: "5%", padding: "2px 2px 2px 3%" }}>
                                                            <CustomCheckBox
                                                                tab="Screens"
                                                                item="selectAll"
                                                                option=""
                                                                handleCheckboxChange={handleSelectAllChange}
                                                                selectAll={selectAll}
                                                            />
                                                        </th>
                                                        <th className="fontFamily fontweightMedium fontsize16 " style={{ backgroundColor: '#F6F6F6', width: "20%", padding: "2px 2px 2px 3%" }}>
                                                            SI.No</th>
                                                        <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6' }}>Asset Name</th>
                                                        <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6' }}>Asset ID</th>
                                                        <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6' }}>Description</th>
                                                        <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6' }}>Status</th>
                                                        <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6' }}>Asset Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredAttachment.length > 0 ? (
                                                        filteredAttachment.slice(pageAttachment * rowsAttachmentPerPage, (pageAttachment + 1) * rowsAttachmentPerPage).map((Attachment, index) => (
                                                            <tr key={Attachment.Rowid} >
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14  ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`} style={{ padding: "2px 2px 2px 4%" }}>{Attachment.Rowid}</td>
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>{Attachment.countryName}</td>
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>{Attachment.statE_NAME}</td>
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>{Attachment.statE_NAME}</td>
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>{Attachment.statE_NAME}</td>
                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>{Attachment.statE_NAME}</td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <td colSpan="9" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between paddingTopShowing">
                                            <div >
                                                <div className="grayTextColor fontFamily fontsize16 fontweightMedium">
                                                    {`Showing ${startAttachmentEntry} to ${endAttachmentEntry} of ${totalAttachment} entries`}
                                                </div>
                                            </div>
                                            <div >
                                                <div className="pagination-container d-flex align-items-center">
                                                    <button onClick={handleAttachmentFirstPage} className="btn btn-link">
                                                        <FontAwesomeIcon icon={faAngleDoubleLeft} className="icon-color" />
                                                    </button>
                                                    <ReactPaginate
                                                        previousLabel={<FontAwesomeIcon icon={faChevronLeft} className="icon-color" />}
                                                        nextLabel={<FontAwesomeIcon icon={faChevronRight} className="icon-color" />}
                                                        breakLabel={'...'}
                                                        breakClassName={'break-me'}
                                                        pageCount={Math.ceil(filteredAttachment.length / rowsAttachmentPerPage)}
                                                        forcePage={pageAttachment}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={5}
                                                        onPageChange={handleAttachmentChangePagePagination}
                                                        containerClassName={'pagination'}
                                                        subContainerClassName={'pages pagination'}
                                                        activeClassName={'active'}
                                                        renderOnZeroPageCount={null}
                                                    />
                                                    <button onClick={handleAttachmentLastPage} className="btn btn-link">
                                                        <FontAwesomeIcon icon={faAngleDoubleRight} className="icon-color" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div style={{ padding: "0px 100px" }}>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <Dialog open={addDesignationTab} >
                                    <DialogTitle className="dialogTitle">
                                        <div className="d-flex justify-content-between">
                                            <div className="fontFamily fontweightMedium fontsize16 d-flex align-items-center">
                                                <span className="fontFamily fontweightMedium fontsize16">Add Designation</span>
                                            </div>
                                            <div>
                                                <a href="#close" onClick={() => closeDesignation()}> <img src={XMarksvgIcon} alt="datesvgIcon" /></a>
                                            </div>
                                        </div>
                                    </DialogTitle>
                                    <hr className="m-2"></hr>
                                    <DialogContent className="dialogContent">
                                        <form className="needs-validation" noValidate onSubmit={handleSubmitDesignation(handleDesignationSubmit)}>
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Designation<span className="mandatoryCss"> *</span></div>
                                            <input
                                                placeholder="Enter Designation"
                                                {...registerDesignation('designationName', { required: 'Please Enter Designation' })}
                                                style={{
                                                    paddingLeft: '12px', backgroundColor: isEmployeeView ? '#FAFAFB' : '#FFFFFF', border: '#00000014 1px solid', borderRadius: '5px', fontFamily: '"Nunito", sans-serif', fontWeight: '400', fontSize: '16px', width: '100%', outline: 'none', height: '32px'
                                                }}
                                                disabled={isEmployeeView}
                                            />
                                            {errorsDesignation.designationName && (
                                                <div className="invalid-feedback" style={{ display: 'block' }}  >
                                                    {errorsDesignation.designationName.message}
                                                </div>
                                            )}
                                            <div className="text-center buttonSpace">
                                                <button type="submit" className="coloredButtonSave">
                                                    Save
                                                </button>
                                                <button type="button" className="outlineButton" onClick={() => closeDesignation()}>
                                                    Close
                                                </button>
                                            </div>
                                        </form>
                                    </DialogContent>
                                </Dialog>

                                <Dialog open={addAttachmentTab} >
                                    <DialogTitle className="dialogTitle">
                                        <div className="d-flex justify-content-between">
                                            <div className="fontFamily fontweightMedium fontsize16 align-items-center">
                                                <span className="fontFamily fontweightMedium fontsize16">Upload Documents</span>
                                            </div>
                                            <div>
                                                <a href="#close" onClick={() => closeAttachment()}> <img src={XMarksvgIcon} alt="datesvgIcon" /></a>
                                            </div>
                                        </div>
                                    </DialogTitle>
                                    <hr className="m-2"></hr>
                                    <DialogContent className="dialogContent">
                                        <form className="needs-validation" noValidate onSubmit={handleSubmitAttachment(handleAttachmentSubmit)}>
                                            <div className="row">
                                                <div className="col-7 col-md-7 col-lg-7 col-sm-12">
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor">
                                                        Document Type
                                                    </div>
                                                    <Controller
                                                        name="documentType"
                                                        control={controlAttachment}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                value={attachmentDropArray?.find(repo => repo.id === attachmentSelectID) || null}
                                                                onChange={(selectedOption) => {
                                                                    setAttachmentSelectID(selectedOption ? selectedOption.id : null);
                                                                    field.onChange(selectedOption ? selectedOption.id : null);
                                                                }}
                                                                options={attachmentDropArray}
                                                                getOptionLabel={(option) => option.name}
                                                                getOptionValue={(option) => option.id}
                                                                isClearable
                                                                isSearchable
                                                                isDisabled={isAttachmentView}
                                                                styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-5 col-md-5 col-lg-5 col-sm-12" style={{ paddingTop: "30px" }}>
                                                    <input
                                                        id="fileInput"
                                                        type="file"
                                                        style={{ display: 'none' }}
                                                        accept=".jpg,.jpeg,.png,.pdf"
                                                        onChange={handleFileChange}
                                                    />
                                                    <button type="button" style={{ cursor: isAttachmentView ? 'not-allowed' : 'pointer' }} disabled={isAttachmentView} className="coloredButtonSave" onClick={() => document.getElementById('fileInput').click()}>
                                                        Browse
                                                    </button>
                                                </div>
                                            </div>

                                            <div style={{ position: 'relative', marginTop: "16px" }}>
                                                <input
                                                    {...registerAttachment('documentLink')}
                                                    style={{
                                                        paddingLeft: '12px', backgroundColor: isAttachmentView ? '#FAFAFB' : '#FFFFFF', border: '#00000014 1px solid', borderRadius: '5px', fontFamily: '"Nunito", sans-serif', fontWeight: '400', fontSize: '16px', width: '100%', outline: 'none', height: '32px'
                                                    }}
                                                    disabled={isAttachmentView}
                                                />
                                                <button
                                                    type="button"
                                                    onClick={clearFileInput}
                                                    disabled={isAttachmentView}
                                                    style={{
                                                        position: 'absolute', right: '8px', top: '50%', transform: 'translateY(-50%)', background: 'none', border: 'none', cursor: isAttachmentView ? 'not-allowed' : 'pointer'
                                                    }}
                                                >
                                                    <img src={XMarksvgIcon} alt="clear" />
                                                </button>
                                            </div>
                                            <div className="fontFamily fontweightRegular" style={{ fontSize: "10px", marginTop: "4px" }}>
                                                Allowed file formats are Pdf, JPG, PNG. Maximum allowed file size is 2MB
                                            </div>
                                            <div className="text-center" style={{ marginTop: "33px" }}>
                                                {!isAttachmentView && (
                                                    <button type="submit" className="coloredButtonSave">
                                                        Save
                                                    </button>)}
                                                <button type="button" className="outlineButton" onClick={() => closeAttachment()}>
                                                    Close
                                                </button>
                                            </div>
                                        </form>
                                    </DialogContent>
                                </Dialog>

                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default EmployeeManagementAdd;
