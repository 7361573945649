import React, { useState, useEffect, useCallback } from 'react';
import { ThreeCircles } from 'react-loader-spinner';
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import CustomCheckBox from './CustomCheckBox';
import LoadingSpinner from '../../../../CommomPages/LoadingSpinner';
import { getBRData } from '../../../../Services/BusinessEntityNewService';
import { getDepartment } from '../../../../Services/DepartmentsAndTeamService';
import { getCategory } from '../../../../Services/AssetCategoriesAndBrandService';
import { insertRoleData, updateRoleData } from '../../../../Services/RoleManagementService';

import './CreateRole.css';


const CreateRole = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [initialRoleData, setInitialRoleData] = useState({});
    const [isViewMode, setIsViewMode] = useState(false);
    const [tabActive, setTabActive] = useState('Screens');
    const [isLoading, setIsLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(true);
    const [txtRoleName, setTxtRoleName] = useState('');
    const [txtDescription, setTxtDescription] = useState('');
    const [roleData, setRoleData] = useState({
        AdminRights: {},
        Asset: {},
        Dashboard: {},
        Events: {},
        Reports: {},
        Screens: {},
    });
    const [allScreens, setAllScreens] = useState([
        { id: 1, name: 'Business Unit' },
        { id: 2, name: 'Department' },
        { id: 3, name: 'Categories' },
    ]);
    const [individualOptions] = useState(['View', 'Add', 'Edit', 'Delete', 'Import', 'Export']);
    const reportsPartOne = ['Standard Reports', 'Asset by Department', 'Asset by Location', 'Asset Depreciation', 'Asset Maintenance'];
    const reportsPartTwo = ['Asset Warranties', 'Asset Contract', 'Asset Check out', 'Asset Users'];
    const dashboardPartOne = ['Standard Reports', 'Custom Dashboard', 'Alert', 'Reminders', 'Notification'];
    const dashboardPartTwo = ['Escalation', 'Task'];
    const [branchArray, setBranchArray] = useState([]);
    const [departmentArray, setDepartmentArray] = useState([]);
    const [categoryArray, setCategoryArray] = useState([]);
    const [RoleIsSubmit] = useState(false);
    const [RoleIsSave] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
        if (location.state?.roleData) {
            const initialRoleData = location.state.roleData;
            console.log('initialRoleData in create role screen', initialRoleData);
            setInitialRoleData(initialRoleData);

            const { rolE_DESC, rolE_Status, rolE_User_Role, rolE_Asset, rolE_DashBoard, rolE_Reports, rolE_Screen } = initialRoleData;
            setTxtRoleName(rolE_User_Role || '');
            setTxtDescription(rolE_DESC || '');
            setIsChecked(rolE_Status === 'Active');
            setRoleData({
                AdminRights: {},
                Asset: JSON.parse(rolE_Asset || '{}'),
                Dashboard: JSON.parse(rolE_DashBoard || '{}'),
                Events: {},
                Reports: JSON.parse(rolE_Reports || '{}'),
                Screens: JSON.parse(rolE_Screen || '{}'),
            });
            setIsViewMode(location.state.isViewMode || false);
        }
    }, [location.state]);

    useEffect(() => {
        const allSelected = allScreens.every(screen =>
            individualOptions.every(option =>
                roleData.Screens?.[screen.name]?.[option]
            )
        );
        setSelectAll(allSelected);
    }, [roleData, allScreens, individualOptions]);

    useEffect(() => {
        getBusinessBranch();
        getDepartmentDetails();
        getCategoryDetails();
    }, []);

    const getBusinessBranch = async () => {
        const BranchData = await getBRData();
        setBranchArray(BranchData);
        setIsLoading(false);
    };

    const getDepartmentDetails = async () => {
        setIsLoading(true);
        const DepartmentData = await getDepartment();
        setDepartmentArray(DepartmentData);
        setIsLoading(false);
    };

    const getCategoryDetails = async () => {
        setIsLoading(true);
        const CategoryData = await getCategory();
        setCategoryArray(CategoryData);
        setIsLoading(false);
    };

    const handleCheckboxChange = useCallback((tab, item, option, newSelectAllState) => {
        if (isViewMode) {
            return;
        }

        if (tab === 'Screens' && item === 'selectAll') {
            setSelectAll(newSelectAllState);
            setRoleData(prevState => {
                const updatedScreens = allScreens.reduce((acc, screen) => {
                    const screenOptions = individualOptions.reduce((opts, opt) => {
                        opts[opt] = newSelectAllState;
                        return opts;
                    }, {});
                    acc[screen.name] = screenOptions;
                    return acc;
                }, {});
                return {
                    ...prevState,
                    Screens: updatedScreens,
                };
            });
        } else if (tab === 'Screens' && option === '') {
            // Handle screen-level checkbox
            setRoleData(prevState => {
                const currentOptions = prevState.Screens[item] || {};
                const allChecked = Object.values(currentOptions).every(value => value);
                const updatedOptions = individualOptions.reduce((opts, opt) => {
                    opts[opt] = !allChecked;
                    return opts;
                }, {});

                return {
                    ...prevState,
                    Screens: {
                        ...prevState.Screens,
                        [item]: updatedOptions,
                    },
                };
            });
        } else {
            // Handle individual option checkbox
            setRoleData(prevState => {
                const updatedScreenOptions = { ...(prevState?.[tab]?.[item] || {}) };
                updatedScreenOptions[option] = !updatedScreenOptions[option];
                const screenChecked = Object.values(updatedScreenOptions).some(value => value === true);

                return {
                    ...prevState,
                    [tab]: {
                        ...(prevState[tab] || {}),
                        [item]: updatedScreenOptions,
                    },
                    Screens: {
                        ...prevState.Screens,
                        [item]: screenChecked ? updatedScreenOptions : {},
                    },
                };
            });
        }
    }, [isViewMode, setRoleData, allScreens, individualOptions]);


    const isFieldInvalid = (field, minLength = 1) => {
        return field === '' || (field.length < minLength && field !== '');
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        filterScreens(event.target.value);
    };

    const filterScreens = (query) => {
        const filtered = allScreens.filter((screen) =>
            screen.name.toLowerCase().includes(query.toLowerCase())
        );
        setAllScreens(filtered);
    };

    const cancelRole = () => {
        navigate('/RolesList');
    }

    const submitRole = async () => {
        if (isViewMode) return;

        if (!txtRoleName && !txtDescription) {
            toast.error('Please enter Role Name and Description.');
            return;
        }

        let screenSelected = false;
        for (const screen of allScreens) {
            if (roleData['Screens'][screen.name]) {
                screenSelected = true;
                break;
            }
        }

        if (!screenSelected) {
            toast.error('Please select at least one screen name with access.');
            return;
        }

        const payload = {
            rolE_ID: (initialRoleData?.rolE_ID) || roleData.rolE_ID || 0,
            rolE_BE: (initialRoleData?.rolE_BE) || roleData.rolE_BE || 6,
            rolE_BU: (initialRoleData?.rolE_BU) || roleData.rolE_BU || 12,
            rolE_DESC: txtDescription || '',
            rolE_Status: isChecked ? 'Active' : 'Inactive',
            rolE_User_Role: txtRoleName,
            rolE_Admin_Rights: '',
            rolE_Asset: JSON.stringify(roleData['Asset']) || '',
            rolE_DashBoard: JSON.stringify(roleData['Dashboard']) || '',
            rolE_Events: '',
            rolE_Reports: JSON.stringify(roleData['Reports']) || '',
            rolE_Screen: JSON.stringify(roleData['Screens']) || '',
        };

        try {
            setIsLoading(true);
            let response;
            if (initialRoleData.rolE_ID) {
                console.log('updating the Role', payload);
                response = await updateRoleData(payload);
            } else {
                console.log('inserting the Role', payload);
                response = await insertRoleData(payload);
            }

            if (response === 'Updated' || response === 'Inserted') {
                toast.success('Role saved successfully!');
                setTimeout(() => navigate("/RolesList"), 500);
            } else {
                console.log('Response from insert/update role data', response);
                toast.error('Failed to save role.');
            }
        } catch (error) {
            console.error('Error while inserting/updating role data', error);
            toast.error('Error saving role.');
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <div style={{ height: "100vh", width: "100%", backgroundColor: '#FAFAFB' }}>
            <ToastContainer theme="colored" />
            {isLoading && <LoadingSpinner />}

            {!isLoading && (
                <div>
                    <div className="pagetitleRole">
                        <div className="Pageheader fontFamily fontsize24 fontweightSemiBold">
                            User Management - Role Management
                            <div>
                                <InfoOutlinedIcon />
                            </div>
                        </div>
                    </div>

                    <div className="card mt-4 customScrollbar">
                        <div className="card-body cardBodyCustom">
                            <div className="d-flex align-items-left">
                                <div className="blackTextColor fontFamily fontsize20 fontweightMedium">
                                    Role Management
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="col-6 col-md-6 col-lg-6 col-sm-12" style={{ paddingBottom: '20px', paddingLeft: '20px', paddingTop: '10px' }}>
                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor">
                                        Role Name<span className="mandatoryCss"> *</span>
                                    </div>
                                    <input
                                        type="text"
                                        value={txtRoleName}
                                        placeholder="Role Name"
                                        onChange={(e) => setTxtRoleName(e.target.value)}
                                        disabled={isViewMode}
                                        style={{
                                            paddingLeft: '12px',
                                            backgroundColor: '#FFFFFF',
                                            border: '#00000014 1px solid',
                                            borderRadius: '3px',
                                            fontFamily: '"Nunito", sans-serif',
                                            fontWeight: '400',
                                            fontSize: '14px',
                                            width: '476px',
                                            outline: 'none',
                                            height: '45px'
                                        }}
                                    />
                                    {RoleIsSubmit && isFieldInvalid(txtRoleName) && (
                                        <div className="fontFamily fontweightRegular fontsize16 validationError" style={{ paddingLeft: '20px' }}>
                                            Please Enter Role Name
                                        </div>
                                    )}
                                </div>

                                <div>
                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor">
                                        Status
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                                        <a href='#Status'
                                            className="switch-container"
                                            onClick={!isViewMode ? () => setIsChecked(prev => !prev) : undefined}
                                            style={{ cursor: isViewMode ? 'not-allowed' : 'pointer' }}
                                        >
                                            <div className={`switch ${isChecked ? 'active' : ''}`}>
                                                <div className="handle"></div>
                                            </div>
                                        </a>
                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ paddingLeft: '10px' }}>
                                            {isChecked ? 'Active' : 'Inactive'}
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="col-6 col-md-6 col-lg-6 col-sm-12" style={{ paddingBottom: '20px', paddingLeft: '20px' }}>
                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor">
                                    Description
                                </div>
                                <input
                                    type="text"
                                    value={txtDescription}
                                    placeholder="Description"
                                    onChange={(e) => setTxtDescription(e.target.value)}
                                    disabled={isViewMode}
                                    style={{
                                        paddingLeft: '12px',
                                        backgroundColor: '#FFFFFF',
                                        border: '#00000014 1px solid',
                                        borderRadius: '3px',
                                        fontFamily: '"Nunito", sans-serif',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        width: '476px',
                                        outline: 'none',
                                        height: '45px'
                                    }}
                                />
                                {RoleIsSubmit && isFieldInvalid(txtDescription) && (
                                    <div className="fontFamily fontweightRegular fontsize16 validationError" style={{ paddingLeft: '20px' }}>
                                        Please Enter Description
                                    </div>
                                )}
                            </div>

                            <div className="RoleBoxShadow">
                                <div className="HeaderTab">
                                    {['Screens', 'Asset', 'Events', 'Reports', 'Dashboard', 'Admin Rights'].map(tab => (
                                        <a
                                            key={tab}
                                            href={`#${tab.replace(' ', '')}`}
                                            className={`${tabActive === tab ? 'activeTab' : 'InactiveTab'}`}
                                            onClick={() => setTabActive(tab)}
                                        >
                                            <div className="fontFamily fontweightRegular fontsize20">
                                                {tab}
                                            </div>
                                        </a>
                                    ))}
                                </div>

                                {tabActive === 'Screens' && (
                                    <div>
                                        <div style={{ overflow: 'auto', height: 'auto', marginBottom: '20px', marginTop: '10px' }}>
                                            <div style={{ padding: '1px' }}>
                                                <div className="d-flex align-items-center justify-content-end">
                                                    <input
                                                        type="text"
                                                        placeholder="Search..."
                                                        value={searchQuery}
                                                        onChange={handleSearchChange}
                                                        className="searchTextBox marginLeftSearchBox"
                                                    />
                                                </div>
                                            </div>
                                            <div className="tableScroll" style={{ height: '233px', overflowY: 'auto', scrollbarWidth: 'thin' }}>
                                                <table className="w-100 mt-2">
                                                    <thead>
                                                        <tr className="text-left">
                                                            <th className="blackTextColor fontFamily fontweightMedium fontsize16" style={{ backgroundColor: '#F6F6F6', width: "10%", padding: '2px 2px 2px 3%' }}>
                                                                <CustomCheckBox
                                                                    tab="Screens"
                                                                    item="selectAll"
                                                                    option=""
                                                                    roleData={roleData}
                                                                    isViewMode={isViewMode}
                                                                    handleCheckboxChange={handleCheckboxChange}
                                                                    selectAll={selectAll}
                                                                />
                                                            </th>
                                                            <th className="blackTextColor fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "10%" }}>
                                                                Screen Name
                                                            </th>
                                                            {['View All', 'Add All', 'Edit All', 'Delete All', 'Import All', 'Export All'].map(option => (
                                                                <th key={option} className="blackTextColor fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "10%" }}>
                                                                    {option}
                                                                </th>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {allScreens.map((screen, index) => (
                                                            <tr key={screen.id}>
                                                                <td className={`grayTextColor fontFamily fontsize16 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`} style={{ padding: "2px 2px 2px 3%" }}>
                                                                    <CustomCheckBox
                                                                        tab="Screens"
                                                                        item={screen.name}
                                                                        option="" // Screen level checkbox
                                                                        roleData={roleData}
                                                                        isViewMode={isViewMode}
                                                                        handleCheckboxChange={handleCheckboxChange}
                                                                    />
                                                                </td>
                                                                <td className={`fontFamily fontsize16 grayTextColor p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>
                                                                    {screen.name}
                                                                </td>
                                                                {individualOptions.map(option => (
                                                                    <td key={`${screen.id}_${option}`} className={`option-item fontFamily fontsize16 p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`}>
                                                                        <CustomCheckBox
                                                                            tab="Screens"
                                                                            item={screen.name}
                                                                            option={option} // Individual option checkbox
                                                                            roleData={roleData}
                                                                            isViewMode={isViewMode}
                                                                            handleCheckboxChange={handleCheckboxChange}
                                                                        />
                                                                        <span className={`optionLabel grayTextColor p-2 ${index % 2 === 0 ? '' : 'tablerowBackgroundColor'}`} style={{ marginLeft: '10px' }}>{option}</span>
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {tabActive === 'Asset' && (
                                    <div>
                                        <div className="accessMapping">
                                            <div className="mt-3">
                                                <div className="fontFamily fontweightRegular fontsize16 blackTextColor" style={{ marginRight: '30px' }}>
                                                    Asset Access Mapping:
                                                </div>
                                            </div>
                                            <div className="mt-3" style={{ marginRight: '30px' }}>
                                                <div className="d-flex align-items-center">
                                                    <CustomCheckBox
                                                        tab="Asset"
                                                        item="Access to own Assets"
                                                        option=""
                                                        roleData={roleData}
                                                        isViewMode={isViewMode}
                                                        handleCheckboxChange={handleCheckboxChange}
                                                        className={"customCheckBox customCheckBoxAsset"}
                                                    />
                                                    <div className="fontFamily fontweightRegular fontsize16 blackTextColor" style={{ marginLeft: '10px' }}>
                                                        Access to own Assets
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-3">
                                                <div className="d-flex align-items-center">
                                                    <CustomCheckBox
                                                        tab="Asset"
                                                        item="Others"
                                                        option=""
                                                        roleData={roleData}
                                                        isViewMode={isViewMode}
                                                        handleCheckboxChange={handleCheckboxChange}
                                                        className={"customCheckBox customCheckBoxAsset"}
                                                    />
                                                    <div className="fontFamily fontweightRegular fontsize16 blackTextColor" style={{ marginLeft: '10px' }}>
                                                        Others
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="customCardsAsset" style={{ display: 'flex', alignItems: 'center' }}>
                                            <div className="card mt-4" style={{ width: '309px', height: '279px', marginRight: '120px', overflowY: 'auto', scrollbarWidth: 'thin' }}>
                                                <div className="card-body">
                                                    <div style={{ display: 'flex', alignItems: 'center', marginRight: '50px' }}>
                                                        <CustomCheckBox
                                                            tab="Asset"
                                                            item={branchArray}
                                                            option=""
                                                            roleData={roleData}
                                                            isViewMode={isViewMode}
                                                            handleCheckboxChange={handleCheckboxChange}
                                                        />
                                                        <div className="fontFamily fontsize20 text-center blackTextColor" style={{ marginLeft: '10px' }}>
                                                            Branch
                                                        </div>
                                                    </div>
                                                    {branchArray.map((branch) => (
                                                        <div key={branch.bR_ID} style={{ display: 'flex', alignItems: 'center', marginRight: '50px', marginTop: '10px' }}>
                                                            <CustomCheckBox
                                                                tab="Asset"
                                                                item={branch.bR_NAME}
                                                                option=""
                                                                roleData={roleData}
                                                                isViewMode={isViewMode}
                                                                handleCheckboxChange={handleCheckboxChange}
                                                            />
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ marginLeft: '40px' }}>
                                                                {branch.bR_NAME}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="card mt-4" style={{ width: '329px', height: '279px', marginRight: '120px', overflowY: 'auto', scrollbarWidth: 'thin' }}>
                                                <div className="card-body cardBodyCustomAsset">
                                                    <div style={{ display: 'flex', alignItems: 'center', marginRight: '50px' }}>
                                                        <CustomCheckBox
                                                            tab="Asset"
                                                            item={departmentArray}
                                                            option=""
                                                            roleData={roleData}
                                                            isViewMode={isViewMode}
                                                            handleCheckboxChange={handleCheckboxChange}
                                                        />
                                                        <div className="fontFamily fontsize20 text-center blackTextColor" style={{ marginLeft: '10px' }}>
                                                            Department
                                                        </div>
                                                    </div>
                                                    {departmentArray.map((department) => (
                                                        <div key={department.id} style={{ display: 'flex', alignItems: 'center', marginRight: '50px', marginTop: '10px' }}>
                                                            <CustomCheckBox
                                                                tab="Asset"
                                                                item={department.departmentName}
                                                                option=""
                                                                roleData={roleData}
                                                                isViewMode={isViewMode}
                                                                handleCheckboxChange={handleCheckboxChange}
                                                            />
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ marginLeft: '40px' }}>
                                                                {department.departmentName}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="card mt-4" style={{ width: '329px', height: '279px', overflowY: 'auto', scrollbarWidth: 'thin' }}>
                                                <div className="card-body cardBodyCustomAsset">
                                                    <div style={{ display: 'flex', alignItems: 'center', marginRight: '50px' }}>
                                                        <CustomCheckBox
                                                            tab="Asset"
                                                            item={categoryArray}
                                                            option=""
                                                            roleData={roleData}
                                                            isViewMode={isViewMode}
                                                            handleCheckboxChange={handleCheckboxChange}
                                                        />
                                                        <div className="fontFamily fontsize20 text-center blackTextColor" style={{ marginLeft: '10px' }}>
                                                            Asset Category
                                                        </div>
                                                    </div>
                                                    {categoryArray.map((category) => (
                                                        <div key={category.id} style={{ display: 'flex', alignItems: 'center', marginRight: '50px', marginTop: '10px' }}>
                                                            <CustomCheckBox
                                                                tab="Asset"
                                                                item={category.categoryName}
                                                                option=""
                                                                roleData={roleData}
                                                                isViewMode={isViewMode}
                                                                handleCheckboxChange={handleCheckboxChange}
                                                            />
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ marginLeft: '40px' }}>
                                                                {category.categoryName}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="fontFamily fontweightRegular fontsize20 grayTextColor" style={{ marginLeft: '10px', marginTop: '20px', marginBottom: '20px' }}>
                                            *Please make sure to define ‘Branch’ and ‘Departments’ for all in the Employees’ list.
                                        </div>
                                        <div className="blackTextColor fontFamily fontsize20 fontweightMedium" style={{ marginLeft: '10px', marginTop: '20px', marginBottom: '20px' }}>
                                            View and Edit Asset
                                        </div>
                                        <div className="fontFamily fontweightRegular fontsize20 grayTextColor" style={{ marginLeft: '10px', marginTop: '20px', marginBottom: '20px' }}>
                                            Set user’s permission to add / edit / delete / view assets:
                                        </div>

                                        <div className='customCardsAsset' style={{ display: 'flex', alignItems: 'center' }}>
                                            <div className="card mt-4" style={{ width: '210px', height: '247px', marginRight: '40px' }}>
                                                <div className="card-body smallCardBodyCustomAsset">
                                                    <div style={{ display: 'flex', alignItems: 'center', marginRight: '50px' }}>
                                                        <div className="fontFamily fontsize16 text-center">
                                                            <CustomCheckBox
                                                                tab="Asset"
                                                                item="viewOnly"
                                                                option=""
                                                                roleData={roleData}
                                                                isViewMode={isViewMode}
                                                                handleCheckboxChange={handleCheckboxChange}
                                                            />
                                                        </div>
                                                        <div className="blackTextColor fontFamily fontsize20 fontweightMedium text-center" style={{ marginLeft: '10px' }}>
                                                            View Only
                                                        </div>
                                                    </div>
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ marginLeft: '10px', marginTop: '20px', marginBottom: '20px' }}>
                                                        User can only view assets
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="fontFamily fontsize16 text-center">
                                                            <CustomCheckBox
                                                                tab="Asset"
                                                                item="hidePriceInfo"
                                                                option=""
                                                                roleData={roleData}
                                                                isViewMode={isViewMode}
                                                                handleCheckboxChange={handleCheckboxChange}
                                                            />
                                                        </div>
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ marginLeft: '10px' }}>
                                                            Hide Price info.
                                                        </div>
                                                    </div>
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ marginLeft: '10px', marginBottom: '20px' }}>
                                                        (This option will also curtail any Editing privileges allowed elsewhere)
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card mt-4" style={{ width: '210px', height: '247px', marginRight: '40px' }}>
                                                <div className="card-body smallCardBodyCustomAsset">
                                                    <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                                                        <div className="fontFamily fontsize16 text-center">
                                                            <CustomCheckBox
                                                                tab="Asset"
                                                                item="addAssets"
                                                                option=""
                                                                roleData={roleData}
                                                                isViewMode={isViewMode}
                                                                handleCheckboxChange={handleCheckboxChange}
                                                            />
                                                        </div>
                                                        <div className="blackTextColor fontFamily fontsize20 fontweightMedium text-center" style={{ marginLeft: '10px' }}>
                                                            Add Assets
                                                        </div>
                                                    </div>
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ marginLeft: '10px', marginTop: '20px', marginBottom: '20px' }}>
                                                        User can view and create new assets. User can edit or delete Self-Created assets.
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card mt-4" style={{ width: '210px', height: '247px', marginRight: '40px' }}>
                                                <div className="card-body smallCardBodyCustomAsset">
                                                    <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                                                        <div className="fontFamily fontsize16 text-center">
                                                            <CustomCheckBox
                                                                tab="Asset"
                                                                item="editAssets"
                                                                option=""
                                                                roleData={roleData}
                                                                isViewMode={isViewMode}
                                                                handleCheckboxChange={handleCheckboxChange}
                                                            />
                                                        </div>
                                                        <div className="blackTextColor fontFamily fontsize20 fontweightMedium text-center" style={{ marginLeft: '10px' }}>
                                                            Edit Assets
                                                        </div>
                                                    </div>
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ marginLeft: '10px', marginTop: '20px', marginBottom: '20px' }}>
                                                        User can view and edit ANY assets
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card mt-4" style={{ width: '210px', height: '247px', marginRight: '40px' }}>
                                                <div className="card-body smallCardBodyCustomAsset">
                                                    <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                                                        <div className="fontFamily fontsize16 text-center">
                                                            <CustomCheckBox
                                                                tab="Asset"
                                                                item="deleteAssets"
                                                                option=""
                                                                roleData={roleData}
                                                                isViewMode={isViewMode}
                                                                handleCheckboxChange={handleCheckboxChange}
                                                            />
                                                        </div>
                                                        <div className="blackTextColor fontFamily fontsize20 fontweightMedium text-center" style={{ marginLeft: '10px' }}>
                                                            Delete Assets
                                                        </div>
                                                    </div>
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ marginLeft: '10px', marginTop: '20px', marginBottom: '20px' }}>
                                                        User can view and delete ANY assets
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card mt-4" style={{ width: '210px', height: '247px' }}>
                                                <div className="card-body smallCardBodyCustomAsset">
                                                    <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                                                        <div className="fontFamily fontsize16 text-center">
                                                            <CustomCheckBox
                                                                tab="Asset"
                                                                item="importAssets"
                                                                option=""
                                                                roleData={roleData}
                                                                isViewMode={isViewMode}
                                                                handleCheckboxChange={handleCheckboxChange}
                                                            />
                                                        </div>
                                                        <div className="blackTextColor fontFamily fontsize20 fontweightMedium text-center" style={{ marginLeft: '10px' }}>
                                                            Import Assets
                                                        </div>
                                                    </div>
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ marginLeft: '10px', marginTop: '20px', marginBottom: '20px' }}>
                                                        User can import assets
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                                                        <div className="fontFamily fontsize16 text-center">
                                                            <CustomCheckBox
                                                                tab="Asset"
                                                                item="exportAssets"
                                                                option=""
                                                                roleData={roleData}
                                                                isViewMode={isViewMode}
                                                                handleCheckboxChange={handleCheckboxChange}
                                                            />
                                                        </div>
                                                        <div className="blackTextColor fontFamily fontsize20 fontweightMedium text-center" style={{ marginLeft: '10px' }}>
                                                            Export Assets
                                                        </div>
                                                    </div>
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ marginLeft: '10px', marginTop: '20px', marginBottom: '20px' }}>
                                                        User can export assets
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {tabActive === 'Reports' && (
                                    <div>
                                        <div className="mt-3">
                                            <div className="fontFamily fontweightMedium fontsize20 blackTextColor">
                                                Reports
                                            </div>
                                            <div className="customFlexbox">
                                                <div className="column" style={{ marginRight: '250px', marginLeft: '20px' }}>
                                                    {reportsPartOne.map((report) => (
                                                        <div key={report} className="d-flex align-items-center mt-2">
                                                            <CustomCheckBox
                                                                tab="Reports"
                                                                item={report}
                                                                option=""
                                                                roleData={roleData}
                                                                isViewMode={isViewMode}
                                                                handleCheckboxChange={handleCheckboxChange}
                                                            />
                                                            <label htmlFor={`report_${report}`} className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ marginLeft: '20px' }}>
                                                                {report}
                                                            </label>
                                                        </div>
                                                    ))}

                                                </div>
                                                <div className="column" style={{ marginBottom: '34px', marginLeft: '20px' }}>
                                                    {reportsPartTwo.map((report) => (
                                                        <div key={report} className="d-flex align-items-center mt-2">
                                                            <CustomCheckBox
                                                                tab="Reports"
                                                                item={report}
                                                                option=""
                                                                roleData={roleData}
                                                                isViewMode={isViewMode}
                                                                handleCheckboxChange={handleCheckboxChange}
                                                            />
                                                            <label htmlFor={`report_${report + 5}`} className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ marginLeft: '20px' }}>
                                                                {report}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {tabActive === 'Dashboard' && (
                                    <div>
                                        <div className="mt-3">
                                            <div className="fontFamily fontweightMedium fontsize20 blackTextColor">
                                                Dashboard
                                            </div>
                                            <div className="customFlexbox">
                                                <div className="column" style={{ marginRight: '250px', marginLeft: '20px' }}>
                                                    {dashboardPartOne.map((Dashboard) => (
                                                        <div key={Dashboard} className="d-flex align-items-center mt-2">
                                                            <CustomCheckBox
                                                                tab="Dashboard"
                                                                item={Dashboard}
                                                                option=""
                                                                roleData={roleData}
                                                                isViewMode={isViewMode}
                                                                handleCheckboxChange={handleCheckboxChange}
                                                            />
                                                            <label htmlFor={`Dashboard_${Dashboard}`} className="ml-2 fontFamily fontweightRegular fontsize16 grayTextColor" style={{ marginLeft: '20px' }}>
                                                                {Dashboard}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="column" style={{ marginBottom: '95px', marginLeft: '20px' }}>
                                                    {dashboardPartTwo.map((Dashboard) => (
                                                        <div key={Dashboard} className="d-flex align-items-center mt-2">
                                                            <CustomCheckBox
                                                                tab="Dashboard"
                                                                item={Dashboard}
                                                                option=""
                                                                roleData={roleData}
                                                                isViewMode={isViewMode}
                                                                handleCheckboxChange={handleCheckboxChange}
                                                            />
                                                            <label htmlFor={`Dashboard_${Dashboard + 5}`} className="ml-2 fontFamily fontweightRegular fontsize16 grayTextColor" style={{ marginLeft: '20px' }}>
                                                                {Dashboard}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            {tabActive !== 'Events' && tabActive !== 'Admin Rights' && (
                                <div className="saveAndSubmit">
                                    {!isViewMode && (
                                        <button type="button" className="buttonSave fontFamily fontweightRegular fontsize16 coloredButton" onClick={submitRole}>
                                            {RoleIsSave ? (
                                                <ThreeCircles
                                                    visible={true}
                                                    height="25"
                                                    width="47"
                                                    color="White"
                                                    ariaLabel="three-circles-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                />
                                            ) : (
                                                'Save'
                                            )}
                                        </button>
                                    )}

                                    <button
                                        type="button"
                                        id="btncloseModalBranch"
                                        className="buttonClose fontFamily fontweightRegular fontsize16 outlineButton"
                                        style={{ marginLeft: '5px' }}
                                        onClick={cancelRole}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CreateRole;
